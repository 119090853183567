
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const CREATE_STORE_START = 'CREATE_STORE_START';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAIL = 'CREATE_STORE_FAIL';

export const CREATE_PACKAGE_START = 'CREATE_PACKAGE_START';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';

export const CREATE_TYPE_START = 'CREATE_TYPE_START';
export const CREATE_TYPE_SUCCESS = 'CREATE_TYPE_SUCCESS';
export const CREATE_TYPE_FAIL = 'CREATE_TYPE_FAIL';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

