import { Col, Progress } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card/index';

export const ScoringsCardStyles = styled(Card)`
	.ant-card-body {
		min-height: 215px;
		padding-left: 30px;
		padding-right: 30px;
	}
`;
export const CustomProgress = styled(Progress)`
	width: 100%;
	.ant-progress-text {
		font-size: 16px;
		font-family: Roboto;
		color: #424242;
	}
`;
export const Content = styled(Col)`
	min-height: 215px;

	display: flex;
	flex-direction: column;
	align-items: middle;
	justify-content: space-between;
	padding-bottom: 45px;
	.ant-progress {
		display: flex;
	}
`;

export const Img = styled.img`
	@media (max-width: 992px) {
		width: 17px;
		height: 18px;
	}
`;
