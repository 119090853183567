/* eslint-disable no-unused-vars */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { LoginCard, LoginStyles } from './styles';
import { Text } from '../../components/Typography/styles';
import LoginForm from './LoginForm';

const LoginPage = () => {
	const userUser = useSelector((state) => state.authStore.user);
	const states = useSelector((state) => state);

	return userUser ? (
		<Redirect to='/admin' />
	) : (
		<LoginStyles justify='center' align='middle'>
			<Col xs={20} sm={16} md={16} lg={14} xl={10} xxl={8}>
				<LoginCard type='tertiary'>
					<Row className='vertical-row' align='middle' justify='center'>
						<Text fontSize='extra-big' fontWeight='medium'>
							Welcome !
						</Text>
						<Text fontWeight='medium' style={{ marginTop: '6px' }}>
							Sign in to access your account.
						</Text>
					</Row>

					<Row justify='center' align='middle'>
						<LoginForm />
					</Row>
				</LoginCard>
			</Col>
		</LoginStyles>
	);
};

export default LoginPage;
