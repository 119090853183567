import { useState } from 'react';
import {
  InfoContent,
  InfoItem,
  CancelButton,
  SendButton,
  Input,
  EditButton,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col } from 'antd';
import { useDispatch } from 'react-redux';
import {
  updatePendingPacket,
  getPendings,
  deletePendingPacket,
} from '../../../../../services/Redux/PendingRestaurants/api';

const PacketModal = ({ packetId, custom, sale }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [customPrice, setCustomPrice] = useState(custom);
  const [salePrice, setSalePrice] = useState(sale);

  const updatePacket = async () => {
    const formData = new FormData();

    formData.append('min_order_price', customPrice);
    formData.append('min_discounted_order_price', salePrice);

    await dispatch(updatePendingPacket(packetId, formData));
    dispatch(getPendings());
  };

  const handleDelete = async () => {
    await dispatch(deletePendingPacket(packetId));
    dispatch(getPendings());
  };

  return (
    <InfoContent>
      <Col span={24}>
        <Text weight="bold">Taahüt Edilen Paket Fiyatı:</Text>
      </Col>

      <Col span={24}>
        <InfoItem gutter={[32, 0]}>
          <Col span={3}>
            <Text weight="bold">Normal Fiyat</Text>
            <Input
              isopen={disabled ? 0 : 1}
              disabled={disabled}
              value={customPrice}
              suffix={'TL'}
              onChange={(event) => {
                const tmp = parseInt(event.target.value);
                if (Number.isNaN(tmp)) {
                  setCustomPrice(0);
                } else {
                  setCustomPrice(parseInt(tmp));
                }
              }}
            />
          </Col>

          <Col span={3}>
            <Text weight="bold">Satış Fiyatı</Text>
            <Input
              isopen={disabled ? 0 : 1}
              disabled={disabled}
              value={salePrice}
              suffix={'TL'}
              onChange={(event) => {
                const tmp = parseInt(event.target.value);
                if (Number.isNaN(tmp)) {
                  setSalePrice(0);
                } else {
                  setSalePrice(parseInt(tmp));
                }
              }}
            />
          </Col>
          <Col span={4} offset={6}>
            <CancelButton onClick={() => handleDelete()}>
              <Text color="red" weight="semi-bold" size="nobleRegular">
                İptal Et
              </Text>
            </CancelButton>
          </Col>
          <Col span={4}>
            <EditButton type="secondary" onClick={() => setDisabled(!disabled)}>
              <Text color="yellow" weight="semi-bold" size="nobleRegular">
                Düzenle
              </Text>
            </EditButton>
          </Col>
          <Col span={4}>
            <SendButton type="secondary" onClick={() => updatePacket()}>
              <Text color="white" weight="semi-bold" size="nobleRegular">
                Onayla
              </Text>
            </SendButton>
          </Col>
        </InfoItem>
      </Col>
    </InfoContent>
  );
};

export default PacketModal;
