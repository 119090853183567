/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { TableStyle, Item, EditButton, CancelButton } from "./styles";
import { message } from "antd";
import moment from "moment";
import { Text } from "../../../components/Typography/styles";
import editImg from "../../../../Assets/components/edit-btn.svg";
import cancelImg from "../../../../Assets/components/cancel-btn.svg";
import xmlFetch from "../../../../services/Api/request";
import AlertModal from "../AlertModal";
import { Row, Col } from "antd";
import {
  getActiveUsers,
  getSuspendUsers,
} from "../../../../services/Redux/Users/api";
import { useDispatch } from "react-redux";

const TableItem = ({ user, roles, setSelectedUser, setSelectedRole }) => {
  const dispatch = useDispatch();
  const [permissionName, setPermissionName] = useState("Yetki yok");
  const [roleName, setRoleName] = useState("Rol yok");

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleCancel = () => {
    setIsAlertVisible(false);
  };

  useEffect(() => {
    if (user.admin_role !== null) {
      const selectedRolePermissions = roles.find(
        (val) => val.id === user.admin_role
      );

      if (selectedRolePermissions) {
        const { permission, role } = selectedRolePermissions;

        setRoleName(role);

        setPermissionName(permission.map(({ permission }) => permission));
      }
    }
  }, [user]);

  const deleteUserRole = async () => {
    await xmlFetch({
      path: `user/${user.id}/`,
      method: "PATCH",
      sendToken: true,
      body: {
        admin_role: null,
      },
    })
      .then(() => {
        setRoleName("Rol yok");
        setPermissionName("Yetki yok");
        message.success("Kullanıcı Yetkileri Başarı ile Silindi. !");
        dispatch(getActiveUsers());
        dispatch(getSuspendUsers());
      })
      .catch(() => {
        setRoleName("Rol yok");
        setPermissionName("Yetki yok");
        message.error("Kullanıcı Yetkileri Silinirken bir Hata Oluştu. !");
      });
  };

  const handleEdit = () => {
    setSelectedUser(user);
    setSelectedRole(roles?.find((item) => item?.id === user?.admin_role));
  };

  return (
    <>
      <TableStyle>
        <Item
          span={1}
          style={{
            alignItems: "center",
            border: "0px",
            justifyContent: "center",
          }}
        >
          <Text size="smallRegular" weight="light">
            {user.id}
          </Text>
        </Item>
        <Item
          span={4}
          style={{ alignItems: "center", justifyContent: "start" }}
        >
          <Text weight="semi-bold" style={{ marginLeft: "12px" }}>
            {user.first_name} {user.last_name}
          </Text>
        </Item>
        <Item
          md={4}
          lg={4}
          xl={5}
          xxl={5}
          style={{
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "12px",
          }}
        >
          <Text size="smallRegular" weight="light">
            {user.email}
          </Text>
        </Item>
        <Item
          span={2}
          style={{
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "12px",
          }}
        >
          <Text size="smallRegular" weight="light">
            {roleName}
          </Text>
        </Item>
        <Item
          span={5}
          style={{
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "12px",
          }}
        >
          <Text size="smallRegular" weight="light">
            {permissionName}
          </Text>
        </Item>
        <Item
          span={3}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "12px",
          }}
        >
          <Text size="smallRegular" weight="light">
            {moment(user.created_at).format("DD MMMM  YYYY")}
          </Text>
          <Text size="smallRegular" weight="light">
            {moment(user.created_at).format("ddd  HH:mm")}
          </Text>
        </Item>
        <Item
          span={2}
          style={{
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "12px",
          }}
        >
          <Text size="semi-bold" color={user?.is_active ? "green" : "red"}>
            {user?.is_active ? "Aktif" : "Askıda"}
          </Text>
        </Item>
        <Item
          md={3}
          lg={3}
          xl={2}
          xxl={2}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <EditButton src={editImg} onClick={handleEdit} />
            </Col>
            <Col span={12}>
              <CancelButton
                src={cancelImg}
                onClick={() => setIsAlertVisible(true)}
              />
            </Col>
          </Row>
        </Item>
        <AlertModal
          deleteRole={deleteUserRole}
          visible={isAlertVisible}
          onCancel={handleCancel}
        />
      </TableStyle>
    </>
  );
};

export default TableItem;
