import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	subCategories: null,
	subCategoriesError: null,

	faqList: null,
	faqListError: null,

	createFaq: null,
	createFaqError: null,

	updateFaq: null,
	updateFaqError: null,

	deleteFaq: null,
	deleteFaqError: null,

	contact: null,
	contactError: null,

	updateContact: null,
	updateContactError: null,
};

const getSubCategoriesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getSubCategoriesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	subCategories: action.payload.results,
});

const getSubCategoriesFailReducer = (state, action) => ({
	...state,
	loading: false,
	subCategoriesError: action.payload,
});

//CREATE

const createFaqStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createFaqSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createFaq: action.payload,
});

const createFaqFailReducer = (state, action) => ({
	...state,
	loading: false,
	createFaqError: action.payload,
});

//GET

const getFaqStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getFaqSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	faqList: action.payload.results,
});

const getFaqFailReducer = (state, action) => ({
	...state,
	loading: false,
	faqListError: action.payload,
});

//UPDATE FAQ

const updateFaqStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateFaqSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateFaq: action.payload,
});

const updateFaqFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateFaqError: action.payload,
});

// DELETE FAQ

const deleteFaqStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteFaqSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteFaq: action.payload,
});

const deleteFaqFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteFaqError: action.payload,
});

//GET CONTACT

const getContactStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getContactSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	contact: action.payload,
});

const getContactFailReducer = (state, action) => ({
	...state,
	loading: false,
	contactError: action.payload,
});

// UPDATE CONTACT
const updateContactStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateContactSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateContact: action.payload,
});

const updateContactFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateContactError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SUB_CATEGORIES_START:
			return getSubCategoriesStartReducer(state);
		case actionTypes.GET_SUB_CATEGORIES_SUCCESS:
			return getSubCategoriesSuccessReducer(state, action);
		case actionTypes.GET_SUB_CATEGORIES_FAIL:
			return getSubCategoriesFailReducer(state, action);
		case actionTypes.CREATE_FAQ_START:
			return createFaqStartReducer(state);
		case actionTypes.CREATE_FAQ_SUCCESS:
			return createFaqSuccessReducer(state, action);
		case actionTypes.CREATE_FAQ_FAIL:
			return createFaqFailReducer(state, action);

		case actionTypes.GET_FAQ_START:
			return getFaqStartReducer(state);
		case actionTypes.GET_FAQ_SUCCESS:
			return getFaqSuccessReducer(state, action);
		case actionTypes.GET_FAQ_FAIL:
			return getFaqFailReducer(state, action);

		case actionTypes.UPDATE_FAQ_START:
			return updateFaqStartReducer(state);
		case actionTypes.UPDATE_FAQ_SUCCESS:
			return updateFaqSuccessReducer(state, action);
		case actionTypes.UPDATE_FAQ_FAIL:
			return updateFaqFailReducer(state, action);

		case actionTypes.DELETE_FAQ_START:
			return deleteFaqStartReducer(state);
		case actionTypes.DELETE_FAQ_SUCCESS:
			return deleteFaqSuccessReducer(state, action);
		case actionTypes.DELETE_FAQ_FAIL:
			return deleteFaqFailReducer(state, action);

		case actionTypes.GET_CONTACT_START:
			return getContactStartReducer(state);
		case actionTypes.GET_CONTACT_SUCCESS:
			return getContactSuccessReducer(state, action);
		case actionTypes.GET_CONTACT_FAIL:
			return getContactFailReducer(state, action);

		case actionTypes.UPDATE_CONTACT_START:
			return updateContactStartReducer(state);
		case actionTypes.UPDATE_CONTACT_SUCCESS:
			return updateContactSuccessReducer(state, action);
		case actionTypes.UPDATE_CONTACT_FAIL:
			return updateContactFailReducer(state, action);
		default:
			return state;
	}
};

export default reducer;
