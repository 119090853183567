import { useState } from 'react';
import { Text } from '../../../../../../../components/Typography/styles';
import { PacketItemStyles, CancelButton, SendButton, SoldButton } from './styles';
import { Col, Row } from 'antd';
import AlertModal from '../AlertModal';
import { useDispatch } from 'react-redux';
import {
	deleteRestaurantsPacket,
	getRestaurantDetail,
} from '../../../../../../../../services/Redux/RegisteredRestaurants/api';

const PacketItem = ({ id, name, defined, sold, storeId }) => {
	const dispatch = useDispatch();

	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const handleCancel = () => {
		setIsAlertVisible(false);
	};

	const deletePacket = async () => {
		await dispatch(deleteRestaurantsPacket(storeId, id));
		await dispatch(getRestaurantDetail(storeId));
	};

	return (
		<PacketItemStyles>
			<Col span={12}>
				<Text weight='semi-bold'> {name}</Text>
			</Col>
			<Col span={4}>
				<Text weight='semi-bold'>09:00</Text>
			</Col>
			<Col span={8}>
				<Row gutter={[32, 0]}>
					<Col span={12}>
						<CancelButton onClick={() => setIsAlertVisible(true)}>
							<Text color='red' weight='semi-bold' size='nobleRegular'>
								Sonlandır
							</Text>
						</CancelButton>
					</Col>
					<Col span={12}>
						{sold ? (
							<SoldButton type='secondary'>
								<Text size='nobleRegular' color='white' weight='semi-bold'>
									Satıldı
								</Text>
							</SoldButton>
						) : (
							<SendButton type='secondary' disabled={true}>
								<Text size='nobleRegular' color='white' weight='semi-bold'>
									Satışta
								</Text>
							</SendButton>
						)}
					</Col>
				</Row>
			</Col>
			<AlertModal deletePacket={deletePacket} visible={isAlertVisible} onCancel={handleCancel} />
		</PacketItemStyles>
	);
};

export default PacketItem;
