/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  PacketNames,
  CardTitle,
  PacketNamesStyles,
  Input,
  EditButton,
  SendButton,
  CancelButton,
  RadioBtn,
} from "./styles";
import { Row, Col, Spin, message, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "../../../../../components/Typography/styles";
import {
  getBoxNames,
  createBoxNames,
  updateBoxNames,
} from "../../../../../../services/Redux/Box/api";
import { getActiveRestaurants } from "../../../../../../services/Redux/RegisteredRestaurants/api";

const RestaurantPacketNames = ({ storeId }) => {
  const dispatch = useDispatch();
  const [namesToRender, setNamesToRender] = useState();
  const [packetName, setPacketName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedNameId, setSelectedNameId] = useState();

  useEffect(() => {
    dispatch(getActiveRestaurants());
  }, [dispatch]);

  const { activeRestaurantsList } = useSelector(
    (state) => state.registeredRestaurantsStore
  );

  useEffect(() => {
    const stores = activeRestaurantsList.find((store) => store.id === storeId);
    setStoreName(stores);
  }, []);

  useEffect(() => {
    dispatch(getBoxNames());
  }, []);

  const { boxNamesList } = useSelector((state) => state.boxNamesStore);

  useEffect(() => {
    if (boxNamesList) {
      setNamesToRender(boxNamesList);
    }
  }, [boxNamesList]);

  const handleCreate = async () => {
    if (packetName === "") {
      message.error("Paket ismi boş geçilemez !");
    } else if (!isEdit && storeId !== null) {
      await dispatch(createBoxNames(storeId, packetName));
      handleCancel();
      await dispatch(getBoxNames());
    } else if (isEdit && packetName !== "") {
      await dispatch(updateBoxNames(selectedNameId, storeId, packetName));
      setPacketName("");
      setSelectedNameId();

      await dispatch(getBoxNames());
    }
  };

  const handleEdit = () => {
    if (selectedNameId !== undefined) {
      const selectedPacket = namesToRender?.find((e) => e.id === selectedNameId);
      const { name } = selectedPacket;
      setIsEdit(true);
      setPacketName(name);
    } else {
      message.error("Lütfen düzenlemek istediğiniz ismi seçiniz !");
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setPacketName("");
    setSelectedNameId();
  };
  return (
    <PacketNames
      title={<CardTitle weight="light">Paket İsmi Oluştur</CardTitle>}
    >
      <PacketNamesStyles>
        <Col span={24}>
          <Text weight="semi-bold"> Paket İsmi Oluştur:</Text>
        </Col>
        <Col span={24} style={{ marginTop: "12px" }}>
          <Row gutter={[12, 0]}>
            <Col span={7}>
              <Input
                placeholder="Paket ismi"
                value={packetName}
                onChange={(e) => setPacketName(e.target.value)}
              />
            </Col>
            <Col span={4}>
              <SendButton type="secondary" onClick={() => handleCreate()}>
                <Text color="white" weight="semi-bold" size="nobleRegular">
                  Ekle
                </Text>
              </SendButton>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: "48px", marginBottom: "32px" }}>
          <Text weight="semi-bold"> Mevcut Paket İsimleri:</Text>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 0]}>
            {namesToRender ? (
              namesToRender.map(
                ({ id, name, store }) =>
                  storeName?.name === store && (
                    <Col span={6} style={{ marginBottom: "32px" }}>
                      <Radio.Group
                        onChange={(e) => setSelectedNameId(e.target.value)}
                        value={selectedNameId}
                      >
                        <Row>
                          <Col span={24}>
                            <RadioBtn value={id}>
                              <Text>{name}</Text>
                            </RadioBtn>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Col>
                  )
              )
            ) : (
              <Spin />
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[32, 0]}>
            <Col span={5} offset={14}>
              <CancelButton type="secondary" onClick={() => handleCancel()}>
                <Text color="red" weight="semi-bold" size="nobleRegular">
                  İptal Et
                </Text>
              </CancelButton>
            </Col>
            <Col span={5}>
              <EditButton type="secondary" onClick={() => handleEdit()}>
                <Text color="yellow" weight="semi-bold" size="nobleRegular">
                  Düzenle
                </Text>
              </EditButton>
            </Col>
          </Row>
        </Col>
      </PacketNamesStyles>
    </PacketNames>
  );
};

export default RestaurantPacketNames;
