import styled from 'styled-components';
import { Row, Col } from 'antd';

export const RestaurantsContent = styled(Row)``;

export const LeftSide = styled(Col)``;

export const RightSide = styled(Col)`
	@media only screen and (max-width: 1400px) {
		margin-top: 32px;
	}
`;
