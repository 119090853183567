import styled from 'styled-components';
import { Row, Button as btn } from 'antd';
import button from '../../../../../../../components/Button';

export const PacketItemStyles = styled(Row)`
	padding: 32px;
`;
export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 100%;

	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const SendButton = styled(button)`
	height: 32px !important;
	width: 100%;
`;

export const SoldButton = styled(btn)`
	height: 32px !important;
	width: 100%;

	background-color: #a4b1ba;
	border: 2px solid #a4b1ba !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #a4b1ba;
		background-color: #a4b1ba;
		transition: 0.2s;
	}
`;
