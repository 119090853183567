export const GET_ACTIVE_RESTAURANTS_START = 'GET_ACTIVE_RESTAURANTS_START';
export const GET_ACTIVE_RESTAURANTS_SUCCESS = 'GET_ACTIVE_RESTAURANTS_SUCCESS';
export const GET_ACTIVE_RESTAURANTS_FAIL = 'GET_ACTIVE_RESTAURANTS_FAIL';

export const GET_SUSPEND_RESTAURANTS_START = 'GET_SUSPEND_RESTAURANTS_START';
export const GET_SUSPEND_RESTAURANTS_SUCCESS = 'GET_SUSPEND_RESTAURANTS_SUCCESS';
export const GET_SUSPEND_RESTAURANTS_FAIL = 'GET_SUSPEND_RESTAURANTS_FAIL';

export const GET_TERMINATE_RESTAURANTS_START = 'GET_TERMINATE_RESTAURANTS_START';
export const GET_TERMINATE_RESTAURANTS_SUCCESS = 'GET_TERMINATE_RESTAURANTS_SUCCESS';
export const GET_TERMINATE_RESTAURANTS_FAIL = 'GET_TERMINATE_RESTAURANTS_FAIL';

export const CREATE_SUSPEND_RESTAURANTS_START = 'CREATE_SUSPEND_RESTAURANTS_START';
export const CREATE_SUSPEND_RESTAURANTS_SUCCESS = 'CREATE_SUSPEND_RESTAURANTS_SUCCESS';
export const CREATE_SUSPEND_RESTAURANTS_FAIL = 'CREATE_SUSPEND_RESTAURANTS_FAIL';

export const CREATE_TERMINATE_RESTAURANTS_START = 'CREATE_TERMINATE_RESTAURANTS_START';
export const CREATE_TERMINATE_RESTAURANTS_SUCCESS = 'CREATE_TERMINATE_RESTAURANTS_SUCCESS';
export const CREATE_TERMINATE_RESTAURANTS_FAIL = 'CREATE_TERMINATE_RESTAURANTS_FAIL';

export const UPDATE_RESTAURANTS_TO_ACTIVE_START = 'UPDATE_RESTAURANTS_TO_ACTIVE_START';
export const UPDATE_RESTAURANTS_TO_ACTIVE_SUCCESS = 'UPDATE_RESTAURANTS_TO_ACTIVE_SUCCESS';
export const UPDATE_RESTAURANTS_TO_ACTIVE_FAIL = 'UPDATE_RESTAURANTS_TO_ACTIVE_FAIL';

export const GET_RESTAURANTS_DETAIL_START = 'GET_RESTAURANTS_DETAIL_START';
export const GET_RESTAURANTS_DETAIL_SUCCESS = 'GET_RESTAURANTS_DETAIL_SUCCESS';
export const GET_RESTAURANTS_DETAIL_FAIL = 'GET_RESTAURANTS_DETAIL_FAIL';

export const DELETE_RESTAURANT_PACKET_START = 'DELETE_RESTAURANT_PACKET_START';
export const DELETE_RESTAURANT_PACKET_SUCCESS = 'DELETE_RESTAURANT_PACKET_SUCCESS';
export const DELETE_RESTAURANT_PACKET_FAIL = 'DELETE_RESTAURANT_PACKET_FAIL';
