import * as actions from './actions';
import { message } from 'antd';

export const getRoles = () => async (dispatch, getState, service) => {
	dispatch(actions.getRolesStartAction());
	await service({
		path: 'superadmin/admin-role/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getRolesSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getRolesFailAction(error));
		});
};

export const createRoles = (role, permission) => async (dispatch, getState, service) => {
	dispatch(actions.createRolesStartAction());
	await service({
		path: 'superadmin/admin-role/',
		method: 'POST',
		sendToken: true,
		body: {
			role: role,
			permission: permission,
		},
	})
		.then((data) => {
			dispatch(actions.createRolesSuccessAction(data));
			message.success('Rol Başarı ile Oluşturuldu. !');
		})
		.catch((error) => {
			dispatch(actions.createRolesFailAction(error));
			message.error('Rol Oluşturulurken bir hata Oluştu. !');
		});
};

export const updateRoles = (id, role, permission) => async (dispatch, getState, service) => {
	dispatch(actions.updateRolesStartAction());
	await service({
		path: `superadmin/admin-role/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			role: role,
			permission: permission,
		},
	})
		.then((data) => {
			dispatch(actions.updateRolesSuccessAction(data));
			message.success('Rol Başarı ile Güncellendi. !');
		})
		.catch((error) => {
			dispatch(actions.updateRolesFailAction(error));
			message.error('Rol Güncellenirken bir Hata Oluştu. !');
		});
};

export const deleteRoles = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deleteRolesStartAction());
	await service({
		path: `superadmin/admin-role/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteRolesSuccessAction(data));
			message.success('Rol Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deleteRolesFailAction(error));
			message.error('Rol Silinirken bir Hata Oluştu. !');
		});
};
