import styled from 'styled-components';
import { Row, Col, Button as btn, Progress } from 'antd';

export const Panel = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	height: 170px;

	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const PanelImg = styled.img`
	width: 100%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	height: 170px;

	object-fit: cover;
	object-position: center;
`;

export const IconContainer = styled(Col)`
	width: 48px;
	border-radius: 8px;
	height: 38px;
	background-color: white;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconsWrapper = styled(Row)`
	position: absolute;
	right: 16px;
	top: 10px;
`;
export const ServiceImg = styled.img`
	width: 80%;
	height: 80%;
	display: block;
`;

export const Profile = styled(Col)`
	position: absolute;
	left: 0px;

	top: 145px;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	width: 90%;
	height: 115px;
	background-color: white;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const ProfileImg = styled.img`
	width: 87px;
	height: 92px;
`;

export const RestImg = styled.img`
	width: 100%;
	border-radius: 8px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const StarImg = styled.img`
	width: 20px;
	height: 20px;
	margin-left: 15px;
	margin-bottom: 3px;
	margin-right: 10px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const Banner = styled(Col)``;

export const HeaderItem = styled(Row)`
	margin-top: 32px;

	padding: 12px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const Location = styled(Row)`
	margin-top: 32px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const EditButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const CustomProgress = styled(Progress)`
	.ant-progress-text {
		font-size: 14px;
	}
`;

export const ProgressItem = styled(Col)`
	display: flex !important;
	align-items: center;
	justify-content: center !important;
	height: 100%;
`;

export const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	width: 100%;
	height: 32px;
	border-radius: 5px;
	background: ${({ theme }) => theme.borderColor};
`;
