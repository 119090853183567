import React from 'react';
import { CustomModal, DeleteButton, SendButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteMeal, getCategories } from '../../../../../../../../services/Redux/PendingMenu/api';

const Title = ({ text }) => (
	<Text weight='semi-bold' color='red' size='title'>
		{text}
	</Text>
);

const AlertModal = ({ mealId, onCancel, visible }) => {
	const dispatch = useDispatch();

	const deleteDraft = async () => {
		if (mealId !== undefined || null) {
			await dispatch(deleteMeal(mealId));
			await dispatch(getCategories());
			onCancel();
		}
	};

	return (
		<CustomModal onCancel={onCancel} visible={visible} title={<Title text='UYARI' />}>
			<Row justify='center'>
				<Text weight='light'>Güncellemeyi reddetmek istediğinizden emin misiniz?</Text>
			</Row>

			<Row justify='center' style={{ marginTop: '48px', marginBottom: '32px' }} gutter={[32, 0]}>
				<Col span={8}>
					<DeleteButton type='secondary' onClick={() => onCancel()}>
						<Text color='white' weight='semi-bold'>
							İptal
						</Text>
					</DeleteButton>
				</Col>
				<Col span={8}>
					<SendButton type='secondary' onClick={() => deleteDraft()}>
						<Text color='white' weight='semi-bold'>
							Sil
						</Text>
					</SendButton>
				</Col>
			</Row>
		</CustomModal>
	);
};

export default AlertModal;
