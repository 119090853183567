import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export default styled(Content)`
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	background: ${({ theme }) => theme.contentBackground};
	margin-top: 8px;
	padding: 24px 32px;
	margin-bottom: 8px;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
`;
