import styled from 'styled-components';
import { Col, Row } from 'antd';

export const StatisticsPageStyles = styled(Row)``;
export const Wrapper = styled(Col)``;
export const ContentTop = styled(Row)``;
export const ContentMiddle = styled(Row)`
	min-height: 510px;
	margin-top: 32px;
`;
