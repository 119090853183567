import * as actions from './actions';

export const createUserInfo = (formData) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createUserStartAction());
  await service({
    path: 'user/',
    method: 'POST',
    sendToken: true,
    body: formData,
  })
    .then((data) => {
      dispatch(actions.createUserSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createUserFailAction(error));
    });
};

export const createStoreInfo = (formData) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createStoreStartAction());
  await service({
    path: 'store/',
    method: 'POST',
    sendToken: true,
    body: formData,
  })
    .then((data) => {
      dispatch(actions.createStoreSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createStoreFailAction(error));
    });
};

export const createPackageInfo = (formData) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createPackageStartAction());
  await service({
    path: 'store/package_settings/',
    method: 'POST',
    sendToken: true,
    body: formData,
  })
    .then((data) => {
      dispatch(actions.createPackageSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createPackageFailAction(error));
    });
};

export const createPackagingType = (formData) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createTypeStartAction());
  await service({
    path: 'store/packaging/',
    method: 'POST',
    sendToken: true,
    body: formData,
  })
    .then((data) => {
      dispatch(actions.createTypeSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createTypeFailAction(error));
    });
};

export const createCategoryInfo = (finalTags) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createCategoryStartAction());
  await service({
    path: 'box/category/bulk-create/',
    method: 'POST',
    sendToken: true,
    body: finalTags,
  })
    .then((data) => {
      dispatch(actions.createCategorySuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createCategoryFailAction(error));
    });
};
