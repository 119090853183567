import styled from 'styled-components';
import { Row } from 'antd';
import Card from '../../components/Card';

export const LoginCard = styled(Card)`
	min-height: 55vh;
	box-shadow: 0 0px 1px rgba(0, 0, 0, 0.19), 0 0px 6px rgba(0, 0, 0, 0.13);
	border-radius: 15px !important;
`;

export const LoginStyles = styled(Row)`
	height: 80vh;

	.vertical-row {
		display: flex;
		flex-direction: column;
	}
`;
