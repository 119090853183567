export const GET_CATEGORY_START = 'GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const CREATE_MEAL_START = 'CREATE_MEAL_START';
export const CREATE_MEAL_SUCCESS = 'CREATE_MEAL_SUCCESS';
export const CREATE_MEAL_FAIL = 'CREATE_MEAL_FAIL';

export const UPDATE_MEAL_START = 'UPDATE_MEAL_START';
export const UPDATE_MEAL_SUCCESS = 'UPDATE_MEAL_SUCCESS';
export const UPDATE_MEAL_FAIL = 'UPDATE_MEAL_FAIL';

export const DELETE_MEAL_START = 'DELETE_MEAL_START';
export const DELETE_MEAL_SUCCESS = 'DELETE_MEAL_SUCCESS';
export const DELETE_MEAL_FAIL = 'DELETE_MEAL_FAIL';

export const DELETE_DRAFT_MEAL_START = 'DELETE_DRAFT_MEAL_START';
export const DELETE_DRAFT_MEAL_SUCCESS = 'DELETE_DRAFT_MEAL_SUCCESS';
export const DELETE_DRAFT_MEAL_FAIL = 'DELETE_DRAFT_MEAL_FAIL';

export const UPDATE_PENDING_MEAL_START = 'UPDATE_PENDING_MEAL_START';
export const UPDATE_PENDING_MEAL_SUCCESS = 'UPDATE_PENDING_MEAL_SUCCESS';
export const UPDATE_PENDING_MEAL_FAIL = 'UPDATE_PENDING_MEAL_FAIL';
