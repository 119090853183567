import styled from 'styled-components';
import { Divider as divider, Card } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';

export const RestaurantInfoContent = styled(Card)`
	margin-top: 32px;
	border: 0px;
	padding: 0px;
	.ant-card-head {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		background: ${({ theme }) => theme.borderColor};
	}
	.ant-card-body {
		padding-left: 24px;
		padding-right: 24px;
	}
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
	cursor: pointer;
`;

export const TitleDivider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	min-width: 30% !important;
	width: 30%;
	height: 2px;
	margin-top: 2px;
	margin-bottom: 0px;
	background: ${({ theme }) => theme.primaryColor};
`;
