import React, { useState } from 'react';
import { CustomModal, Input, SendButton } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import {
	createTerminateRestaurants,
	getActiveRestaurants,
} from '../../../../../services/Redux/RegisteredRestaurants/api';

const Title = ({ text }) => (
	<Text weight='light' size='title'>
		{text}
	</Text>
);

const TerminateModal = ({ storeId, onCancel, visible }) => {
	const dispatch = useDispatch();
	const [description, setDescription] = useState('');

	const createTerminate = async () => {
		if (description === '') {
			message.error(' Sebep alanı boş geçilemez !');
		} else {
			await dispatch(createTerminateRestaurants(storeId, description));
			setDescription('');
			await dispatch(getActiveRestaurants());
			onCancel();
		}
	};

	return (
		<CustomModal onCancel={onCancel} visible={visible} title={<Title text='Sonlandırma Nedeni' />}>
			<Text weight='light'>
				Aşağıda yapacağınız sonlandırma nedeni restorant sahibine bilgilendirme olarak iletilecektir.
			</Text>
			<Row style={{ marginTop: '48px' }}>
				<Text weight='semi-bold' style={{ marginBottom: '8px' }}>
					Sebep
				</Text>
				<Input placeholder='Sebep giriniz...' value={description} onChange={(e) => setDescription(e.target.value)} />
			</Row>
			<Row style={{ marginTop: '48px', marginBottom: '48px' }}>
				<Col span={6} offset={18}>
					<SendButton type='secondary' onClick={() => createTerminate()}>
						<Text color='white' weight='semi-bold'>
							Kaydet
						</Text>
					</SendButton>
				</Col>
			</Row>
		</CustomModal>
	);
};

export default TerminateModal;
