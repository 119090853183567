import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	permissionList: null,
	permissionError: null,

	createPermission: null,
	createPermissionError: null,

	updatePermission: null,
	updatePermissionError: null,

	deletePermission: null,
	deletePermissionError: null,
};

const getPermissionStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getPermissionSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	permissionList: action.payload.results,
});

const getPermissionFailReducer = (state, action) => ({
	...state,
	loading: false,
	permissionError: action.payload,
});

//CREATE

const createPermissionStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createPermissionSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createPermission: action.payload,
});

const createPermissionFailReducer = (state, action) => ({
	...state,
	loading: false,
	createPermissionError: action.payload,
});

//UPDATE

const updatePermissionStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updatePermissionSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updatePermission: action.payload,
});

const updatePermissionFailReducer = (state, action) => ({
	...state,
	loading: false,
	updatePermissionError: action.payload,
});

//DELETE

const deletePermissionStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deletePermissionSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deletePermission: action.payload,
});

const deletePermissionFailReducer = (state, action) => ({
	...state,
	loading: false,
	deletePermissionError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PERMISSION_START:
			return getPermissionStartReducer(state);
		case actionTypes.GET_PERMISSION_SUCCESS:
			return getPermissionSuccessReducer(state, action);
		case actionTypes.GET_PERMISSION_FAIL:
			return getPermissionFailReducer(state, action);
		case actionTypes.CREATE_PERMISSION_START:
			return createPermissionStartReducer(state);
		case actionTypes.CREATE_PERMISSION_SUCCESS:
			return createPermissionSuccessReducer(state, action);
		case actionTypes.CREATE_PERMISSION_FAIL:
			return createPermissionFailReducer(state, action);
		case actionTypes.UPDATE_PERMISSION_START:
			return updatePermissionStartReducer(state);
		case actionTypes.UPDATE_PERMISSION_SUCCESS:
			return updatePermissionSuccessReducer(state, action);
		case actionTypes.UPDATE_PERMISSION_FAIL:
			return updatePermissionFailReducer(state, action);
		case actionTypes.DELETE_PERMISSION_START:
			return deletePermissionStartReducer(state);
		case actionTypes.DELETE_PERMISSION_SUCCESS:
			return deletePermissionSuccessReducer(state, action);
		case actionTypes.DELETE_PERMISSION_FAIL:
			return deletePermissionFailReducer(state, action);
		default:
			return state;
	}
};
export default reducer;
