import * as actions from './actions';
import { message } from 'antd';

export const getFoodCategories = () => async (dispatch, getState, service) => {
	dispatch(actions.getCategoriesStartAction());
	await service({
		path: 'box/category-name/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getCategoriesSuccessAction(data.results));
		})
		.catch((error) => {
			dispatch(actions.getCategoriesFailAction(error));
		});
};

export const createFoodCategory = (category) => async (dispatch, getState, service) => {
	dispatch(actions.createCategoryStartAction());
	await service({
		path: 'box/category-name/',
		method: 'POST',
		sendToken: true,
		body: category,
	})
		.then((data) => {
			dispatch(actions.createCategorySuccessAction(data));
			message.success('Kategori Başarı ile Oluşturuldu. !');
		})
		.catch((error) => {
			dispatch(actions.createCategoryFailAction(error));
			message.error('Kategori Oluşturulurken Bir Hata Oluştu. !');
		});
};

export const updateFoodCategory = (id, category) => async (dispatch, getState, service) => {
	dispatch(actions.updateCategoryStartAction());
	await service({
		path: `box/category-name/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: category,
	})
		.then((data) => {
			dispatch(actions.updateCategorySuccessAction(data));
			message.success('Kategori Başarı ile Güncellendi. !');
		})
		.catch((error) => {
			dispatch(actions.updateCategoryFailAction(error));
			message.error('Kategori Güncellenirken Bir Hata Oluştu. !');
		});
};

export const deleteFoodCategory = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deleteCategoryStartAction());
	await service({
		path: `box/category-name/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteCategorySuccessAction(data));
			message.success('Kategori Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deleteCategoryFailAction(error));
			message.error('Kategori Silinirken Bir Hata Oluştu. !');
		});
};
