import { Modal, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../../../components/Button';

export const CustomModal = styled(Modal)`
	.ant-modal-header {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		border-bottom: 3px solid ${({ theme }) => theme.borderColor};
	}

	.ant-modal-content {
		border-radius: 15px;
	}

	.ant-modal-footer {
		display: none;
		.ant-btn {
			display: none;
		}
		background: ${({ theme }) => theme.borderColor};
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
`;

export const CustomDivider = styled(Divider)`
	width: 20px;
	border-top: 2px solid rgba(0, 0, 0, 0.06);
`;

export const CustomButton = styled(Button)`
	width: 200px;
	height: 48px;
	border-radius: 4px;
`;

export const FoodnameLabel = styled.div`
	padding: 5px 8px;
	border: 2px solid ${({ theme }) => theme.borderColor};
	margin-right: 8px;
	border-radius: 3px;
	text-align: center;
	transition: 0.2s;
	cursor: pointer;

	&.selected-tag {
		border: 2px solid ${({ theme }) => theme.secondaryColor};
	}

	&.unselected-tag {
		border: 2px solid ${({ theme }) => theme.borderColor};
		&:hover {
			border: 2px solid ${({ theme }) => theme.secondaryColor};
		}
	}
`;
