import React from 'react';
import { Row, Col } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GraphCardStyles, Content, Button, ButtonContainer, CustomButton, GraphicWrapper } from './styles';
import { Text } from '../../../../components/Typography/styles';

const Title = ({ text, isWeekly, setIsWeekly }) => (
	<Row align='middle' justify='space-between'>
		<Col>
			<CustomButton type='secondary'>
				<Text weight='semi-bold' color='white' style={{ alignSelf: 'center' }}>
					Raporu Dışa Aktar
				</Text>
			</CustomButton>
		</Col>
		<Col>
			<Text size='title' weight='light'>
				{text}
			</Text>
		</Col>
		<Col span={6}>
			<ButtonContainer>
				<Button
					ghost={isWeekly}
					onClick={() => {
						setIsWeekly(true);
					}}
				>
					Haftalık
				</Button>
				<Button
					ghost={!isWeekly}
					onClick={() => {
						setIsWeekly(false);
					}}
				>
					Aylık
				</Button>
			</ButtonContainer>
		</Col>
	</Row>
);

const GraphCard = ({ weeklyData, monthlyData, isWeekly, setIsWeekly }) => (
	<GraphCardStyles title={<Title isWeekly={isWeekly} setIsWeekly={setIsWeekly} text='Satılan Paket Sayısı' />}>
		<Content>
			<GraphicWrapper>
				<ResponsiveContainer>
					<LineChart data={isWeekly ? weeklyData : monthlyData}>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='name' />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line type='monotone' dataKey='box' stroke='#82ca9d' />
					</LineChart>
				</ResponsiveContainer>
			</GraphicWrapper>
		</Content>
	</GraphCardStyles>
);
export default GraphCard;
