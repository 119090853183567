import { useState } from 'react';
import { TableStyle, Item, InfoWrapper, PanelButton, CardTitle, InfoItem, InfoContent } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, Row } from 'antd';
import rightOutline from '../../../../../Assets/components/right-outline.svg';
import moment from 'moment';
import cancel from '../../../../../Assets/components/cancel.svg';
import checkX from '../../../../../Assets/components/checkX.svg';
import checkV from '../../../../../Assets/components/checkV.svg';

const Title = ({ setIsShown }) => (
	<Row align='middle'>
		<Col span={23}>
			<CardTitle size='title' weight='light'>
				Kullanıcı Detayı
			</CardTitle>
		</Col>
		<Col span={1}>
			<img src={cancel} alt='logo' onClick={() => setIsShown(false)} />
		</Col>
	</Row>
);

const TableItem = ({ user }) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<>
			<TableStyle>
				<Item span={2} style={{ alignItems: 'center', border: '0px' }}>
					<Text weight='light'>{user.user.id}</Text>
				</Item>
				<Item span={4}>
					<Col span={24} style={{ display: 'flex', flexDirection: 'column', marginLeft: '22px' }}>
						<Text weight='semi-bold'>
							{user.user.first_name} {user.user.last_name}
						</Text>
						<Text size='smallRegular' weight='light'>
							{user.user.active_address !== null ? user.user.active_address.city : 'null'}
						</Text>
					</Col>
				</Item>
				<Item span={5} style={{ display: 'flex', flexDirection: 'column' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{user.user.email}
					</Text>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{user.user.phone_number}
					</Text>
				</Item>
				<Item span={2} style={{ flexDirection: 'column', alignItems: 'center' }}>
					<Text size='smallRegular' color='red' weight='semi-bold'>
						Sonlandırıldı
					</Text>
				</Item>
				<Item span={3} style={{ flexDirection: 'column' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{moment(user.user.created_at).format('DD MMMM  YYYY')}
					</Text>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{moment(user.user.created_at).format('dddd  HH:mm')}
					</Text>
				</Item>
				<Item span={3} style={{ flexDirection: 'column' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{moment(user.created_at).format('DD MMMM  YYYY')}
					</Text>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{moment(user.created_at).format('dddd  HH:mm')}
					</Text>
				</Item>
				<Item span={3} style={{ display: 'flex', flexDirection: 'column' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '16px' }}>
						{user.description !== null ? user.description.slice(0, 18).toString() + '...' : 'null'}
					</Text>
				</Item>
				<Item span={2} style={{ alignItems: 'center' }}>
					<PanelButton onClick={() => setIsShown(!isShown)}>
						<img src={rightOutline} alt="" />
					</PanelButton>
				</Item>
			</TableStyle>
			<InfoWrapper title={<Title setIsShown={setIsShown} />} style={{ display: isShown ? 'block' : 'none' }}>
				<InfoContent>
					<Text weight='bold'>Kişi Bilgisi:</Text>

					<Col span={24}>
						<InfoItem>
							<Col span={10}>
								<Text weight='bold'>İsim Soyisim:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.first_name} {user.user.last_name}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>ID:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.id}
								</Text>
							</Col>
							<Col span={6}>
								<Text weight='bold'>Hesap Durumu:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									Üyeliği Sonlandırıldı
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={10}>
								<Text weight='bold'>Kayıt Tarihi:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{moment(user.user.created_at).format('DD MMMM  YYYY')}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Saat:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{moment(user.user.created_at).format('HH:mm')}
								</Text>
							</Col>
							<Col span={6}>
								<Text weight='bold'>Nedeni: </Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.description !== null ? user.description : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={10}>
								<Text weight='bold'>Üyelik Sonlandırma Tarihi:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{moment(user.created_at).format('DD MMMM  YYYY')}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Üyelik Sonlandırma Tarihi Saati:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{moment(user.created_at).format('HH:mm')}
								</Text>
							</Col>
							<Col span={6}>
								<Text weight='bold'>Doğum Tarihi: </Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.birthdate !== null ? moment(user.user.birthdate).format('DD MMMM  YYYY') : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={18}>
								<Text weight='bold'>Adres:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.active_address !== null
										? `${user.user.active_address.address} - ${user.user.active_address.city}`
										: 'null'}
								</Text>
							</Col>
							<Col span={6}>
								<Text weight='bold'>Telefon:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.phone_number}
								</Text>
							</Col>
						</InfoItem>
					</Col>
					<Col span={24}>
						<InfoItem>
							<Col span={6} offset={18}>
								<Text weight='bold'>E-mail:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{user.user.email}
								</Text>
							</Col>
						</InfoItem>
					</Col>
					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>İzinler:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									tt
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={10}>
								<Text weight='bold'>SMS</Text>
								<img src={checkX} alt="" style={{ marginLeft: '22px', marginBottom: '5px' }} />
							</Col>
							<Col span={8}>
								<Text weight='bold'>E-posta:</Text>
								<img
									src={user.user.allow_email ? checkV : checkX}
									alt='logo'
									style={{ marginLeft: '22px', marginBottom: '5px' }}
								/>
							</Col>
							<Col span={6}>
								<Text weight='bold'>Telefonla Arama:</Text>
								<img
									src={user.user.allow_phone ? checkV : checkX}
									alt='logo'
									style={{ marginLeft: '22px', marginBottom: '5px' }}
								/>
							</Col>
						</InfoItem>
					</Col>
				</InfoContent>
			</InfoWrapper>
		</>
	);
};

export default TableItem;
