import styled from "styled-components";
import Card from "../../components/Card/index";
import { between } from "polished";
import { Text as text } from "../../components/Typography/styles";
import { Button, Input as input, Row, Select as select } from "antd";

export const PendingContent = styled(Card)`
  width: 100%;

  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-body {
    padding-left: 39px;
    padding-right: 41px;
  }
  margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
  font-size: ${between("14px", "22px")};
`;

export const TableStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 0 0 2px 2px;
  background: ${({ theme }) => theme.lightColor};
`;

export const Input = styled(input)`
  margin-left: 20px;
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
  padding-right: 0px;
`;

export const Header = styled(Row)`
  margin-top: 32px;
`;

export const Select = styled(select)`
  width: 100%;

  .ant-select-selector {
    height: 48px !important;
    background-color: #f2f2fc !important;
    border-radius: 3px !important;
    align-items: center;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  max-width: 342px;
`;

export const Location = styled(Row)`
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const FilterButton = styled(Button)`
  height: 3rem;
  width: 15rem;
  margin: 0 1rem;
  background-color: #f2f2fc !important;
  border-radius: 3px !important;
  text-align: start;
  color: rgba(192,192,192,0.7);
  border: none;
  
`
