import { useState } from 'react';
import { PacketStyles, SupriseItemStyles, DefinedItemStyles, Location } from './styles';
import PacketItem from './PacketItem';
import { Text } from '../../../../../../components/Typography/styles';

const Packet = ({ boxes, settings }) => {
	const [isSuprise, setIsSuprise] = useState(false);
	const [isDefined, setIsDefined] = useState(false);
	const [deliveryTime] = useState(settings.courier_delivery_time_start);

	return (
		<PacketStyles>
			<SupriseItemStyles span={24}>
				<Location onClick={() => setIsSuprise(!isSuprise)}>
					<Text weight='semi-bold'>Sürpriz Paketler </Text>
				</Location>
				{isSuprise &&
					boxes.map(
						({ id, text_name, defined, sold, store }) =>
							!defined && (
								<PacketItem
									id={id}
									storeId={store}
									name={text_name}
									defined={defined}
									sold={sold}
									deliveryTime={deliveryTime}
								/>
							),
					)}
			</SupriseItemStyles>
			<DefinedItemStyles span={24}>
				<Location onClick={() => setIsDefined(!isDefined)}>
					<Text weight='semi-bold'>Tanımlı Paketler</Text>
				</Location>
				{isDefined &&
					boxes.map(
						({ id, text_name, defined, sold, store }) =>
							defined && <PacketItem id={id} storeId={store} name={text_name} defined={defined} sold={sold} />,
					)}
			</DefinedItemStyles>
		</PacketStyles>
	);
};
export default Packet;
