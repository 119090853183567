import * as actionTypes from './actionTypes';

export const getActiveRestaurantsStartAction = () => ({
	type: actionTypes.GET_ACTIVE_RESTAURANTS_START,
});

export const getActiveRestaurantsSuccessAction = (restaurants) => ({
	type: actionTypes.GET_ACTIVE_RESTAURANTS_SUCCESS,
	payload: restaurants,
});

export const getActiveRestaurantsFailAction = (error) => ({
	type: actionTypes.GET_ACTIVE_RESTAURANTS_FAIL,
	payload: error,
});

//UPDATE RESTORANT TO ACTIVE

export const updateRestorantsToActiveStartAction = () => ({
	type: actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_START,
});

export const updateRestaurantsToActiveSuccessAction = (restaurants) => ({
	type: actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_SUCCESS,
	payload: restaurants,
});

export const updateRestaurantsToActiveFailAction = (error) => ({
	type: actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_FAIL,
	payload: error,
});

// SUSPEND

export const getSuspendRestaurantsStartAction = () => ({
	type: actionTypes.GET_SUSPEND_RESTAURANTS_START,
});

export const getSuspendRestaurantsSuccessAction = (restaurants) => ({
	type: actionTypes.GET_SUSPEND_RESTAURANTS_SUCCESS,
	payload: restaurants,
});

export const getSuspendRestaurantsFailAction = (error) => ({
	type: actionTypes.GET_SUSPEND_RESTAURANTS_FAIL,
	payload: error,
});

// SUSPEND UPDATE

export const createSuspendRestaurantsStartAction = () => ({
	type: actionTypes.CREATE_SUSPEND_RESTAURANTS_START,
});

export const createSuspendRestaurantsSuccessAction = (restaurants) => ({
	type: actionTypes.CREATE_SUSPEND_RESTAURANTS_SUCCESS,
	payload: restaurants,
});

export const createSuspendRestaurantsFailAction = (error) => ({
	type: actionTypes.CREATE_SUSPEND_RESTAURANTS_FAIL,
	payload: error,
});

// TERMINATE

export const getTerminateRestaurantsStartAction = () => ({
	type: actionTypes.GET_TERMINATE_RESTAURANTS_START,
});

export const getTerminateRestaurantsSuccessAction = (restaurants) => ({
	type: actionTypes.GET_TERMINATE_RESTAURANTS_SUCCESS,
	payload: restaurants,
});

export const getTerminateRestaurantsFailAction = (error) => ({
	type: actionTypes.GET_TERMINATE_RESTAURANTS_FAIL,
	payload: error,
});

// TERMINATE UPDATE

export const createTerminateRestaurantsStartAction = () => ({
	type: actionTypes.CREATE_TERMINATE_RESTAURANTS_START,
});

export const createTerminateRestaurantsSuccessAction = (restaurants) => ({
	type: actionTypes.CREATE_TERMINATE_RESTAURANTS_SUCCESS,
	payload: restaurants,
});

export const createTerminateRestaurantsFailAction = (error) => ({
	type: actionTypes.CREATE_TERMINATE_RESTAURANTS_FAIL,
	payload: error,
});

// GET RESTAURANTS DETAIL

export const getRestaurantsDetailStartAction = () => ({
	type: actionTypes.GET_RESTAURANTS_DETAIL_START,
});

export const getRestaurantsDetailSuccessAction = (restaurants) => ({
	type: actionTypes.GET_RESTAURANTS_DETAIL_SUCCESS,
	payload: restaurants,
});

export const getRestaurantsDetailFailAction = (error) => ({
	type: actionTypes.GET_RESTAURANTS_DETAIL_FAIL,
	payload: error,
});

// DELETE RESTAURANT PACKET

export const deleteRestaurantsPacketStartAction = () => ({
	type: actionTypes.DELETE_RESTAURANT_PACKET_START,
});

export const deleteRestaurantsPacketSuccessAction = (restaurants) => ({
	type: actionTypes.DELETE_RESTAURANT_PACKET_SUCCESS,
	payload: restaurants,
});

export const deleteRestaurantsPacketFailAction = (error) => ({
	type: actionTypes.DELETE_RESTAURANT_PACKET_FAIL,
	payload: error,
});
