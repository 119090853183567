import React, { useState } from 'react';
import { Badge, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Label, FoodLabelStyles, CustomButton } from './styles';
import { Text } from '../../../../components/Typography/styles';
import xIcon from '../../../../../Assets/components/x-marks.png';
import xmlDelete from '../../../../../services/Api/request';
import { getFoodTags } from '../../../../../services/Redux/FoodTags/api';

const Title = ({ tagsId }) => {
	const dispatch = useDispatch();

	const RemoveItem = async () => {
		await xmlDelete({
			path: `box/tag/${tagsId}/`,
			method: 'DELETE',
			sendToken: true,
		});

		await dispatch(getFoodTags());
	};

	return (
		<Row align='middle' justify='center'>
			<CustomButton onClick={() => RemoveItem()} type='link'>
				<img src={xIcon} alt='x' />
			</CustomButton>
		</Row>
	);
};

const FoodLabel = ({ tagsId, name }) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<FoodLabelStyles onMouseLeave={() => setIsShown(false)} onMouseEnter={() => setIsShown(true)}>
			<Badge.Ribbon placement='end' text={isShown && <Title tagsId={tagsId} text='X' />}>
				<Label>
					<Text size='non-regular' family='secondary' color='smoke' weight='light'>
						{name}
					</Text>
				</Label>
			</Badge.Ribbon>
		</FoodLabelStyles>
	);
};

export default FoodLabel;
