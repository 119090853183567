import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../components/Typography/styles';

export const TableStyle = styled(Row)`
	height: 64px;
	border-radius: 8px;
	margin-bottom: 20px;
	border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const Item = styled(Col)`
	display: flex !important;
	height: 100%;
	border-left: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const EditButton = styled.img`
	height: 32px !important;
	width: 32px;
	border-radius: 50px;
	border-width: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@media (max-width: 1200px) {
		width: 24px;
		height: 24px;
	}
`;

export const CancelButton = styled.img`
	height: 32px !important;
	width: 32px;
	border-width: 0px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	@media (max-width: 1200px) {
		width: 24px;
		height: 24px;
	}
`;
