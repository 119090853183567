import React from 'react';
import { Text } from '../../../../../../../components/Typography/styles';
import { PacketItemStyles, CancelButton, SendButton } from './styles';
import { Col, Row } from 'antd';

const PacketItem = () => {
	return (
		<PacketItemStyles>
			<Col span={12}>
				<Text weight='semi-bold'> Sürpriz Paket 1</Text>
			</Col>
			<Col span={4}>
				<Text weight='semi-bold'> 09.00</Text>
			</Col>
			<Col span={8}>
				<Row gutter={[32, 0]}>
					<Col span={12}>
						<CancelButton>
							<Text color='red' weight='semi-bold' size='nobleRegular'>
								Sonlandır
							</Text>
						</CancelButton>
					</Col>
					<Col span={12}>
						<SendButton type='secondary'>
							<Text size='nobleRegular' color='white' weight='semi-bold'>
								Satışta
							</Text>
						</SendButton>
					</Col>
				</Row>
			</Col>
		</PacketItemStyles>
	);
};

export default PacketItem;
