/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { InfoContent, Header, Content } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, Row } from 'antd';
import FoodItem from './FoodItem';

const MenuModal = ({ menu, categories, storeId, setIsShown }) => {
  const [catName, setCatName] = useState('');
  const { id, category, price, description, photo, name, tag } = menu;

  useEffect(() => {
    if (categories.length !== 0) {
      const filtered = categories.find((item) => item.id === category);
      setCatName(filtered?.name);
    }
  }, [categories]);

  return (
    <InfoContent>
      <Col span={24}>
        <Header gutter={[24, 0]}>
          <Col className="title-styles" span={24} lg={{ span: 24 }}>
            <Text family="secondary" weight="bold">
              {catName}
            </Text>
          </Col>
        </Header>
        <Content>
          <Row>
            <FoodItem
              id={id}
              category={category}
              photo={photo}
              price={price}
              name={name}
              description={description}
              tags={tag}
              storeId={storeId}
              setIsShown={setIsShown}
            />
          </Row>
        </Content>
      </Col>
    </InfoContent>
  );
};

export default MenuModal;
