import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	storeInfo: null,
	storeInfoError: null,
};

const getStoreStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getStoreSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	storeInfo: action.payload,
});

const getStoreFailReducer = (state, action) => ({
	...state,
	loading: false,
	storeInfoError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_STORE_START:
			return getStoreStartReducer(state);
		case actionTypes.GET_STORE_SUCCESS:
			return getStoreSuccessReducer(state, action);
		case actionTypes.GET_STORE_FAIL:
			return getStoreFailReducer(state, action);

		default:
			return state;
	}
};

export default reducer;
