import React  from 'react';
import { CustomModal, DeleteButton, SendButton } from './styles';
import { Text } from '../../../components/Typography/styles';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteFoodCategory, getFoodCategories } from '../../../../services/Redux/FoodCategories/api';

const Title = ({ text }) => (
	<Text weight='semi-bold' color='red' size='title'>
		{text}
	</Text>
);

const AlertModal = ({ categoryId, onCancel, visible }) => {
	const dispatch = useDispatch();

	const deleteCategory = async () => {
		if (categoryId !== undefined || null) {
			await dispatch(deleteFoodCategory(categoryId));
			await dispatch(getFoodCategories());
			onCancel();
		}
	};

	return (
		<CustomModal onCancel={onCancel} visible={visible} title={<Title text='UYARI' />}>
			<Row justify='center'>
				<Text weight='light'>Seçilen veriyi silmek istediğinizden emin misiniz?</Text>
			</Row>

			<Row justify='center' style={{ marginTop: '48px', marginBottom: '32px' }} gutter={[32, 0]}>
				<Col span={8}>
					<DeleteButton type='secondary' onClick={() => onCancel()}>
						<Text color='white' weight='semi-bold'>
							İptal
						</Text>
					</DeleteButton>
				</Col>
				<Col span={8}>
					<SendButton type='secondary' onClick={() => deleteCategory()}>
						<Text color='white' weight='semi-bold'>
							Sil
						</Text>
					</SendButton>
				</Col>
			</Row>
		</CustomModal>
	);
};

export default AlertModal;
