import styled from 'styled-components';
import { Col, Input as input, Divider as divider, Button as btn } from 'antd';
import { between } from 'polished';
import Card from '../../components/Card/index';
import button from '../../components/Button/index';
import { Text as text } from '../../components/Typography/styles';

export const FoodTagsContent = styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const FoodTagsCol = styled(Col)``;

export const Content = styled(Col)`
	padding: 0;
`;
export const Divider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	margin-top: 64px;
	margin-bottom: 32px;
	height: 2px;
	background: ${({ theme }) => theme.borderColor};
`;

export const Input = styled(input)`
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	margin-top: 32px;
`;

export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
`;

export const FoodnameLabel = styled.div`
	padding: 5px 8px;
	border: 2px solid ${({ theme }) => theme.borderColor};
	margin-right: 10px;
	margin-bottom: 20px;
	border-radius: 3px;

	&:active, &:hover, &:focus{
		border-color: ${({ theme }) => theme.primaryColor};
	}
`;

export const CustomText = styled(text)`
	&.buttons {
		margin-left: 3px;
	}

	&.tag-text {
		@media (min-width: 1200px) {
			font-size: 11px;
		}
	}

	&.name-size {
		@media (min-width: 1200px) {
			font-size: 13px;
		}
	}

	&.price-size {
		@media (min-width: 1200px) {
			font-size: 12px;
		}
	}
`;

export const EditButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const EditSaveButton = styled(button)`
	height: 48px !important;
	width: 100%;
`;

export const DeleteButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	background-color: #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		background-color: #f38262;
		transition: 0.2s;
	}
`;
