/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Text } from '../../components/Typography/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Spin, message, Checkbox, Row } from 'antd';
import { AuthPermissionContent, CardTitle, Item, CancelButton, SendButton, Content, TableStyle } from './styles';
import TableItem from './TableItem';
import {
	getPermissions,
	createPermission,
	updatePermission,
} from '../../../services/Redux/Permission/api';

const permissionData = [
	{ value: 'Genel Ayarlar' },
	{ value: 'Restorantlar' },
	{ value: 'Siparişler' },
	{ value: 'Kullanıcılar' },
	{ value: 'İstatistikler' },
	{ value: 'Yardım Merkezi' },
	{ value: 'Yetkilendirme' },
];

const AuthPermission = () => {
	const dispatch = useDispatch();
	const [isEdit, setIsEdit] = useState(false);
	const [permissions, setPermissions] = useState([]);
	const [permissionInput, setPermissionInput] = useState('');
	const [selectedPerm, setSelectedPerm] = useState(null);
	const [checkedList, setCheckedList] = useState([]);

	useEffect(() => {
		dispatch(getPermissions());
	}, []);

	const { permissionList } = useSelector((state) => state.permissionStore);

	useEffect(() => {
		if (permissionList) {
			setPermissions(permissionList);
		}
	}, [permissionList]);

	const createPerm = async () => {
		if (checkedList.length === 0) {
			message.error('Yetki içeriği boş geçilemez !');
		} else if (checkedList.length !== 0 && !isEdit) {
			await dispatch(createPermission(checkedList.map((item) => ' ' + item).toString()));
			dispatch(getPermissions());
			setCheckedList([]);
		} else if (checkedList.length !== 0 && isEdit) {
			await dispatch(updatePermission(selectedPerm, checkedList.map((item) => ' ' + item).toString()));
			dispatch(getPermissions());
			setSelectedPerm(null);
			setCheckedList([]);
			setIsEdit(false);
		}
	};

	const editPerm = (id, content) => {
		setIsEdit(true);
		const permArray = content.split(',');

		setCheckedList(permArray.map((item) => item.trim()));

		setSelectedPerm(id);
	};

	const cancelPerm = () => {
		setIsEdit(false);
		setPermissionInput('');
		setCheckedList([]);
		setSelectedPerm(null);
	};

	const onChange = (e) => {
		if (checkedList.includes(e.target.value)) {
			const newArray = checkedList.filter((item) => item !== e.target.value && item);
			setCheckedList(newArray);
		} else {
			setCheckedList([...checkedList, e.target.value]);
		}
	};

	return (
		<Col md={24} lg={24} xl={22} xxl={22}>
			<AuthPermissionContent
				title={
					<CardTitle size='title' weight='light'>
						Yetkilendirme
					</CardTitle>
				}
			>
				<Text size='regular' weight='light'>
					Buradan oluşturduğun kullanıcılara verebileceğin rollerin yetkilerini oluşturabilirsin.
				</Text>
				<Item>
					<Text size='regular' weight='semi-bold'>
						Yetkiler:
					</Text>
				</Item>
				<Item>
					<Text size='regular' weight='semi-bold'>
						{isEdit ? 'Yetki Düzenle:' : 'Yetki Oluştur:'}
					</Text>
				</Item>
				<Item>
					<Col span={18}>
						<Row>
							{permissionData.map(({ value }) => (
								<Col span={8} style={{ marginTop: '32px' }}>
									<Checkbox value={value} onChange={onChange} checked={checkedList.includes(value) ? true : false}>
										<Text weight='semi-bold'>{value}</Text>
									</Checkbox>
								</Col>
							))}
						</Row>
					</Col>

					{/* <Col span={12}>
						<Input
							placeholder='Yetki adı oluştur'
							value={permissionInput}
							onChange={(e) => setPermissionInput(e.target.value)}
						/>
					</Col> */}
				</Item>

				<Col md={{ span: 12, offset: 12 }} lg={{ span: 10, offset: 14 }} xl={{ span: 8, offset: 16 }}>
					<Item gutter={[32, 0]}>
						<Col span={12}>
							<CancelButton onClick={() => cancelPerm()}>
								<Text color='red'>İptal Et</Text>
							</CancelButton>
						</Col>
						<Col span={12}>
							<SendButton type='secondary' onClick={() => createPerm()}>
								<Text color='white' weight='semi-bold'>
									Kaydet
								</Text>
							</SendButton>
						</Col>
					</Item>
				</Col>
				<Item>
					<Text size='regular' weight='semi-bold'>
						Liste:
					</Text>
				</Item>
				<TableStyle>
					<Content>
						<Col span={2}>
							<Text weight='semi-bold' size='regular'>
								No:
							</Text>
						</Col>
						<Col span={22}>
							<Text weight='semi-bold' size='regular'>
								Yetkiler
							</Text>
						</Col>
					</Content>
					{permissions ? (
						permissions.map((permissions) => <TableItem permissions={permissions} editPerm={editPerm} />)
					) : (
						<Spin />
					)}
				</TableStyle>
			</AuthPermissionContent>
		</Col>
	);
};

export default AuthPermission;
