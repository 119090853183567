/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Text } from '../../components/Typography/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Spin, message } from 'antd';
import {
  AuthUsersContent,
  CardTitle,
  Item,
  Content,
  TableStyle,
} from './styles';
import TableItem from './TableItem';
import {
  getActiveUsers,
  getSuspendUsers,
} from '../../../services/Redux/Users/api';
import { getRoles } from '../../../services/Redux/Roles/api';
import { patchUser } from '../../../services/Redux/AuthUsers/api';
import UserCard from './UserCard';

const AuthUsers = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const { rolesList } = useSelector((state) => state.roleStore);
  const { activeUsersList, suspendUsersList } = useSelector(
    (state) => state.usersStore
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getActiveUsers());
    dispatch(getSuspendUsers());
  }, []);

  useEffect(() => {
    if (activeUsersList && suspendUsersList) {
      setUserList([...activeUsersList, ...suspendUsersList]);
    }
  }, [activeUsersList, suspendUsersList]);

  const patchUserRole = async (userStatus) => {
    if (selectedRole && selectedRole?.id) {
      dispatch(patchUser(selectedUser.id, selectedRole.id, userStatus)).then(
        () => {
          dispatch(getActiveUsers());
          dispatch(getSuspendUsers());
          cancelUserRole();
        }
      );
    } else {
      message.error('Lütfen Rol Seçimi Yapınız. !');
    }
  };

  const cancelUserRole = () => {
    // setSelectedUser(null);
    setSelectedRole(null);
  };

  return (
    <Col md={24} lg={24} xl={22} xxl={22}>
      <AuthUsersContent
        title={
          <CardTitle size="title" weight="light">
            Yetkilendirme
          </CardTitle>
        }
      >
        <UserCard
          roles={rolesList}
          setSelectedRole={setSelectedRole}
          cancelUserRole={cancelUserRole}
          patchUserRole={patchUserRole}
          selectedUser={selectedUser}
          selectedRole={selectedRole}
        />

        <Item>
          <Text size="regular" weight="semi-bold">
            Liste:
          </Text>
        </Item>
        <TableStyle>
          <Content>
            <Col span={1}>
              <Text weight="semi-bold" size="regular">
                No:
              </Text>
            </Col>
            <Col span={4}>
              <Text weight="semi-bold" size="regular">
                İsim Soyisim
              </Text>
            </Col>
            <Col span={5}>
              <Text weight="semi-bold" size="regular">
                E-posta
              </Text>
            </Col>
            <Col span={2}>
              <Text weight="semi-bold" size="regular">
                Roller
              </Text>
            </Col>
            <Col span={5}>
              <Text weight="semi-bold" size="regular">
                Yetkiler
              </Text>
            </Col>
            <Col span={3}>
              <Text weight="semi-bold" size="regular">
                Kayıt Tarih/Saat
              </Text>
            </Col>
            <Col span={2}>
              <Text weight="semi-bold" size="regular">
                Durumu
              </Text>
            </Col>
          </Content>
          {userList ? (
            userList.map((user, index) =>
              user?.groups && user?.groups[0] === 3 ? (
                <TableItem
                  key={index}
                  user={user}
                  roles={rolesList}
                  setSelectedUser={setSelectedUser}
                  setSelectedRole={setSelectedRole}
                />
              ) : user?.user?.groups && user?.user?.groups[0] === 3 ? (
                <TableItem
                  key={index}
                  user={user?.user}
                  roles={rolesList}
                  setSelectedUser={setSelectedUser}
                  setSelectedRole={setSelectedRole}
                />
              ) : null
            )
          ) : (
            <Spin />
          )}
        </TableStyle>
      </AuthUsersContent>
    </Col>
  );
};

export default AuthUsers;
