import React, { useState, useEffect } from 'react';
import { Col, Spin } from 'antd';
import moment from 'moment';
import { StatisticsPageStyles, Wrapper, ContentTop, ContentMiddle } from './styles';
import GeneralRatingCard from './GeneralRatingCard';
import ScoringsCard from './ScoringsCard';
import SaleStatsCard from './SaleStatsCard';
import ProfitCard from './ProfitCard';
import GraphCard from './GraphCard';
import xml from '../../../../services/Api/request';

const DonguStats = () => {
	const [statisticsToRender, setStatisticsToRender] = useState();
	const [weeklyDataToRender, setWeeklyDataToRender] = useState();
	const [monthlyDataToRender, setMonthlyDataToRender] = useState();
	const [isWeekly, setIsWeekly] = useState(true);

	useEffect(() => {
		const loadStats = async () => {
			const result = await xml({
				path: `superadmin/statistics/`,
				method: 'GET',
				sendToken: true,
			});

			setStatisticsToRender(result);
		};
		loadStats();
		const loadWeeklyData = async () => {
			const result = await xml({
				path: `superadmin/statistics/weekly/?state_date=${moment().startOf('isoWeek').format('YYYY-MM-DD')}`,
				method: 'GET',
				sendToken: true,
			});
			setWeeklyDataToRender(result);
		};
		loadWeeklyData();
		const loadMonthlyData = async () => {
			const result = await xml({
				path: `superadmin/statistics/monthly/?year=${moment().year()}`,
				method: 'GET',
				sendToken: true,
			});
			setMonthlyDataToRender(result);
		};
		loadMonthlyData();
	}, []);

	return (
		<StatisticsPageStyles>
			{statisticsToRender && weeklyDataToRender && monthlyDataToRender ? (
				<Wrapper xxl={24} xl={24}>
					<ContentTop
						gutter={[
							{ xs: 12, md: 12, xl: 32 },
							{ xs: 12, sm: 12, md: 32, lg: 32, xl: 32 },
						]}
					>
						<Col md={12} lg={12} xl={12} xxl={7}>
							<GeneralRatingCard
								voteCount={statisticsToRender.total_vote}
								generalRating={statisticsToRender.total_avg}
							/>
						</Col>
						<Col md={12} lg={12} xl={12} xxl={7}>
							<ScoringsCard
								fiveStarRating={statisticsToRender.total_five_star_avg}
								fourStarRating={statisticsToRender.total_four_star_avg}
								threeStarRating={statisticsToRender.total_three_star_avg}
								twoStarRating={statisticsToRender.total_two_star_avg}
								oneStarRating={statisticsToRender.total_one_star_avg}
							/>
						</Col>
						<Col md={12} lg={12} xl={12} xxl={5}>
							<SaleStatsCard TotalProfit={statisticsToRender.total_count} />
						</Col>
						<Col md={12} lg={12} xl={12} xxl={5}>
							<ProfitCard TotalProfit={statisticsToRender.total_price} />
						</Col>
					</ContentTop>
					<ContentMiddle>
						<Col span={24}>
							<GraphCard
								setIsWeekly={setIsWeekly}
								isWeekly={isWeekly}
								weeklyData={weeklyDataToRender.data}
								monthlyData={monthlyDataToRender.data}
							/>
						</Col>
					</ContentMiddle>
				</Wrapper>
			) : (
				<Spin size='large' />
			)}
		</StatisticsPageStyles>
	);
};

export default DonguStats;
