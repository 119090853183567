export const GET_PENDING_APPROVAL_START = 'GET_PENDING_APPROVAL_START';
export const GET_PENDING_APPROVAL_SUCCESS = 'GET_PENDING_APPROVAL_SUCCESS';
export const GET_PENDING_APPROVAL_FAIL = 'GET_PENDING_APPROVAL_FAIL';

export const UPDATE_PENDING_STORE_START = 'UPDATE_PENDING_STORE_START';
export const UPDATE_PENDING_STORE_SUCCESS = 'UPDATE_PENDING_STORE_SUCCESS';
export const UPDATE_PENDING_STORE_FAIL = 'UPDATE_PENDING_STORE_FAIL';

export const DELETE_PENDING_STORE_START = 'DELETE_PENDING_STORE_START';
export const DELETE_PENDING_STORE_SUCCESS = 'DELETE_PENDING_STORE_SUCCESS';
export const DELETE_PENDING_STORE_FAIL = 'DELETE_PENDING_STORE_FAIL';

export const UPDATE_PENDING_PACKET_START = 'UPDATE_PENDING_PACKET_START';
export const UPDATE_PENDING_PACKET_SUCCESS = 'UPDATE_PENDING_PACKET_SUCCESS';
export const UPDATE_PENDING_PACKET_FAIL = 'UPDATE_PENDING_PACKET_FAIL';

export const DELETE_PENDING_PACKET_START = 'DELETE_PENDING_PACKET_START';
export const DELETE_PENDING_PACKET_SUCCESS = 'DELETE_PENDING_PACKET_SUCCESS';
export const DELETE_PENDING_PACKET_FAIL = 'DELETE_PENDING_PACKET_FAIL';
