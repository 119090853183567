import * as actionTypes from './actionTypes';

export const getRolesStartAction = () => ({
	type: actionTypes.GET_ROLES_START,
});

export const getRolesSuccessAction = (roles) => ({
	type: actionTypes.GET_ROLES_SUCCESS,
	payload: roles,
});

export const getRolesFailAction = (error) => ({
	type: actionTypes.GET_ROLES_FAIL,
	payload: error,
});

//CREATE

export const createRolesStartAction = () => ({
	type: actionTypes.CREATE_ROLES_START,
});

export const createRolesSuccessAction = (roles) => ({
	type: actionTypes.CREATE_ROLES_SUCCESS,
	payload: roles,
});

export const createRolesFailAction = (error) => ({
	type: actionTypes.CREATE_ROLES_FAIL,
	payload: error,
});

//UPDATE

export const updateRolesStartAction = () => ({
	type: actionTypes.UPDATE_ROLES_START,
});

export const updateRolesSuccessAction = (roles) => ({
	type: actionTypes.UPDATE_ROLES_SUCCESS,
	payload: roles,
});

export const updateRolesFailAction = (error) => ({
	type: actionTypes.UPDATE_ROLES_FAIL,
	payload: error,
});

//DELETE

export const deleteRolesStartAction = () => ({
	type: actionTypes.DELETE_ROLES_START,
});

export const deleteRolesSuccessAction = (roles) => ({
	type: actionTypes.DELETE_ROLES_SUCCESS,
	payload: roles,
});

export const deleteRolesFailAction = (error) => ({
	type: actionTypes.DELETE_ROLES_FAIL,
	payload: error,
});
