import styled from 'styled-components';
import { Row, Select as select, Input as input } from 'antd';

export const Header = styled(Row)`
	margin-top: 32px;
`;

export const Select = styled(select)`
	width: 100%;

	.ant-select-selector {
		height: 48px !important;
		background-color: #f2f2fc !important;
		border-radius: 3px !important;
		align-items: center;
	}
	.ant-select-selection-item {
		display: flex;
		align-items: center;
	}
	max-width: 342px;
`;

export const Input = styled(input)`
	margin-left: 20px;
	position: relative;
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	padding-right: 0px;
`;

export const Location = styled(Row)`
	margin-top: 32px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const TableStyle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	border-radius: 0 0 2px 2px;
	background: ${({ theme }) => theme.lightColor};
`;

export const Content = styled(Row)`
	margin-top: 32px;
	margin-bottom: 16px;
	display: flex;
	align-content: center;
`;

export const Footer = styled(Row)`
	width: 100%;
	height: 75px;
	border-radius: 30px 30px 0 0;
	background: ${({ theme }) => theme.lightColor};
`;

export const NavButton = styled.img`
	padding-left: 30px;
	padding-right: 30px;
	cursor: pointer;
`;
