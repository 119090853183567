import styled from "styled-components";
import Card from "../../../components/Card/index";
import { between } from "polished";
import { Text as text } from "../../../components/Typography/styles";
import { Input as input, Button as btn, Row, Select, TimePicker } from "antd";
import button from "../../../components/Button/index";

export const AuthUsersContents = styled(Card)`
  width: 100%;
  margin-top: 2rem;

  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-body {
    padding-left: 39px;
    padding-right: 41px;
  }
  margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
  font-size: ${between("14px", "22px")};
`;

export const Item = styled(Row)`
  margin-top: 64px;
`;

export const Input = styled(input)`
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const Timer = styled(TimePicker)`
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const SendButton = styled(button)`
  height: 48px !important;
  width: 100%;
`;

export const CancelButton = styled(btn)`
  height: 48px !important;
  width: 100%;

  border: 2px solid #f38262;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #f38262;
    transition: 0.2s;
  }
`;

export const EditButton = styled(btn)`
  height: 48px !important;
  width: 100%;

  border: 2px solid #ffbc41;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #ffbc41;
    transition: 0.2s;
  }
`;

export const CustomSelect = styled(Select)`
  margin-top: 32px;
  border-radius: 5px;
  width: 100%;

  .ant-select-selection-item {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 400;
  }

  .ant-select-selector {
    border-width: 2px !important;
    height: 48px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .ant-select-selector:hover + .ant-select-arrow {
    color: ${({ theme }) => theme.primaryColor};
  }
  .ant-select-arrow {
    padding-right: 24px;
  }
`;

export const SelectList = styled(Select)`
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px !important;
    transition: all 0.3s !important;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector::after {
    line-height: 50px;
  }
`;

export const Wrapper = styled(Row)`
  background-color: #fff;
  height: 48px;
  border-radius: 5px;
  margin-top: 32px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;
