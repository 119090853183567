import styled from 'styled-components';
import Card from '../../components/Card/index';
import { between } from 'polished';
import { Col, Divider as divider } from 'antd';
import { Text as text } from '../../components/Typography/styles';

export const RegisteredContent = styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;

export const RegisteredContentCol = styled(Col)``;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
	cursor: pointer;
`;

export const TitleDivider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	min-width: 30% !important;
	width: 30%;
	height: 2px;
	margin-top: 2px;
	margin-bottom: 0px;
	background: ${({ theme }) => theme.primaryColor};
`;

export const ArrowImg = styled.img`
	padding-top: 5px;
	height: 75%;
	cursor: pointer;
`;
