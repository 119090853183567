/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { Input as input, Button as btn, Modal } from 'antd';
import button from '../../../../components/Button';

export const CustomModal = styled(Modal)`
	min-width: 900px;

	.ant-modal-header {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		border-bottom: 3px solid ${({ theme }) => theme.borderColor};
	}

	.ant-modal-content {
		border-radius: 15px;
	}

	.ant-modal-footer {
		display: none;
		height: 30px;
		.ant-btn {
			display: none;
		}
		/* background: ${({ theme }) => theme.borderColor}; */
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}

	.ant-modal-close-x {
		display: none;
	}
`;

export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
`;

export const Input = styled(input)`
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	padding-right: 0px;
`;
