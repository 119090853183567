/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Text } from '../../components/Typography/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import {
  PendingContent,
  CardTitle,
  Input,
  Location,
  TableStyle,
  Select,
  Header,
  FilterButton,
} from './styles';
import search from '../../../Assets/components/search.png';
import StoreItem from './StoreItem';
import PacketItem from './PacketItem';
import MenuItem from './MenuItem';
import { getPendings } from '../../../services/Redux/PendingRestaurants/api';
import useResponsive from '../../../helpers/Hooks/useMediaQuery';

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: '48px',
      width: '48px',
      backgroundColor: '#ffbc41',
      justifyContent: 'center',
      borderRadius: '50px',
      position: 'absolute',
      right: '-18px',
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const PendingRestaurants = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [pendingStore, setPendingStore] = useState();
  const [pendingBilling, setPendingBilling] = useState();
  const [pendingPacket, setPendingPacket] = useState();
  const [pendingMenu, setPendingMenu] = useState();
  const [search, setSearch] = useState('');
  const [city, setCity] = useState('');
  const [sortByName, setSortByName] = useState(false);

  const pendingList = useSelector((state) => state?.pendingStore?.pendingList);

  useEffect(() => {
    dispatch(getPendings());
  }, []);

  useEffect(() => {
    // !!! Every 10 seconds, we trigger the function repeteadly. But this can be changed.
    setInterval(() => dispatch(getPendings()), 5000);
  }, []);

  const { xxll } = useResponsive();

  // Filtering, changing city, search bar, and everything related to it.
  useEffect(() => {
    if (pendingList) {
      if ((city === 'Tüm' || !city) && !search) {
        setPendingStore(pendingList[0]);
        setPendingMenu(pendingList[1]);
        setPendingPacket(pendingList[2]);
      } else if ((city === 'Tüm' || !city) && !!search) {
        setPendingStore(
          pendingList[0].filter((item) =>
            item?.store?.name
              ?.toLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        );
        setPendingMenu(
          pendingList[1].filter((item) =>
            item?.store?.name
              ?.toLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        );
        setPendingPacket(
          pendingList[2].filter((item) =>
            item?.store?.name
              ?.toLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        );
      } else if (!!city && city !== 'Tüm' && !search) {
        setPendingStore(
          pendingList[0].filter((item) => item?.store?.city === city)
        );
        setPendingMenu(
          pendingList[1].filter((item) => item?.store?.city === city)
        );
        setPendingPacket(
          pendingList[2].filter((item) => item?.store?.city === city)
        );
      } else if (!!city && city !== 'Tüm' && !!search) {
        setPendingStore(
          pendingList[0]
            .filter((item) => item?.store?.city === city)
            .filter((item) =>
              item?.store?.name
                ?.toLowerCase()
                .includes(search.toLocaleLowerCase())
            )
        );
        setPendingMenu(
          pendingList[1]
            .filter((item) => item?.store?.city === city)
            .filter((item) =>
              item?.store?.name
                ?.toLowerCase()
                .includes(search.toLocaleLowerCase())
            )
        );
        setPendingPacket(
          pendingList[2]
            .filter((item) => item?.store?.city === city)
            .filter((item) =>
              item?.store?.name
                ?.toLowerCase()
                .includes(search.toLocaleLowerCase())
            )
        );
      }
    }
  }, [pendingList, city, search]);

  return (
    <Col md={24} lg={24} xl={xxll ? 22 : 24} xxl={22}>
      <PendingContent
        title={
          <CardTitle size="title" weight="light">
            Onay Bekleyenler
          </CardTitle>
        }
      >
        <Header>
          <Col span={10} style={{ display: 'flex' }}>
            <Select placeholder="Bölgeye Göre" onChange={(e) => setCity(e)}>
              <Option value="Tüm">Tüm</Option>
              <Option value="İstanbul">İstanbul</Option>
              <Option value="Ankara">Ankara</Option>
              <Option value="İzmir">İzmir</Option>
            </Select>
            <FilterButton onClick={() => setSortByName(!sortByName)}>
              İsme Göre
            </FilterButton>
          </Col>
          <Col span={13}>
            <Input
              placeholder="Ara"
              suffix={<SearchIcon />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Header>

        <Location>
          <Text>Restorantlar</Text>
        </Location>
        <TableStyle>
          {pendingStore &&
            !sortByName &&
            pendingStore.map((store) => <StoreItem store={store} />)}

          {pendingPacket &&
            !sortByName &&
            pendingPacket.map((packet) => <PacketItem packet={packet} />)}

          {pendingMenu &&
            !sortByName &&
            pendingMenu.map((menu) => <MenuItem menu={menu} />)}

          {pendingStore &&
            sortByName &&
            pendingStore
              .sort((a, b) => (a.store.name < b.store.name ? -1 : 1))
              .map((store) => <StoreItem store={store} />)}

          {pendingPacket &&
            sortByName &&
            pendingPacket
              .sort((a, b) => (a.store.name < b.store.name ? -1 : 1))
              .map((packet) => <PacketItem packet={packet} />)}

          {pendingMenu &&
            sortByName &&
            pendingMenu
              .sort((a, b) => (a.store.name < b.store.name ? -1 : 1))
              .map((menu) => <MenuItem menu={menu} />)}
        </TableStyle>
      </PendingContent>
    </Col>
  );
};

export default PendingRestaurants;
