import { useState } from 'react';
import { TableStyle, Item, EditButton, CancelButton } from './styles';
import { Text } from '../../../components/Typography/styles';
import editImg from '../../../../Assets/components/edit-btn.svg';
import cancelImg from '../../../../Assets/components/cancel-btn.svg';
import AlertModal from '../AlertModal';
import { useDispatch } from 'react-redux';
import { deletePermission, getPermissions } from '../../../../services/Redux/Permission/api';
import { Row, Col } from 'antd';

const TableItem = ({ permissions, editPerm }) => {
	const dispatch = useDispatch();
	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const handleCancel = () => {
		setIsAlertVisible(false);
	};

	const deletePerm = async () => {
		await dispatch(deletePermission(permissions.id));
		dispatch(getPermissions());
	};

	return (
		<>
			<TableStyle>
				<Item span={2} style={{ alignItems: 'center', justifyContent: 'center', border: '0px' }}>
					<Text weight='light'>{permissions.id}</Text>
				</Item>
				<Item md={18} lg={19} xl={19} xxl={20} style={{ alignItems: 'center', justifyContent: 'start' }}>
					<Text weight='light' style={{ marginLeft: '22px' }}>{`${permissions.permission}`}</Text>
				</Item>
				<Item md={4} lg={3} xl={3} xxl={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
					<Row gutter={[16, 0]}>
						<Col span={12}>
							<EditButton src={editImg} onClick={() => editPerm(permissions.id, permissions.permission)} />
						</Col>
						<Col span={12}>
							<CancelButton src={cancelImg} onClick={() => setIsAlertVisible(true)} />
						</Col>
					</Row>
				</Item>
			</TableStyle>
			<AlertModal deletePerm={deletePerm} visible={isAlertVisible} onCancel={handleCancel} />
		</>
	);
};

export default TableItem;
