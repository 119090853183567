import { useState } from 'react';
import { TableStyle, Item, InfoWrapper, PanelButton } from './styles';
import { Text } from '../../../components/Typography/styles';
import { Col } from 'antd';
import rightOutline from '../../../../Assets/components/right-outline.svg';
import PacketModal from './PacketModal';

const PacketItem = ({ packet }) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<>
			<TableStyle>
				<Item span={2} style={{ alignItems: 'center', border: '0px' }}>
					<Text size='smallRegular' weight='light'>
						{packet ? packet.id : 'null'}
					</Text>
				</Item>
				<Item span={4}>
					<Col span={7} style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}>
						{packet.store ? <img src={packet.store.photo} alt='' width='48px' height='48px' /> : <Text value='null' />}
					</Col>
					<Col span={17} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text weight='semi-bold'> {packet.store ? packet.store.name : ''}</Text>
						<Text size='smallRegular' weight='light'>
							{packet?.store ? `${packet?.store?.province} - ${packet?.store?.city}` : ''}
						</Text>
					</Col>
				</Item>
				<Item span={16} style={{ alignItems: 'center', justifyContent: 'start' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '22px' }}>
						Fiyat Güncellemesi hakkında onay bekliyor.
					</Text>
				</Item>
				<Item span={2} style={{ alignItems: 'center' }}>
					<PanelButton onClick={() => setIsShown(!isShown)}>
						<img src={rightOutline} alt='rightOutLine' />
					</PanelButton>
				</Item>
			</TableStyle>
			<InfoWrapper style={{ display: isShown ? 'block' : 'none' }}>
				<PacketModal packetId={packet.id} custom={packet.min_order_price} sale={packet.min_discounted_order_price} setIsShown={setIsShown} />
			</InfoWrapper>
		</>
	);
};

export default PacketItem;
