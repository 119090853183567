import * as actions from './actions';

export const getActiveUsers = () => async (dispatch, getState, service) => {
  dispatch(actions.getActiveUsersStartAction());
  await service({
    path: 'superadmin/user-operations/list_active/',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getActiveUsersSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.getActiveUsersFailAction(error));
    });
};

export const getSuspendUsers = () => async (dispatch, getState, service) => {
  dispatch(actions.getSuspendUsersStartAction());
  await service({
    path: 'superadmin/user-operations/list_suspend/',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getSuspendUsersSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.getSuspendUsersFailAction(error));
    });
};

export const createSuspendUser = (id, desc, time) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createSuspendUserStartAction());
  await service({
    path: 'superadmin/user-operations/suspend/',
    method: 'POST',
    sendToken: true,
    body: {
      description: desc,
      user: id,
      time: time,
    },
  })
    .then((data) => {
      dispatch(actions.createSuspendUserSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createSuspendUserFailAction(error));
    });
};

export const getTerminateUsers = () => async (dispatch, getState, service) => {
  dispatch(actions.getTerminateUsersStartAction());
  await service({
    path: 'superadmin/user-operations/list_terminate/',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getTerminateUsersSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.getTerminateUsersFailAction(error));
    });
};

export const createTerminateUser = (id, desc) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.createTerminateUserStartAction());
  await service({
    path: 'superadmin/user-operations/terminate/',
    method: 'POST',
    sendToken: true,
    body: {
      description: desc,
      user: id,
    },
  })
    .then((data) => {
      dispatch(actions.createTerminateUserSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.createTerminateUserFailAction(error));
    });
};
