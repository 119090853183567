import styled from 'styled-components';
import { Col, Row, Button as btn } from 'antd';

export const Location = styled(Row)`
	padding-left: 32px;
	margin-top: 20px;
	border-bottom: ${({ theme }) => ` 3px solid ${theme.borderColor}`};
`;

export const ContactStyles = styled(Col)``;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 100%;

	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const InfoContent = styled(Row)`
	margin-top: 20px;
	padding: 24px 40px;
	border-radius: 8px;
	border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const InfoItem = styled(Row)`
	margin-top: 32px;
`;
