import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import dashboardAuthReducer from './auth/reducer';
import foodCategoriesReducer from './FoodCategories/reducer';
import foodTagsReducer from './FoodTags/reducer';
import SpecialReasonsReducer from './SpecialReasons/reducer';
import ContentCreatorReducer from './ContentCreator/reducer';
import HelpRequestsReducer from './HelpRequests/reducer';
import OrdersReducer from './Orders/reducer';
import UsersReducer from './Users/reducer';
import StatisticsReducer from './Statistics/reducer';
import PermissionReducer from './Permission/reducer';
import RoleReducer from './Roles/reducer';
import PendingReducer from './PendingRestaurants/reducer';
import RegisteredRestaurantsReducer from './RegisteredRestaurants/reducer';
import BoxNamesReducer from './Box/reducer';
import StoreReducer from './Store/reducer';
import PendingMenuReducer from './PendingMenu/reducer';
import xmlHttpRequestService from '../Api/request';
import UserInfoCardReducer from '../Redux/AddRestaurant/reducer';
import StoreInfoCardReducer from '../Redux/AddRestaurant/reducer';
import PackageInfoCardReducer from '../Redux/AddRestaurant/reducer';

const reducers = combineReducers({
  authStore: dashboardAuthReducer,
  foodCategoriesStore: foodCategoriesReducer,
  foodTagsStore: foodTagsReducer,
  specialReasonsStore: SpecialReasonsReducer,
  contentCreatorStore: ContentCreatorReducer,
  helpRequestsStore: HelpRequestsReducer,
  ordersStore: OrdersReducer,
  usersStore: UsersReducer,
  statisticsStore: StatisticsReducer,
  permissionStore: PermissionReducer,
  roleStore: RoleReducer,
  pendingStore: PendingReducer,
  registeredRestaurantsStore: RegisteredRestaurantsReducer,
  boxNamesStore: BoxNamesReducer,
  storeStore: StoreReducer,
  pendingMenuStore: PendingMenuReducer,
  userCardInfo: UserInfoCardReducer,
  storeCardInfo: StoreInfoCardReducer,
  packageCardInfo: PackageInfoCardReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(xmlHttpRequestService), logger)
  )
);

export default store;
