import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	activeRestaurantsList: null,
	activeRestaurantsError: null,

	updateRestToActive: null,
	updateRestToActiveError: null,

	suspendRestaurantsList: null,
	suspendRestaurantsError: null,

	terminateRestaurantsList: null,
	terminateRestaurantsError: null,

	createSuspend: null,
	createSuspendError: null,

	createTerminate: null,
	createTerminateError: null,

	getDetail: null,
	getDetailError: null,

	deletePacket: null,
	deletePacketError: null,
};

const getActiveRestaurantsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getActiveRestaurantsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	activeRestaurantsList: action.payload,
});

const getActiveRestaurantsFailReducer = (state, action) => ({
	...state,
	loading: false,
	activeRestaurantsError: action.payload,
});

// UPDATE RESTORANT TO ACTIVE

const updateRestaurantsToActiveStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateRestaurantsToActiveSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateRestToActive: action.payload,
});

const updateRestaurantsToActiveFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateRestToActiveError: action.payload,
});

// SUSPEND

const getSuspendRestaurantsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getSuspendRestaurantsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	suspendRestaurantsList: action.payload,
});

const getSuspendRestaurantsFailReducer = (state, action) => ({
	...state,
	loading: false,
	suspendRestaurantsError: action.payload,
});

//UPDATE SUSPEND

const createSuspendRestaurantsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createSuspendRestaurantsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createSuspend: action.payload,
});

const createSuspendRestaurantsFailReducer = (state, action) => ({
	...state,
	loading: false,
	createSuspendError: action.payload,
});

//TERMINATE

const getTerminateRestaurantsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getTerminateRestaurantsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	terminateRestaurantsList: action.payload,
});

const getTerminateRestaurantsFailReducer = (state, action) => ({
	...state,
	loading: false,
	terminateRestaurantsError: action.payload,
});

// UPDATE TERMINATE

const createTerminateRestaurantsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createTerminateRestaurantsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createTerminate: action.payload,
});

const createTerminateRestaurantsFailReducer = (state, action) => ({
	...state,
	loading: false,
	createTerminateError: action.payload,
});

// GET RESTAURANT DETAİL

const getRestaurantsDetailStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getRestaurantsDetailSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	getDetail: action.payload,
});

const getRestaurantsDetailFailReducer = (state, action) => ({
	...state,
	loading: false,
	getDetailError: action.payload,
});

// DELETE RESTAURANT PACKET

const deleteRestaurantsPacketStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteRestaurantsPacketSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deletePacket: action.payload,
});

const deleteRestaurantsPacketFailReducer = (state, action) => ({
	...state,
	loading: false,
	deletePacketError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ACTIVE_RESTAURANTS_START:
			return getActiveRestaurantsStartReducer(state);
		case actionTypes.GET_ACTIVE_RESTAURANTS_SUCCESS:
			return getActiveRestaurantsSuccessReducer(state, action);
		case actionTypes.GET_ACTIVE_RESTAURANTS_FAIL:
			return getActiveRestaurantsFailReducer(state, action);

		case actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_START:
			return updateRestaurantsToActiveStartReducer(state);
		case actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_SUCCESS:
			return updateRestaurantsToActiveSuccessReducer(state, action);
		case actionTypes.UPDATE_RESTAURANTS_TO_ACTIVE_FAIL:
			return updateRestaurantsToActiveFailReducer(state, action);

		case actionTypes.GET_SUSPEND_RESTAURANTS_START:
			return getSuspendRestaurantsStartReducer(state);
		case actionTypes.GET_SUSPEND_RESTAURANTS_SUCCESS:
			return getSuspendRestaurantsSuccessReducer(state, action);
		case actionTypes.GET_SUSPEND_RESTAURANTS_FAIL:
			return getSuspendRestaurantsFailReducer(state, action);

		case actionTypes.GET_TERMINATE_RESTAURANTS_START:
			return getTerminateRestaurantsStartReducer(state);
		case actionTypes.GET_TERMINATE_RESTAURANTS_SUCCESS:
			return getTerminateRestaurantsSuccessReducer(state, action);
		case actionTypes.GET_TERMINATE_RESTAURANTS_FAIL:
			return getTerminateRestaurantsFailReducer(state, action);

		case actionTypes.CREATE_SUSPEND_RESTAURANTS_START:
			return createSuspendRestaurantsStartReducer(state);
		case actionTypes.CREATE_SUSPEND_RESTAURANTS_SUCCESS:
			return createSuspendRestaurantsSuccessReducer(state, action);
		case actionTypes.CREATE_SUSPEND_RESTAURANTS_FAIL:
			return createSuspendRestaurantsFailReducer(state, action);

		case actionTypes.CREATE_TERMINATE_RESTAURANTS_START:
			return createTerminateRestaurantsStartReducer(state);
		case actionTypes.CREATE_TERMINATE_RESTAURANTS_SUCCESS:
			return createTerminateRestaurantsSuccessReducer(state, action);
		case actionTypes.CREATE_TERMINATE_RESTAURANTS_FAIL:
			return createTerminateRestaurantsFailReducer(state, action);

		case actionTypes.GET_RESTAURANTS_DETAIL_START:
			return getRestaurantsDetailStartReducer(state);
		case actionTypes.GET_RESTAURANTS_DETAIL_SUCCESS:
			return getRestaurantsDetailSuccessReducer(state, action);
		case actionTypes.GET_RESTAURANTS_DETAIL_FAIL:
			return getRestaurantsDetailFailReducer(state, action);

		case actionTypes.DELETE_RESTAURANT_PACKET_START:
			return deleteRestaurantsPacketStartReducer(state);
		case actionTypes.DELETE_RESTAURANT_PACKET_SUCCESS:
			return deleteRestaurantsPacketSuccessReducer(state, action);
		case actionTypes.DELETE_RESTAURANT_PACKET_FAIL:
			return deleteRestaurantsPacketFailReducer(state, action);

		default:
			return state;
	}
};
export default reducer;
