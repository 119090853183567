import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	foodTagsList: null,
	foodTagsError: null,

	createFoodTags: null,
	createFoodTagsError: null,

	updateFoodTags: null,
	updateFoodTagsError: null,
};

const getFoodTagsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getFoodTagsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	foodTagsList: action.payload.results,
});

const getFoodTagsFailReducer = (state, action) => ({
	...state,
	loading: false,
	foodTagsError: action.payload,
});

const createFoodTagsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createFoodTagsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createFoodTags: action.payload,
});

const createFoodTagsFailReducer = (state, action) => ({
	...state,
	loading: false,
	createFoodTagsError: action.payload,
});

const updateFoodTagsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateFoodTagsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateFoodTags: action.payload,
});

const updateFoodTagsFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateFoodTagsError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FOOD_TAGS_START:
			return getFoodTagsStartReducer(state);
		case actionTypes.GET_FOOD_TAGS_SUCCESS:
			return getFoodTagsSuccessReducer(state, action);
		case actionTypes.GET_FOOD_TAGS_FAIL:
			return getFoodTagsFailReducer(state, action);
		case actionTypes.CREATE_FOOD_TAGS_START:
			return createFoodTagsStartReducer(state);
		case actionTypes.CREATE_FOOD_TAGS_SUCCESS:
			return createFoodTagsSuccessReducer(state, action);
		case actionTypes.CREATE_FOOD_TAGS_FAIL:
			return createFoodTagsFailReducer(state, action);
		case actionTypes.UPDATE_FOOD_TAGS_START:
			return updateFoodTagsStartReducer(state);
		case actionTypes.UPDATE_FOOD_TAGS_SUCCESS:
			return updateFoodTagsSuccessReducer(state, action);
		case actionTypes.UPDATE_FOOD_TAGS_FAIL:
			return updateFoodTagsFailReducer(state, action);
		default:
			return state;
	}
};

export default reducer;
