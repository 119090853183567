import styled from 'styled-components';
import { Col, Divider as divider, Input as input, Button as btn, Radio } from 'antd';
import { between } from 'polished';
import Card from '../../components/Card/index';
import { Text as text } from '../../components/Typography/styles';
import button from '../../components/Button/index';

export const SpecialContent = styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const SpecialCol = styled(Col)``;

export const Content = styled(Col)`
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Divider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	margin-top: 64px;
	margin-bottom: 32px;
	height: 2px;
	background: ${({ theme }) => theme.borderColor};
`;

export const Input = styled(input)`
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	margin-top: 32px;
`;

export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
`;

export const CancelButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const RadioBtn = styled(Radio)`
	width: 100%;
	margin-top: 32px;
`;

export const DeleteButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	background-color: #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		background-color: #f38262;
		transition: 0.2s;
	}
`;
