import * as actionTypes from './actionTypes';

export const getPendingApprovalStartAction = () => ({
  type: actionTypes.GET_PENDING_APPROVAL_START,
});

export const getPendingApprovalSuccessAction = (pending) => ({
  type: actionTypes.GET_PENDING_APPROVAL_SUCCESS,
  payload: pending,
});

export const getPendingApprovalFailAction = (error) => ({
  type: actionTypes.GET_PENDING_APPROVAL_FAIL,
  payload: error,
});

// UPDATE PENDING STORE

export const updatePendingStoreStartAction = () => ({
  type: actionTypes.UPDATE_PENDING_STORE_START,
});

export const updatePendingStoreSuccessAction = (pending) => ({
  type: actionTypes.UPDATE_PENDING_STORE_SUCCESS,
  payload: pending,
});

export const updatePendingStoreFailAction = (error) => ({
  type: actionTypes.UPDATE_PENDING_STORE_FAIL,
  payload: error,
});

// DELETE PENDING STORE

export const deletePendingStoreStartAction = () => ({
  type: actionTypes.DELETE_PENDING_STORE_START,
});

export const deletePendingStoreSuccessAction = (pending) => ({
  type: actionTypes.DELETE_PENDING_STORE_SUCCESS,
  payload: pending,
});

export const deletePendingStoreFailAction = (error) => ({
  type: actionTypes.DELETE_PENDING_STORE_FAIL,
  payload: error,
});

// UPDATE PENDING PACKET

export const updatePendingPacketStartAction = () => ({
  type: actionTypes.UPDATE_PENDING_PACKET_START,
});

export const updatePendingPacketSuccessAction = (pending) => ({
  type: actionTypes.UPDATE_PENDING_PACKET_SUCCESS,
  payload: pending,
});

export const updatePendingPacketFailAction = (error) => ({
  type: actionTypes.UPDATE_PENDING_PACKET_FAIL,
  payload: error,
});

// DELETE PENDING PACKET

export const deletePendingPacketStartAction = () => ({
  type: actionTypes.DELETE_PENDING_PACKET_START,
});

export const deletePendingPacketSuccessAction = (pending) => ({
  type: actionTypes.DELETE_PENDING_PACKET_SUCCESS,
  payload: pending,
});

export const deletePendingPacketFailAction = (error) => ({
  type: actionTypes.DELETE_PENDING_PACKET_FAIL,
  payload: error,
});
