/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { InfoStyles, Location } from "./styles";
import { Text } from "../../../../../../components/Typography/styles";
import { Col, Spin } from "antd";
import ContactInfo from "./ContactInfo";
import FoodCategoriesInfo from "./FoodCategoriesInfo";
import WorkingTime from "./WorkingTime";
import { useDispatch, useSelector } from "react-redux";
import { getStoreInfo } from "../../../../../../../services/Redux/Store/api";

const Info = ({ storeId }) => {
  const dispatch = useDispatch();

  const [storeInfoToRender, setStoreInfoToRender] = useState();

  useEffect(() => {
    if (storeId !== null || storeId !== undefined) {
      dispatch(getStoreInfo(storeId));
    }
  }, [storeId]);

  const storeInfo  = useSelector((state) => state?.storeStore?.storeInfo);

  useEffect(() => {
    if (storeInfo) {
      setStoreInfoToRender(storeInfo);
    }
  }, [storeInfo]);

  return (
    <InfoStyles>
      <Col span={24}>
        <Location>
          <Col span={7} style={{ paddingBottom: "12px" }}>
            <Text weight="semi-bold">Teslim Saatleri </Text>
          </Col>
          <Col span={13}>
            <Text weight="light">
              {storeInfo ? storeInfo.package_settings.delivery_time_start : ""}
              -{storeInfo ? storeInfo.package_settings.delivery_time_end : ""}
            </Text>
          </Col>
        </Location>
      </Col>
      <Col span={24}>
        <Location>
          <Col span={7} style={{ paddingBottom: "12px" }}>
            <Text weight="semi-bold">Paket Teslimi </Text>
          </Col>
          <Col span={10}>
            <Text weight="light">Gel Al Paket </Text>
          </Col>
        </Location>
      </Col>
      {storeInfoToRender ? (
        <WorkingTime storeInfo={storeInfoToRender} />
      ) : (
        <Spin />
      )}
      <Col span={24}>
        <Location>
          <Col span={7} style={{ paddingBottom: "12px" }}>
            <Text weight="semi-bold">Ödeme Şekli </Text>
          </Col>
          <Col span={10}>
            <Text weight="light">Online Kredi/Banka Kartı - Kapıda Ödeme </Text>
          </Col>
        </Location>
      </Col>
      {storeInfoToRender ? (
        <ContactInfo storeInfo={storeInfoToRender} />
      ) : (
        <Spin />
      )}
      {storeInfoToRender ? (
        <FoodCategoriesInfo storeInfo={storeInfoToRender} />
      ) : (
        <Spin />
      )}
    </InfoStyles>
  );
};

export default Info;
