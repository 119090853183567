import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { ButtonTheme } from './constants';

// TYPES: primary - secondary - tertiary - deafult - link

const PrimaryButtonStyles = css`
	background-color: ${ButtonTheme.Color.primary} !important;

	&.ant-btn:hover,
	&.ant-btn:focus {
		transition: 0.2s;
	}
`;

const SecondaryButtonStyles = css`
	background-color: ${ButtonTheme.Color.secondary};
	color: #ffffff;
	border-color: ${ButtonTheme.Color.secondary};

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverSecondary};
		transition: 0.2s;
	}
`;

const SecondaryButtonReverse = css`
	background-color: transparent;
	color: ${ButtonTheme.Color.secondary};
	border: 2px solid;
	border-color: ${ButtonTheme.Color.secondary} !important;

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverSecondary};
		transition: 0.2s;
	}
`;

const TertiaryButtonStyles = css`
	background-color: transparent;
	background-color: ${ButtonTheme.Color.tertiary} !important;

	border-color: ${ButtonTheme.Color.tertiary}!important;

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverTertiary} !important;
		transition: 0.2s;
	}
`;

const BorderedButtonStyles = css`
	background-color: transparent;
	border-color: ${ButtonTheme.Color.secondary} !important;

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverBordered};
		border-color: ${ButtonTheme.Color.secondary} !important;
		transition: 0.2s;
	}
`;

const LinkButtonStyles = css`
	background-color: transparent;
	color: ${ButtonTheme.Color.primary};
	border-color: ${ButtonTheme.Color.secondary} !important;

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverSecondary};
		border-color: ${ButtonTheme.Color.secondary} !important;
		transition: 0.2s;
	}
`;

const QuaternaryButtonStyles = css`
	background-color: ${ButtonTheme.Color.quaternary} !important;
	color: ${ButtonTheme.Radius.primary};
	border-color: ${ButtonTheme.Color.quaternary};

	&.ant-btn:hover,
	&.ant-btn:focus {
		background-color: ${ButtonTheme.Color.hoverTertiary} !important;
		border-color: ${ButtonTheme.Color.hoverBordered} !important;
		transition: 0.2s;
	}
`;

export default styled(Button)`
	&&& {
		border-radius: ${ButtonTheme.Radius.primary};
		border-color: ${({ theme, borderColor }) => borderColor || theme.borderColor} !important;
		box-shadow: ${({ type }) => type !== 'link' && ButtonTheme.Shadow.primary};
		${({ type }) => {
			switch (type) {
				case 'primary':
					return PrimaryButtonStyles;
				case 'secondary':
					return SecondaryButtonStyles;
				case 'secondaryReverse':
					return SecondaryButtonReverse;
				case 'tertiary':
					return TertiaryButtonStyles;
				case 'quaternary':
					return QuaternaryButtonStyles;
				case 'border':
					return BorderedButtonStyles;
				case 'link':
					return LinkButtonStyles;
				default:
					break;
			}
		}}
	}
`;
