import styled from 'styled-components';
import { Row, Button as btn, Input as input } from 'antd';
import button from '../../../../components/Button/index';

const colorPicker = (theme, isOpen, disabled) => {
	if (disabled) {
		return theme.inactiveSecondaryColor;
	}
	if (isOpen) {
		return theme.quaternaryColor;
	}

	return theme.inactiveSecondaryColor;
};

export const InfoContent = styled(Row)`
	margin-bottom: 20px;
	padding: 24px 40px;
	border-radius: 8px;
	border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const InfoItem = styled(Row)`
	margin-top: 32px;
`;

export const Input = styled(input)`
	width: 70%;
	height: 32px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};

	font-size: 16px;
	font-family: Roboto;
	font-weight: 700;
	text-align: center;
	color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};

	.ant-input {
		font-size: 16px;
		font-family: Roboto;
		font-weight: 700;
		text-align: center;
		color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield !important;
	}

	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
	}
`;

export const SendButton = styled(button)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
`;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;
