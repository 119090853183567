export const GET_FOOD_TAGS_START = 'GET_FOOD_TAGS_START';
export const GET_FOOD_TAGS_SUCCESS = 'GET_FOOD_TAGS_SUCCESS';
export const GET_FOOD_TAGS_FAIL = 'GET_FOOD_TAGS_FAIL';

export const CREATE_FOOD_TAGS_START = 'CREATE_FOOD_TAGS_START';
export const CREATE_FOOD_TAGS_SUCCESS = 'CREATE_FOOD_TAGS_SUCCESS';
export const CREATE_FOOD_TAGS_FAIL = 'CREATE_FOOD_TAGS_FAIL';

export const UPDATE_FOOD_TAGS_START = 'UPDATE_FOOD_TAGS_START';
export const UPDATE_FOOD_TAGS_SUCCESS = 'UPDATE_FOOD_TAGS_SUCCESS';
export const UPDATE_FOOD_TAGS_FAIL = 'UPDATE_FOOD_TAGS_FAIL';
