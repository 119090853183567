import * as actionTypes from './actionTypes';

export const getActiveUsersStartAction = () => ({
  type: actionTypes.GET_ACTIVE_USERS_START,
});

export const getActiveUsersSuccessAction = (users) => ({
  type: actionTypes.GET_ACTIVE_USERS_SUCCESS,
  payload: users,
});

export const getActiveUsersFailAction = (error) => ({
  type: actionTypes.GET_ACTIVE_USERS_FAIL,
  payload: error,
});

export const getSuspendUsersStartAction = () => ({
  type: actionTypes.GET_SUSPEND_USERS_START,
});

export const getSuspendUsersSuccessAction = (users) => ({
  type: actionTypes.GET_SUSPEND_USERS_SUCCESS,
  payload: users,
});

export const getSuspendUsersFailAction = (error) => ({
  type: actionTypes.GET_SUSPEND_USERS_FAIL,
  payload: error,
});

export const createSuspendUserStartAction = () => ({
  type: actionTypes.CREATE_SUSPEND_USER_START,
});

export const createSuspendUserSuccessAction = (users) => ({
  type: actionTypes.CREATE_SUSPEND_USER_SUCCESS,
  payload: users,
});

export const createSuspendUserFailAction = (error) => ({
  type: actionTypes.CREATE_SUSPEND_USER_FAIL,
  payload: error,
});

export const getTerminateUsersStartAction = () => ({
  type: actionTypes.GET_TERMINATE_USERS_START,
});

export const getTerminateUsersSuccessAction = (users) => ({
  type: actionTypes.GET_TERMINATE_USERS_SUCCESS,
  payload: users,
});

export const getTerminateUsersFailAction = (error) => ({
  type: actionTypes.GET_TERMINATE_USERS_FAIL,
  payload: error,
});

export const createTerminateUserStartAction = () => ({
  type: actionTypes.CREATE_TERMINATE_USER_START,
});

export const createTerminateUserSuccessAction = (users) => ({
  type: actionTypes.CREATE_TERMINATE_USER_SUCCESS,
  payload: users,
});

export const createTerminateUserFailAction = (error) => ({
  type: actionTypes.CREATE_TERMINATE_USER_FAIL,
  payload: error,
});
