import { useState } from 'react';
import {
  TableStyle,
  Item,
  InfoWrapper,
  PanelButton,
  CardTitle,
  InfoItem,
  InfoContent,
  CustomProgress,
  PhotoImg,
  StarImg,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, Row } from 'antd';
import rightOutline from '../../../../../Assets/components/right-outline.svg';
import moment from 'moment';
import cancel from '../../../../../Assets/components/cancel.svg';
import starIcon from '../../../../../Assets/components/stars.svg';
import checkX from '../../../../../Assets/components/checkX.svg';
import checkV from '../../../../../Assets/components/checkV.svg';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';

const Title = ({ setIsShown }) => (
  <Row align="middle">
    <Col span={23}>
      <CardTitle size="title" weight="light">
        Restorant Bilgisi
      </CardTitle>
    </Col>
    <Col span={1}>
      <img src={cancel} alt="cancel" onClick={() => setIsShown(false)} />
    </Col>
  </Row>
);

const TableItem = ({
  store,
  avgReview,
  totalCount,
  totalPrice,
  serviceRating,
  qualityRating,
  mealRating,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { xl } = useResponsive();

  return (
    <>
      <TableStyle>
        <Item md={4} lg={4} xl={5} xxl={5} style={{ border: '0px' }}>
          <Col
            span={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '12px',
            }}
          >
            {store ? <PhotoImg src={store.photo} /> : <Text value=" no data" />}
          </Col>
          <Col
            span={17}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Text
              size={xl ? 'smallRegular' : '12px'}
              weight="semi-bold"
              style={{ marginBottom: '8px' }}
            >
              {store ? store.name : 'null'}
            </Text>
            <Text size={xl ? 'smallRegular' : '12px'} weight="light">
              {store ? `${store.city} - ${store.address}` : 'null'}
            </Text>
          </Col>
        </Item>
        <Item md={2} lg={2} xl={2} xxl={2} style={{ alignItems: 'center' }}>
          <Col span={7} style={{ display: 'flex', alignItems: 'center' }}>
            <StarImg src={starIcon} alt="" height="20px" width="20px" />
          </Col>
          <Col
            span={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '3px',
              marginLeft: '5px',
            }}
          >
            <Text size={xl ? '18px' : '14px'} weight="light">
              {avgReview?.toString()?.slice(0, 3)}
            </Text>
          </Col>
        </Item>
        <Item md={3} lg={3} xl={3} xxl={3}>
          <Row justify="center" align="middle" gutter={[16, 0]}>
            <Col span={12}>
              <Text size="smallRegular" weight="light">
                Servis
              </Text>
            </Col>
            <Col span={12}>
              <CustomProgress
                strokeWidth="13"
                width={xl ? '45px' : '35px'}
                strokeColor="#9FD5A4"
                trailColor="#E2E9EF"
                type="circle"
                percent={serviceRating?.toString()?.slice(0, 3)}
                format={(percent) => `${percent}`}
              />
            </Col>
          </Row>
        </Item>
        <Item md={3} lg={3} xl={3} xxl={3}>
          <Row justify="center" align="middle" gutter={[16, 0]}>
            <Col span={12}>
              <Text size="smallRegular" weight="light">
                Kalite
              </Text>
            </Col>
            <Col span={12}>
              <CustomProgress
                strokeWidth="13"
                width={xl ? '45px' : '35px'}
                strokeColor="#E5876A"
                trailColor="#E2E9EF"
                type="circle"
                percent={qualityRating?.toString()?.slice(0, 3)}
                format={(percent) => `${percent}`}
              />
            </Col>
          </Row>
        </Item>
        <Item md={3} lg={3} xl={3} xxl={3}>
          <Row justify="center" align="middle" gutter={[16, 0]}>
            <Col span={12}>
              <Text size="smallRegular" weight="light">
                Lezzet
              </Text>
            </Col>
            <Col span={12}>
              <CustomProgress
                strokeWidth="13"
                width={xl ? '45px' : '30px'}
                strokeColor="#827F7F"
                trailColor="#E2E9EF"
                type="circle"
                percent={mealRating?.toString()?.slice(0, 3)}
                format={(percent) => `${percent}`}
              />
            </Col>
          </Row>
        </Item>
        <Item
          md={4}
          lg={4}
          xl={3}
          xxl={3}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Row gutter={[4, 0]} align="middle" style={{ marginLeft: '4px' }}>
            <Col span={16}>
              <Text size="smallRegular" weight="light">
                Yemek Sayısı
              </Text>
            </Col>
            <Col>
              <Text color="red" size="14px" weight="bold">
                {totalCount === null ? 3 : totalCount}
              </Text>
            </Col>
          </Row>
        </Item>
        <Item
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Row gutter={[4, 0]} align="middle" style={{ marginLeft: '4px' }}>
            <Col span={16}>
              <Text size="smallRegular" weight="light">
                Toplam Tutar
              </Text>
            </Col>
            <Col>
              <Text color="green" size="14px" weight="bold">
                {totalPrice === null ? 32 : totalPrice} TL
              </Text>
            </Col>
          </Row>
        </Item>
        <Item md={1} lg={1} xl={1} xxl={1} style={{ alignItems: 'center' }}>
          <PanelButton onClick={() => setIsShown(!isShown)}>
            <img src={rightOutline} alt="" />
          </PanelButton>
        </Item>
      </TableStyle>
      <InfoWrapper
        title={<Title setIsShown={setIsShown} />}
        style={{ display: isShown ? 'block' : 'none' }}
      >
        <InfoContent style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Text weight="bold">Restorant Bilgisi:</Text>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">ID:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.id : 'null'}
                </Text>
              </Col>
              <Col span={14}>
                <Text weight="bold">İşletme Adı:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.name : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={18}>
                <Text weight="bold">Adres:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? `${store.address} - ${store.city}` : 'null'}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Web-Sitesi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.website_link : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Telefon 1:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.phone_number : 'null'}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">Telefon 2:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.phone_number_2 : 'null'}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Email:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.email : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
        </InfoContent>
        <InfoContent style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Text weight="bold">İşletme Sahibi Bilgisi:</Text>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Adı - Soyadı:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store
                    ? `${store.store_owner.first_name} ${store.store_owner.last_name}`
                    : 'null'}
                </Text>
              </Col>
              <Col span={14}>
                <Text weight="bold">Son Giriş Tarihi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store
                    ? moment(store.store_owner.created_at).format(
                        'DD MMMM  YYYY'
                      )
                    : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Telefon 1:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.store_owner.phone_number : 'null'}
                </Text>
              </Col>

              <Col span={6}>
                <Text weight="bold">Email:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.store_owner.email : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">SMS</Text>
                <img
                  src={checkX}
                  alt=""
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
              <Col span={8}>
                <Text weight="bold">E-posta:</Text>
                <img
                  src={store.store_owner.allow_email ? checkV : checkX}
                  alt="email"
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
              <Col span={6}>
                <Text weight="bold">Telefonla Arama:</Text>
                <img
                  src={store.store_owner.allow_phone ? checkV : checkX}
                  alt="phone"
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
            </InfoItem>
          </Col>
        </InfoContent>
      </InfoWrapper>
    </>
  );
};

export default TableItem;
