import { useState } from 'react';
import {
  TableStyle,
  Item,
  InfoWrapper,
  PanelButton,
  CardTitle,
  InfoItem,
  InfoContent,
  CancelButton,
  PhotoImg,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, Row } from 'antd';
import rightOutline from '../../../../../Assets/components/right-outline.svg';
import moment from 'moment';
import activeDetail from '../../../../../Assets/components/active_detail.svg';
import passiveDetail from '../../../../../Assets/components/passive_detail.svg';
import cancel from '../../../../../Assets/components/cancel.svg';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';

const Title = ({ setIsShown }) => (
  <Row align="middle">
    <Col span={23}>
      <CardTitle size="title" weight="light">
        Siparişler
      </CardTitle>
    </Col>
    <Col span={1}>
      <img src={cancel} alt="cancel" onClick={() => setIsShown(false)} />
    </Col>
  </Row>
);

const TableItem = ({
  refCode,
  boxes,
  box_count,
  user,
  buyTime,
  id,
  address,
  cost,
  delivery,
  store,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { xxl } = useResponsive();

  return (
    <>
      <TableStyle>
        <Item span={2} style={{ alignItems: 'center', border: '0px' }}>
          <Text weight="light">{id}</Text>
        </Item>
        <Item span={4}>
          <Col
            span={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '16px',
            }}
          >
            {store ? (
              <PhotoImg src={store?.photo} alt="" />
            ) : (
              <Text value=" no data" />
            )}
          </Col>
          <Col
            span={17}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Text weight="semi-bold" size={xxl ? 'smallRegular' : '12px'}>
              {store ? store?.name : 'Restaurant bulunamadı'}
            </Text>
            <Text size={xxl ? 'smallRegular' : '12px'} weight="light">
              {store ? `${store?.province} - ${store?.city}` : ''}
            </Text>
          </Col>
        </Item>
        <Item span={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {box_count}
          </Text>
          <Text
            size="smallRegular"
            weight="bold"
            style={{ marginLeft: '16px' }}
          >
            {refCode}
          </Text>
        </Item>
        <Item span={2} style={{ flexDirection: 'column' }}>
          <Text
            size="smallRegular"
            color="yellow"
            weight="semi-bold"
            style={{ marginLeft: '16px' }}
          >
            İptal Edildi
          </Text>
        </Item>
        <Item span={4} style={{ flexDirection: 'column' }}>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(buyTime).format('DD MMMM  YYYY')}
          </Text>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(buyTime).format('dddd  HH:mm')}
          </Text>
        </Item>
        <Item span={3} style={{ alignItems: 'center', cursor: 'pointer' }}>
          {
            <img
              src={!isShown ? activeDetail : passiveDetail}
              alt=""
              onClick={() => setIsShown(!isShown)}
            />
          }
        </Item>
        <Item span={4} style={{ alignItems: 'center' }}>
          <CancelButton>
            <Text color="red" weight="semi-bold" size="nobleRegular">
              {xxl ? 'Ücret İadesi Yap' : 'İade '}
            </Text>
          </CancelButton>
        </Item>
        <Item span={1} style={{ alignItems: 'center' }}>
          <PanelButton onClick={() => setIsShown(!isShown)}>
            <img src={rightOutline} alt="" />
          </PanelButton>
        </Item>
      </TableStyle>
      <InfoWrapper
        title={<Title setIsShown={setIsShown} />}
        style={{ display: isShown ? 'block' : 'none' }}
      >
        <InfoContent>
          <Text weight="bold">Siparişin Gönderildiği Kullanıcı Bilgisi:</Text>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Gönderilen:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.first_name} {user.last_name}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">Referans Kodu:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {refCode}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Sipariş Durumu:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  İptal Edildi
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Gönderim Tarihi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {moment(buyTime).format('DD MMMM  YYYY - dddd')}
                </Text>
              </Col>
              <Col span={14}>
                <Text weight="bold">Gönderim Zamanı:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {moment(buyTime).format('HH:mm')}
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={18}>
                <Text weight="bold">Alıcı Adresi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {' '}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Telefon:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.phone_number}
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={18}>
                <Text weight="bold">Paket:</Text>
                <Text weight="light" style={{ marginLeft: '10px' }}>
                  {boxes.length} Paket
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">E-mail:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.email}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              {boxes &&
                boxes.map((value) => (
                  <Col style={{ marginTop: '5px' }}>
                    <Text weight="light" style={{ marginLeft: '75px' }}>
                      {value.text_name} ({' '}
                      {value.meals.map((meal) => `${meal.name} ,`)})
                    </Text>
                  </Col>
                ))}
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Sipariş Tutarı:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {cost}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">Kurye Ücreti:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {delivery}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Ödenen Toplan Tutar:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {cost} TL
                </Text>
              </Col>
            </InfoItem>
          </Col>
        </InfoContent>
        <InfoContent style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Text weight="bold">Siparişin Çıktığı Restorant Bilgisi:</Text>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">ID:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.id : 'null'}
                </Text>
              </Col>
              <Col span={14}>
                <Text weight="bold">İşletme Adı:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.name : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={18}>
                <Text weight="bold">Adres:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? `${store.address} - ${store.city}` : 'null'}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Web-Sitesi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.website_link : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Telefon 1:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.phone_number : 'null'}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">Telefon 2:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.phone_number_2 : 'null'}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Email:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {store ? store.email : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>
        </InfoContent>
      </InfoWrapper>
    </>
  );
};

export default TableItem;
