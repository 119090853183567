import { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import {
  OrdersContent,
  OrdersContentCol,
  CardTitle,
  TitleDivider,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import ActiveOrders from './ActiveOrders';
import PassedOrders from './PassedOrders';
import CancelledOrders from './CancelledOrders';
import {
  getActiveOrders,
  getPassedOrders,
  getCancelledOrders,
} from '../../../services/Redux/Orders/api';
import useResponsive from '../../../helpers/Hooks/useMediaQuery';

const Title = ({ xxl, page, setPage }) => (
  <Row align="middle">
    <Col span={8} onClick={() => setPage(0)}>
      <CardTitle
        size="title"
        weight={page === 0 ? 'bold' : 'light'}
        color={page === 0 ? 'red' : 'black'}
      >
        {xxl ? 'Aktif Siparişler' : 'Aktif'}
      </CardTitle>
      {page === 0 && <TitleDivider />}
    </Col>
    <Col span={8} onClick={() => setPage(1)}>
      <CardTitle
        size="title"
        weight={page === 1 ? 'bold' : 'light'}
        color={page === 1 ? 'red' : 'black'}
      >
        {xxl ? 'Geçmiş Siparişler' : 'Geçmiş'}
      </CardTitle>
      {page === 1 && <TitleDivider />}
    </Col>
    <Col span={8} onClick={() => setPage(2)}>
      <CardTitle
        size="title"
        weight={page === 2 ? 'bold' : 'light'}
        color={page === 2 ? 'red' : 'black'}
      >
        {xxl ? 'İptal Edilen Siparişler' : 'İptal Edilen'}
      </CardTitle>
      {page === 2 && <TitleDivider />}
    </Col>
  </Row>
);

const Orders = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [activeOrdersToRender, setActiveOrdersToRender] = useState(null);
  const [passedOrdersToRender, setPassedOrdersToRender] = useState(null);
  const [cancelledOrdersToRender, setCancelledOrdersToRender] = useState(null);

  const { xxl } = useResponsive();

  useEffect(() => {
    (async () => {
      await dispatch(getActiveOrders());
      await dispatch(getPassedOrders());
      await dispatch(getCancelledOrders());
    })();
  }, [dispatch]);

  const {
    activeOrdersList,
    passedOrdersList,
    cancelledOrdersList,
  } = useSelector((state) => state.ordersStore);

  useEffect(() => {
    if (activeOrdersList) {
      setActiveOrdersToRender(
        activeOrdersList?.sort(
          (a, b) => new Date(b.buying_time) - new Date(a.buying_time)
        )
      );
    }
  }, [activeOrdersList]);

  useEffect(() => {
    if (passedOrdersList) {
      setPassedOrdersToRender(
        passedOrdersList?.sort(
          (a, b) => new Date(b.buying_time) - new Date(a.buying_time)
        )
      );
    }
  }, [passedOrdersList]);

  useEffect(() => {
    if (cancelledOrdersList) {
      setCancelledOrdersToRender(
        cancelledOrdersList?.sort(
          (a, b) => new Date(b.buying_time) - new Date(a.buying_time)
        )
      );
    }
  }, [cancelledOrdersList]);

  return (
    <OrdersContentCol md={24} lg={24} xl={xxl ? 22 : 24} xxl={22}>
      <OrdersContent title={<Title xxl={xxl} page={page} setPage={setPage} />}>
        {page === 0 &&
          (activeOrdersToRender ? (
            <ActiveOrders orders={activeOrdersToRender} />
          ) : (
            <Spin />
          ))}
        {page === 1 &&
          (passedOrdersToRender ? (
            <PassedOrders orders={passedOrdersToRender} />
          ) : (
            <Spin />
          ))}
        {page === 2 &&
          (cancelledOrdersToRender ? (
            <CancelledOrders orders={cancelledOrdersToRender} />
          ) : (
            <Spin />
          ))}
      </OrdersContent>
    </OrdersContentCol>
  );
};

export default Orders;
