import { useState } from 'react';
import { Row, Col } from 'antd';
import { UsersContent, UsersContentCol, CardTitle, TitleDivider } from './styles';
import ActiveUsers from './ActiveUsers';
import SuspendUsers from './SuspendUsers';
import TerminateUsers from './TerminateUsers';
import useResponsive from '../../../helpers/Hooks/useMediaQuery';

const Title = ({ page, setPage, xxl }) => (
	<Row align='middle'>
		<Col span={8} onClick={() => setPage(0)}>
			<CardTitle size='title' weight={page === 0 ? 'bold' : 'light'} color={page === 0 ? 'red' : 'black'}>
				{xxl ? 'Kayıtlı Kullanıcılar' : ' Kayıtlı'}
			</CardTitle>
			{page === 0 && <TitleDivider />}
		</Col>
		<Col span={8} onClick={() => setPage(1)}>
			<CardTitle size='title' weight={page === 1 ? 'bold' : 'light'} color={page === 1 ? 'red' : 'black'}>
				{xxl ? 'Askıya Alınan Kullanıcılar' : 'Askıya Alınan'}
			</CardTitle>
			{page === 1 && <TitleDivider />}
		</Col>
		<Col span={8} onClick={() => setPage(2)}>
			<CardTitle size='title' weight={page === 2 ? 'bold' : 'light'} color={page === 2 ? 'red' : 'black'}>
				{xxl ? 'Üyeliği Sonlandırılan Kullanıcılar' : 'Üyeliği Sonlandırılan'}
			</CardTitle>
			{page === 2 && <TitleDivider />}
		</Col>
	</Row>
);

const Users = () => {
	const [page, setPage] = useState(0);
	const { xxl } = useResponsive();

	return (
		<UsersContentCol md={24} lg={24} xl={xxl ? 22 : 24} xxl={22}>
			<UsersContent title={<Title xxl={xxl} page={page} setPage={setPage} />}>
				{page === 0 && <ActiveUsers />}
				{page === 1 && <SuspendUsers />}
				{page === 2 && <TerminateUsers />}
			</UsersContent>
		</UsersContentCol>
	);
};

export default Users;
