export const GET_SPECIAL__REASONS_START = 'GET_SPECIAL__REASONS_START';
export const GET_SPECIAL_REASONS_SUCCESS = 'GET_SPECIAL_REASONS_SUCCESS';
export const GET_SPECIAL_REASONS_FAIL = 'GET_SPECIAL_REASONS_FAIL';

export const CREATE_SPECIAL__REASONS_START = 'CREATE_SPECIAL__REASONS_START';
export const CREATE_SPECIAL_REASONS_SUCCESS = 'CREATE_SPECIAL_REASONS_SUCCESS';
export const CREATE_SPECIAL_REASONS_FAIL = 'CREATE_SPECIAL_REASONS_FAIL';

export const UPDATE_SPECIAL__REASONS_START = 'UPDATE_SPECIAL__REASONS_START';
export const UPDATE_SPECIAL_REASONS_SUCCESS = 'UPDATE_SPECIAL_REASONS_SUCCESS';
export const UPDATE_SPECIAL_REASONS_FAIL = 'UPDATE_SPECIAL_REASONS_FAIL';

export const DELETE_SPECIAL__REASONS_START = 'DELETE_SPECIAL__REASONS_START';
export const DELETE_SPECIAL_REASONS_SUCCESS = 'DELETE_SPECIAL_REASONS_SUCCESS';
export const DELETE_SPECIAL_REASONS_FAIL = 'DELETE_SPECIAL_REASONS_FAIL';
