import * as actionTypes from './actionTypes';

export const getHelpRequestsStartAction = () => ({
	type: actionTypes.GET_HELP_REQUESTS_START,
});

export const getHelpRequestsSuccessAction = (request) => ({
	type: actionTypes.GET_HELP_REQUESTS_SUCCESS,
	payload: request,
});

export const getHelpRequestsFailAction = (error) => ({
	type: actionTypes.GET_HELP_REQUESTS_FAIL,
	payload: error,
});

export const setSolvedRequestStartAction = () => ({
	type: actionTypes.SET_SOLVED_REQUEST_START,
});

export const setSolvedRequestSuccessAction = (request) => ({
	type: actionTypes.SET_SOLVED_REQUEST_SUCCESS,
	payload: request,
});

export const setSolvedRequestFailAction = (error) => ({
	type: actionTypes.SET_SOLVED_REQUEST_FAIL,
	payload: error,
});

export const setRejectedRequestStartAction = () => ({
	type: actionTypes.SET_REJECTED_REQUEST_START,
});

export const setRejectedRequestSuccessAction = (request) => ({
	type: actionTypes.SET_REJECTED_REQUEST_SUCCESS,
	payload: request,
});

export const setRejectedRequestFailAction = (error) => ({
	type: actionTypes.SET_REJECTED_REQUEST_FAIL,
	payload: error,
});
