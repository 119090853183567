import { message } from 'antd';
import * as actions from './actions';

export const tryAuth = (_email, _password) => (dispatch, getState, service) => {
	dispatch(actions.authStartAction());
	return service({
		method: 'POST',
		path: 'user/login/',
		body: {
			credential: _email,
			password: _password,
		},
	})
		.then((userData) => {
			dispatch(actions.authSuccessAction(userData));
		})
		.catch((error) => {
			dispatch(actions.authFailAction());
			message.error('Hatali şifre ve üye adi !');
		});
};
