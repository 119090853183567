import * as actionTypes from './actionTypes';

export const getFoodTagsStartAction = () => ({
	type: actionTypes.GET_FOOD_TAGS_START,
});

export const getFoodTagsSuccessAction = (tags) => ({
	type: actionTypes.GET_FOOD_TAGS_SUCCESS,
	payload: tags,
});

export const getFoodTagsFailAction = (error) => ({
	type: actionTypes.GET_FOOD_TAGS_FAIL,
	payload: error,
});

export const createFoodTagsStartAction = () => ({
	type: actionTypes.CREATE_FOOD_TAGS_START,
});

export const createFoodTagsSuccessAction = (tag) => ({
	type: actionTypes.CREATE_FOOD_TAGS_SUCCESS,
	payload: tag,
});

export const createFoodTagsFailAction = (error) => ({
	type: actionTypes.CREATE_FOOD_TAGS_FAIL,
	payload: error,
});

export const updateFoodTagsStartAction = () => ({
	type: actionTypes.UPDATE_FOOD_TAGS_START,
});

export const updateFoodTagsSuccessAction = (tag) => ({
	type: actionTypes.UPDATE_FOOD_TAGS_SUCCESS,
	payload: tag,
});

export const updateFoodTagsFailAction = (error) => ({
	type: actionTypes.UPDATE_FOOD_TAGS_FAIL,
	payload: error,
});
