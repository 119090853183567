import * as actionTypes from "./actionTypes";

const initialState = {
  loading: null,
  createUser: null,
  createUserError: null,
  createStore: null,
  createStoreError: null,
  createPackage: null,
  createPackageError: null,
  createCategory: null,
  createCategoryError: null,
};

//CREATE USER
const createUserStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createUserSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaq: action.payload,
});

const createUserFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaqError: action.payload,
});

//CREATE STORE
const createStoreStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createStoreSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaq: action.payload,
});

const createStoreFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaqError: action.payload,
});

//CREATE PACKAGE
const createPackageStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createPackageSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaq: action.payload,
});

const createPackageFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaqError: action.payload,
});

//CREATE CATEGORY
const createCategoryStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createCategorySuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaq: action.payload,
});

const createCategoryFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createFaqError: action.payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_START:
      return createUserStartReducer(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccessReducer(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFailReducer(state, action);

    case actionTypes.CREATE_STORE_START:
      return createStoreStartReducer(state);
    case actionTypes.CREATE_STORE_SUCCESS:
      return createStoreSuccessReducer(state, action);
    case actionTypes.CREATE_STORE_FAIL:
      return createStoreFailReducer(state, action);

    case actionTypes.CREATE_PACKAGE_START:
      return createPackageStartReducer(state);
    case actionTypes.CREATE_PACKAGE_SUCCESS:
      return createPackageSuccessReducer(state, action);
    case actionTypes.CREATE_PACKAGE_FAIL:
      return createPackageFailReducer(state, action);

    case actionTypes.CREATE_CATEGORY_START:
      return createCategoryStartReducer(state);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return createCategorySuccessReducer(state, action);
    case actionTypes.CREATE_CATEGORY_FAIL:
      return createCategoryFailReducer(state, action);

    default:
      return state;
  }
};

export default reducer;
