import styled from 'styled-components';
import { Col, Row } from 'antd';

export const SupriseItemStyles = styled(Col)``;

export const DefinedItemStyles = styled(Col)``;

export const Location = styled(Row)`
	margin-top: 32px;
	border-bottom: ${({ theme }) => ` 3px solid ${theme.borderColor}`};
	cursor: pointer;
`;

export const PacketStyles = styled(Row)``;
