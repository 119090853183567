import * as actionTypes from './actionTypes';

export const getRestaurantStatisticsStartAction = () => ({
	type: actionTypes.GET_RESTAURANT_STATISTICS_START,
});

export const getRestaurantStatisticsSuccessAction = (restaurant) => ({
	type: actionTypes.GET_RESTAURANT_STATISTICS_SUCCESS,
	payload: restaurant,
});

export const getRestaurantStatisticsFailAction = (error) => ({
	type: actionTypes.GET_RESTAURANT_STATISTICS_FAIL,
	payload: error,
});
