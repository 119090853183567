import { useState } from 'react';
import { Location, ContactStyles, CancelButton, InfoContent, InfoItem } from './styles';
import { Col, Row } from 'antd';
import { Text } from '../../../../../../../components/Typography/styles';
import cancelIcon from '../../../../../../../../Assets/components/cancel.svg';

const ContactInfo = ({ storeInfo }) => {
	const [isShown, setIsShown] = useState(false);
	return (
		<ContactStyles span={24}>
			<Location style={{ borderBottom: isShown && '0px' }}>
				<Col span={7} style={{ paddingBottom: '12px' }}>
					<Text weight='semi-bold'>İletişim </Text>
				</Col>
				<Col span={13}>
					<Text weight='light'>{storeInfo !== null ? storeInfo.name : 'null'} </Text>
				</Col>
				{isShown ? (
					<Col span={2} offset={2}>
						<Row justify='center' align='middle'>
							<img src={cancelIcon} alt='cancel' style={{ cursor: 'pointer' }} onClick={() => setIsShown(false)} />
						</Row>
					</Col>
				) : (
					<Col span={4}>
						<CancelButton type='secondary' onClick={() => setIsShown(true)}>
							<Text color='red' weight='semi-bold' size='nobleRegular'>
								Detaylar
							</Text>
						</CancelButton>
					</Col>
				)}
			</Location>

			<Row style={{ display: isShown ? 'block' : 'none', marginTop: '28px' }}>
				<InfoContent>
					<Text weight='bold'>Mağaza Bilgisi:</Text>

					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>İşletme Adı:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.name : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={16}>
								<Text weight='bold'>Adres:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.address : 'null'} - {storeInfo !== null ? storeInfo.city : 'null'} /{' '}
									{storeInfo !== null ? storeInfo.province : 'null'}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Web Sitesi:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.website_link : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={8}>
								<Text weight='bold'>Telefon 1:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.phone_number : 'null'}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Telefon 2:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.phone_number_2 : 'null'}
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>E-mail:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.email : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>
				</InfoContent>
				<InfoContent>
					<Col span={24}>
						<Text weight='bold'>Satış ve Fatura Bilgisi:</Text>
					</Col>
					<Col span={24}>
						<InfoItem>
							<Col span={8}>
								<Text weight='bold'>Vergi Dairesi İli:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Vergi Dairesi:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Vergi Numarası:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={8} offset={16}>
								<Text weight='bold'>T.C. Numarası:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
						</InfoItem>
					</Col>
					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>Banka Bilgileri:</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={16}>
								<Text weight='bold'>Ödemenin Alınacağı Banka:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'> Alıcı:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
						</InfoItem>
					</Col>
					<Col span={24}>
						<InfoItem>
							<Col span={16}>
								<Text weight='bold'>IBAN:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Telefon:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24} style={{ marginTop: '48px' }}>
						<InfoItem>
							<Col span={8}>
								<Text weight='bold'>Ödeme Dönemi:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Minimum Ödeme Tutarı:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
						</InfoItem>
					</Col>
				</InfoContent>

				<InfoContent>
					<Text weight='bold'>E-mail Yönlendirmeleri:</Text>

					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>E-mail:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									{storeInfo !== null ? storeInfo.email : 'null'}
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={8}>
								<Text weight='bold'>Sipariş Onayı:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Sipariş İptali:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									-
								</Text>
							</Col>
							<Col span={8}>
								<Text weight='bold'>Aylık Rapor:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									İzin verildi
								</Text>
							</Col>
						</InfoItem>
					</Col>
				</InfoContent>
			</Row>
			{isShown && <Location />}
		</ContactStyles>
	);
};

export default ContactInfo;
