import * as actions from "./actions";
import { message } from "antd";

export const patchUser = (id, roleId, userStatus) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.patchAuthUserStartAction());
  await service({
    path: `user/${id}/`,
    method: "PATCH",
    sendToken: true,
    body: {
      admin_role: roleId,
      is_active: userStatus,
    },
  })
    .then((data) => {
      dispatch(actions.patchAuthUserSuccessAction(data));
      message.success("Kullanıcı Yetkileri Başarı ile Güncellendi. !");
    })
    .catch((error) => {
      dispatch(actions.patchAuthUserFailAction(error));
      message.error("Kullanıcı Yetkileri Güncellenirken bir Hata Oluştu. !");
    });
};
