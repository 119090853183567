import styled from 'styled-components';
import { Row, Col, Button as btn } from 'antd';

export const TableStyle = styled(Row)`
	height: 64px;
	border-radius: 8px;
	margin-bottom: 20px;
	border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const Item = styled(Col)`
	display: flex !important;
	justify-content: center;
	height: 100%;
	border-left: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const PanelButton = styled(btn)`
	height: 32px !important;
	width: 32px;
	border: 2px solid #8fd89f;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #8fd89f;
		transition: 0.2s;
	}
`;
export const InfoWrapper = styled(Row)``;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 75%;
	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const PhotoImg = styled.img`
	width: 48px;
	height: 48px;

	@media (max-width: 1200px) {
		width: 30px;
		height: 30px;
	}
`;
