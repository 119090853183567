import styled from 'styled-components';
import { Row, Card, Input as input, Button as btn, Radio } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button';

export const PacketNames = styled(Card)`
	margin-top: 32px;
	border: 0px;
	padding: 0px;
	.ant-card-head {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		background: ${({ theme }) => theme.borderColor};
	}
	.ant-card-body {
		padding-left: 24px;
		padding-right: 24px;
	}
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const PacketNamesStyles = styled(Row)`
	padding-left: 32px;
`;

export const Input = styled(input)`
	width: 100%;
	height: 32px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const SendButton = styled(button)`
	height: 32px !important;
	width: 100%;
`;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 100%;

	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const RadioBtn = styled(Radio)`
	width: 100%;
	margin-top: 32px;
`;
