/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  LeftSide,
  RightSide,
  CustomText,
  BottomSide,
  EditButton,
  SendButton,
  Input,
  AnswerInput,
  Divider,
  CategoriesContent,
  ExistContent,
  Content,
  CustomCard,
  CancelButton,
  CardWrapper,
  CategoriesCard,
  DeleteButton,
} from "./styles";
import { Text } from "../../../components/Typography/styles";
import search from "../../../../Assets/components/search.png";
import QuestionItem from "./QuestionItem";
import {
  getSubCategories,
  createFaq,
  getFaqList,
  updateFaq,
  deleteFaq,
  getContact,
  updateContact,
} from "../../../../services/Redux/ContentCreator/api";
import AlertModal from "../AlertModal";

const Title = ({ text }) => (
  <Row align="middle" justify="center">
    <Text
      size="smallTitle"
      weight="light"
      style={{ width: "100%", textAlign: "center" }}
    >
      {text}
    </Text>
  </Row>
);

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: "48px",
      width: "48px",
      backgroundColor: "#ffbc41",
      justifyContent: "center",
      borderRadius: "50px",
      position: "absolute",
      right: "-18px",
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const MobilCard = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [subCategoriesToRender, setSubCategoriesToRender] = useState();
  const [faqListToRender, setFaqListToRender] = useState();
  const [isActive, setIsActive] = useState(null);
  const [questionInput, setQuestionInput] = useState("");
  const [answerInput, setAnswerInput] = useState("");
  const [existIsActive, setExistIsActive] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [search, setSearch] = useState();

  const handleCancel = () => {
    setIsAlertVisible(false);
  };

  useEffect(() => {
    dispatch(getSubCategories());
    dispatch(getFaqList());
    dispatch(getContact());
  }, []);

  const { subCategories, faqList, contact } = useSelector(
    (state) => state.contentCreatorStore
  );

  useEffect(() => {
    if (subCategories && faqList) {
      setSubCategoriesToRender(
        subCategories.filter((val) => val.category === 1)
      );
      setFaqListToRender(faqList);
      setIsActive(subCategories.filter((val) => val.category === 1)[0].id);
      setExistIsActive(subCategories.filter((val) => val.category === 1)[0].id);
    }
  }, [subCategories, faqList]);

  useEffect(() => {
    if (search !== "") {
      const searchedName = faqList?.filter((faq) =>
        faq?.question?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
      );
      setFaqListToRender(searchedName);
    } else if (faqList) {
      setFaqListToRender(faqList);
    }
  }, [search]);

  useEffect(() => {
    if (contact) {
      setPhone(contact.phone_number);
      setEmail(contact.email);
    }
  }, [contact]);

  const createContent = async () => {
    if (!isEdit) {
      if (questionInput !== "" && answerInput !== "") {
        await dispatch(createFaq(questionInput, answerInput, isActive));
        await dispatch(getFaqList());
        setQuestionInput("");
        setAnswerInput("");
      } else {
        message.error("Soru ve Cevap boş geçilemez !");
      }
    } else {
      if (questionInput !== "" && answerInput !== "") {
        await dispatch(
          updateFaq(selectedQuestion, questionInput, answerInput, isActive)
        );
        await dispatch(getFaqList());
        setEmpty();
        setIsEdit(false);
      } else {
        message.error("Soru ve Cevap boş geçilemez !");
      }
    }
  };

  const editContent = () => {
    const selected = faqListToRender.find(({ id }) => id === selectedQuestion);
    if (!selected && !selectedQuestion) {
      message.error("Düzenlemek için Mevcut Bir İçerik Seçiniz !");
    } else {
      const { question, answer, sub_category } = selected;
      setQuestionInput(question);
      setAnswerInput(answer);
      setIsActive(sub_category);
      setIsEdit(true);
    }
  };

  const preDeleteContent = () => {
    const selected = faqListToRender.find(({ id }) => id === selectedQuestion);
    if (!selected && !selectedQuestion) {
      message.error("Düzenlemek için Mevcut Bir İçerik Seçiniz !");
    } else {
      setIsAlertVisible(true);
    }
  };

  const deleteContent = async () => {
    await dispatch(deleteFaq(selectedQuestion));
    setEmpty();
    await dispatch(getFaqList());
  };

  const setEmpty = () => {
    setQuestionInput("");
    setAnswerInput("");
    setIsActive(subCategories.filter((val) => val.category === 1)[0].id);
    setExistIsActive(subCategories.filter((val) => val.category === 1)[0].id);
    setSelectedQuestion(null);
  };

  const editContactContent = async () => {
    if (email !== "" && phone !== "") {
      await dispatch(updateContact(email, phone));
    } else {
      message.error("İletişim Bilgileri Boş Geçilemez !");
    }
  };

  return (
    <Row>
      <LeftSide md={24} lg={24} xl={16} xxl={16}>
        <Text weight="semi-bold">Kategori Seç:</Text>
        <CategoriesContent>
          {subCategoriesToRender &&
            subCategoriesToRender.map(
              ({ id, name, category, logo, logo_inactive, description }) =>
                isActive === id && category === 1 ? (
                  <CardWrapper
                    md={11}
                    lg={11}
                    xl={11}
                    onClick={() => setIsActive(id)}
                  >
                    <CategoriesCard title={<Title text={name} />}>
                      <img src={logo} alt="" width="80px" />
                    </CategoriesCard>
                  </CardWrapper>
                ) : (
                  <CardWrapper
                    md={11}
                    lg={11}
                    xl={11}
                    onClick={() => setIsActive(id)}
                  >
                    <CategoriesCard title={<Title text={name} />}>
                      <img src={logo_inactive} alt="" width="80px"/>
                    </CategoriesCard>
                  </CardWrapper>
                )
            )}
        </CategoriesContent>
        {isEdit ? (
          <Text weight="semi-bold">İçerik Düzenle:</Text>
        ) : (
          <Text weight="semi-bold">İçerik Oluştur:</Text>
        )}

        <CustomText weight="semi-bold">Soru:</CustomText>
        <Input
          placeholder="Örnek İçerik"
          value={questionInput}
          onChange={(e) => setQuestionInput(e.target.value)}
        />
        <CustomText weight="semi-bold">Cevap:</CustomText>
        <AnswerInput.TextArea
          placeholder="Örnek İçerik"
          rows={3}
          value={answerInput}
          onChange={(e) => setAnswerInput(e.target.value)}
        />
        <Row gutter={[32, 0]}>
          <Col
            md={{ span: 6, offset: 12 }}
            lg={{ span: 6, offset: 12 }}
            xl={{ span: 5, offset: 14 }}
            xxl={{ span: 5, offset: 14 }}
          >
            <DeleteButton onClick={() => preDeleteContent()}>
              <Text color="white" weight="semi-bold">
                Sil
              </Text>
            </DeleteButton>
          </Col>

          <Col md={6} lg={6} xl={5} xxl={5}>
            <SendButton type="secondary" onClick={() => createContent()}>
              <Text color="white" weight="semi-bold">
                Kaydet
              </Text>
            </SendButton>
          </Col>
        </Row>
      </LeftSide>
      <RightSide
        md={24}
        lg={{ span: 24 }}
        xl={{ span: 5, offset: 2 }}
        xxl={{ span: 5, offset: 2 }}
      >
        <Text weight="semi-bold">İletişim:</Text>
        <CustomText weight="semi-bold">Telefon Numarası:</CustomText>
        <Input
          placeholder="Telefon Numarası"
          disabled={disabled}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <CustomText weight="semi-bold">Mail:</CustomText>
        <Input
          placeholder="E-Mail"
          disabled={disabled}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Row gutter={[32, 0]}>
          <Col
            md={{ span: 6, offset: 12 }}
            lg={{ span: 6, offset: 12 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
          >
            <SendButton
              type="secondary"
              disabled={disabled}
              onClick={() => editContactContent()}
            >
              <Text color="white" weight="semi-bold">
                Kaydet
              </Text>
            </SendButton>
          </Col>
          <Col md={6} lg={6} xl={24} xxl={24}>
            <EditButton onClick={() => setDisabled(!disabled)}>
              <Text color="yellow"> Düzenle</Text>
            </EditButton>
          </Col>
        </Row>
      </RightSide>
      <Divider />
      <BottomSide span={24}>
        <Text weight="semi-bold">Mevcut Sorular/Cevaplar:</Text>
        <Row
          style={{
            marginTop: "32px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CardWrapper md={10} lg={8} xl={5} xxl={5}>
            <ExistContent>
              {subCategoriesToRender &&
                subCategoriesToRender.map(
                  ({ id, category, logo, logo_inactive }) =>
                    existIsActive === id && category === 1 ? (
                      <Col span={10}>
                        <CustomCard onClick={() => setExistIsActive(id)}>
                          <img src={logo} alt="" width="50%" />
                        </CustomCard>
                      </Col>
                    ) : (
                      <Col span={10}>
                        <CustomCard onClick={() => setExistIsActive(id)}>
                          <img src={logo_inactive} alt="" width="50%" />
                        </CustomCard>
                      </Col>
                    )
                )}
            </ExistContent>
          </CardWrapper>
          <Col md={6} lg={6} xl={8} xxl={9} style={{ marginTop: "10px" }}>
            <Input
              placeholder="Ara"
              suffix={<SearchIcon />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <Content>
          {faqListToRender &&
            faqListToRender.map(
              ({ id, question, answer, sub_category }) =>
                existIsActive === sub_category && (
                  <QuestionItem
                    id={id}
                    answer={answer}
                    question={question}
                    setSelectedQuestion={setSelectedQuestion}
                  />
                )
            )}
        </Content>
        <Col
          md={{ span: 12, offset: 12 }}
          lg={{ span: 12, offset: 12 }}
          xl={{ span: 8, offset: 16 }}
          xxl={{ span: 8, offset: 16 }}
          style={{ marginTop: "60px" }}
        >
          <Row gutter={[32, 0]}>
            <Content span={12}>
              <CancelButton onClick={() => setEmpty()}>
                <Text color="red">İptal Et</Text>
              </CancelButton>
            </Content>
            <Content span={12}>
              <EditButton
                onClick={() => editContent()}
                style={{ marginTop: "0px" }}
              >
                <Text color="yellow"> Düzenle</Text>
              </EditButton>
            </Content>
          </Row>
        </Col>
      </BottomSide>
      <AlertModal
        deleteContent={deleteContent}
        visible={isAlertVisible}
        onCancel={handleCancel}
      />
    </Row>
  );
};
export default MobilCard;
