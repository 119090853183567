import { CalendarColumn, Cnt } from './styles';
import moment from 'moment';
import { Text } from '../../../../../../../../components/Typography/styles';

const CalendarItem = ({ box, isOpen, start, end }) => {
	return (
		<CalendarColumn span={24}>
			<Cnt span={24}>
				<Text weight='semi-bold'>{moment(start).format('DD ddd')}</Text>
			</Cnt>
			<Cnt span={24}>
				<Text weight='light' size='smallRegular'>
					{moment(start).format('HH:mm-')}
					{moment(end).format('HH:mm')}
				</Text>
			</Cnt>
			<Cnt span={24}>
				<Text weight='light' size='smallRegular' fontStyle='italic'>
					{isOpen ? 'Açık' : 'Kapalı'}
				</Text>
			</Cnt>
			<Cnt span={24} style={{ borderBottom: '0px' }}>
				<Text weight='light' size='smallRegular'>
					{box} Paket
				</Text>
			</Cnt>
		</CalendarColumn>
	);
};

export default CalendarItem;
