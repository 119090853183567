import styled from 'styled-components';
import { Row, Col } from 'antd';

const profilePaddingHorizontal = '15px';
const profilePaddingVertical = '4px';

export const ProfileSectionStyles = styled(Row)`
	border-radius: 20px;
	background-color: ${({ theme }) => theme.contentBackground};
	width: 100%;
	height: 60px;

	padding: ${profilePaddingVertical} ${profilePaddingHorizontal};

	.cover-image {
		height: 100%;
		width: 100%;
	}
`;

export const LeftSide = styled(Col)`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-right: 3px solid ${({ theme }) => theme.lightColor};
`;

export const RightSide = styled(Col)`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export const UserIconHolder = styled.div`
	height: 36px;
	width: 41px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	:hover {
		cursor: pointer;
	}
`;

export const MenuStyles = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	width: 120px;
	height: 50px;
`;
