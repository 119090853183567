import React, { useState } from 'react';
import { Row, Col } from 'antd';
import emptyImg from '../../../../../../../Assets/components/empty-file-img.svg';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as EditIcon } from '../../../../../../../Assets/components/edit-btn.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../Assets/components/cancel-btn.svg';
import {
	FoodItemStyles,
	Content,
	ContentRight,
	ContentLeft,
	FoodnameLabel,
	CustomText,
	TagRow,
	FoodImg,
} from './styles.js';
import NewMealModal from '../NewMealModal';
import AlertModal from './AlertModal';

const MenuItem = ({ storeId, catId, id, name, description, labels, price, photo, edit }) => {
	const [isEdit, setIsEdit] = useState(false);
	const [isAlertModal, setIsAlertModal] = useState(false);

	const handleCancel = () => {
		setIsEdit(false);
		setIsAlertModal(false);
	};

	return (
		<FoodItemStyles span={24}>
			<Content>
				<ContentLeft xl={5} lg={4} md={4} sm={4}>
					<FoodImg alt='' src={photo ? photo : emptyImg} />
				</ContentLeft>
				<ContentRight xl={8} lg={9} md={9} sm={9}>
					<TagRow gutter={[0, 0]} justify='center'>
						<Col span={18} lg={24} md={24}>
							<Row>
								<Text size='non-regular' weight='semi-bold' color='smoke'>
									{name}
								</Text>
							</Row>
							<Row style={{ marginTop: '12px' }}>
								<CustomText className='price-size' size='non-regular' color='smoke'>
									{price} TL
								</CustomText>
							</Row>
						</Col>
					</TagRow>
				</ContentRight>
				<Col xl={10} lg={10} md={10} sm={10}>
					<TagRow gutter={[0, 0]}>
						{labels ? (
							labels.map((item) => (
								<FoodnameLabel className='foodlabel'>
									<CustomText className='tag-text' size='non-regular' family='secondary' color='smoke' weight='light'>
										{item.name}
									</CustomText>
								</FoodnameLabel>
							))
						) : (
							<div>nodata</div>
						)}
					</TagRow>
				</Col>
				<Col span={1}>
					<Row justify='center' align='middle' style={{ display: 'flex', flexDirection: 'column' }}>
						<EditIcon style={{ marginTop: '12px', cursor: 'pointer' }} onClick={() => setIsEdit(true)} />
						<DeleteIcon style={{ marginTop: '12px', cursor: 'pointer' }} onClick={() => setIsAlertModal(true)} />
					</Row>
				</Col>
			</Content>
			<NewMealModal
				isEdit={isEdit}
				storeId={storeId}
				catId={catId}
				mealId={id}
				visible={isEdit}
				onCancel={handleCancel}
				setVisible={setIsEdit}
				editName={name}
				editDesc={description}
				editLabels={labels}
				editPrice={price}
				editImage={photo}
			/>
			<AlertModal visible={isAlertModal} onCancel={handleCancel} mealId={id} />
		</FoodItemStyles>
	);
};

export default MenuItem;
