import React from 'react';
import { Row, Col } from 'antd';
import { LoginStyles } from './styles';
import { Text } from '../../components/Typography/styles';

const PageNotFound = () => {
	return (
		<LoginStyles justify='center' align='middle'>
			<Col xs={20} sm={16} md={16} lg={14} xl={10} xxl={8}>
				<Row className='vertical-row' align='middle' justify='center'>
					<Text weight='semi-bold' size='36px'>
						PAGE NOT FOUND
					</Text>
				</Row>
			</Col>
		</LoginStyles>
	);
};

export default PageNotFound;
