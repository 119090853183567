import { between } from 'polished';

export const FontTheme = {
	Family: {
		primary: 'Montserrat',
		secondary: 'Roboto',
	},
	Color: {
		black: '#424242',
		smoke: '#837F7F',
		green: '#8FD89F',
		yellow: '#ffbc41',
		white: '#ffffff',
		red: '#F38262',
	},
	Weight: {
		light: '300',
		regular: '450',
		semiBold: '600',
		medium: '500',
		bold: '700',
		extraBold: '800',
	},
	Size: {
		smallRegular: between('8px', '14px', '320px', '1920px'),
		regular: between('12px', '16px', '320px', '1920px'),
		nobleRegular: '16px',
		smallTitle: between('16px', '22px', '320px', '1920px'),
		title: '22px',
		description: between('16px', '22px', '320px', '1920px'),
		mediumTitle: between('28px', '48px', '320px', '1920px'),
		bigTitle: between('34px', '60px', '320px', '1920px'),
	},
	LineHeight: {
		regular: between('20px', '30px', '320px', '1920px'),
		description: between('24px', '34px', '320px', '1920px'),
		mediumTitle: between('36px', '68px', '320px', '1920px'),
		bigTitle: between('48px', '70px', '320px', '1920px'),
	},

	Style: {
		normal: 'normal',
		italic: 'italic',
		oblique: 'oblique',
	},
};
