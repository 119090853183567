import * as actionTypes from './actionTypes';

const initialState = {
  loading: null,
  activeOrdersList: null,
  activeOrdersListError: null,

  passedOrdersList: null,
  passedOrdersListError: null,

  cancelledOrdersList: null,
  cancelledOrdersListError: null,
};

const getActiveOrdersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getActiveOrdersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  activeOrdersList: action.payload,
});

const getActiveOrdersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  activeOrdersListError: action.payload,
});

const getPassedOrdersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getPassedOrdersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  passedOrdersList: action.payload,
});

const getPassedOrdersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  passedOrdersListError: action.payload,
});

const getCancelledOrdersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getCancelledOrdersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  cancelledOrdersList: action.payload,
});

const getCancelledOrdersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  cancelledOrdersListError: action.payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_ORDERS_START:
      return getActiveOrdersStartReducer(state);
    case actionTypes.GET_ACTIVE_ORDERS_SUCCESS:
      return getActiveOrdersSuccessReducer(state, action);
    case actionTypes.GET_ACTIVE_ORDERS_FAIL:
      return getActiveOrdersFailReducer(state, action);
    case actionTypes.GET_PASSED_ORDERS_START:
      return getPassedOrdersStartReducer(state);
    case actionTypes.GET_PASSED_ORDERS_SSUCCESS:
      return getPassedOrdersSuccessReducer(state, action);
    case actionTypes.GET_PASSED_ORDERS_SFAIL:
      return getPassedOrdersFailReducer(state, action);
    case actionTypes.GET_CANCELLED_ORDERS_START:
      return getCancelledOrdersStartReducer(state);
    case actionTypes.GET_CANCELLED_ORDERS_SUCCESS:
      return getCancelledOrdersSuccessReducer(state, action);
    case actionTypes.GET_CANCELLED_ORDERS_FAIL:
      return getCancelledOrdersFailReducer(state, action);
    default:
      return state;
  }
};
export default reducer;
