import * as actionTypes from './actionTypes';

export const getActiveOrdersStartAction = () => ({
  type: actionTypes.GET_ACTIVE_ORDERS_START,
});

export const getActiveOrdersSuccessAction = (orders) => ({
  type: actionTypes.GET_ACTIVE_ORDERS_SUCCESS,
  payload: orders,
});

export const getActiveOrdersFailAction = (error) => ({
  type: actionTypes.GET_ACTIVE_ORDERS_FAIL,
  payload: error,
});

export const getPassedOrdersStartAction = () => ({
  type: actionTypes.GET_PASSED_ORDERS_START,
});

export const getPassedOrdersSuccessAction = (orders) => ({
  type: actionTypes.GET_PASSED_ORDERS_SSUCCESS,
  payload: orders,
});

export const getPassedOrdersFailAction = (error) => ({
  type: actionTypes.GET_PASSED_ORDERS_SFAIL,
  payload: error,
});

export const getCancelledOrdersStartAction = () => ({
  type: actionTypes.GET_CANCELLED_ORDERS_START,
});

export const getCancelledOrdersSuccessAction = (orders) => ({
  type: actionTypes.GET_CANCELLED_ORDERS_SUCCESS,
  payload: orders,
});

export const getCancelledOrdersFailAction = (error) => ({
  type: actionTypes.GET_CANCELLED_ORDERS_FAIL,
  payload: error,
});
