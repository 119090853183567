export const GET_BOX_NAMES_START = 'GET_BOX_NAMES_START';
export const GET_BOX_NAMES_SUCCESS = 'GET_BOX_NAMES_SUCCESS';
export const GET_BOX_NAMES_FAIL = 'GET_BOX_NAMES_FAIL';

export const CREATE_BOX_NAMES_START = 'CREATE_BOX_NAMES_START';
export const CREATE_BOX_NAMES_SUCCESS = 'CREATE_BOX_NAMES_SUCCESS';
export const CREATE_BOX_NAMES_FAIL = 'CREATE_BOX_NAMES_FAIL';

export const UPDATE_BOX_NAMES_START = 'UPDATE_BOX_NAMES_START';
export const UPDATE_BOX_NAMES_SUCCESS = 'UPDATE_BOX_NAMES_SUCCESS';
export const UPDATE_BOX_NAMES_FAIL = 'UPDATE_BOX_NAMES_FAIL';
