import styled from "styled-components";
import Card from "../../components/Card/index";
import { between } from "polished";
import { Text as text } from "../../components/Typography/styles";
import { Row } from "antd";

export const AuthUsersContent = styled(Card)`
  width: 100%;

  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-body {
    padding-left: 39px;
    padding-right: 41px;
  }
  margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
  font-size: ${between("14px", "22px")};
`;

export const Item = styled(Row)`
  margin-top: 32px;
`;
export const Content = styled(Row)`
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  align-content: center;
`;

export const TableStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 0 0 2px 2px;
  background: ${({ theme }) => theme.lightColor};
`;
