/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Text } from "../../../components/Typography/styles";
import { Col, Row } from "antd";
import { Option } from "antd/lib/mentions";
import {
  AuthUsersContents,
  CardTitle,
  Input,
  SelectList,
  SendButton,
} from "../PackageInfoCard/styles";
// import { Text } from '../StoreInfoCard/styles';
import { Content, Form, Item } from "../StoreInfoCard/styles";
import { createPackagingType } from "../../../../services/Redux/AddRestaurant/api";
import { useDispatch } from "react-redux";

const PackageInfoCard = () => {
  const dispatch = useDispatch();
  const [packageName, setPackageName] = useState();
  const [storeOwnerEmail, setStoreOwnerEmail] = useState();

  const onFinish = (values) => {
    const formData = {
      name: packageName,
      store: storeOwnerEmail
    }
    dispatch(createPackagingType(formData));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Col md={24} lg={24} xl={22} xxl={22}>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <AuthUsersContents title={<CardTitle>Paket tipi</CardTitle>}>
          <Content>
            <Item span={24}>
              <Item span={24} style={{ marginTop: "32px" }}>
                <Text weight="semi-bold">Paket tipi</Text>
              </Item>
            </Item>
          </Content>

          <Content> 
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Kullanıcı Bölümünde Girilen Email
              </Text>
              <Form.Item
                name="storeOwnerEmail"
                rules={[
                  {
                    type: "email",
                    message: "Geçerli bir email giriniz!",
                  },
                  {
                    required: true,
                    message: "Emailinizi giriniz!",
                  },
                ]}
              >
                <Input
                  placeholder="Kullanıcı Bölümünde Girilen Email"
                  type="email"
                  style={{marginTop: 0}}
                  value={storeOwnerEmail}
                  onChange={(e) => setStoreOwnerEmail(e.target.value)}
                />
              </Form.Item>
            </Item>

            <Item span={11} offset={1}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Paketleme
              </Text>
              <Form.Item>
                <SelectList
                  style={{ width: "100%" }}
                  defaultValue="Paketleme Türünü Seç"
                  value={packageName}
                  onChange={(e) => setPackageName(e)}
                >
                  <Option value="Kutu">Kutu</Option>
                  <Option value="Poşet">Poşet</Option>
                  <Option value="File">File</Option>
                </SelectList>
              </Form.Item>
            </Item>
          </Content>
          
          <Row justify="center" style={{ marginTop: "32px" }}>
            <Col span={6}>
              <SendButton type="secondary" htmlType="submit">
                <Text color="white" weight="semi-bold">
                  EKLE
                </Text>
              </SendButton>
            </Col>
          </Row>
        </AuthUsersContents>
      </Form>
    </Col>
  );
};

export default PackageInfoCard;
