import React from 'react';
import { Row } from 'antd';
import { SaleStatsCardStyles, Content, DescriptionContainer } from './styles';
import { Text } from '../../../../components/Typography/styles';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const SaleStatsCard = ({ TotalProfit }) => {
	return (
		<SaleStatsCardStyles title={<Title text='Kurtarılan Yemek' />}>
			<Content>
				<Row justify='center' align='middle'>
					<Text color='red' size='32px' weight='bold'>
						{TotalProfit}
					</Text>
				</Row>
				<DescriptionContainer justify='center' align='middle'>
					<Text style={{ textAlign: 'center' }} weight='bold'>
						İsraftan kurtarılan yemek sayısı
					</Text>
				</DescriptionContainer>
			</Content>
		</SaleStatsCardStyles>
	);
};

export default SaleStatsCard;
