import * as actions from './actions';
import { message } from 'antd';

export const getCategories = () => async (dispatch, getState, service) => {
	dispatch(actions.getCategoryStartAction());
	await service({
		path: 'box/category/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getCategorySuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getCategoryFailAction(error));
		});
};

export const createMeal = (info) => async (dispatch, getState, service) => {
	dispatch(actions.createMealStartAction());
	await service({
		path: 'box/meal/',
		method: 'POST',
		sendToken: true,
		body: info,
	})
		.then((data) => {
			dispatch(actions.createMealSuccessAction(data));
			message.success('Ürün Başarı ile Oluşturuldu. !');
		})
		.catch((error) => {
			dispatch(actions.createMealFailAction(error));
			message.error('Ürün Oluşturulurken bir Hata Oluştu  !');
		});
};

export const updateMeal = (id, updateMeal) => async (dispatch, getState, service) => {
	dispatch(actions.updateMealStartAction());
	await service({
		path: `box/meal/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: updateMeal,
	})
		.then((data) => {
			dispatch(actions.updateMealSuccessAction(data));
			message.success('Restorant Ürün Güncellemesi Başarı ile  Onaylandı !');
		})
		.catch((error) => {
			dispatch(actions.updateMealFailAction(error));
			message.error('Bir Hata Meydana Geldi !');
		});
};
export const deleteMeal = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deleteMealStartAction());
	await service({
		path: `box/meal/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteMealSuccessAction(data));
			message.success('Ürün Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deleteMealFailAction(error));
			message.error('Ürün Silinirken bir Hata Oluştu  !');
		});
};

export const deleteDraftMeal = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deleteDraftMealStartAction());
	await service({
		path: `superadmin/pending_approval/draft-meal/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteDraftMealSuccessAction(data));
			message.success('Ürün Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deleteDraftMealFailAction(error));
			message.error('Ürün Silinirken bir Hata Oluştu  !');
		});
};

export const updatePendingMeal = (id, updateMeal) => async (dispatch, getState, service) => {
	dispatch(actions.updatePendingMealStartAction());
	await service({
		path: `superadmin/pending_approval/draft-meal/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: updateMeal,
	})
		.then((data) => {
			dispatch(actions.updatePendingMealSuccessAction(data));
			message.success('Restorant Ürün Güncellemesi Başarı ile  Onaylandı !');
		})
		.catch((error) => {
			dispatch(actions.updatePendingMealFailAction(error));
			message.error('Bir Hata Meydana Geldi !');
		});
};
