import styled from 'styled-components';
import { Col } from 'antd';
import { between } from 'polished';

export const FormWrapper = styled(Col)`
	padding-top: 30px;
	min-height: 300px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	#nest-messages {
		min-height: 250px;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.btn-size {
		width: ${between('200px', '300px')};
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.stick-center-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
`;
