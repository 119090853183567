import React from 'react';
import { HeaderStyles, ImageContainer, ProfileContainer } from './styles';
import logo from '../../../../Assets/Header/dongu-logo.png';
import Profile from './Profile';

const Header = ({ userData }) => {
	return (
		<HeaderStyles justify='space-between'>
			<ImageContainer>
				<img className='img-covered' src={logo} alt='' />
			</ImageContainer>

			<ProfileContainer>
				<Profile isLogin={userData ? true : false} />
			</ProfileContainer>
		</HeaderStyles>
	);
};

export default Header;
