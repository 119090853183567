import styled from 'styled-components';
import { Col } from 'antd';

export const CalendarColumn = styled(Col)`
	border-right: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const Cnt = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	padding: 5px;
	border-bottom: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;
