import * as actions from './actions';
import { message } from 'antd';

export const getSubCategories = () => async (dispatch, state, service) => {
	dispatch(actions.getSubCategoriesStartAction());
	await service({
		path: 'helpcenter/sub-category/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getSubCategoriesSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getSubCategoriesFailAction(error));
		});
};

export const createFaq = (question, answer, sub) => async (dispatch, getState, service) => {
	dispatch(actions.createFaqStartAction());
	await service({
		path: 'helpcenter/faq/',
		method: 'POST',
		sendToken: true,
		body: {
			question: question,
			answer: answer,
			sub_category: sub,
		},
	})
		.then((data) => {
			dispatch(actions.createFaqSuccessAction(data));
			message.success('İçerik Başarı ile Oluşturuldu !');
		})
		.catch((error) => {
			dispatch(actions.createFaqFailAction(error));
			message.error('İçerik Oluşturulurken Bir Hata Oluştu !');
		});
};

export const getFaqList = () => async (dispatch, state, service) => {
	dispatch(actions.getFaqStartAction());
	await service({
		path: 'helpcenter/faq/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getFaqSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getFaqFailAction(error));
		});
};

export const updateFaq = (id, question, answer, sub) => async (dispatch, state, service) => {
	dispatch(actions.updateFaqStartAction());
	await service({
		path: `helpcenter/faq/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			question: question,
			answer: answer,
			sub_category: sub,
		},
	})
		.then((data) => {
			dispatch(actions.updateFaqSuccessAction(data));
			message.success('İçerik Başarı ile Güncellendi !');
		})
		.catch((error) => {
			dispatch(actions.updateFaqFailAction(error));
			message.error('İçerik Oluşturulurken Bir Hata Oluştu !');
		});
};

export const deleteFaq = (id) => async (dispatch, state, service) => {
	dispatch(actions.deleteFaqStartAction());
	await service({
		path: `helpcenter/faq/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteFaqSuccessAction(data));
			message.success('İçerik Başarı ile Silindi !');
		})
		.catch((error) => {
			dispatch(actions.deleteFaqFailAction(error));
			message.error('İçerik Silinirken Bir Hata Oluştu !');
		});
};

export const getContact = () => async (dispatch, state, service) => {
	dispatch(actions.getContactStartAction());
	await service({
		path: 'helpcenter/contact-informations/informations_get/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getContactSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getContactFailAction(error));
		});
};

export const updateContact = (email, phone_number) => async (dispatch, state, service) => {
	dispatch(actions.updateContactStartAction());
	await service({
		path: 'helpcenter/contact-informations/informations_update/',
		method: 'PUT',
		sendToken: true,
		body: {
			email,
			phone_number,
		},
	})
		.then((data) => {
			dispatch(actions.updateContactSuccessAction(data));
			message.success('İletişim Bilgileri Başarı ile Güncellendi !');
		})
		.catch((error) => {
			dispatch(actions.updateContactFailAction(error));
			message.error('İletişim Bilgileri Güncellenirken bir Hata Meydana Geldi !');
		});
};
