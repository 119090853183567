import { BadgeStyles } from './styles';
import { Row, Col } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import threeMonthBadge from '../../../../../../Assets/components/3month-badge.svg';
import moment from 'moment';

const Badges = ({ reviews, storeId, joined }) => {
	return (
		<Col>
			<BadgeStyles>
				<Row justify='center'>
					<img src={threeMonthBadge} alt='' srcSet='' />
				</Row>
				<Row justify='center'>
					<Text color='green' size='30px' weight='bold' style={{ marginTop: '5px' }}>
						{reviews !== null ? reviews.total_count : '0'}
					</Text>
				</Row>
				<Row justify='center'>
					<Text weight='semi-bold' style={{ textAlign: 'center', marginTop: '5px' }}>
						İsraftan Kurtarılan Yemek
					</Text>
				</Row>
			</BadgeStyles>
			<BadgeStyles style={{ marginTop: '32px' }}>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text color='red' size='32px' weight='bold'>
						{reviews !== null ? reviews.total_count : '0'}
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='semi-bold'>
						İsraftan kurtarılan yemek sayısı
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='light'>
						{moment().format('DD MMM YYYY')}
					</Text>
				</Row>
			</BadgeStyles>
			<BadgeStyles style={{ marginTop: '32px' }}>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text color='green' size='32px' weight='bold'>
						{reviews !== null ? reviews.total_price : '0'} TL
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='semi-bold'>
						İsraftan kurtarılan yemeklerin TL karşılığı
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='light'>
						{moment().format('DD MMM YYYY')}
					</Text>
				</Row>
			</BadgeStyles>
			<BadgeStyles style={{ marginTop: '32px', backgroundColor: 'white' }}>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='semi-bold'>
						Döngü'ye katılım tarihi:
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='light'>
						{moment(joined).format('DD MMM YYYY')}
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='semi-bold'>
						Restorant ID:
					</Text>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: '32px' }}>
					<Text style={{ textAlign: 'center' }} weight='light'>
						{storeId}
					</Text>
				</Row>
			</BadgeStyles>
		</Col>
	);
};

export default Badges;
