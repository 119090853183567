import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	rolesList: null,
	rolesError: null,

	createRole: null,
	createRoleError: null,

	updateRole: null,
	updateRoleError: null,

	deleteRole: null,
	deleteRoleError: null,
};

const getRolesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getRolesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	rolesList: action.payload.results,
});

const getRolesFailReducer = (state, action) => ({
	...state,
	loading: false,
	rolesError: action.payload,
});

//CREATE

const createRolesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createRolesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createRole: action.payload,
});

const createRolesFailReducer = (state, action) => ({
	...state,
	loading: false,
	createRoleError: action.payload,
});

//UPDATE

const updateRolesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateRolesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateRole: action.payload,
});

const updateRolesFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateRoleError: action.payload,
});

//DELETE

const deleteRolesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteRolesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteRole: action.payload,
});

const deleteRolesFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteRoleError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ROLES_START:
			return getRolesStartReducer(state);
		case actionTypes.GET_ROLES_SUCCESS:
			return getRolesSuccessReducer(state, action);
		case actionTypes.GET_ROLES_FAIL:
			return getRolesFailReducer(state, action);
		case actionTypes.CREATE_ROLES_START:
			return createRolesStartReducer(state);
		case actionTypes.CREATE_ROLES_SUCCESS:
			return createRolesSuccessReducer(state, action);
		case actionTypes.CREATE_ROLES_FAIL:
			return createRolesFailReducer(state, action);
		case actionTypes.UPDATE_ROLES_START:
			return updateRolesStartReducer(state);
		case actionTypes.UPDATE_ROLES_SUCCESS:
			return updateRolesSuccessReducer(state, action);
		case actionTypes.UPDATE_ROLES_FAIL:
			return updateRolesFailReducer(state, action);
		case actionTypes.DELETE_ROLES_START:
			return deleteRolesStartReducer(state);
		case actionTypes.DELETE_ROLES_SUCCESS:
			return deleteRolesSuccessReducer(state, action);
		case actionTypes.DELETE_ROLES_FAIL:
			return deleteRolesFailReducer(state, action);
		default:
			return state;
	}
};
export default reducer;
