import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Text } from '../../../../../../components/Typography/styles';

export const FoodItemStyles = styled(Col)`
	padding-bottom: 20px;
	padding-top: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
export const Content = styled(Row)`
	.avatar-uploader > .ant-upload {
		width: 84px;
		height: 84px;
	}
`;

export const TagRow = styled(Row)`
	padding-left: 8px;
`;

export const ContentLeft = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const ContentRight = styled(Col)``;

export const FoodnameLabel = styled.div`
	padding: 5px 8px;
	border: 2px solid ${({ theme }) => theme.borderColor};
	margin-right: 4px;
	margin-bottom: 1px;
	border-radius: 3px;
`;

export const FoodImg = styled.img`
	width: 80px;
	height: 90%;
	object-fit: cover;
	object-position: center;
`;

export const CustomText = styled(Text)`
	&.buttons {
		margin-left: 3px;
	}

	&.tag-text {
		@media (min-width: 1200px) {
			font-size: 11px;
		}
	}

	&.name-size {
		@media (min-width: 1200px) {
			font-size: 13px;
		}
	}

	&.price-size {
		@media (min-width: 1200px) {
			font-size: 12px;
		}
	}
`;
