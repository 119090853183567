/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Text } from "../../../components/Typography/styles";
import search from "../../../../Assets/components/search.png";
import { Select, Header, Input, Location, Footer, NavButton } from "./styles";
import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import leftArrow from "../../../../Assets/components/left-arrow.svg";
import rightArrow from "../../../../Assets/components/right-arrow.svg";
import RestaurantsCard from "./RestaurantsCard";
import { getSuspendRestaurants } from "../../../../services/Redux/RegisteredRestaurants/api";
import useResponsive from "../../../../helpers/Hooks/useMediaQuery";

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: "48px",
      width: "48px",
      backgroundColor: "#ffbc41",
      justifyContent: "center",
      borderRadius: "50px",
      position: "absolute",
      right: "-18px",
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const SuspendRestaurants = ({ setSuspendReason, setRestPage, setStoreId }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [count] = useState(12);
  const [page, setPage] = useState(1);
  const [restaurants, setRestaurants] = useState();
  const [filterProvince, setFilterProvince] = useState("");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const { xl } = useResponsive();
  const provinceList = [
    ...new Set(
      restaurants?.map((item) => item?.store?.province || item?.province)
    ),
  ];

  useEffect(() => {
    dispatch(getSuspendRestaurants());
  }, [dispatch]);

  const { suspendRestaurantsList } = useSelector(
    (state) => state?.registeredRestaurantsStore
  );

  useEffect(() => {
    if (suspendRestaurantsList) {
      setRestaurants(suspendRestaurantsList.slice(0, 12));
    }
  }, [suspendRestaurantsList]);

  useEffect(() => {
    if (search !== "") {
      const searchedName = suspendRestaurantsList?.filter(({ store }) =>
        store?.name.toLowerCase().includes(search.toLocaleLowerCase())
      );
      setRestaurants(searchedName);
    } else {
      setRestaurants(suspendRestaurantsList);
    }
  }, [search]);

  function prevPage() {
    const slice = suspendRestaurantsList.slice(
      count * (page - 2),
      count * (page - 1)
    );
    if (slice.length !== 0) {
      setRestaurants(slice);
      setPage(page - 1);
    }
  }

  function nextPage() {
    const slice = suspendRestaurantsList.slice(
      count * page,
      count * (page + 1)
    );
    if (slice.length !== 0) {
      setRestaurants(slice);
      setPage(page + 1);
    }
  }

  useEffect(() => {
    if (restaurants) {
      if (sortBy === "Alfabetik") {
        setRestaurants(
          [...restaurants].sort((a, b) =>
            a?.store?.name.localeCompare(b?.store?.name)
          )
        );
      }
      if (sortBy === "Kullanıcı Puanı") {
        setRestaurants(
          [...restaurants].sort(
            (item1, item2) =>
              item2?.store?.avg_review - item1?.store?.avg_review
          )
        );
      }
      if (sortBy === "Artan Fiyat") {
        setRestaurants(
          [...restaurants].sort(
            (item1, item2) =>
              item1?.store?.package_setting?.min_order_price -
              item2?.store?.package_setting?.min_order_price
          )
        );
      }
      if (sortBy === "Azalan Fiyat") {
        setRestaurants(
          [...restaurants].sort(
            (item1, item2) =>
              item2?.store?.package_setting?.min_order_price -
              item1?.store?.package_setting?.min_order_price
          )
        );
      }
    }
  }, [sortBy, restaurants]);

  return (
    <Col>
      <Header>
        <Col span={24}>
          <Row gutter={[32, 0]}>
            <Col md={24} lg={24} xl={15} xxl={15}>
              <Row gutter={[32, 0]}>
                <Col span={6}>
                  <Select
                    defaultValue="Semtini Seç"
                    onChange={(value) => {
                      setFilterProvince(value);
                    }}
                  >
                    <Option>Tüm Semtler</Option>
                    {provinceList &&
                      provinceList?.map((province, index) => (
                        <Option key={index} value={`${province}`}>
                          {province}
                        </Option>
                      ))}
                  </Select>
                </Col>

                <Col span={6}>
                  <Select defaultValue="Sırala" onChange={(e) => setSortBy(e)}>
                    <Option value="Alfabetik">Alfabetik</Option>
                    <Option value="Kullanıcı Puanı">Kullanıcı Puanı</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Select
                    defaultValue="Paket Fiyatı"
                    onChange={(e) => setSortBy(e)}
                  >
                    <Option value="Artan Fiyat">Artan Fiyat</Option>
                    <Option value="Azalan Fiyat">Azalan Fiyat</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Select
                    defaultValue="Paket Teslimi"
                    onChange={(e) => setSortBy(e)}
                  >
                    <Option value="1">Gel-Al</Option>
                    <Option value="2">Kurye</Option>
                    <Option value="3">Her İkisi</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col md={9} style={{ marginTop: xl ? "0px" : "32px" }}>
              <Input
                placeholder="Ara"
                suffix={<SearchIcon />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Header>
      <Location>
        <Text>Restoranlar</Text>
      </Location>
      <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
        {restaurants &&
          restaurants
            ?.filter((item) =>
              filterProvince
                ? (item?.province || item?.store?.province) === filterProvince
                : true
            )
            ?.filter(
              (item) => item?.store?.package_setting?.delivery_type !== sortBy
            )
            .map(({ description, store, created_at }) => (
              <Col
                key={created_at}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 10, offset: 1 }}
                xl={{ span: 8, offset: 0 }}
                xxl={{ span: 6, offset: 0 }}
              >
                <RestaurantsCard
                  setSuspendReason={setSuspendReason}
                  description={description}
                  store={store}
                  setRestPage={setRestPage}
                  setStoreId={setStoreId}
                />
              </Col>
            ))}
      </Row>
      <Footer align="middle" justify="center">
        <NavButton
          src={leftArrow}
          alt="left-arrow"
          onClick={() => {
            if (page !== 1) {
              prevPage();
            }
          }}
        />
        <Text>{page}</Text>
        <NavButton
          src={rightArrow}
          alt="right-arrow"
          onClick={() => nextPage()}
        />
      </Footer>
    </Col>
  );
};

export default SuspendRestaurants;
