export const GET_ACTIVE_ORDERS_START = 'GET_ACTIVE_ORDERS_START';
export const GET_ACTIVE_ORDERS_SUCCESS = 'GET_ACTIVE_ORDERS_SUCCESS';
export const GET_ACTIVE_ORDERS_FAIL = 'GET_ACTIVE_ORDERS_FAIL';

export const GET_PASSED_ORDERS_START = 'GET_PASSED_ORDERS_START';
export const GET_PASSED_ORDERS_SSUCCESS = 'GET_PASSED_ORDERS_SSUCCESS';
export const GET_PASSED_ORDERS_SFAIL = 'GET_PASSED_ORDERS_SFAIL';

export const GET_CANCELLED_ORDERS_START = 'GET_CANCELLED_ORDERS_START';
export const GET_CANCELLED_ORDERS_SUCCESS = 'GET_CANCELLED_ORDERS_SUCCESS';
export const GET_CANCELLED_ORDERS_FAIL = 'GET_CANCELLED_ORDERS_FAIL';
