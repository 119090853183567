import * as actions from './actions';
import { message } from 'antd';

export const getSpecialReasons = () => async (dispatch, getState, service) => {
	dispatch(actions.getSpecialReasonsStartAction());
	await service({
		path: 'store/time_label/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getSpecialReasonsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getSpecialReasonsFailAction(error));
		});
};

export const createSpecialReasons = (reasons) => async (dispatch, getState, service) => {
	dispatch(actions.createSpecialReasonsStartAction());
	await service({
		path: 'store/time_label/',
		method: 'POST',
		sendToken: true,
		body: {
			name: reasons,
		},
	})
		.then((data) => {
			dispatch(actions.createSpecialReasonsSuccessAction(data));
			message.success('Özel Sebep Başarı ile Oluşturuldu. !');
		})
		.catch((error) => {
			dispatch(actions.createSpecialReasonsFailAction(error));
			message.error('Özel Sebep Yaratılırken Bir Hata Oluştu !');
		});
};

export const updateSpecialReasons = (id, reasons) => async (dispatch, getState, service) => {
	dispatch(actions.updateSpecialReasonsStartAction());
	await service({
		path: `store/time_label/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			name: reasons,
		},
	})
		.then((data) => {
			dispatch(actions.updateSpecialReasonsSuccessAction(data));
			message.success('Özel Sebep Başarı ile Güncellendi. !');
		})
		.catch((error) => {
			dispatch(actions.updateSpecialReasonsFailAction(error));
			message.error('Özel Sebep Güncellenirken Bir Hata Oluştu !');
		});
};

export const deleteSpecialReasons = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deleteSpecialReasonsStartAction());
	await service({
		path: `store/time_label/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deleteSpecialReasonsSuccessAction(data));
			message.success('Özel Sebep Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deleteSpecialReasonsFailAction(error));
			message.error('Özel Sebep Silinirken Bir Hata Oluştu. !');
		});
};
