import { useState, useEffect } from 'react';
import { WorkingTimeStyles, CancelButton, Location, CalendarContent } from './styles';
import { Col, Row } from 'antd';
import moment from 'moment';
import { Text } from '../../../../../../../components/Typography/styles';
import cancelIcon from '../../../../../../../../Assets/components/cancel.svg';
import CalendarItem from './CalendarItem';

const WorkingTime = ({ storeInfo }) => {
	const [isShown, setIsShown] = useState(false);
	const [calendarToRender, setCalendarToRender] = useState([]);

	const { calendar } = storeInfo;

	useEffect(() => {
		if (calendar) {
			const weekly = calendar.filter(
				({ start_date }) => moment(start_date).format('YYYY-MM-DD') >= moment().startOf('isoWeek').format('YYYY-MM-DD'),
			);

			const sortedWeekly = weekly.sort(function (a, b) {
				var dateA = moment(a.start_date).format('YYYY-MM-DD');
				var dateB = moment(b.start_date).format('YYYY-MM-DD');
				if (dateA < dateB) {
					return -1;
				}
				if (dateA > dateB) {
					return 1;
				}
				return 0;
			});

			setCalendarToRender(sortedWeekly);
		}
	}, [calendar]);

	return (
		<WorkingTimeStyles span={24}>
			<Location style={{ borderBottom: isShown && '0px' }}>
				<Col span={7} style={{ paddingBottom: '12px' }}>
					<Text weight='semi-bold'>Çalışma Saatleri </Text>
				</Col>
				<Col span={13}>
					<Text weight='light'>
						Bugün: {storeInfo ? storeInfo.package_settings.courier_delivery_time_start : 'Null'} -{' '}
						{storeInfo ? storeInfo.package_settings.courier_delivery_time_end : 'Null'}{' '}
					</Text>
				</Col>
				{isShown ? (
					<Col span={2} offset={2}>
						<Row justify='center' align='middle'>
							<img src={cancelIcon} alt='cancel' style={{ cursor: 'pointer' }} onClick={() => setIsShown(false)} />
						</Row>
					</Col>
				) : (
					<Col span={4}>
						<CancelButton type='secondary' onClick={() => setIsShown(true)}>
							<Text color='red' weight='semi-bold' size='nobleRegular'>
								Detaylar
							</Text>
						</CancelButton>
					</Col>
				)}
			</Location>
			<Row style={{ paddingRight: '25px', paddingLeft: '32px', display: isShown ? 'block' : 'none' }}>
				<Col span={24}>
					<CalendarContent style={{ borderRight: '0px' }}>
						{calendarToRender.length !== 0 ? (
							calendarToRender.map(({ box_count, is_active, start_date, end_date }) => (
								<Col style={{ width: '14.28%' }}>
									<CalendarItem box={box_count} isOpen={is_active} start={start_date} end={end_date} />
								</Col>
							))
						) : (
							<Text style={{ textAlign: 'center', marginTop: '12px' }}> Takvim Verisi Bulunamadı ! </Text>
						)}
					</CalendarContent>
				</Col>
			</Row>

			{isShown && <Location />}
		</WorkingTimeStyles>
	);
};

export default WorkingTime;
