import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	boxNamesList: null,
	boxNamesError: null,

	createBoxNames: null,
	createBoxNamesError: null,

	updateBoxNames: null,
	updateBoxNamesError: null,
};

const getBoxNamesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getBoxNamesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	boxNamesList: action.payload,
});

const getBoxNamesFailReducer = (state, action) => ({
	...state,
	loading: false,
	boxNamesError: action.payload,
});

// CREATE

const createBoxNamesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createBoxNamesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createBoxNames: action.payload,
});

const createBoxNamesFailReducer = (state, action) => ({
	...state,
	loading: false,
	createBoxNamesError: action.payload,
});

// UPDATE

const updateBoxNamesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateBoxNamesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateBoxNames: action.payload,
});

const updateBoxNamesFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateBoxNamesError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_BOX_NAMES_START:
			return getBoxNamesStartReducer(state);
		case actionTypes.GET_BOX_NAMES_SUCCESS:
			return getBoxNamesSuccessReducer(state, action);
		case actionTypes.GET_BOX_NAMES_FAIL:
			return getBoxNamesFailReducer(state, action);

		case actionTypes.CREATE_BOX_NAMES_START:
			return createBoxNamesStartReducer(state);
		case actionTypes.CREATE_BOX_NAMES_SUCCESS:
			return createBoxNamesSuccessReducer(state, action);
		case actionTypes.CREATE_BOX_NAMES_FAIL:
			return createBoxNamesFailReducer(state, action);

		case actionTypes.UPDATE_BOX_NAMES_START:
			return updateBoxNamesStartReducer(state);
		case actionTypes.UPDATE_BOX_NAMES_SUCCESS:
			return updateBoxNamesSuccessReducer(state, action);
		case actionTypes.UPDATE_BOX_NAMES_FAIL:
			return updateBoxNamesFailReducer(state, action);
		default:
			return state;
	}
};

export default reducer;
