import styled from 'styled-components';
import { Col, Row, Layout, Dropdown, Menu as menu } from 'antd';
import Button from '../../Button';
import { Text } from '../../Typography/styles';

const { Sider } = Layout;
const navItemHoverColor = '#cc6d52';
const siderPaddingTop = '32px';
const siderPaddingBottom = '10px';

export const SiderButton = styled(Button)`
	&&& {
		height: 71px;
		width: 100%;

		box-shadow: none;
		padding-top: 12px;
		padding-bottom: 12px;
		background-color: transparent;
		border: none;
		border-radius: 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 25px;

		&.ant-btn:hover {
			border: none;
			background-color: ${navItemHoverColor};
		}

		.icon-container {
			width: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const BtnContent = styled(Row)`
	width: 100%;
	height: 40px;
`;

export const BtnText = styled(Text)``;

export const SiderStyles = styled(Sider)`
	overflow: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	padding-top: ${siderPaddingTop};
	padding-bottom: ${siderPaddingBottom};
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}
	background-color: ${({ theme }) => theme.primaryColor};

	.active-nav-item {
		border-left: 6px solid ${({ theme }) => theme.secondaryColor} !important;
		background-color: ${navItemHoverColor} !important;
	}

	.active-nav-item-sub {
		border-left: 6px solid ${({ theme }) => theme.lightColor} !important;
		background-color: ${navItemHoverColor} !important;
	}
`;

export const MenuDropdown = styled(Dropdown)``;

export const MenuWrapper = styled(Col)`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const SiderItem = styled(Row)``;

export const Menu = styled(menu)`
	cursor: pointer !important;
	min-height: 71px;
	width: 100%;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	background: transparent !important;
	border-right: 1px solid transparent;

	.ant-menu-submenu-arrow {
		display: ${(props) => (props.arrow ? 'none' : 'block')};
	}

	.ant-menu-submenu-title {
		width: 100%;
		font-weight: 600;
		display: flex;
		align-items: center;
		min-height: 71px;
		color: #fff;
		font-size: 16px;

		margin: 0 !important;

		img {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-left: 6px;
		}

		&:hover {
			border: none;
			background-color: ${navItemHoverColor} !important;
		}

		.ant-menu-submenu-arrow {
			color: #fff !important;
		}
	}

	ul {
		span {
			padding-left: 0px;
		}
		.icon-container {
			width: 0 !important;
		}
		font-size: 16px;
		background: transparent !important;
	}
`;
