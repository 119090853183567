/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Text } from "../../../components/Typography/styles";
import { Col, Row } from "antd";
import { message } from 'antd';
import { Option } from "antd/lib/mentions";
import {
  AuthUsersContents,
  CardTitle,
  Input,
  SelectList,
  SendButton,
  Timer,
} from "./styles";
import { Content, Form, Item } from "../StoreInfoCard/styles";
import { createPackageInfo } from "../../../../services/Redux/AddRestaurant/api";
import { useDispatch } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";

const PackageInfoCard = () => {
  const dispatch = useDispatch();
  const format = "HH:mm";
  const [deliveryType, setDeliveryType] = useState();
  const [extraAddress, setExtraAddress] = useState();
  const [deliveryTimeStart, setDeliveryTimeStart] = useState();
  const [deliveryTimeEnd, setDeliveryTimeEnd] = useState();
  const [courierDeliveryStart, setCourierDeliveryStart] = useState();
  const [courierDeliveryEnd, setCourierDeliveryEnd] = useState();
  const [extraSuggestion, setExtraSuggestion] = useState();
  const [openBuffet, setOpenBuffet] = useState();
  const [minOrderPrice, setMinOrderPrice] = useState();
  const [minDiscount, setMinDiscount] = useState();
  const [defaultBoxCount, setDefaultBoxCount] = useState();
  const [storeOwnerEmail, setStoreOwnerEmail] = useState();

  const createPackageInformation = (values) => {
    const formData = new FormData();
    formData.append("delivery_type", deliveryType);
    formData.append("ekstra_address", values?.extraAddress);
    formData.append("open_buffet", openBuffet);
    formData.append("extra_suggestion", values?.extraSuggestion);
    formData.append("packaging_type", "");
    formData.append("store", values?.storeOwnerEmail);
    formData.append("delivery_time_start", deliveryTimeStart?.format("HH:mm"));
    formData.append("delivery_time_end", deliveryTimeEnd?.format("HH:mm"));
    deliveryType !== "1" && formData.append(
      "courier_delivery_time_start",
      courierDeliveryStart?.format("HH.mm")
    );
    deliveryType !== "1" && formData.append(
      "courier_delivery_time_end",
      courierDeliveryEnd?.format("HH.mm")
    );
    formData.append("min_order_price", values?.minOrderPrice);
    formData.append("min_discounted_order_price", values?.minDiscount);
    formData.append("default_box_count", values?.defaultBoxCount);

    dispatch(createPackageInfo(formData));
  };

  const onFinish = (values) => {
    if(!deliveryTimeStart?.format("HH:mm") || !deliveryTimeEnd?.format("HH:mm")) {
      message.warn("Lütfen teslimat başlangıç  ve teslimat bitiş saatlerini giriniz");
      return;
    }
    else if(deliveryTimeStart?.format("HH:mm") === deliveryTimeEnd?.format("HH:mm")) {
      message.warn("Teslimat başlangıç ve teslimat bitiş saati aynı olamaz");
      return;
    }
    else if(deliveryTimeStart?.format("HH:mm") > deliveryTimeEnd?.format("HH:mm")){
      message.warn("Teslimat başlangıç saati bitiş saatinden sonra olamaz!");
      return;
    }

    if(deliveryType !== "1"){
      if(!courierDeliveryStart?.format("HH:mm") || !courierDeliveryEnd?.format("HH:mm")) {
        message.warn("Lütfen kurye başlangıç  ve kurye bitiş saatlerini giriniz");
        return;
      }
      else if(courierDeliveryStart?.format("HH:mm") === courierDeliveryEnd?.format("HH:mm")) {
        message.warn("Kurye başlangıç ve kurye bitiş saati aynı olamaz");
        return;
      }
    }

    if(courierDeliveryStart?.format("HH:mm") <= deliveryTimeStart?.format("HH:mm") || courierDeliveryEnd?.format("HH:mm") >= deliveryTimeEnd?.format("HH:mm")){
      message.warn("Kurye başlangıç ve bitiş saatleri restoran çalışma saatleri içerisinde olmalıdır!");
      return;
    }

    createPackageInformation(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Col md={24} lg={24} xl={22} xxl={22}>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <AuthUsersContents title={<CardTitle>Restorant Ayarları</CardTitle>}>
          <Content>
            <Item span={24}>
              <Item span={24} style={{ marginTop: "32px" }}>
                <Text weight="semi-bold">Restorant Ayarları</Text>
              </Item>
            </Item>
          </Content>

          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Kullanıcı Bölümünde Girilen Email
              </Text>
              <Form.Item
                name="storeOwnerEmail"
                rules={[
                  {
                    type: "email",
                    message: "Geçerli bir email giriniz!",
                  },
                  {
                    required: true,
                    message: "Emailinizi giriniz!",
                  },
                ]}
              >
                
                <Input
                  placeholder="Kullanıcı Bölümünde Girilen Email"
                  type="email"
                  value={storeOwnerEmail}
                  onChange={(e) => setStoreOwnerEmail(e.target.value)}
                />
              </Form.Item>
            </Item>
          </Content>
          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Teslimat Türü
              </Text>
              <Form.Item>
                <SelectList
                  style={{ width: "100%" }}
                  defaultValue="Teslimat Türünü Seç"
                  value={deliveryType}
                  onChange={(e) => setDeliveryType(e)}
                >
                  <Option value="1">Gel-Al Paket</Option>
                  <Option value="2">Kurye</Option>
                  <Option value="3">Her İkisi</Option>
                </SelectList>
              </Form.Item>
            </Item>
            <Item span={11} offset={1}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Extra Adres
              </Text>
              <Form.Item
                name="extraAddress"
                rules={[
                  { required: false, message: "Extra Bilgi boş geçilemez." },
                ]}
              >
                <Input
                  placeholder="Extra Adres"
                  type="text"
                  value={extraAddress}
                  onChange={(e) => setExtraAddress(e.target.value)}
                />
              </Form.Item>
            </Item>
          </Content>
          <Content>
            <Item span={11}>
              <Form.Item>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Teslimat Başlangıç
                </Text>
                <Timer
                  format={format}
                  value={deliveryTimeStart}
                  placeholder="Teslimat Başlangıç"
                  onChange={(e) => setDeliveryTimeStart(e)}
                  style={{ marginBottom: "32px" }}
                />

                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Teslimat Bitiş
                </Text>
                <Timer
                  format={format}
                  value={deliveryTimeEnd}
                  placeholder="Teslimat Bitiş"
                  onChange={(e) => setDeliveryTimeEnd(e)}
                />
              </Form.Item>
            </Item>

            <Item span={11} offset={1}>
              <Form.Item>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Kurye Başlangıç
                </Text>
                <Timer
                  format={format}
                  value={courierDeliveryStart}
                  placeholder="Kurye Başlangıç"
                  onChange={(e) => setCourierDeliveryStart(e)}
                  style={{ marginBottom: "32px" }}
                  disabled={deliveryType === "1" ? true : false}
                />
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Kurye Bitiş
                </Text>
                <Timer
                  format={format}
                  value={courierDeliveryEnd}
                  placeholder="Kurye Bitiş"
                  onChange={(e) => setCourierDeliveryEnd(e)}
                  disabled={deliveryType === "1" ? true : false}
                />
              </Form.Item>
            </Item>
          </Content>

          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Extra Öneri
              </Text>
              <Form.Item
                name="extraSuggestion"
                rules={[
                  { required: true, message: "Extra Öneri boş geçilemez." },
                ]}
              >
                <Input
                  placeholder="Extra Öneri"
                  type="text"
                  value={extraSuggestion}
                  onChange={(e) => setExtraSuggestion(e.target.value)}
                />
              </Form.Item>
            </Item>
            <Item span={11} offset={1}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
              Minimum Normal Satış Fiyatı
              </Text>
              <Form.Item
                name="minOrderPrice"
                rules={[
                  { required: true, message: "Minimum Tutar boş geçilemez." },
                ]}
              >
                <Input
                  placeholder="Minimum Normal Satış Fiyatı"
                  type="number"
                  value={minOrderPrice}
                  onChange={(e) => setMinOrderPrice(e.target.value)}
                />
              </Form.Item>
            </Item>
          </Content>
          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                İndirimli satış Fiyatı
              </Text>
              <Form.Item
                name="minDiscount"
                rules={[
                  {
                    required: true,
                    message: "Minimum İndirimli Tutar boş geçilemez.",
                  },
                ]}
              >
                <Input
                  placeholder="İndirimli satış Fiyatı"
                  type="number"
                  value={minDiscount}
                  onChange={(e) => setMinDiscount(e.target.value)}
                />
              </Form.Item>
            </Item>
            <Item span={11} offset={1}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Varsayılan Kutu Sayısı
              </Text>
              <Form.Item
                name="defaultBoxCount"
                rules={[
                  {
                    required: true,
                    message: "Varsayılan Kutu boş geçilemez.",
                  },
                ]}
              >
                <Input
                  placeholder="Varsayılan Kutu Sayısı"
                  type="number"
                  value={defaultBoxCount}
                  onChange={(e) => setDefaultBoxCount(e.target.value)}
                />
              </Form.Item>
            </Item>
          </Content>
          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Açık Büfe Var Mı?
              </Text>
              <Form.Item>
                <SelectList
                  style={{ width: "100%" }}
                  defaultValue="Açık Büfe Var Mı?"
                  value={openBuffet}
                  onChange={(e) => setOpenBuffet(e)}
                >
                  <Option value="true">Evet</Option>
                  <Option value="false">Hayır</Option>
                </SelectList>
              </Form.Item>
            </Item>
          </Content>
          <Row justify="center" style={{ marginTop: "32px" }}>
            <Col span={6}>
              <SendButton type="secondary" htmlType="submit">
                <Text color="white" weight="semi-bold">
                  EKLE
                </Text>
              </SendButton>
            </Col>
          </Row>
        </AuthUsersContents>
      </Form>
    </Col>
  );
};

export default PackageInfoCard;
