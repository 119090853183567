import React, { useState, useEffect } from "react";
import CategoriesStyle, {
  CardTitle,
  Content,
  Divider,
  Item,
  Input,
  SendButton,
  ImgContent,
  ImgContainer,
  ExistCategory,
} from "./styles";
import { Text } from "../../../components/Typography/styles";
// import { Row, Col, message, Form } from "antd";
import { Row, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getFoodCategories } from "../../../../services/Redux/FoodCategories/api";
import FoodLabel from "./FoodLabel";
import { createCategoryInfo } from "../../../../services/Redux/AddRestaurant/api";

const FoodCategories = () => {
  const dispatch = useDispatch();
  const [categoryToRender, setCategoryToRender] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [storeOwnerEmail, setStoreOwnerEmail] = useState();

  useEffect(() => {
    dispatch(getFoodCategories());
  }, [dispatch]);

  const { foodCategoryList } = useSelector(
    (state) => state.foodCategoriesStore
  );

  useEffect(() => {
    setCategoryToRender(foodCategoryList);
  }, [foodCategoryList]);

  const handleCategoryClick = ({ id, name }) => {
    if (selectedTags.find(({ id: tagId }) => id === tagId)) {
      return setSelectedTags(
        selectedTags.filter(({ id: tagId }) => id !== tagId)
      );
    }
    setSelectedTags((prevState) => [...prevState, { id, name }]);
  };

  const handleClick = async () => {
    if (selectedTags.length) {
      const finalTags = selectedTags.map((tag) => ({
        name: tag.id,
        store: storeOwnerEmail,
      }));
      await dispatch(createCategoryInfo(finalTags));
    }
  };

  return (
    <Col md={24} lg={24} xl={21} xxl={21}>
      <CategoriesStyle
        title={
          <CardTitle size="title" weight="light">
            Yemek Kategorileri
          </CardTitle>
        }
      >
        <Content>
          <Item>
            <Text weight="semi-bold">Kategori Oluştur:</Text>
          </Item>
        </Content>

        <Row>
          <Content>
            <Item span={11}>
              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Kullanıcı Bölümünde Girilen Email
              </Text>
              <Form.Item
                name="storeOwnerEmail"
                rules={[
                  {
                    type: "email",
                    message: "Geçerli bir email giriniz!",
                  },
                  {
                    required: true,
                    message: "Emailinizi giriniz!",
                  },
                ]}
              >
                <Input
                  style={{ marginTop: 0 }}
                  placeholder="Kullanıcı Bölümünde Girilen Email"
                  type="email"
                  value={storeOwnerEmail}
                  onChange={(e) => setStoreOwnerEmail(e.target.value)}
                />
              </Form.Item>
            </Item>
          </Content>
        </Row>


        <ExistCategory>
          <Content>
            <Item>
              <Text weight="semi-bold">Kategoriler:</Text>
            </Item>
          </Content>
          <Content>
            <Col span={24}>
              <Row gutter={[32, 0]}>
                {categoryToRender &&
                  categoryToRender.map(({ id, name, color, photo }) => (
                    <ImgContainer xxl={3} xl={3} lg={3} md={6}>
                      <ImgContent
                        style={{ backgroundColor: color }}
                        onClick={() => handleCategoryClick({ id, name })}
                      >
                        <img src={photo} alt="" />
                      </ImgContent>
                      <Text style={{ marginTop: "8px" }}>{name}</Text>
                    </ImgContainer>
                  ))}
              </Row>
            </Col>
          </Content>
        </ExistCategory>
        <Divider />
        <Content>
          <Col span={24}>
            <Row style={{ marginTop: "16px" }}>
              {selectedTags &&
                selectedTags?.map(({ id, name }) => (
                  <FoodLabel key={id} tagsId={id} name={name} />
                ))}
            </Row>
          </Col>
        </Content>
        <Row justify="center" style={{ marginTop: "32px" }}>
          <Col span={6}>
            <SendButton
              type="secondary"
              htmlType="submit"
              onClick={handleClick}
            >
              <Text color="white" weight="semi-bold">
                EKLE
              </Text>
            </SendButton>
          </Col>
        </Row>
      </CategoriesStyle>
    </Col>
  );
};

export default FoodCategories;
