/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { HelpRequestContent, CardTitle } from "./styles";
import { Text } from "../../components/Typography/styles";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Select, Header, Input, Location, TableStyle, Content } from "./styles";
import search from "../../../Assets/components/search.png";
import TableItem from "./TableItem/index";
import { getHelpRequests } from "../../../services/Redux/HelpRequests/api";
import useResponsive from "../../../helpers/Hooks/useMediaQuery";
import { FilterButton } from "../PendingRestaurants/styles";

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: "48px",
      width: "48px",
      backgroundColor: "#ffbc41",
      justifyContent: "center",
      borderRadius: "50px",
      position: "absolute",
      right: "-18px",
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const HelpRequests = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [requestsToRender, setRequestsToRender] = useState([]);
  const [selectRender, setSelectRender] = useState([]);
  const [search, setSearch] = useState("");
  const [city, setCity] = useState("Tüm");
  const [sortByName, setSortByName] = useState(false);

  const { xxl } = useResponsive();

  useEffect(() => {
    dispatch(getHelpRequests());
  }, [dispatch]);

  const { helpRequests } = useSelector((state) => state?.helpRequestsStore);

  useEffect(() => {
    if (helpRequests) {
      setRequestsToRender(helpRequests);
    }
  }, [helpRequests]);

  useEffect(() => {
    if (city === "Tüm") {
      return setSelectRender(requestsToRender);
    } else {
      setSelectRender(
        requestsToRender.filter((item) => item?.store?.province === city)
      );
    }
  }, [city]);

  useEffect(() => {
    if (search !== "") {
      const searchedName = helpRequests
        ? helpRequests.filter(({ store }) =>
            store?.name?.toLowerCase().includes(search.toLocaleLowerCase())
          )
        : setSelectRender(helpRequests);
      setSelectRender(searchedName);
    } else if (helpRequests) {
      setSelectRender(helpRequests);
    }
  }, [search]);

  return (
    <Col md={24} lg={24} xl={xxl ? 22 : 24} xxl={22}>
      <HelpRequestContent
        title={
          <CardTitle size="title" weight="light">
            Yardım Talepleri
          </CardTitle>
        }
      >
        <Text size="regular" weight="light">
          Kayıtlı {requestsToRender && requestsToRender?.length} Restorant Destek
          talebi aşağıda sıralanmıştır.Tamamlanan talepler 24 saat sonra sisteme
          arşivlenecektir.
        </Text>
        <Header>
          <Col span={10} style={{ display: "flex" }}>
            <Select
              placeholder="Bölgeye Göre"
              onChange={(e) => setCity(e)}
              value={city}
            >
              <Option value="Tüm">Tüm</Option>
              {requestsToRender &&
                requestsToRender.map((item) => (
                  <Option key={item.id} value={item?.store?.province}>
                    {item?.store?.province}
                  </Option>
                ))}
            </Select>
            <FilterButton onClick={() => setSortByName(!sortByName)}>
              İsme Göre
            </FilterButton>
          </Col>
          <Col span={13}>
            <Input
              placeholder="Ara"
              suffix={<SearchIcon />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Header>
        <Location>
          <Text>Talepler:</Text>
        </Location>
        <TableStyle>
          <Content>
            <Col span={2}>
              <Text weight="semi-bold" size="regular">
                ID:
              </Text>
            </Col>
            <Col span={4}>
              <Text weight="semi-bold" size="regular">
                Restorant
              </Text>
            </Col>
            <Col span={6}>
              <Text weight="semi-bold" size="regular">
                Mesaj
              </Text>
            </Col>
            <Col span={4}>
              <Text weight="semi-bold" size="regular">
                Restorant İletişim
              </Text>
            </Col>
            <Col span={3}>
              <Text weight="semi-bold" size="regular">
                Durumu
              </Text>
            </Col>
            <Col span={5}>
              <Text weight="semi-bold" size="regular">
                Tarih/Saat
              </Text>
            </Col>
          </Content>
          {selectRender?.length !== 0 ? (
            selectRender &&
            selectRender.map(
              ({ id, status, message, store, created_at }) => (
                <TableItem
                  key={id}
                  id={id}
                  status={status}
                  message={message}
                  store={store}
                  date={created_at}
                />
              )
            )
          ) : (
            <Text
              weight="semi-bold"
              color="red"
              style={{ marginTop: "32px", alignSelf: "center" }}
            >
              Sonuç bulunamadı
            </Text>
          )}
        </TableStyle>
      </HelpRequestContent>
    </Col>
  );
};

export default HelpRequests;
