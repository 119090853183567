import StoreStyles, { Content, Item } from "./styles";
import StoreInfoCard from "./StoreInfoCard";
import UserInfoCard from "./UserInfoCard";
import PaketCard from "./PaketCard";
import PackageInfoCard from "./PackageInfoCard";
import FoodCategories from "./FoodCategories";

const AddRestaurants = () => {
  return (
    <StoreStyles>
      <Content>
        <Item md={24}>
          <UserInfoCard />
        </Item>
        <Item md={24}>
          <StoreInfoCard />
        </Item>
        <Item md={24}>
          <PaketCard />
        </Item>
        <Item md={24}>
          <PackageInfoCard />
        </Item>
        <Item md={24}>
          <FoodCategories />
        </Item>
      </Content>
    </StoreStyles>
  );
};

export default AddRestaurants;
