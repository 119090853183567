import styled from 'styled-components';
import { Col, Row } from 'antd';

export const CategoryItemStyles = styled(Row)`
	transition: 0.2s;
`;
export const Header = styled(Row)`
	.title-styles {
		background-color: #fafafe;
		border-radius: 5px;
		padding: 5px 30px;
	}
`;
export const Content = styled(Col)`
	padding-top: 20px;
	padding-left: 20px;
	padding-bottom: 20px;
`;

export const FoodnameLabel = styled.div`
	border: 2px solid ${({ theme }) => theme.borderColor};
	height: 36px;
	border-radius: 3px;
	cursor: pointer;
`;
