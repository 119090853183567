import React from 'react';
import { Row } from 'antd';
import { ProfitCardStyles, Content, DescriptionContainer } from './styles';
import { Text } from '../../../../components/Typography/styles';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const ProfitCard = ({ TotalProfit }) => (
	<ProfitCardStyles title={<Title text='Elde Edilen Kar' />}>
		<Content>
			<Row justify='center' align='middle'>
				<Text color='green' size='32px' weight='bold'>
					{TotalProfit} TL
				</Text>
			</Row>
			<DescriptionContainer justify='center' align='middle'>
				<Text style={{ textAlign: 'center' }} weight='bold'>
					Kurtarılan yemeklerin TL karşılığı
				</Text>
			</DescriptionContainer>
		</Content>
	</ProfitCardStyles>
);

export default ProfitCard;
