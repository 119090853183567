/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Text } from '../../../components/Typography/styles';
import { useState, useEffect } from 'react';
import search from '../../../../Assets/components/search.png';
import {
  Select,
  Header,
  Input,
  Location,
  TableStyle,
  Content,
  Footer,
  NavButton,
} from './styles';
import { Col, Row, Spin } from 'antd';
import leftArrow from '../../../../Assets/components/left-arrow.svg';
import rightArrow from '../../../../Assets/components/right-arrow.svg';
import TableItem from './TableItem/index';

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: '48px',
      width: '48px',
      backgroundColor: '#ffbc41',
      justifyContent: 'center',
      borderRadius: '50px',
      position: 'absolute',
      right: '-18px',
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const canceled = ['4', '5'];

const CancelledOrders = ({ orders }) => {
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [provinceList, setProvinceList] = useState();
  const [select, setSelect] = useState('Tüm');
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(7);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCancelledOrders(orders);
    if (canceled.length > 7) {
      setPageCount(Math.ceil(orders.length / 7));
    } else setPageCount(1);
    const filterArr = [];
    orders.forEach((order) => {
      order?.store &&
        !filterArr.includes(order?.store?.province) &&
        filterArr.push(order?.store?.province);
    });
    setProvinceList(filterArr);
  }, [orders]);

  useEffect(() => {
    const cancelledSearch = orders.filter(({ status }) =>
      canceled.includes(status)
    );
    setPageCount(Math.ceil(cancelledSearch.length / 7));

    if (search !== '') {
      if (select !== 'Tüm') setSelect('Tüm');
      const searchedName = cancelledSearch
        ? cancelledSearch.filter(
            ({ id, store }) =>
              store?.name?.toLowerCase().includes(search.toLocaleLowerCase()) ||
              id?.toString().includes(search?.toString().toLocaleLowerCase())
          )
        : setCancelledOrders(cancelledSearch.slice(0, 7));
      setCancelledOrders(searchedName);
      setSearchedOrders(searchedName);
      setPage(1);
      if (searchedName.length > 7) {
        setPageCount(Math.ceil(searchedName.length / 7));
      } else setPageCount(1);
    } else if (orders) {
      setCancelledOrders(cancelledSearch.slice(0, 7));
      setSearchedOrders([]);
      setPageCount(Math.ceil(cancelledSearch.length / 7));
    }
  }, [search]);

  useEffect(() => {
    const cancelledSearch = orders.filter(({ status }) =>
      canceled.includes(status)
    );

    if (select === 'Tüm') {
      setCancelledOrders(cancelledSearch.slice(0, 7));
      setPage(1);
      if (cancelledSearch.length > 7) {
        setPageCount(Math.ceil(cancelledSearch.length / 7));
      } else setPageCount(1);
    } else {
      setCancelledOrders(
        cancelledSearch
          .slice(0, 7)
          .filter((order) => order?.store?.province === select)
      );
      setPage(1);
      if (cancelledSearch.length > 7) {
        setPageCount(
          Math.ceil(
            cancelledSearch.filter((order) => order?.store?.province === select)
              .length / 7
          )
        );
      } else setPageCount(1);
    }
  }, [select]);

  function prevPage() {
    const slice =
      searchedOrders.length !== 0
        ? Array.isArray(provinceList)
          ? searchedOrders

              .filter((order) => {
                if (select === 'Tüm') return order;
                else return order?.store?.province === select;
              })
              .slice(count * (page - 2), count * (page - 1))
          : searchedOrders.slice(count * (page - 2), count * (page - 1))
        : Array.isArray(provinceList)
        ? orders

            .filter((order) => {
              if (select === 'Tüm') return order;
              else return order?.store?.province === select;
            })
            .slice(count * (page - 2), count * (page - 1))
        : orders.slice(count * (page - 2), count * (page - 1));

    if (slice.length !== 0) {
      setCancelledOrders(slice);
      setPage(page - 1);
    }
  }

  function nextPage() {
    const slice =
      searchedOrders.length !== 0
        ? Array.isArray(provinceList)
          ? searchedOrders

              .filter((order) => {
                if (select === 'Tüm') return order;
                else return order?.store?.province === select;
              })
              .slice(count * page, count * (page + 1))
          : searchedOrders.slice(count * page, count * (page + 1))
        : Array.isArray(provinceList)
        ? orders

            .filter((order) => {
              if (select === 'Tüm') return order;
              else return order?.store?.province === select;
            })
            .slice(count * page, count * (page + 1))
        : orders.slice(count * page, count * (page + 1));

    if (slice.length !== 0) {
      setCancelledOrders(slice);
      setPage(page + 1);
    }
  }

  return (
    <Col>
      <Header>
        <Col span={10} style={{ display: 'flex' }}>
          <Select
            placeholder="Bölgeye Göre Sırala"
            onChange={(value) => setSelect(value)}
            value={Array.isArray(provinceList) && select}
          >
            <Option value="Tüm">Tüm</Option>
            {orders &&
              provinceList?.map((province) => {
                return <Option value={province}>{province}</Option>;
              })}
          </Select>
          <Select
            placeholder="Tarihe Göre"
            style={{ marginLeft: '20px' }}
            suffixIcon={null}
            disabled
          />
        </Col>
        <Col span={13}>
          <Input
            placeholder="Ara"
            suffix={<SearchIcon />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Header>
      <Location />
      <TableStyle>
        <Content>
          <Col span={2}>
            <Text weight="semi-bold" size="regular">
              ID:
            </Text>
          </Col>
          <Col span={4}>
            <Text weight="semi-bold" size="regular">
              Restorant
            </Text>
          </Col>
          <Col span={4}>
            <Text weight="semi-bold" size="regular">
              Paket/Referans Kodu
            </Text>
          </Col>
          <Col span={2}>
            <Text weight="semi-bold" size="regular">
              Durumu
            </Text>
          </Col>
          <Col span={4}>
            <Text weight="semi-bold" size="regular">
              Tarih/Saat
            </Text>
          </Col>
          <Col span={3}>
            <Text weight="semi-bold" size="regular">
              Sipariş Detayı
            </Text>
          </Col>
        </Content>
        {cancelledOrders.length !== 0 ? (
          cancelledOrders ? (
            cancelledOrders.map(
              ({
                store,
                cost,
                ref_code,
                id,
                buying_time,
                address,
                user,
                boxes,
                box_count,
                delivery_type,
              }) => (
                <TableItem
                  refCode={ref_code}
                  id={id}
                  buyTime={buying_time}
                  boxes={boxes}
                  box_count={box_count}
                  address={address}
                  user={user}
                  cost={cost}
                  delivery={delivery_type}
                  store={store}
                />
              )
            )
          ) : (
            <Spin />
          )
        ) : (
          <Text
            weight="semi-bold"
            color="red"
            style={{ marginTop: '32px', alignSelf: 'center' }}
          >
            Sonuç bulunamadı
          </Text>
        )}
        <Footer align="middle" justify="center">
          {page !== 1 && (
            <NavButton
              src={leftArrow}
              alt="left-arrow"
              onClick={() => {
                if (page !== 1) {
                  prevPage();
                }
              }}
            />
          )}
          <Text>
            {page} / {pageCount}{' '}
          </Text>
          {page !== pageCount && (
            <NavButton
              src={rightArrow}
              alt="right-arrow"
              onClick={() => nextPage()}
            />
          )}
        </Footer>
      </TableStyle>
    </Col>
  );
};

export default CancelledOrders;
