import { useState } from 'react';
import { TableStyle, Item, InfoWrapper, PanelButton } from './styles';
import { Text } from '../../../components/Typography/styles';
import { Col } from 'antd';
import rightOutline from '../../../../Assets/components/right-outline.svg';
import StoreModal from './StoreModal';

const StoreItem = ({ store }) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<>
			<TableStyle>
				<Item span={2} style={{ alignItems: 'center', border: '0px' }}>
					<Text size='smallRegular' weight='light'>
						{store ? store.id : 'null'}
					</Text>
				</Item>
				<Item span={4}>
					<Col span={7} style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}>
						{store ? <img src={store.photo} alt='no-data' width='48px' height='48px' /> : <Text value=' no data' />}
					</Col>
					<Col span={17} style={{ display: 'flex', flexDirection: 'column' }}>
						<Text weight='semi-bold'> {store ? store.name : ''}</Text>
						<Text size='smallRegular' weight='light'>
							{store ? `${store.province} - ${store.city}` : ''}
						</Text>
					</Col>
				</Item>
				<Item span={16} style={{ alignItems: 'center', justifyContent: 'start' }}>
					<Text size='smallRegular' weight='light' style={{ marginLeft: '22px' }}>
						Mağaza Bilgisi hakkında onay bekliyor.
					</Text>
				</Item>
				<Item span={2} style={{ alignItems: 'center' }}>
					<PanelButton onClick={() => setIsShown(!isShown)}>
						<img src={rightOutline} alt='rightOutLine' />
					</PanelButton>
				</Item>
			</TableStyle>
			<InfoWrapper style={{ display: isShown ? 'block' : 'none' }}>
				<StoreModal store={store} />
			</InfoWrapper>
		</>
	);
};

export default StoreItem;
