import { useState } from 'react';
import { Row, Col } from 'antd';
import { RegisteredContent, RegisteredContentCol, CardTitle, TitleDivider, ArrowImg } from './styles';
import leftArrow from '../../../Assets/components/arrow-left.png';
import ActiveRestaurants from './ActiveRestaurants';
import SuspendRestaurants from './SuspendRestaurants';
import TerminateRestaurants from './TerminateRestaurants';
import ActiveRestaurantsDetail from './ActiveRestaurants/RestaurantsDetail';
import SuspendRestaurantsDetail from './SuspendRestaurants/RestaurantsDetail';
import TerminateRestaurantDetail from './TerminateRestaurants/RestaurantsDetail';
import useResponsive from '../../../helpers/Hooks/useMediaQuery';

const RestaurantsTitle = ({ page, setPage, xxl }) => (
	<Row align='middle'>
		<Col span={8}>
			<CardTitle
				size='title'
				weight={page === 0 ? 'bold' : 'light'}
				color={page === 0 ? 'red' : 'black'}
				onClick={() => setPage(0)}
			>
				{xxl ? 'Kayıtlı Restorantlar' : 'Kayıtlı'}
			</CardTitle>
			{page === 0 && <TitleDivider />}
		</Col>
		<Col span={8}>
			<CardTitle
				size='title'
				weight={page === 1 ? 'bold' : 'light'}
				color={page === 1 ? 'red' : 'black'}
				onClick={() => setPage(1)}
			>
				{xxl ? 'Askıya Alınan Restorantlar' : 'Askıya Alınan'}
			</CardTitle>
			{page === 1 && <TitleDivider />}
		</Col>
		<Col span={8}>
			<CardTitle
				size='title'
				weight={page === 2 ? 'bold' : 'light'}
				color={page === 2 ? 'red' : 'black'}
				onClick={() => setPage(2)}
			>
				{xxl ? 'Üyeliği Sonlandırılan Restorantlar' : 'Üyeliği Sonlandırılan'}
			</CardTitle>
			{page === 2 && <TitleDivider />}
		</Col>
	</Row>
);

const RestaurantsDetail = ({ page, setPage }) => {
	if (page === 3) {
		return (
			<Row align='middle'>
				<Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>
					<Col span={1}>
						<ArrowImg src={leftArrow} alt='' onClick={() => setPage(0)} />
					</Col>
					<Col span={21}>
						<CardTitle size='title' weight='light'>
							Restorant Detay
						</CardTitle>
					</Col>
				</Col>
			</Row>
		);
	} else if (page === 4) {
		return (
			<Row align='middle'>
				<Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>
					<Col span={1}>
						<ArrowImg src={leftArrow} alt='' onClick={() => setPage(1)} />
					</Col>
					<Col span={21}>
						<CardTitle size='title' weight='light'>
							Restorant Detay
						</CardTitle>
					</Col>
				</Col>
			</Row>
		);
	} else {
		return (
			<Row align='middle'>
				<Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>
					<Col span={1}>
						<ArrowImg src={leftArrow} alt='' onClick={() => setPage(2)} />
					</Col>
					<Col span={21}>
						<CardTitle size='title' weight='light'>
							Restorant Detay
						</CardTitle>
					</Col>
				</Col>
			</Row>
		);
	}
};

const RegisteredRestaurants = () => {
	const [page, setPage] = useState(0);
	const [storeId, setStoreId] = useState(0);
	const [suspendReason, setSuspendReason] = useState('');
	const [terminateReason, setTerminateReason] = useState('');
	const restaurants = [0, 1, 2];
	const { xxl } = useResponsive();

	return (
		<RegisteredContentCol md={24} lg={24} xl={xxl ? 22 : 24} xxl={22}>
			<RegisteredContent
				title={
					restaurants.includes(page) ? (
						<RestaurantsTitle xxl={xxl} page={page} setPage={setPage} />
					) : (
						<RestaurantsDetail page={page} setPage={setPage} />
					)
				}
			>
				{page === 0 && <ActiveRestaurants setRestPage={setPage} setStoreId={setStoreId} />}
				{page === 1 && (
					<SuspendRestaurants setSuspendReason={setSuspendReason} setRestPage={setPage} setStoreId={setStoreId} />
				)}
				{page === 2 && (
					<TerminateRestaurants setTerminateReason={setTerminateReason} setRestPage={setPage} setStoreId={setStoreId} />
				)}
				{page === 3 && <ActiveRestaurantsDetail storeId={storeId} setStoreId={setStoreId} />}
				{page === 4 && (
					<SuspendRestaurantsDetail suspendReason={suspendReason} storeId={storeId} setStoreId={setStoreId} />
				)}
				{page === 5 && (
					<TerminateRestaurantDetail terminateReason={terminateReason} storeId={storeId} setStoreId={setStoreId} />
				)}
			</RegisteredContent>
		</RegisteredContentCol>
	);
};

export default RegisteredRestaurants;
