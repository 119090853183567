import { useState } from 'react';
import {
  TableStyle,
  Item,
  SendButton,
  CancelButton,
  InfoWrapper,
  InfoLeftSide,
  InfoRightSide,
  PanelButton,
  PhotoImg,
} from './styles';
import { Text } from '../../../components/Typography/styles';
import { Col, Row } from 'antd';
import rightOutline from '../../../../Assets/components/right-outline.svg';
import moment from 'moment';
import AlertModal from '../AlertModal';
import {
  setSolvedRequest,
  getHelpRequests,
  setRejectedRequest,
} from '../../../../services/Redux/HelpRequests/api';
import { useDispatch } from 'react-redux';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';

const StoreStatus = [
  { id: 0, storeStatus: 'Beklemede', color: 'yellow' },
  { id: 1, storeStatus: 'Tamamlandı', color: 'green' },
  { id: 2, storeStatus: 'Reddedildi', color: 'red' },
];

const TableItem = ({ id, status, message, store, date }) => {
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const { xxl } = useResponsive();

  const handleCancel = () => {
    setIsAlertVisible(false);
  };

  const setSolved = async () => {
    await dispatch(setSolvedRequest(id));
    await dispatch(getHelpRequests());
  };

  const setRejected = async () => {
    await dispatch(setRejectedRequest(id));
    await dispatch(getHelpRequests());
  };
  return (
    <>
      <TableStyle>
        <Item
          span={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: '0px',
          }}
        >
          <Text weight="light">{id}</Text>
        </Item>
        <Item
          md={5}
          lg={5}
          xl={5}
          xxl={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Col span={7}>
            <PhotoImg src={store.photo} />
          </Col>
          <Col span={17} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size={xxl ? 'smallRegular' : '12px'} weight="semi-bold">
              {store.name}
            </Text>
            <Text size={xxl ? 'smallRegular' : '12px'} weight="light">
              İstanbul-Beşiktaş
            </Text>
          </Col>
        </Item>
        <Item
          md={5}
          lg={5}
          xl={5}
          xxl={6}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {message.slice(0, 38) + '....'}
          </Text>
        </Item>
        <Item
          span={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {store.email}
          </Text>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {store.phone_number}
          </Text>
        </Item>
        <Item
          md={2}
          lg={2}
          xl={3}
          xxl={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {StoreStatus.map(
            ({ id, storeStatus, color }) =>
              id === status && (
                <Text weight="semi-bold" color={color}>
                  {xxl ? storeStatus : storeStatus.slice(0, 3)}
                </Text>
              )
          )}
        </Item>
        <Item
          span={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(date).format('DD MMMM  YYYY')}
          </Text>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(date).format('dddd  HH:mm')}
          </Text>
        </Item>
        <Item
          md={2}
          lg={2}
          xl={1}
          xxl={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PanelButton onClick={() => setIsShown(!isShown)}>
            <img src={rightOutline} alt="" />
          </PanelButton>
        </Item>
      </TableStyle>
      <InfoWrapper style={{ display: isShown ? 'block' : 'none' }}>
        <Row>
          <InfoLeftSide span={12}>
            <Text>Mağaza Yardım Talebi</Text>
            <Text style={{ marginTop: '32px' }} weight="light">
              {message}
            </Text>
            <Text style={{ marginTop: '32px' }} weight="light">
              {store.name} - {store.address} / {store.city}
            </Text>
            <Text weight="light">Jhon Doe - Restorant Müdürü</Text>
            <Text weight="light">{store.email}</Text>
            <Text weight="light">{store.phone_number}</Text>
            <Text weight="light">{store.phone_number_2}</Text>
          </InfoLeftSide>
          <InfoRightSide span={8} offset={4}>
            <Row style={{ marginLeft: '20px', marginTop: '72px' }}>
              <Text>Durumu:</Text>
              {StoreStatus.map(
                ({ id, storeStatus, color }) =>
                  id === status && (
                    <Text style={{ marginLeft: '10px' }} weight="light">
                      {storeStatus}
                    </Text>
                  )
              )}
            </Row>
            <Row style={{ marginLeft: '20px', marginTop: '32px' }}>
              <Text>Tarih/Saat:</Text>
              <Text style={{ marginLeft: '10px' }} weight="light">
                {moment(date).format('DD MMMM  YYYY')}{' '}
                {moment(date).format('dddd -HH:mm')}
              </Text>
            </Row>
            <Row style={{ marginTop: '64px' }}>
              <Col span={11}>
                <CancelButton
                  disabled={status !== 1 ? true : false}
                  onClick={() => setIsAlertVisible(true)}
                >
                  <Text color="red" weight="semi-bold" size="nobleRegular">
                    İptal Et
                  </Text>
                </CancelButton>
              </Col>
              <Col span={11} offset={2}>
                <SendButton
                  type="secondary"
                  disabled={status !== 2 ? true : false}
                  onClick={() => setSolved()}
                >
                  <Text color="white" weight="semi-bold" size="nobleRegular">
                    Tamamla
                  </Text>
                </SendButton>
              </Col>
            </Row>
          </InfoRightSide>
        </Row>
        <AlertModal
          setRejected={setRejected}
          visible={isAlertVisible}
          onCancel={handleCancel}
        />
      </InfoWrapper>
    </>
  );
};

export default TableItem;
