import React from 'react';
import { Popover } from 'antd';
import { Link } from 'react-router-dom';
import { ProfileSectionStyles, LeftSide, RightSide, UserIconHolder, MenuStyles } from './styles';
import { Text } from '../../../Typography/styles';
import { useHistory } from 'react-router';
import profileLogo from '../../../../../Assets/Header/profile-logo.png';

const Menu = () => {
	const history = useHistory();
	const reloadPage = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		history.push('/');
		window.location.reload();
	};
	
	return (
		<MenuStyles>
			<Link onClick={reloadPage}>
				<Text color='smoke'>Logout </Text>
			</Link>
		</MenuStyles>
	);
};

const Profile = ({ isLogin }) => {
	const userData = JSON.parse(localStorage.getItem('user'));

	return (
		<ProfileSectionStyles align='middle'>
			<LeftSide span={19}>
				<Text color='black' size='noble-regular' weight='bold'>
					{userData ? `${userData?.first_name} ${userData?.last_name}` : "" }
				</Text>
			</LeftSide>
			<RightSide span={5}>
				{isLogin ? (
					<Popover placement='bottom' content={Menu} trigger='hover'>
						<UserIconHolder>
							<img className='cover-image' src={profileLogo} alt='' />
						</UserIconHolder>
					</Popover>
				) : (
					<UserIconHolder>
						<img className='cover-image' src={profileLogo} alt='' />
					</UserIconHolder>
				)}
			</RightSide>
		</ProfileSectionStyles>
	);
};

export default Profile;
