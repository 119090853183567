import React from 'react';
import { Row, Col } from 'antd';
import { ScoringsCardStyles, CustomProgress, Content, Img } from './styles';
import { Text } from '../../../../components/Typography/styles';
import starIcon from '../../../../../Assets/components/stars.svg';
import inactiveStarIcon from '../../../../../Assets/components/stars-inactive.svg';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const ScoringsCard = ({ fiveStarRating, fourStarRating, threeStarRating, twoStarRating, oneStarRating }) => {
	return (
		<ScoringsCardStyles title={<Title text='Puanlama Dağılımları' />}>
			<Content>
				<Row align='middle' justify='space-between'>
					<Col span={10}>
						<Row justify='space-between'>
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
						</Row>
					</Col>
					<Col span={12}>
						<CustomProgress strokeWidth='10px' percent={fiveStarRating.toString().slice(0, 2)} strokeColor='#8FD89F' />
					</Col>
				</Row>
				<Row align='middle' justify='space-between'>
					<Col span={10}>
						<Row justify='space-between'>
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
						</Row>
					</Col>
					<Col span={12}>
						<CustomProgress strokeWidth='10px' percent={fourStarRating.toString().slice(0, 2)} strokeColor='#8FD89F' />
					</Col>
				</Row>
				<Row align='middle' justify='space-between'>
					<Col span={10}>
						<Row justify='space-between'>
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
						</Row>
					</Col>
					<Col span={12}>
						<CustomProgress strokeWidth='10px' percent={threeStarRating.toString().slice(0, 2)} strokeColor='#8FD89F' />
					</Col>
				</Row>
				<Row align='middle' justify='space-between'>
					<Col span={10}>
						<Row justify='space-between'>
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
						</Row>
					</Col>
					<Col span={12}>
						<CustomProgress strokeWidth='10px' percent={twoStarRating.toString().slice(0, 2)} strokeColor='#8FD89F' />
					</Col>
				</Row>
				<Row align='middle' justify='space-between'>
					<Col span={10}>
						<Row justify='space-between'>
							<Img src={starIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
							<Img src={inactiveStarIcon} alt='' srcSet='' />
						</Row>
					</Col>
					<Col span={12}>
						<CustomProgress strokeWidth='10px' percent={oneStarRating.toString().slice(0, 2)} strokeColor='#8FD89F' />
					</Col>
				</Row>
			</Content>
		</ScoringsCardStyles>
	);
};

export default ScoringsCard;
