import * as actions from './actions';
import { message } from 'antd';

export const getActiveRestaurants = () => async (dispatch, getState, service) => {
	dispatch(actions.getActiveRestaurantsStartAction());
	await service({
		path: 'superadmin/operations/list_active/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getActiveRestaurantsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getActiveRestaurantsFailAction(error));
		});
};

// UPDATE REST TO ACTIVE

export const updateRestToActive = (id) => async (dispatch, getState, service) => {
	dispatch(actions.updateRestorantsToActiveStartAction());

	await service({
		path: `superadmin/operations/${id}/active/`,
		method: 'PUT',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.updateRestaurantsToActiveSuccessAction(data));
			message.success('Restorant Başarı ile Aktif Hale Getirildi. !');
		})
		.catch((error) => {
			dispatch(actions.updateRestaurantsToActiveFailAction(error));
			message.error('Aktif Duruma Alma Sırasında Bir Hata Oluştu. !');
		});
};

//SUSPEND

export const getSuspendRestaurants = () => async (dispatch, getState, service) => {
	dispatch(actions.getSuspendRestaurantsStartAction());
	await service({
		path: 'superadmin/operations/list_suspend/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getSuspendRestaurantsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getSuspendRestaurantsFailAction(error));
		});
};

export const createSuspendRestaurants = (id, desc, time) => async (dispatch, getState, service) => {
	dispatch(actions.createSuspendRestaurantsStartAction());
	await service({
		path: 'superadmin/operations/suspend/',
		method: 'POST',
		sendToken: true,
		body: {
			description: desc,
			store: id,
			time: time,
		},
	})
		.then((data) => {
			dispatch(actions.createSuspendRestaurantsSuccessAction(data));
			message.success('Restorant Başarı ile Askıya Alındı. !');
		})
		.catch((error) => {
			dispatch(actions.createSuspendRestaurantsFailAction(error));
			message.error('Askıya Alma Sırasında Bir Hata Oluştu. !');
		});
};

// TERMINATE

export const getTerminateRestaurants = () => async (dispatch, getState, service) => {
	dispatch(actions.getTerminateRestaurantsStartAction());
	await service({
		path: 'superadmin/operations/list_terminate/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getTerminateRestaurantsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getTerminateRestaurantsFailAction(error));
		});
};

export const createTerminateRestaurants = (id, desc) => async (dispatch, getState, service) => {
	dispatch(actions.createTerminateRestaurantsStartAction());
	await service({
		path: 'superadmin/operations/terminate/',
		method: 'POST',
		sendToken: true,
		body: {
			description: desc,
			store: id,
		},
	})
		.then((data) => {
			dispatch(actions.createTerminateRestaurantsSuccessAction(data));
			message.success('Restorant Üyeliği Başarı ile Sonlandırıldı. !');
		})
		.catch((error) => {
			dispatch(actions.createTerminateRestaurantsFailAction(error));
			message.error('Üyeliği sonlandırma kısmında bir hata oluştu !');
		});
};

// GET RESTAURANTS DETAIL

export const getRestaurantDetail = (id) => async (dispatch, getState, service) => {
	dispatch(actions.getRestaurantsDetailStartAction());

	await service({
		path: `superadmin/restaurant-detail/${id}/`,
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getRestaurantsDetailSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getRestaurantsDetailFailAction(error));
		});
};

// DELETE RESTAURANTS PACKET

export const deleteRestaurantsPacket = (storeId, packetId) => async (dispatch, getState, service) => {
	dispatch(actions.deleteRestaurantsPacketStartAction());

	await service({
		path: `superadmin/restaurant-detail/${storeId}/delete-box/`,
		method: 'DELETE',
		sendToken: true,
		body: {
			pk: packetId,
		},
	})
		.then((data) => {
			dispatch(actions.deleteRestaurantsPacketSuccessAction(data));
			message.success('Paket Başarı ile Silindi !');
		})
		.catch((error) => {
			dispatch(actions.deleteRestaurantsPacketFailAction(error));
			message.error('Paket Silinirken Bir Hata Oluştu !');
		});
};
