/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { TableStyle, Item, InfoWrapper, PanelButton, PhotoImg } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../components/Typography/styles';
import { Col, Row } from 'antd';
import { getCategories } from '../../../../services/Redux/PendingMenu/api';
import rightOutline from '../../../../Assets/components/right-outline.svg';
import MenuModal from './MenuModal';
import MenuPreviewCard from './MenuPreviewCard';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';

const MenuItem = ({ menu }) => {
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();
  const [categoryToRender, setCategoryToRender] = useState([]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const { xxll } = useResponsive();

  const { categoryList } = useSelector((state) => state.pendingMenuStore);

  useEffect(() => {
    if (categoryList) {
      setCategoryToRender(categoryList);
    }
  }, [categoryList]);

  const { id, name } = menu.store;

  return (
    <>
      <TableStyle>
        <Item span={2} style={{ alignItems: 'center', border: '0px' }}>
          <Text size="smallRegular" weight="light">
            {menu ? menu.id : 'null'}
          </Text>
        </Item>
        <Item xxl={4} xl={5} lg={6} md={6}>
          <Col
            span={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '22px',
            }}
          >
            {menu.store ? <PhotoImg src={menu.store.photo} /> : ''}
          </Col>
          <Col span={17} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text weight="semi-bold"> {menu.store ? menu.store.name : ''}</Text>
            <Text size="smallRegular" weight="light">
              {menu?.store
                ? `${menu?.store?.province} - ${menu?.store?.city}`
                : ''}
            </Text>
          </Col>
        </Item>
        <Item
          xxl={16}
          xl={15}
          lg={14}
          md={14}
          style={{ alignItems: 'center', justifyContent: 'start' }}
        >
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '22px' }}
          >
            Restorant Menü Güncellemesi hakkında onay bekliyor.
          </Text>
        </Item>
        <Item xxl={2} xl={2} lg={2} md={2} style={{ alignItems: 'center' }}>
          <PanelButton onClick={() => setIsShown(!isShown)}>
            <img src={rightOutline} alt="" />
          </PanelButton>
        </Item>
      </TableStyle>
      <InfoWrapper
        style={{ display: isShown ? 'block' : 'none', marginBottom: '32px' }}
      >
        <Col span={24}>
          <Row gutter={[32, 0]}>
            <Col xxl={16} xl={xxll ? 16 : 24} lg={24} md={24} sm={24}>
              <MenuModal
                categories={categoryToRender}
                menu={menu}
                storeId={id}
                setIsShown={setIsShown}
              />
            </Col>
            <Col xxl={8} xl={xxll ? 8 : 24} lg={24} md={24} sm={24}>
              <MenuPreviewCard
                category={categoryToRender}
                storeName={name}
                storeId={id}
              />
            </Col>
          </Row>
        </Col>
      </InfoWrapper>
    </>
  );
};

export default MenuItem;
