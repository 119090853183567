/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { RestaurantsContent, LeftSide, RightSide } from './styles';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurantDetail } from '../../../../../services/Redux/RegisteredRestaurants/api';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import RestaurantsInfo from './RestaurantsInfo';
import Header from './Header';
import RestaurantPacketNames from './RestaurantsPacketNames';
import Badges from './Badges';
import RestaurantsCategories from './RestaurantsCategories';

const RestaurantsDetail = ({ suspendReason, storeId, setStoreId }) => {
	const [isPacketPanel, setIsPacketPanel] = useState(true);
	const [detailToRender, setDetailToRender] = useState();
	const dispatch = useDispatch();

	const { xxl } = useResponsive();

	useEffect(() => {
		if (storeId !== null || storeId !== undefined) {
			dispatch(getRestaurantDetail(storeId));
		}
	}, [storeId]);

	const { getDetail } = useSelector((state) => state.registeredRestaurantsStore);

	useEffect(() => {
		if (getDetail) {
			setDetailToRender(getDetail);
		}
	}, [getDetail]);

	if (detailToRender !== undefined && detailToRender !== null && detailToRender) {
		return (
			<RestaurantsContent gutter={[18, 0]}>
				<LeftSide md={24} lg={24} xl={xxl ? 18 : 24} xxl={18}>
					<Header
						suspendReason={suspendReason}
						storeId={storeId}
						storeDetail={detailToRender}
						reviews={detailToRender.reviews}
						settings={detailToRender.package_settings}
						boxes={detailToRender.boxes}
					/>
					<RestaurantsCategories storeId={storeId} categories={detailToRender.categories} />
					<RestaurantsInfo
						storeId={storeId}
						isPacketPanel={isPacketPanel}
						setIsPacketPanel={setIsPacketPanel}
						boxes={detailToRender.boxes}
						settings={detailToRender.package_settings}
					/>
					{isPacketPanel && <RestaurantPacketNames storeId={storeId} />}
				</LeftSide>
				<RightSide md={24} lg={24} xl={xxl ? 6 : 24} xxl={6}>
					<Badges reviews={detailToRender.reviews} joined={detailToRender.created_at} storeId={storeId} />
				</RightSide>
			</RestaurantsContent>
		);
	} else {
		return <Spin />;
	}
};

export default RestaurantsDetail;
