export const GET_HELP_REQUESTS_START = 'GET_HELP_REQUESTS_START';
export const GET_HELP_REQUESTS_SUCCESS = 'GET_HELP_REQUESTS_SUCCESS';
export const GET_HELP_REQUESTS_FAIL = 'GET_HELP_REQUESTS_FAIL';

export const SET_SOLVED_REQUEST_START = 'SET_SOLVED_REQUEST_START';
export const SET_SOLVED_REQUEST_SUCCESS = 'SET_SOLVED_REQUEST_SUCCESS';
export const SET_SOLVED_REQUEST_FAIL = 'SET_SOLVED_REQUEST_FAIL';

export const SET_REJECTED_REQUEST_START = 'SET_REJECTED_REQUEST_START';
export const SET_REJECTED_REQUEST_SUCCESS = 'SET_REJECTED_REQUEST_SUCCESS';
export const SET_REJECTED_REQUEST_FAIL = 'SET_REJECTED_REQUEST_FAIL';
