import { useState } from 'react';
import { Row, Col } from 'antd';
import {
	PanelImg,
	Panel,
	Profile,
	Banner,
	StarImg,
	ProgressItem,
	CustomProgress,
	HeaderItem,
	Div,
	CancelButton,
} from './styles';
import backImg from '../../../../../../Assets/components/empty-img.png';
import starIcon from '../../../../../../Assets/components/stars.svg';
import timeIcon from '../../../../../../Assets/components/time-icon.svg';
import { Text } from '../../../../../components/Typography/styles';
import { useDispatch } from 'react-redux';
import AlertModal from '../../AlertModal';
import { updateRestToActive, getSuspendRestaurants } from '../../../../../../services/Redux/RegisteredRestaurants/api';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery';

const Header = ({ terminateReason, storeId, storeDetail, reviews, settings, boxes }) => {
	const dispatch = useDispatch();
	const { xl } = useResponsive();

	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const handleCancel = () => {
		setIsAlertVisible(false);
	};

	const restToActive = async () => {
		await dispatch(updateRestToActive(storeId));
		await dispatch(getSuspendRestaurants());
	};
	return (
		<Col span={24} style={{ padding: '0px' }}>
			<Panel>
				<PanelImg src={backImg} alt='' />
			</Panel>
			<Profile span={5} offset={2}>
				<PanelImg src={backImg} alt='' />
			</Profile>
			<HeaderItem>
				<Banner span={16} offset={8}>
					<Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
						<Text weight='bold' size='regular'>
							{storeDetail.name !== null ? storeDetail.name : 'No Name'}
						</Text>
						<StarImg src={starIcon} alt='' />
						<Text size='20px' weight='light'>
							{storeDetail.avg_review !== null ? storeDetail?.avg_review.toString().slice(0, 3) : '0'}
						</Text>
					</Col>
					<Col span={24} style={{ marginTop: '32px', display: xl ? 'flex' : 'block' }}>
						<Col md={24} lg={24} xl={12} xxl={12} style={{ display: 'flex', alignItems: 'center' }}>
							<Text weight='light' color='smoke'>
								{storeDetail.address !== null ? storeDetail.address : ' '} -
								{storeDetail.city !== null ? storeDetail.city : ''} -
								{storeDetail.province !== null ? storeDetail.province : ''}
							</Text>
						</Col>
						<Col
							md={24}
							lg={24}
							xl={12}
							xxl={12}
							style={{ display: 'flex', flexDirection: 'row', opacity: '0.5', marginTop: xl ? '0px' : '12px' }}
						>
							<ProgressItem span={8}>
								<Col span={12}>
									<Text size='smallRegular' weight='light'>
										Servis
									</Text>
								</Col>
								<Col span={12}>
									<CustomProgress
										strokeWidth='13'
										width='45px'
										strokeColor='#9FD5A4'
										trailColor='#E2E9EF'
										type='circle'
										percent={reviews !== undefined ? reviews?.service_avg?.toString().slice(0, 3) : 1}
										format={(percent) => `${percent}`}
									/>
								</Col>
							</ProgressItem>
							<ProgressItem span={8}>
								<Col span={12}>
									<Text size='smallRegular' weight='light'>
										Kalite
									</Text>
								</Col>
								<Col span={12}>
									<CustomProgress
										strokeWidth='13'
										width='45px'
										strokeColor='#E5876A'
										trailColor='#E2E9EF'
										type='circle'
										percent={reviews !== undefined ? reviews?.quality_avg?.toString().slice(0, 3) : 1}
										format={(percent) => `${percent}`}
									/>
								</Col>
							</ProgressItem>
							<ProgressItem span={8}>
								<Col span={12}>
									<Text size='smallRegular' weight='light'>
										Lezzet
									</Text>
								</Col>
								<Col span={12}>
									<CustomProgress
										strokeWidth='13'
										width='45px'
										strokeColor='#827F7F'
										trailColor='#E2E9EF'
										type='circle'
										percent={reviews !== undefined ? reviews?.meal_avg?.toString().slice(0, 3) : 1}
										format={(percent) => `${percent}`}
									/>
								</Col>
							</ProgressItem>
						</Col>
					</Col>
				</Banner>
			</HeaderItem>
			<HeaderItem>
				<Col md={19} lg={19} xl={18} xxl={18} style={{ marginBottom: '24px' }}>
					<Row gutter={[20, 0]}>
						<Col md={6} lg={6} xl={6} xxl={6} style={{ opacity: '0.5' }}>
							<Div>
								<img src={timeIcon} alt='' style={{ marginRight: '20px' }} />
								<Text weight='semi-bold' color='yellow' style={{ marginLeft: '22px' }}>
									Kapalı
								</Text>
							</Div>
						</Col>
						<Col md={5} lg={5} xl={4} xxl={4} style={{ display: 'flex', alignItems: 'center', opacity: '0.5' }}>
							<Text
								color='smoke'
								weight='semi-bold'
								size='regular'
								style={{ marginLeft: '16px', textDecoration: 'line-through' }}
							>
								{settings.min_order_price !== null ? settings.min_order_price : '0'} TL
							</Text>
						</Col>
						<Col md={5} lg={5} xl={4} xxl={4} style={{ opacity: '0.5' }}>
							<Div>
								<Text weight='semi-bold' color='green'>
									{settings.min_discounted_order_price !== null ? settings.min_discounted_order_price : '0'} TL
								</Text>
							</Div>
						</Col>
						<Col md={5} lg={5} xl={4} xxl={4} style={{ opacity: '0.6' }}>
							<Div style={{ background: '#bcbcbc' }}>
								<Text weight='semi-bold' color='white'>
									tükendi
								</Text>
							</Div>
						</Col>
					</Row>
				</Col>
				<Col md={5} lg={5} xl={6} xxl={6}>
					<Row gutter={[16, 0]}>
						<Col span={24}>
							<CancelButton type='secondary' onClick={() => setIsAlertVisible(true)}>
								<Text color='red' weight='semi-bold' size='nobleRegular'>
									Yeniden Aktif Et
								</Text>
							</CancelButton>
						</Col>
					</Row>
				</Col>
				<Col style={{ display: 'flex', flexDirection: 'row' }}>
					<Text weight='semi-bold'>Sonlandırılma Nedeni:</Text>
					<Text weight='light' style={{ marginLeft: '8px' }}>
						{terminateReason}
					</Text>
				</Col>
			</HeaderItem>
			<AlertModal restToActive={restToActive} visible={isAlertVisible} onCancel={handleCancel} />
		</Col>
	);
};

export default Header;
