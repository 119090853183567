/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Text } from "../../components/Typography/styles";
import { useDispatch, useSelector } from "react-redux";
import { Col, Spin, message } from "antd";
import {
	AuthRolesContent,
	CardTitle,
	Input,
	Item,
	CancelButton,
	SendButton,
	Content,
	TableStyle,
	CustomSelect,
} from "./styles";
import TableItem from "./TableItem";
import {
	getRoles,
	createRoles,
	updateRoles,
} from "../../../services/Redux/Roles/api";
import { getPermissions } from "../../../services/Redux/Permission/api";
import selectArrow from "../../../Assets/components/select-suffix-icon.png";

const AuthRoles = () => {
	const dispatch = useDispatch();
	const { Option } = CustomSelect;
	const [isEdit, setIsEdit] = useState(false);
	const [roles, setRoles] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [checkedValue, setCheckedValue] = useState(null);
	const [roleInput, setRoleInput] = useState("");
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedPermission, setSelectedPermission] = useState("Yetki Seç");

	useEffect(() => {
		dispatch(getRoles());
		dispatch(getPermissions());
	}, []);

	const { rolesList } = useSelector((state) => state.roleStore);
	const { permissionList } = useSelector((state) => state.permissionStore);

	useEffect(() => {
		if (rolesList && permissionList) {
			setRoles(rolesList);
			setPermissions(permissionList);
		}
	}, [rolesList, permissionList]);

	const createRole = async () => {
		if (roleInput === "") {
			message.error("Rol içeriği boş geçilemez !");
		} else if (roleInput !== "" && !isEdit) {
			await dispatch(createRoles(roleInput, checkedValue));
			dispatch(getRoles());
			setRoleInput("");
			setCheckedValue();
		} else if (roleInput !== "" && isEdit) {
			await dispatch(updateRoles(selectedRole, roleInput, checkedValue));
			dispatch(getRoles());
			setSelectedRole(null);
			setRoleInput("");
			setCheckedValue();
			setIsEdit(false);
		}
	};

	const editRole = (id, content, permission) => {
		setIsEdit(true);
		setSelectedRole(id);
		setRoleInput(content);
		setCheckedValue([permission[0].id]);
	};

	const cancelRole = () => {
		setIsEdit(false);
		setRoleInput("");
		setSelectedRole(null);
		setCheckedValue();
	};

	const handleSelectChange = (value, option) => {
		setSelectedPermission(option.children);
		setCheckedValue([value]);
	};

	return (
		<Col md={24} lg={24} xl={22} xxl={22}>
			<AuthRolesContent
				title={
					<CardTitle size="title" weight="light">
						Yetkilendirme
					</CardTitle>
				}
			>
				<Text size="regular" weight="light">
					Buradan oluşturduğun kullanıcılara verebileceğin rolleri
					oluşturabilirsin.
				</Text>
				<Item>
					<Text size="regular" weight="semi-bold">
						Roller:
					</Text>
				</Item>
				<Item>
					<Col span={8}>
						<Text size="regular" weight="semi-bold">
							{isEdit ? "Rol Adı Düzenle:" : "Rol Adı Oluştur:"}
						</Text>
						<Input
							placeholder="Rol adı"
							value={roleInput}
							onChange={(e) => setRoleInput(e.target.value)}
						></Input>
					</Col>
					<Col span={14} offset={2}>
						<Text size="regular" weight="semi-bold">
							{isEdit ? "Yetki Düzenle:" : "Yetki Seç:"}
						</Text>
						<Col span={24}>
							{permissions && (
								<CustomSelect
									value={selectedPermission}
									suffixIcon={<img src={selectArrow} alt="" srcSet="" />}
									onChange={handleSelectChange}
								>
									{permissions.map(({ id, permission }) => (
										<Option key={id} value={id}>
											{permission}
										</Option>
									))}
								</CustomSelect>
							)}
						</Col>
					</Col>
				</Item>
				<Col
					md={{ span: 12, offset: 12 }}
					lg={{ span: 10, offset: 14 }}
					xl={{ span: 8, offset: 16 }}
					style={{ marginTop: "48px" }}
				>
					<Item gutter={[32, 0]}>
						<Col span={12}>
							<CancelButton onClick={() => cancelRole()}>
								<Text color="red">İptal Et</Text>
							</CancelButton>
						</Col>
						<Col span={12}>
							<SendButton type="secondary" onClick={() => createRole()}>
								<Text color="white" weight="semi-bold">
									Kaydet
								</Text>
							</SendButton>
						</Col>
					</Item>
				</Col>
				<Item>
					<Text size="regular" weight="semi-bold">
						Liste:
					</Text>
				</Item>
				<TableStyle>
					<Content>
						<Col span={2}>
							<Text weight="semi-bold" size="regular">
								No:
							</Text>
						</Col>
						<Col span={3}>
							<Text weight="semi-bold" size="regular">
								Roller
							</Text>
						</Col>
						<Col span={16}>
							<Text weight="semi-bold" size="regular">
								Yetkiler
							</Text>
						</Col>
					</Content>
					{roles ? (
						roles.map(({ id, role, permission }) => (
							<TableItem
								id={id}
								role={role}
								permission={permission}
								editRole={editRole}
							/>
						))
					) : (
						<Spin />
					)}
				</TableStyle>
			</AuthRolesContent>
		</Col>
	);
};

export default AuthRoles;
