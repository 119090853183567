import * as actions from './actions';

export const getFoodTags = () => async (dispatch, getState, service) => {
	dispatch(actions.getFoodTagsStartAction());
	await service({
		path: 'box/tag/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getFoodTagsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getFoodTagsFailAction(error));
		});
};

export const createFoodTag = (tagName) => async (dispatch, getState, service) => {
	dispatch(actions.createFoodTagsStartAction());
	await service({
		path: 'box/tag/',
		method: 'POST',
		sendToken: true,
		body: {
			name: tagName,
			confirmed: true,
		},
	})
		.then((data) => {
			dispatch(actions.createFoodTagsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.createFoodTagsFailAction(error));
		});
};

export const updateFoodTag = (id, tagName) => async (dispatch, getState, service) => {
	dispatch(actions.updateFoodTagsStartAction());
	await service({
		path: `box/tag/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			name: tagName,
			confirmed: true,
		},
	})
		.then((data) => {
			dispatch(actions.updateFoodTagsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.updateFoodTagsFailAction(error));
		});
};
