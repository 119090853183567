import * as actions from './actions';
import { message } from 'antd';

export const getHelpRequests = () => async (dispatch, getState, service) => {
	dispatch(actions.getHelpRequestsStartAction());
	await service({
		path: 'helpcenter/help_request/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getHelpRequestsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getHelpRequestsFailAction(error));
		});
};

export const setSolvedRequest = (id) => async (dispatch, getState, service) => {
	dispatch(actions.setSolvedRequestStartAction());
	await service({
		path: `helpcenter/help_request/${id}/set_solved/`,
		method: 'PUT',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.setSolvedRequestSuccessAction(data));
			message.success('Yardım İsteği Tamamlandı !');
		})
		.catch((error) => {
			dispatch(actions.setSolvedRequestFailAction(error));
			message.error('Bir Hata Meydana Geldi !');
		});
};

export const setRejectedRequest = (id) => async (dispatch, getState, service) => {
	dispatch(actions.setRejectedRequestStartAction());
	await service({
		path: `helpcenter/help_request/${id}/set_rejected/`,
		method: 'PUT',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.setRejectedRequestSuccessAction(data));
			message.success('Yardım İsteği Reddedildi !');
		})
		.catch((error) => {
			dispatch(actions.setRejectedRequestFailAction(error));
			message.error('Bir Hata Meydana Geldi !');
		});
};
