import { useState } from 'react';
import {
  TableStyle,
  Item,
  InfoWrapper,
  PanelButton,
  CardTitle,
  InfoItem,
  InfoContent,
  CancelButton,
  EditButton,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, Row } from 'antd';
import rightOutline from '../../../../../Assets/components/right-outline.svg';
import moment from 'moment';
import cancel from '../../../../../Assets/components/cancel.svg';
import checkX from '../../../../../Assets/components/checkX.svg';
import checkV from '../../../../../Assets/components/checkV.svg';
import SuspendModal from '../../components/SuspendModal';
import TerminateModal from '../../components/TerminateModal';

const Title = ({ setIsShown }) => (
  <Row align="middle">
    <Col span={23}>
      <CardTitle size="title" weight="light">
        Kullanıcı Detayı
      </CardTitle>
    </Col>
    <Col span={1}>
      <img src={cancel} alt="cancel" onClick={() => setIsShown(false)} />
    </Col>
  </Row>
);

const TableItem = ({ user }) => {
  const [isShown, setIsShown] = useState(false);
  const [isSuspendModalVisible, setIsSuspendModalVisible] = useState(false);
  const [isTerminateModalVisible, setIsTerminateModalVisible] = useState(false);

  const handleCancel = () => {
    setIsTerminateModalVisible(false);
    setIsSuspendModalVisible(false);
  };

  return (
    <>
      <TableStyle>
        <Item span={2} style={{ alignItems: 'center', border: '0px' }}>
          <Text weight="light">{user.id}</Text>
        </Item>
        <Item span={4}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '16px',
            }}
          >
            <Text weight="semi-bold">
              {user.first_name} {user.last_name}
            </Text>
            <Text size="smallRegular" weight="light">
              {user.active_address !== null ? user.active_address.city : 'null'}
            </Text>
          </Col>
        </Item>
        <Item span={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {user.email}
          </Text>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {user.phone_number}
          </Text>
        </Item>
        <Item
          span={2}
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Text size="smallRegular" color="green" weight="semi-bold">
            Aktif
          </Text>
        </Item>
        <Item span={3} style={{ flexDirection: 'column' }}>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(user.created_at).format('DD MMMM  YYYY')}
          </Text>
          <Text
            size="smallRegular"
            weight="light"
            style={{ marginLeft: '16px' }}
          >
            {moment(user.created_at).format('dddd  HH:mm')}
          </Text>
        </Item>
        <Item
          span={1}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text size="smallRegular" weight="light">
            SMS
          </Text>
          <img src={checkX} alt="check" />
        </Item>
        <Item
          span={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text size="smallRegular" weight="light">
            E-posta
          </Text>
          <img src={user.allow_email ? checkV : checkX} alt="email" />
        </Item>
        <Item
          span={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text size="smallRegular" weight="light">
            Telefonla Arama
          </Text>
          <img src={user.allow_phone ? checkV : checkX} alt="phone" />
        </Item>
        <Item span={2} style={{ alignItems: 'center' }}>
          <PanelButton onClick={() => setIsShown(!isShown)}>
            <img src={rightOutline} alt="rightOutLine" />
          </PanelButton>
        </Item>
      </TableStyle>
      <InfoWrapper
        title={<Title setIsShown={setIsShown} />}
        style={{ display: isShown ? 'block' : 'none' }}
      >
        <InfoContent>
          <Text weight="bold">Kişi Bilgisi:</Text>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">İsim Soyisim:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.first_name} {user.last_name}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">ID:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.id}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Hesap Durumu:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  Aktif
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Kayıt Tarihi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {moment(user.created_at).format('DD MMMM  YYYY')}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">Saat:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {moment(user.created_at).format('HH:mm')}
                </Text>
              </Col>
              <Col span={6}>
                <Text weight="bold">Doğum Tarihi:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.birthdate !== null ? user.birthdate : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={24}>
                <Text weight="bold">Adres:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.active_address !== null
                    ? `${user.active_address.address} - ${user.active_address.city}`
                    : 'null'}
                </Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">Telefon:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.phone_number}
                </Text>
              </Col>
              <Col span={8}>
                <Text weight="bold">E-mail:</Text>
                <Text weight="light" style={{ marginLeft: '12px' }}>
                  {user.email}
                </Text>
              </Col>
              <Col span={3}>
                <EditButton
                  type="secondary"
                  onClick={() => setIsSuspendModalVisible(true)}
                >
                  <Text color="yellow" weight="semi-bold" size="nobleRegular">
                    Askıya Al
                  </Text>
                </EditButton>
              </Col>
              <Col span={3}>
                <CancelButton onClick={() => setIsTerminateModalVisible(true)}>
                  <Text color="red" weight="semi-bold" size="nobleRegular">
                    Sonlandır
                  </Text>
                </CancelButton>
              </Col>
            </InfoItem>
          </Col>
          <Col span={24}>
            <InfoItem>
              <Col span={24}>
                <Text weight="bold">İzinler:</Text>
              </Col>
            </InfoItem>
          </Col>

          <Col span={24}>
            <InfoItem>
              <Col span={10}>
                <Text weight="bold">SMS</Text>
                <img
                  src={checkX}
                  alt=""
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
              <Col span={8}>
                <Text weight="bold">E-posta:</Text>
                <img
                  src={user.allow_email ? checkV : checkX}
                  alt="email"
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
              <Col span={6}>
                <Text weight="bold">Telefonla Arama:</Text>
                <img
                  src={user.allow_phone ? checkV : checkX}
                  alt="phone"
                  style={{ marginLeft: '22px', marginBottom: '5px' }}
                />
              </Col>
            </InfoItem>
          </Col>
        </InfoContent>
        <SuspendModal
          userId={user?.id}
          visible={isSuspendModalVisible}
          onCancel={handleCancel}
        />
        <TerminateModal
          userId={user?.id}
          visible={isTerminateModalVisible}
          onCancel={handleCancel}
        />
      </InfoWrapper>
    </>
  );
};

export default TableItem;
