import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginPage from '../presentation/pages/LoginPage';
import AdminLayout from '../presentation/components/Layout';
import AdminHeader from '../presentation/components/Layout/Header';
import AdminSider from '../presentation/components/Layout/Sider';
import AdminContent from '../presentation/components/Layout/Content';
import FoodCategories from '../presentation/pages/FoodCategories';
import FoodTags from '../presentation/pages/FoodTags';
import SpecialReasons from '../presentation/pages/SpecialReasons';
import HelpContentCreator from '../presentation/pages/HelpContentCreator';
import HelpRequests from '../presentation/pages/HelpRequests';
import Orders from '../presentation/pages/Orders';
import Users from '../presentation/pages/Users';
import Statistics from '../presentation/pages/Statistics';
import AuthPermission from '../presentation/pages/AuthPermission';
import AuthRoles from '../presentation/pages/AuthRoles';
import AuthUsers from '../presentation/pages/AuthUsers';
import PendingRestaurants from '../presentation/pages/PendingRestaurants';
import RegisteredRestaurants from '../presentation/pages/RegisteredRestaurants';
import PageNotFound from '../presentation/pages/PageNotFound';
import AddRestaurants from '../presentation/pages/AddRestaurants';

const Home = () => {
	const userData = JSON.parse(localStorage.getItem('user'));
	return userData && userData.status === '1' ? (
		<Route to='/admin' component={Admin} />
	) : (
		<>
			<AdminLayout color='#F5F9FC' style={{ height: '100vh' }}>
				<AdminHeader userData={userData} />
				<AdminLayout>
					<AdminContent>
						<Switch>
							<Route exact path='/' component={LoginPage} />
							<Route exact path='/*' component={PageNotFound} />
						</Switch>
					</AdminContent>
				</AdminLayout>
			</AdminLayout>
		</>
	);
};

const Admin = () => {
	const userData = JSON.parse(localStorage.getItem('user'));

	return userData && userData.status !== '1' ? (
		<Route path='/' component={Home} />
	) : (
		<>
			<AdminLayout color='#F5F9FC' style={{ height: '100vh' }}>
				<AdminHeader userData={userData} />
				<AdminLayout>
					<AdminSider userData={userData} />
					<AdminContent>
						<Switch>
							<Route exact path='/' component={FoodCategories} />
							<Route exact path='/admin' component={FoodCategories} />
							<Route exact path='/food-categories' component={FoodCategories} />
							<Route exact path='/food-tags' component={FoodTags} />
							<Route exact path='/special-reasons' component={SpecialReasons} />
							<Route exact path='/content-creator' component={HelpContentCreator} />
							<Route exact path='/help-requests' component={HelpRequests} />
							<Route exact path='/orders' component={Orders} />
							<Route exact path='/users' component={Users} />
							<Route exact path='/stats' component={Statistics} />
							<Route exact path='/auth-permissions' component={AuthPermission} />
							<Route exact path='/auth-roles' component={AuthRoles} />
							<Route exact path='/auth-users' component={AuthUsers} />
							<Route exact path='/pending-restaurants' component={PendingRestaurants} />
							<Route exact path='/registered-restaurants' component={RegisteredRestaurants} />
							<Route exact path='/add-restaurants' component={AddRestaurants} />
							<Route exact path='/*' component={PageNotFound} />
						</Switch>
					</AdminContent>
				</AdminLayout>
			</AdminLayout>
		</>
	);
};

const Routes = () => (
	<Router>
		<Switch>
			<Route path='/admin' component={Admin} />
			<Route path='/' component={Home} />
		</Switch>
	</Router>
);

export default Routes;
