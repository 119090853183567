/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Text } from '../../../components/Typography/styles';
import search from '../../../../Assets/components/search.png';
import { Select, Header, Input, Location, TableStyle, Footer, NavButton } from './styles';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import TableItem from './TableItem';
import leftArrow from '../../../../Assets/components/left-arrow.svg';
import rightArrow from '../../../../Assets/components/right-arrow.svg';
import { getRestaurantStatistics } from '../../../../services/Redux/Statistics/api';

const SearchIcon = () => (
	<Row
		align='middle'
		style={{
			height: '48px',
			width: '48px',
			backgroundColor: '#ffbc41',
			justifyContent: 'center',
			borderRadius: '50px',
			position: 'absolute',
			right: '-18px',
		}}
	>
		<img src={search} alt='search' />
	</Row>
);

const RestaurantStats = () => {
	const { Option } = Select;
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [restaurants, setRestaurants] = useState([]);
	const [count] = useState(7);
	const [search, setSearch] = useState('');

	useEffect(() => {
		dispatch(getRestaurantStatistics());
	}, []);

	const { restaurantStatisticsList } = useSelector((state) => state.statisticsStore);

	useEffect(() => {
		if (restaurantStatisticsList) {
			setRestaurants(restaurantStatisticsList);
		}
	}, [restaurantStatisticsList]);

	useEffect(() => {
		if (search !== '') {
			const searchedName = restaurantStatisticsList
				? restaurantStatisticsList.filter(({ store }) => store.name.toLowerCase().includes(search.toLocaleLowerCase()))
				: setRestaurants(restaurantStatisticsList);
			setRestaurants(searchedName);
		} else if (restaurantStatisticsList) {
			setRestaurants(restaurantStatisticsList);
		}
	}, [search]);

	function prevPage() {
		const slice = restaurantStatisticsList.slice(count * (page - 2), count * (page - 1));
		if (slice.length !== 0) {
			setRestaurants(slice);
			setPage(page - 1);
		}
	}

	function nextPage() {
		const slice = restaurantStatisticsList.slice(count * page, count * (page + 1));
		if (slice.length !== 0) {
			setRestaurants(slice);
			setPage(page + 1);
		}
	}

	return (
		<Col>
			<Text size='regular' weight='light'>
				Kayıtlı {restaurants && restaurants.length} Restorant bulunmaktadır
			</Text>
			<Header
				gutter={[
					{ xs: 12, md: 12, xl: 16, xxl: 32 },
					{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
				]}
			>
				<Col md={18} lg={18} xl={14} xxl={14} style={{ display: 'flex' }}>
					<Col span={6}>
						<Select placeholder='Bölgeye Göre'>
							<Option value='Yiminghe'>test</Option>
						</Select>
					</Col>
					<Col span={6}>
						<Select placeholder='Sırala'>
							<Option value='Yiminghe'>test</Option>
						</Select>
					</Col>
					<Col span={6}>
						<Select placeholder='Paket Fiyatı'>
							<Option value='Yiminghe'>test</Option>
						</Select>
					</Col>
					<Col span={6}>
						<Select placeholder='Paket Teslimi'>
							<Option value='Yiminghe'>test</Option>
						</Select>
					</Col>
				</Col>
				<Col md={6} lg={6} xl={10} xxl={10}>
					<Input placeholder='Ara' suffix={<SearchIcon />} onChange={(e) => setSearch(e.target.value)} />
				</Col>
			</Header>
			<Location>
				<Text>Beşiktaş/İstanbul</Text>
			</Location>
			<TableStyle>
				{restaurants.length !== 0 ? (
					restaurants ? (
						restaurants.map(({ store, avg_review, meal_avg, quality_avg, service_avg, total_count, total_price }) => (
							<TableItem
								store={store}
								avgReview={avg_review}
								serviceRating={service_avg}
								qualityRating={quality_avg}
								mealRating={meal_avg}
								totalCount={total_count}
								totalPrice={total_price}
							/>
						))
					) : (
						<Spin />
					)
				) : (
					<Text weight='semi-bold' color='red' style={{ marginTop: '32px', alignSelf: 'center' }}>
						Sonuç bulunamadı
					</Text>
				)}

				<Footer align='middle' justify='center'>
					<NavButton
						src={leftArrow}
						alt='left-arrow'
						onClick={() => {
							if (page !== 1) {
								prevPage();
							}
						}}
					/>
					<Text>{page}</Text>
					<NavButton src={rightArrow} alt='right-arrow' onClick={() => nextPage()} />
				</Footer>
			</TableStyle>
		</Col>
	);
};

export default RestaurantStats;
