import * as actions from './actions';
import { message } from 'antd';

export const getPermissions = () => async (dispatch, getState, service) => {
	dispatch(actions.getPermissionStartAction());
	await service({
		path: 'superadmin/admin-permission/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getPermissionSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getPermissionFailAction(error));
		});
};

export const createPermission = (permission) => async (dispatch, getState, service) => {
	dispatch(actions.createPermissionStartAction());
	await service({
		path: 'superadmin/admin-permission/',
		method: 'POST',
		sendToken: true,
		body: {
			permission: permission,
		},
	})
		.then((data) => {
			dispatch(actions.createPermissionSuccessAction(data));
			message.success('Yetki Başarı ile Oluşturuldu. !');
		})
		.catch((error) => {
			dispatch(actions.createPermissionFailAction(error));
			message.error('Yetki Oluşturulurken bir Hata Oluştu. !');
		});
};

export const updatePermission = (id, permission) => async (dispatch, getState, service) => {
	dispatch(actions.updatePermissionStartAction());
	await service({
		path: `superadmin/admin-permission/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			permission: permission,
		},
	})
		.then((data) => {
			dispatch(actions.updatePermissionSuccessAction(data));
			message.success('Yetki Başarı ile Güncellendi. !');
		})
		.catch((error) => {
			dispatch(actions.updatePermissionFailAction(error));
			message.error('Yetki Güncellenirken bir Hata Oluştu. !');
		});
};

export const deletePermission = (id) => async (dispatch, getState, service) => {
	dispatch(actions.deletePermissionStartAction());
	await service({
		path: `superadmin/admin-permission/${id}/`,
		method: 'DELETE',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.deletePermissionSuccessAction(data));
			message.success('Yetki Başarı ile Silindi. !');
		})
		.catch((error) => {
			dispatch(actions.deletePermissionFailAction(error));
			message.error('Yetki Silinirken bir Hata Oluştu. !');
		});
};
