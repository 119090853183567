import * as actionTypes from './actionTypes';

export const getPermissionStartAction = () => ({
	type: actionTypes.GET_PERMISSION_START,
});

export const getPermissionSuccessAction = (permission) => ({
	type: actionTypes.GET_PERMISSION_SUCCESS,
	payload: permission,
});

export const getPermissionFailAction = (error) => ({
	type: actionTypes.GET_PERMISSION_FAIL,
	payload: error,
});

//CREATE

export const createPermissionStartAction = () => ({
	type: actionTypes.CREATE_PERMISSION_START,
});

export const createPermissionSuccessAction = (permission) => ({
	type: actionTypes.CREATE_PERMISSION_SUCCESS,
	payload: permission,
});

export const createPermissionFailAction = (error) => ({
	type: actionTypes.CREATE_PERMISSION_FAIL,
	payload: error,
});

//UPDATE

export const updatePermissionStartAction = () => ({
	type: actionTypes.UPDATE_PERMISSION_START,
});

export const updatePermissionSuccessAction = (permission) => ({
	type: actionTypes.UPDATE_PERMISSION_SUCCESS,
	payload: permission,
});

export const updatePermissionFailAction = (error) => ({
	type: actionTypes.UPDATE_PERMISSION_FAIL,
	payload: error,
});

//DELETE

export const deletePermissionStartAction = () => ({
	type: actionTypes.DELETE_PERMISSION_START,
});

export const deletePermissionSuccessAction = (permission) => ({
	type: actionTypes.DELETE_PERMISSION_SUCCESS,
	payload: permission,
});

export const deletePermissionFailAction = (error) => ({
	type: actionTypes.DELETE_PERMISSION_FAIL,
	payload: error,
});
