import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	restaurantStatisticsList: null,
	restaurantStatisticsError: null,
};

const getRestaurantStatisticsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getRestaurantStatisticsuccessReducer = (state, action) => ({
	...state,
	loading: false,
	restaurantStatisticsList: action.payload,
});

const getRestaurantStatisticsFailReducer = (state, action) => ({
	...state,
	loading: false,
	restaurantStatisticsError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RESTAURANT_STATISTICS_START:
			return getRestaurantStatisticsStartReducer(state);
		case actionTypes.GET_RESTAURANT_STATISTICS_SUCCESS:
			return getRestaurantStatisticsuccessReducer(state, action);
		case actionTypes.GET_RESTAURANT_STATISTICS_FAIL:
			return getRestaurantStatisticsFailReducer(state, action);

		default:
			return state;
	}
};

export default reducer;
