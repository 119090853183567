import { useState, useEffect } from "react";
import {
  FoodTagsContent,
  FoodTagsCol,
  Content,
  CardTitle,
  Input,
  SendButton,
  Divider,
  FoodnameLabel,
  EditButton,
  EditSaveButton,
  CustomText,
  DeleteButton,
} from "./styles";
import { Text } from "../../components/Typography/styles";
import { Row, Col, message } from "antd";
import FoodLabel from "./FoodLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoodTags,
  createFoodTag,
  updateFoodTag,
} from "../../../services/Redux/FoodTags/api";
import xmlDelete from "../../../services/Api/request";

const FoodTags = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tagsToRender, setTagsToRender] = useState();
  const [tagName, setTagName] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    dispatch(getFoodTags());
  }, [dispatch]);

  const { foodTagsList } = useSelector((state) => state.foodTagsStore);

  useEffect(() => {
    setTagsToRender(foodTagsList);
  }, [foodTagsList]);

  const handleInputChange = (e) => {
    setTagName(e.target.value);
  };

  const createTag = async () => {
    if (tagName !== "" && !isEdit) {
      await dispatch(createFoodTag(tagName));
      dispatch(getFoodTags());
      message.success("Etiket Başarı ile Eklendi !");
      setTagName("");
    } else if (tagName !== "" && isEdit) {
      await dispatch(updateFoodTag(selectedTag, tagName));
      dispatch(getFoodTags());
      message.success("Etiket Başarı ile Düzenlendi !");
      setSelectedTag(null);
      setTagName("");
      setIsEdit(false);
    }
  };

  const editTag = () => {
    const selected = tagsToRender.find(({ id }) => id === selectedTag);
    if (!selected && !selectedTag) {
      message.error("Düzenlemek için Mevcut Bir Etiket Seçiniz. !");
    } else {
      const { name } = selected;
      setTagName(name);
      setIsEdit(true);
    }
  };

  const removeTag = async () => {
    const selected = tagsToRender.find(({ id }) => id === selectedTag);
    if (!selected && !selectedTag) {
      message.error("Silmek için Mevcut Bir Etiket Seçiniz. !");
    } else {
      await xmlDelete({
        path: `box/tag/${selected?.id}/`,
        method: "DELETE",
        sendToken: true,
      });
      setTagName("");
      setIsEdit(false);
    }
    dispatch(getFoodTags());
  };

  const confirmTag = async () => {
    const selected = tagsToRender.find(({ id }) => id === selectedTag);
    if (!selectedTag && !selectedTag) {
      message.error("Lütfen Bir Etiket Seçiniz ! ");
    } else {
      const { name } = selected;
      await dispatch(updateFoodTag(selectedTag, name));
      message.success("Etiket Başarı ile Etiket Bulutuna Eklendi !");
      dispatch(getFoodTags());
      setSelectedTag(null);
    }
  };

  return (
    <FoodTagsCol md={24} lg={24} xl={21} xxl={21}>
      <FoodTagsContent
        title={
          <CardTitle size="title" weight="light">
            Yemek Etiketleri
          </CardTitle>
        }
      >
        <Row>
          {isEdit ? (
            <Text weight="semi-bold">Etiket Düzenle:</Text>
          ) : (
            <Text weight="semi-bold">Etiket Oluştur:</Text>
          )}
        </Row>
        <Row gutter={[32, 0]}>
          <Content md={11} lg={9} xl={8}>
            <Input
              placeholder="Etiket ismi"
              value={tagName}
              onChange={(e) => handleInputChange(e)}
            />
          </Content>
          <Content md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
            <SendButton type="secondary" onClick={() => createTag()}>
              <Text color="white" weight="semi-bold">
                Kaydet
              </Text>
            </SendButton>
          </Content>
        </Row>
        <Divider />
        <Row>
          <Text weight="semi-bold">Restorantlardan Gelen Öneriler:</Text>
          <Text weight="light" style={{ marginLeft: "12px" }}>
            Yayınlanmasını istediğiniz etiketi seçerek Kaydet butonuna basınız.
          </Text>
        </Row>
        <Row>
          <Content md={12} lg={14} xl={12}>
            <Row gutter={[0, 0]} style={{ marginTop: "16px" }}>
              {tagsToRender &&
                tagsToRender.map(
                  ({ id, name, confirmed }) =>
                    !confirmed && (
                      <FoodnameLabel
                        key={id}
                        className={"foodlabel"}
                        onClick={() => setSelectedTag(id)}
                      >
                        <CustomText
                          size="non-regular"
                          family="secondary"
                          color="smoke"
                          weight="light"
                        >
                          {name}
                        </CustomText>
                      </FoodnameLabel>
                    )
                )}
            </Row>
          </Content>

          <Content md={12} lg={16} xl={12} style={{ marginTop: "16px" }}>
            <Row>
              <Col span={7}>
                <DeleteButton onClick={() => removeTag()}>
                  <Text color="white"> Sil</Text>
                </DeleteButton>
              </Col>
              <Col span={7} offset={1}>
                <EditButton onClick={() => editTag()}>
                  <Text color="yellow"> Düzenle</Text>
                </EditButton>
              </Col>
              <Col span={7} offset={1}>
                <EditSaveButton type="secondary" onClick={() => confirmTag()}>
                  <Text color="white" weight="semi-bold">
                    Kaydet
                  </Text>
                </EditSaveButton>
              </Col>
            </Row>
          </Content>
        </Row>
        <Divider />
        <Row>
          <Text weight="semi-bold">Etiket Bulutu:</Text>
        </Row>
        <Row style={{ marginTop: "16px" }}>
          {tagsToRender &&
            tagsToRender.map(
              ({ id, name, confirmed }) =>
                confirmed && <FoodLabel key={id} tagsId={id} name={name} />
            )}
        </Row>
      </FoodTagsContent>
    </FoodTagsCol>
  );
};

export default FoodTags;
