import styled from 'styled-components';
import { between } from 'polished';
import Card from '../../components/Card/index';
import { Text as text } from '../../components/Typography/styles';
import { Row, Select as select, Input as input } from 'antd';

export const HelpRequestContent = styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const Header = styled(Row)`
	margin-top: 45px;
`;

export const Select = styled(select)`
	width: 100%;

	.ant-select-selector {
		height: 48px !important;
		background-color: #f2f2fc !important;
		border-radius: 3px !important;
		align-items: center;
	}
	.ant-select-selection-item {
		display: flex;
		align-items: center;
	}
	max-width: 342px;
`;

export const Input = styled(input)`
	margin-left: 20px;
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	padding-right: 0px;
	position: relative;
`;

export const Location = styled(Row)`
	margin-top: 38px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const TableStyle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 0 0 2px 2px;
	background: ${({ theme }) => theme.lightColor};
`;

export const Content = styled(Row)`
	margin-top: 32px;
	margin-bottom: 16px;
	display: flex;
	align-content: center;
`;

export const PlanContainer = styled(Row)`
	padding-left: 25px;
	padding-right: 25px;
	background: ${({ theme }) => theme.lightColor};
`;
