import styled from 'styled-components';
import Card from '../../../components/Card/index';
import { between } from 'polished';
import { Text as text } from '../../../components/Typography/styles';
import { Col, Row, Divider as divider, Input as input, Button as btn, Upload as upload } from 'antd';
import button from '../../../components/Button/index';

export default styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;
export const ExistCategory = styled(Row)`
	padding-bottom: 32px;
`;
export const Content = styled(Col)`
	display: flex;
	width: 100%;
`;

export const Upload = styled(upload)`
	margin: auto;
	@media screen and (max-width: 1100px) {
		margin: 0;
	}
`;

export const ImgContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 86px;
	height: 130px;
	margin-top: 32px;
	cursor: pointer;
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const Divider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	margin-top: 64px;
	margin-bottom: 36px;
	height: 2px;
	background: ${({ theme }) => theme.borderColor};
`;

export const Item = styled(Col)`
	padding: 0;
	display: flex;
	flex-direction: column;
`;

export const ColorBtnContent = styled(Row)`
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 0px !important;
	margin-top: 10px;
`;

export const Input = styled(input)`
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	margin-top: 32px;
`;

export const AddButton = styled(button)`
	img {
		width: 20px;
		height: 20px;
		margin-right: 6px;
	}
	display: flex;
	justify-content: center;
	align-self: center;
	align-items: center;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	border-radius: 5px;
	width: 87px !important;
	margin-top: 10px;
`;

export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
`;

export const ColorButton = styled(button)`
	width: 32px;
	height: 32px;
	border-radius: 32px !important;
	margin-top: 32px;
`;

export const ImgContent = styled.div`
	width: 86px;
	height: 86px;
	border-radius: 50px !important;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CancelButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;
export const DeleteButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	background-color: #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		background-color: #f38262;
		transition: 0.2s;
	}
`;
