import styled from 'styled-components';
import { Col, Row, Input as input, Button as btn, Divider as divider } from 'antd';
import button from '../../../components/Button/index';
import Card from '../../../components/Card/index';
import { Text } from '../../../components/Typography/styles';

export const Content = styled(Col)`
	padding: 0;
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const LeftSide = styled(Col)`
	display: flex;
	flex-direction: column;
`;

export const RightSide = styled(Col)`
	display: flex;
	flex-direction: column;
`;

export const CustomText = styled(Text)`
	margin-top: 32px;
`;

export const BottomSide = styled(Col)``;

export const Input = styled(input)`
	width: 100%;
	position: relative;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	margin-top: 20px;
	padding-right: 0px;
`;

export const AnswerInput = styled(input)`
	width: 100%;
	height: 96px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	margin-top: 20px;
`;
export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
`;

export const EditButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const Divider = styled(divider)`
	margin-left: auto;
	margin-right: auto;
	margin-top: 64px;
	margin-bottom: 32px;
	height: 2px;
	background: ${({ theme }) => theme.borderColor};
`;

export const CustomCard = styled(Card)`
	.ant-card-body {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		min-height: 120px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const CategoriesCard = styled(Card)`
	.ant-card-body {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		height: 200px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const CardWrapper = styled(Col)`
	:hover {
		cursor: pointer;
	}
`;

export const CategoriesContent = styled(Row)`
	margin-top: 32px;
	margin-bottom: 60px;
	display: flex;
	justify-content: space-between;
`;

export const ExistContent = styled(Row)`
	display: flex;
	justify-content: space-between;
`;

export const CancelButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	border: 2px solid #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const DeleteButton = styled(btn)`
	margin-top: 32px;
	height: 48px !important;
	width: 100%;
	background-color: #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		background-color: #f38262;
		transition: 0.2s;
	}
`;
