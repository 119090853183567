import React, { useState } from 'react';
import {
  CustomModal,
  Input,
  DailyInput,
  SendButton,
  CancelButton,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import {
  createSuspendUser,
  getActiveUsers,
} from '../../../../../services/Redux/Users/api';

const Title = ({ text }) => (
  <Text weight="light" size="title">
    {text}
  </Text>
);

const SuspendModal = ({ userId, onCancel, visible }) => {
  const dispatch = useDispatch();
  const [day, setDay] = useState(0);
  const [description, setDescription] = useState('');

  const createSuspend = async () => {
    if (description === '') {
      message.error(' Sebep alanı boş geçilemez !');
    } else if (day < 1) {
      message.error('Askıya alınacak gün sayısı 1 sen az olamaz !');
    } else {
      await dispatch(createSuspendUser(userId, description, day));
      setDay(0);
      setDescription('');
      await dispatch(getActiveUsers());
      onCancel();
    }
  };

  return (
    <CustomModal
      onCancel={onCancel}
      visible={visible}
      title={<Title text="Askıya Alma Nedeni" />}
    >
      <Text weight="light">
        Aşağıda yapacağınız askıya alma nedeni hesab sahibine bilgilendirme
        olarak iletilecektir.
      </Text>
      <Row style={{ marginTop: '48px' }}>
        <Text weight="semi-bold" style={{ marginBottom: '8px' }}>
          Sebep
        </Text>
        <Input
          placeholder="Sebep giriniz..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Row>
      <Row style={{ marginTop: '48px', marginBottom: '48px' }}>
        <Col span={24} style={{ marginBottom: '8px' }}>
          <Text weight="semi-bold">Süresi</Text>
        </Col>
        <Col span={12}>
          <DailyInput
            value={day}
            suffix={'Gün'}
            onChange={(event) => {
              const tmp = parseInt(event.target.value);
              if (Number.isNaN(tmp)) {
                setDay(0);
              } else {
                setDay(parseInt(tmp));
              }
            }}
          />
        </Col>
        <Col span={6}>
          <CancelButton onClick={onCancel}>
            <Text color="red" weight="semi-bold" size="nobleRegular">
              İptal Et
            </Text>
          </CancelButton>
        </Col>
        <Col span={6}>
          <SendButton type="secondary" onClick={() => createSuspend()}>
            <Text color="white" weight="semi-bold">
              Kaydet
            </Text>
          </SendButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default SuspendModal;
