/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { CategoryItemStyles, Header, Content, FoodnameLabel } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import { ReactComponent as AddIcon } from '../../../../../../Assets/components/add-icon.svg';
import MenuItem from './MenuItem';
import NewMealModal from './NewMealModal';

const CategoryItem = ({ categoryName, catId, meals, storeId }) => {
	const [updated, setUpdated] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<CategoryItemStyles>
			<Col span={24}>
				<Header gutter={[24, 0]}>
					<Col className='title-styles' xxl={16} xl={20} lg={20} md={20}>
						<Text weight='bold' size='regular' family='secondary'>
							{categoryName}
						</Text>
					</Col>
					<Col xxl={8} xl={4} lg={4} md={4}>
						<FoodnameLabel onClick={() => setIsModalVisible(true)}>
							<Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<AddIcon />
								<Text family='secondary' weight='bold' style={{ marginLeft: '4px' }}>
									Ekle
								</Text>
							</Row>
						</FoodnameLabel>
					</Col>
				</Header>
				<Content>
					{meals ? (
						meals.map(
							({ id, name, description, tag, price, photo, store }) =>
								storeId === store.id && (
									<Row>
										<MenuItem
											storeId={storeId}
											catId={catId}
											photo={photo}
											price={price}
											id={id}
											name={name}
											description={description}
											labels={tag}
										/>
									</Row>
								),
						)
					) : (
						<div>nodata</div>
					)}
				</Content>
			</Col>
			<NewMealModal
				storeId={storeId}
				catId={catId}
				visible={isModalVisible}
				onCancel={handleCancel}
				setVisible={setIsModalVisible}
			/>
		</CategoryItemStyles>
	);
};

export default CategoryItem;
