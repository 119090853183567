/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import {
  Content,
  Item,
  Input,
  SendButton,
  Text,
  CustomSelect,
  CancelButton,
} from "./styles";
import { createUserInfo } from "../../../../services/Redux/AddRestaurant/api";
import { useDispatch } from "react-redux";
import { Col, Row, Switch, message } from "antd";
import selectArrow from "../../../../Assets/components/select-suffix-icon.png";
import {
  getActiveUsers,
  getSuspendUsers,
} from "../../../../services/Redux/Users/api";

const UserCard = ({
  roles,
  setSelectedRole,
  cancelUserRole,
  patchUserRole,
  selectedUser,
  selectedRole,
}) => {
  const { Option } = CustomSelect;
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [groupsName, setGroupsName] = useState("Grup Seç");
  const [userStatus, setUserStatus] = useState(true);

  useEffect(() => {
    if (selectedUser?.id) {
      setUserStatus(selectedUser.is_active);
    }
  }, [selectedUser]);

  const handleCreateUser = () => {
    if (
      !(
        name &&
        surname &&
        email &&
        phoneNumber &&
        password &&
        password2 &&
        groupsName &&
        selectedRole?.id
      )
    ) {
      message.error("Lütfen tüm alanları doldurunuz!");
      return;
    }
    const formData = new FormData();
    formData.append("first_name", name);
    formData.append("last_name", surname);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("password", password);
    formData.append("password2", password2);
    formData.append("groups", groupsName);
    formData.append("admin_role", selectedRole?.id || null);
    formData.append("is_active", userStatus);

    dispatch(createUserInfo(formData)).then(() => {
      dispatch(getActiveUsers());
      dispatch(getSuspendUsers());
    });
  };

  const handleSave = () => {
    if (selectedUser) {
      patchUserRole(userStatus);
    } else {
      handleCreateUser();
    }
  };

  const onSwitchChange = (checked) => {
    setUserStatus(checked);
  };

  const UserStatusSwitchComp = () => (
    <Item md={11} xxl={11}>
      <Text weight="semi-bold" style={{ marginTop: "1.2rem" }}>
        Durumu
      </Text>
      <Col
        span={4}
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "0.8rem",
        }}
      >
        <Switch
          defaultChecked={userStatus}
          style={{ width: "40px" }}
          onChange={onSwitchChange}
        />
        <Text weight="light" fontStyle="italic" style={{ marginLeft: "20px" }}>
          {userStatus ? "Aktif" : "Pasif"}
        </Text>
      </Col>
    </Item>
  );

  return (
    <div>
      <Text size="regular" weight="light">
        Buradan kullanıcılarına ulaşabileceği rol ve yetkileri verebileceğin
        hesapları{selectedUser ? " düzenleyebilirsin." : " oluşturabilirsin."}
      </Text>
      <Item style={{ marginTop: "24px" }}>
        <Text size="regular" weight="semi-bold">
          Kullanıcılar:
        </Text>
      </Item>
      <Item span={24} style={{ marginTop: "24px" }}>
        <Text weight="semi-bold">
          {selectedUser ? "Kullanıcı Düzenle" : "Kullanıcı Ekle"}
        </Text>
      </Item>

      {selectedUser ? (
        <Content justify="start">
          <Item span={24} style={{ marginTop: "1rem" }}>
            <Content style={{ marginTop: "1rem" }}>
              <Item md={11} xxl={11}>
                <Text weight="semi-bold">İsim</Text>
                <Item
                  name="name"
                  rules={[{ required: true, message: "İsim boş geçilemez." }]}
                >
                  <Input
                    placeholder="İsim"
                    value={selectedUser.first_name}
                    disabled
                  />
                </Item>
              </Item>

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold">Soyisim</Text>
                <Item
                  name="surname"
                  rules={[
                    { required: true, message: "Soyisim boş geçilemez." },
                  ]}
                >
                  <Input
                    placeholder="Soyisim"
                    type="text"
                    value={selectedUser.last_name}
                    disabled
                  />
                </Item>
              </Item>

              <UserStatusSwitchComp />

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Email
                </Text>
                <Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Geçerli bir email giriniz!",
                    },
                    {
                      required: true,
                      message: "Emailinizi giriniz!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    value={selectedUser.email}
                    disabled
                  />
                </Item>
              </Item>
            </Content>
          </Item>
        </Content>
      ) : (
        <Content span={23} justify="start">
          <Item style={{ marginTop: "1rem" }}>
            <Content style={{ marginTop: "1rem" }}>
              <Item md={11} xxl={11}>
                <Text weight="semi-bold">İsim</Text>
                <Item
                  name="name"
                  rules={[{ required: true, message: "İsim boş geçilemez." }]}
                >
                  <Input
                    placeholder="İsim"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Item>
              </Item>

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold">Soyisim</Text>
                <Item
                  name="surname"
                  rules={[
                    { required: true, message: "Soyisim boş geçilemez." },
                  ]}
                >
                  <Input
                    placeholder="Soyisim"
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </Item>
              </Item>

              <Item md={11} xxl={11}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Telefon Numarası
                </Text>
                <Item
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Telefon boş geçilemez." },
                  ]}
                >
                  <Input
                    type="tel"
                    placeholder="+90 (5__) ___ __ __"
                    value={phoneNumber}
                    onBlur={() => {
                      phoneNumber.length < 4 && setPhoneNumber("");
                    }}
                    onFocus={() => {
                      !phoneNumber && setPhoneNumber("+90");
                    }}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Item>
              </Item>

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Email
                </Text>
                <Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Geçerli bir email giriniz!",
                    },
                    {
                      required: true,
                      message: "Emailinizi giriniz!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Item>
              </Item>

              <Item md={11} xxl={11}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Şifre
                </Text>
                <Item
                  name="password"
                  type="password"
                  rules={[{ required: true, message: "Parola boş geçilemez." }]}
                  hasFeedback
                >
                  <Input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Item>
              </Item>

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Şifre Tekrar
                </Text>
                <Item
                  name="password2"
                  rules={[
                    {
                      required: true,
                      message: "Email boş geçilemez",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Parolalarınız uyuşmamaktadır...")
                        );
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                  hasFeedback
                >
                  <Input
                    type="password"
                    placeholder="Şifre"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </Item>
              </Item>

              <UserStatusSwitchComp />

              <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                  Grup Seç
                </Text>
                <CustomSelect
                  required
                  value={groupsName}
                  onChange={(e) => setGroupsName(e)}
                  suffixIcon={<img src={selectArrow} alt="" srcSet="" />}
                >
                  <Option value="Customer">Kullanıcı</Option>
                  <Option value="Business User">Restorant Kullanıcısı</Option>
                  <Option value="Dongu User">Admin</Option>
                </CustomSelect>
              </Item>
            </Content>
          </Item>
        </Content>
      )}
      <Content span={23} style={{ marginTop: "2rem" }}>
        <Item md={11} xxl={11}>
          <Text weight="semi-bold">Rol Seç:</Text>
          {roles && (
            <CustomSelect
              value={selectedRole?.role || "Rol Seç"}
              suffixIcon={<img src={selectArrow} alt="" srcSet="" />}
              onChange={(value) => setSelectedRole(JSON.parse(value))}
            >
              {roles.map((item) => (
                <Option key={item.id} value={JSON.stringify(item)}>
                  {item.role}
                </Option>
              ))}
            </CustomSelect>
          )}
        </Item>

        <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
          <Text weight="semi-bold">Yetkiler:</Text>
          <Item name="permission">
            <Input.TextArea
              style={{ minHeight: "48px" }}
              rows={1}
              placeholder="Yetkiler"
              type="text"
              value={selectedRole && selectedRole?.permission[0]?.permission}
              disabled
            />
          </Item>
        </Item>
      </Content>

      <Content span={23} style={{ marginTop: "2rem" }}>
        <Item
          md={{ span: 13, offset: 11 }}
          xxl={{ span: 12, offset: 12 }}
          style={{ marginTop: "1.5rem" }}
        >
          <Row>
            <Col span={10} offset={2}>
              <CancelButton onClick={cancelUserRole}>
                <Text color="red">Vazgeç</Text>
              </CancelButton>
            </Col>
            <Col span={10} offset={2}>
              <SendButton type="secondary" onClick={handleSave}>
                <Text color="white" weight="semi-bold">
                  Kaydet
                </Text>
              </SendButton>
            </Col>
          </Row>
        </Item>
      </Content>
    </div>
  );
};

export default UserCard;
