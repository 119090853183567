import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	foodCategoryList: null,
	foodCategoryError: null,

	createFoodCategory: null,
	createFoodCategoryError: null,

	updateFoodCategoryError: null,
	updateFoodCategory: null,

	deleteFoodCategory: null,
	deleteFoodCategoryError: null,
};

//GET

const getCategoriesStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getCategoriesSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	foodCategoryList: action.payload,
});

const getCategoriesFailReducer = (state, action) => ({
	...state,
	loading: false,
	foodCategoryError: action.payload,
});

//CREATE

const createCategoryStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createCategorySuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createFoodCategory: action.payload,
});

const createCategoryFailReducer = (state, action) => ({
	...state,
	loading: false,
	createFoodCategoryError: action.payload,
});

// UPDATE

const updateCategoryStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateCategorySuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateFoodCategory: action.payload,
});

const updateCategoryFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateFoodCategoryError: action.payload,
});

// DELETE

const deleteCategoryStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteCategorySuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteFoodCategory: action.payload,
});

const deleteCategoryFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteFoodCategoryError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CATEGORİES:
			return getCategoriesStartReducer(state);
		case actionTypes.GET_CATEGORİES_SUCCESS:
			return getCategoriesSuccessReducer(state, action);
		case actionTypes.GET_CATEGORİES_FAIL:
			return getCategoriesFailReducer(state, action);
		case actionTypes.CREATE_CATEGORY:
			return createCategoryStartReducer(state);
		case actionTypes.CREATE_CATEGORY_SUCCESS:
			return createCategorySuccessReducer(state, action);
		case actionTypes.CREATE_CATEGORY_FAIL:
			return createCategoryFailReducer(state, action);
		case actionTypes.UPDATE_CATEGORY:
			return updateCategoryStartReducer(state);
		case actionTypes.UPDATE_CATEGORY_SUCCESS:
			return updateCategorySuccessReducer(state, action);
		case actionTypes.UPDATE_CATEGORY_FAIL:
			return updateCategoryFailReducer(state, action);

		case actionTypes.DELETE_CATEGORY:
			return deleteCategoryStartReducer(state);
		case actionTypes.DELETE_CATEGORY_SUCCESS:
			return deleteCategorySuccessReducer(state, action);
		case actionTypes.DELETE_CATEGORY_FAIL:
			return deleteCategoryFailReducer(state, action);

		default:
			return state;
	}
};

export default reducer;
