import * as actionTypes from './actionTypes';

export const getStoreStartAction = () => ({
	type: actionTypes.GET_STORE_START,
});

export const getStoreSuccessAction = (restaurant) => ({
	type: actionTypes.GET_STORE_SUCCESS,
	payload: restaurant,
});

export const getStoreFailAction = (error) => ({
	type: actionTypes.GET_STORE_FAIL,
	payload: error,
});
