/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useState } from 'react';
import { Col } from 'antd';
import StoreCard, {
  Content,
  Item,
  CardTitle,
  Input,
  SendButton,
  Text,
  Form,
} from './styles';
import { SelectList } from '../PackageInfoCard/styles';
import { Option } from 'antd/lib/mentions';
import { createUserInfo } from '../../../../services/Redux/AddRestaurant/api';
import { useDispatch } from 'react-redux';

const UserInfoCard = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [groupsName, setGroupsName] = useState();

  const handleGroups = (e) => {
    setGroupsName(e);
  };

  const updateUserInformation = (values) => {
    const formData = new FormData();
    formData.append('first_name', values?.name);
    formData.append('last_name', values?.surname);
    formData.append('email', values?.email);
    formData.append('phone_number', values.phoneNumber && '+90' + phoneNumber);
    formData.append('password', values?.password);
    formData.append('password2', values?.password2);
    formData.append('groups', groupsName);
    formData.append('is_active', true);

    dispatch(createUserInfo(formData));
  };

  const onFinish = (values) => {
    updateUserInformation(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Col md={24} lg={24} xl={22} xxl={22}>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <StoreCard title={<CardTitle>Kullanıcı Bilgileri</CardTitle>}>
          <Content>
            <Item span={24}>
              <Text color="smoke" family="secondary">
                Kişisel bilgilerinizi buradan girebilirsiniz.
              </Text>

              <Item span={24} style={{ marginTop: '32px' }}>
                <Text weight="semi-bold">Kullanıcı Detayı:</Text>
              </Item>
            </Item>
          </Content>

          <Content justify="start">
            <Item span={22} style={{ marginTop: '1rem' }}>
              <Content style={{ marginTop: '1rem' }}>
                <Item md={11} xxl={11}>
                  <Text weight="semi-bold">İsim</Text>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'İsim boş geçilemez.' }]}
                  >
                    <Input
                      placeholder="İsim"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: '1rem' }}>
                    Telefon Numarası
                  </Text>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        pattern: /^[0-9]{1,10}$/g,
                        message: 'Telefon boş geçilemez.',
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      bordered={false}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      placeholder="Telefon Numarası"
                      addonBefore="+90"
                      maxLength={10}
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: '1rem' }}>
                    Parola
                  </Text>
                  <Form.Item
                    name="password"
                    type="password"
                    rules={[
                      { required: true, message: 'Parola boş geçilemez.' },
                    ]}
                    hasFeedback
                  >
                    <Input
                      type="password"
                      placeholder="*********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Item>

                <Item
                  md={{ span: 12, offset: 1 }}
                  xxl={{ span: 11, offset: 2 }}
                >
                  <Text weight="semi-bold">Soyisim</Text>
                  <Form.Item
                    name="surname"
                    rules={[
                      { required: true, message: 'Soyisim boş geçilemez.' },
                    ]}
                  >
                    <Input
                      placeholder="Soyisim"
                      type="text"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: '1rem' }}>
                    Email
                  </Text>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Geçerli bir email giriniz!',
                      },
                      {
                        required: true,
                        message: 'Emailinizi giriniz!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="ornek@ornek.com"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: '1rem' }}>
                    Parola Tekrar
                  </Text>
                  <Form.Item
                    name="password2"
                    rules={[
                      {
                        required: true,
                        message: 'Email boş geçilemez',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Parolalarınız uyuşmamaktadır...')
                          );
                        },
                      }),
                    ]}
                    dependencies={['password']}
                    hasFeedback
                  >
                    <Input
                      type="password"
                      placeholder="**********"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item name="groups" required>
                    <SelectList
                      style={{ width: '100%' }}
                      defaultValue="Grup Seçiniz"
                      value={groupsName}
                      onChange={handleGroups}
                    >
                      <Option value="Customer">Kullanıcı</Option>
                      <Option value="Business User">
                        Restorant Kullanıcısı
                      </Option>
                      <Option value="Dongu User">Admin</Option>
                    </SelectList>
                  </Form.Item>
                </Item>
              </Content>
            </Item>
            <Content
              justify="center"
              style={{ marginTop: '1rem' }}
              gutter={[32, 0]}
            >
              <Item xxl={6} xl={7} lg={7} md={8}>
                <Form.Item>
                  <SendButton type="secondary" htmlType="submit">
                    <Text color="white" weight="semi-bold">
                      EKLE
                    </Text>
                  </SendButton>
                </Form.Item>
              </Item>
            </Content>
          </Content>
        </StoreCard>
      </Form>
    </Col>
  );
};

export default UserInfoCard;
