import styled from "styled-components";
import { Row, Col, Input as input, Select, Button as btn } from "antd";
import { Text as text } from "../../../components/Typography/styles";
import button from "../../../components/Button/index";

export const Content = styled(Row)`
  width: 100%;
  margin: 0px;
`;

export const Item = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const Input = styled(input)`
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const Text = styled(text)``;

export const SendButton = styled(button)`
  height: 48px !important;
  width: 100%;
`;

export const EditButton = styled(btn)`
  height: 48px !important;
  width: 100%;

  border: 2px solid #ffbc41;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #ffbc41;
    transition: 0.2s;
  }
`;

export const CustomSelect = styled(Select)`
  border-radius: 5px;
  width: 100%;

  .ant-select-selection-item {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 400;
  }

  .ant-select-selector {
    border-width: 2px !important;
    height: 48px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .ant-select-selector:hover + .ant-select-arrow {
    color: ${({ theme }) => theme.primaryColor};
  }
  .ant-select-arrow {
    padding-right: 24px;
  }
`;

export const CancelButton = styled(btn)`
  height: 48px !important;
  width: 100%;

  border: 2px solid #f38262;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #f38262;
    transition: 0.2s;
  }
`;
