import styled from 'styled-components';
import { Row, Col } from 'antd';
import button from '../../components/Button/index';

export default styled.div``;

export const Content = styled(Row)`
    display: flex;
    margin-bottom: 32px;
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;

export const RightSide = styled(Col)`
    display: flex;
    flex-direction: ${({ size }) => (size.md || size.lg ? 'row' : 'column')};
    margin-left: ${({ size }) => (size.md || size.lg ? '0' : '32px')};
    width: 100%;
    height: 100%;
`;

export const SendButton = styled(button)`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100% !important;
    width: 100%;
    margin-top: 26px;
`;