import * as actionTypes from './actionTypes';

export const getBoxNamesStartAction = () => ({
	type: actionTypes.GET_BOX_NAMES_START,
});

export const getBoxNamesSuccessAction = (names) => ({
	type: actionTypes.GET_BOX_NAMES_SUCCESS,
	payload: names,
});

export const getBoxNamesFailAction = (error) => ({
	type: actionTypes.GET_BOX_NAMES_FAIL,
	payload: error,
});

// CREATE

export const createBoxNamesStartAction = () => ({
	type: actionTypes.CREATE_BOX_NAMES_START,
});

export const createBoxNamesSuccessAction = (names) => ({
	type: actionTypes.CREATE_BOX_NAMES_SUCCESS,
	payload: names,
});

export const createBoxNamesFailAction = (error) => ({
	type: actionTypes.CREATE_BOX_NAMES_FAIL,
	payload: error,
});

// UPDATE

export const updateBoxNamesStartAction = () => ({
	type: actionTypes.UPDATE_BOX_NAMES_START,
});

export const updateBoxNamesSuccessAction = (names) => ({
	type: actionTypes.UPDATE_BOX_NAMES_SUCCESS,
	payload: names,
});

export const updateBoxNamesFailAction = (error) => ({
	type: actionTypes.UPDATE_BOX_NAMES_FAIL,
	payload: error,
});
