export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const CREATE_ROLES_START = 'CREATE_ROLES_START';
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS';
export const CREATE_ROLES_FAIL = 'CREATE_ROLES_FAIL';

export const UPDATE_ROLES_START = 'UPDATE_ROLES_START';
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS';
export const UPDATE_ROLES_FAIL = 'UPDATE_ROLES_FAIL';

export const DELETE_ROLES_START = 'DELETE_ROLES_START';
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS';
export const DELETE_ROLES_FAIL = 'DELETE_ROLES_FAIL';
