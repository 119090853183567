import * as actionTypes from './actionTypes';

export const getSubCategoriesStartAction = () => ({
	type: actionTypes.GET_SUB_CATEGORIES_START,
});

export const getSubCategoriesSuccessAction = (categories) => ({
	type: actionTypes.GET_SUB_CATEGORIES_SUCCESS,
	payload: categories,
});

export const getSubCategoriesFailAction = (error) => ({
	type: actionTypes.GET_SUB_CATEGORIES_FAIL,
	payload: error,
});

// CREATE

export const createFaqStartAction = () => ({
	type: actionTypes.CREATE_FAQ_START,
});

export const createFaqSuccessAction = (faq) => ({
	type: actionTypes.CREATE_FAQ_SUCCESS,
	payload: faq,
});

export const createFaqFailAction = (error) => ({
	type: actionTypes.CREATE_FAQ_FAIL,
	payload: error,
});

// GET

export const getFaqStartAction = () => ({
	type: actionTypes.GET_FAQ_START,
});

export const getFaqSuccessAction = (faq) => ({
	type: actionTypes.GET_FAQ_SUCCESS,
	payload: faq,
});

export const getFaqFailAction = (error) => ({
	type: actionTypes.GET_FAQ_FAIL,
	payload: error,
});

//UPDATE FAQ

export const updateFaqStartAction = () => ({
	type: actionTypes.UPDATE_FAQ_START,
});

export const updateFaqSuccessAction = (faq) => ({
	type: actionTypes.UPDATE_FAQ_SUCCESS,
	payload: faq,
});

export const updateFaqFailAction = (error) => ({
	type: actionTypes.UPDATE_FAQ_FAIL,
	payload: error,
});

// DELETE FAQ

export const deleteFaqStartAction = () => ({
	type: actionTypes.DELETE_FAQ_START,
});

export const deleteFaqSuccessAction = (faq) => ({
	type: actionTypes.DELETE_FAQ_SUCCESS,
	payload: faq,
});

export const deleteFaqFailAction = (error) => ({
	type: actionTypes.DELETE_FAQ_FAIL,
	payload: error,
});

//GET CONTACT

export const getContactStartAction = () => ({
	type: actionTypes.GET_CONTACT_START,
});

export const getContactSuccessAction = (contact) => ({
	type: actionTypes.GET_CONTACT_SUCCESS,
	payload: contact,
});

export const getContactFailAction = (error) => ({
	type: actionTypes.GET_CONTACT_FAIL,
	payload: error,
});

// UPDATE CONTACT

export const updateContactStartAction = () => ({
	type: actionTypes.UPDATE_CONTACT_START,
});

export const updateContactSuccessAction = (contact) => ({
	type: actionTypes.UPDATE_CONTACT_SUCCESS,
	payload: contact,
});

export const updateContactFailAction = (error) => ({
	type: actionTypes.UPDATE_CONTACT_FAIL,
	payload: error,
});
