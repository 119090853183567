import * as actions from './actions';
import { message } from 'antd';

export const getPendings = () => async (dispatch, getState, service) => {
  dispatch(actions.getPendingApprovalStartAction());
  await service({
    path: 'superadmin/pending_approval/',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getPendingApprovalSuccessAction(data));
    })
    .catch((error) => {
      dispatch(actions.getPendingApprovalFailAction(error));
    });
};

export const updatePendingStore = (id, updatedStore) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.updatePendingStoreStartAction());
  await service({
    path: `superadmin/pending_approval/draft-store/${id}/`,
    method: 'PUT',
    sendToken: true,
    body: updatedStore,
  })
    .then((data) => {
      dispatch(actions.updatePendingStoreSuccessAction(data));
      message.success(
        'Mağaza Bilgisi Güncelleme İsteği Başarı ile  Onaylandı !'
      );
    })
    .catch((error) => {
      dispatch(actions.updatePendingStoreFailAction(error));
      message.error('Bir Hata Meydana Geldi !');
    });
};

export const deletePendingStore = (id) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.deletePendingStoreStartAction());
  await service({
    path: `superadmin/pending_approval/draft-store/${id}/`,
    method: 'DELETE',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.deletePendingStoreSuccessAction(data));
      message.success('Değişiklik talebi başarıyla iptal edildi');
    })
    .catch((error) => {
      dispatch(actions.deletePendingStoreFailAction(error));
      message.error('Bir Hata Meydana Geldi!');
    });
};

export const updatePendingPacket = (id, updatedPacket) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.updatePendingPacketStartAction());
  await service({
    path: `superadmin/pending_approval/draft-packagesetting/${id}/`,
    method: 'PUT',
    sendToken: true,
    body: updatedPacket,
  })
    .then((data) => {
      dispatch(actions.updatePendingPacketSuccessAction(data));
      message.success('Fiyat Güncellemesi Başarı ile  Onaylandı !');
    })
    .catch((error) => {
      dispatch(actions.updatePendingPacketFailAction(error));
      message.error(error.detail);
    });
};

export const deletePendingPacket = (id) => async (
  dispatch,
  getState,
  service
) => {
  dispatch(actions.updatePendingPacketStartAction());
  await service({
    path: `superadmin/pending_approval/draft-packagesetting/${id}/`,
    method: 'DELETE',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.deletePendingPacketSuccessAction(data));
      message.success('Fiyat Güncellemesi Başarı ile  Onaylandı !');
    })
    .catch((error) => {
      dispatch(actions.deletePendingPacketFailAction(error));
      message.error('Bir Hata Meydana Geldi !');
    });
};
