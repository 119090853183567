import styled  from 'styled-components';
import { Card } from 'antd';

const shadowColor = '#E2E4EE';
const shadow = `0 0px 20px ${shadowColor}`;
const alertBorderColor = '#F69F8E';
const tipsBorderColor = '#ffbc41';
const notificationBorderColor = '#8FD89F';
const themeWhite = '#FFFFFF';

export const CardStyles = styled(Card)`
	${({ type }) => {
		switch (type) {
			case 'primary':
				return `border-radius: 15px;
                        box-shadow: ${shadow};
                        background: ${({ theme }) => theme.primaryColor};`;
			case 'secondary':
				return `border-radius: 15px;
                        box-shadow: ${shadow};
                        background: ${({ theme }) => theme.secondaryColor};`;
			case 'tertiary':
				return `border-radius: 15px;
                        box-shadow: ${shadow};
                        background: ${({ theme }) => theme.tertiaryColor};`;
			case 'alert':
				return `border-radius: 5px;
                        box-shadow: none;
                        background: ${themeWhite};
                        border-left: 16px solid ${alertBorderColor};`;
			case 'notification':
				return `border-radius: 5px;
                        box-shadow: none;
                        background: ${themeWhite};
                        border-left: 16px solid ${notificationBorderColor};`;
			case 'tips':
				return `border-radius: 5px;
                        box-shadow: none;
                        background: ${themeWhite};
                        border-left: 16px solid ${tipsBorderColor};`;
			case 'timer':
				return `border-radius: 5px;
                        box-shadow: none;
                        background: ${themeWhite};
                        border-left: 16px solid ${tipsBorderColor};`;
			default:
				return `border-radius: 15px;
                        box-shadow: ${shadow};
                        background: ${themeWhite};

                        .ant-card-head-title {
                            padding: 16px 0px;
                        };

                        .ant-card-head {
                            border-bottom:3px solid #F2F2FC;
                        };`;
		}
	}};
`;
