import * as actionTypes from './actionTypes';

//GET

export const getCategoriesStartAction = () => ({
	type: actionTypes.GET_CATEGORİES,
});

export const getCategoriesSuccessAction = (categories) => ({
	type: actionTypes.GET_CATEGORİES_SUCCESS,
	payload: categories,
});

export const getCategoriesFailAction = (error) => ({
	type: actionTypes.GET_CATEGORİES_FAIL,
	payload: error,
});

//UPDATE

export const updateCategoryStartAction = () => ({
	type: actionTypes.UPDATE_CATEGORY,
});

export const updateCategorySuccessAction = (category) => ({
	type: actionTypes.UPDATE_CATEGORY_SUCCESS,
	payload: category,
});

export const updateCategoryFailAction = (error) => ({
	type: actionTypes.UPDATE_CATEGORY_FAIL,
	payload: error,
});

//CREATE

export const createCategoryStartAction = () => ({
	type: actionTypes.CREATE_CATEGORY,
});

export const createCategorySuccessAction = (category) => ({
	type: actionTypes.CREATE_CATEGORY_SUCCESS,
	payload: category,
});

export const createCategoryFailAction = (error) => ({
	type: actionTypes.CREATE_CATEGORY_FAIL,
	payload: error,
});

// DELETE

export const deleteCategoryStartAction = () => ({
	type: actionTypes.DELETE_CATEGORY,
});

export const deleteCategorySuccessAction = (category) => ({
	type: actionTypes.DELETE_CATEGORY_SUCCESS,
	payload: category,
});

export const deleteCategoryFailAction = (error) => ({
	type: actionTypes.DELETE_CATEGORY_FAIL,
	payload: error,
});
