import styled from 'styled-components';
import { Col, Row, Button as btn } from 'antd';
import Card from '../../../../components/Card';

export const CustomCard = styled(Card)`
	.ant-card-body {
		padding: 0px;
	}
`;

export const Panel = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	height: 170px;

	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const PanelImg = styled.img`
	width: 100%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	height: 170px;

	object-fit: cover;
	object-position: center;
`;

export const IconContainer = styled(Col)`
	width: 48px;
	border-radius: 8px;
	height: 38px;
	background-color: white;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconsWrapper = styled(Row)`
	position: absolute;
	right: 10px;
	top: 10px;
`;
export const ServiceImg = styled.img`
	width: 80%;
	height: 80%;
	display: block;
`;

export const Profile = styled(Col)`
	position: absolute;
	left: 0px;

	top: 145px;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	width: 100%;
	height: 60px;
	background-color: white;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const ProfileImg = styled.img`
	width: 38px;
	height: 36px;
`;

export const StarImg = styled.img`
	width: 20px;
	height: 20px;
	margin-left: 5px;
	margin-bottom: 3px;
	margin-right: 10px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const CardItem = styled(Row)`
	padding: 12px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const SendButton = styled(btn)`
	height: 32px !important;
	width: 100%;

	border: 2px solid #8fd89f !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #8fd89f;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;
