import { useState } from 'react';
import {
	CustomCard,
	Panel,
	PanelImg,
	Profile,
	ProfileImg,
	StarImg,
	CardItem,
	CancelButton,
	EditButton,
	SendButton,
	ServiceImg,
	IconContainer,
	IconsWrapper,
} from './styles';
import { Col } from 'antd';
import { Text } from '../../../../components/Typography/styles';
import backImg from '../../../../../Assets/components/background.jpg';
import profileImg from '../../../../../Assets/components/profile-img.png';
import starIcon from '../../../../../Assets/components/stars.svg';
import emptyMoto from '../../../../../Assets/components/moto-service-empty.svg';
import moto from '../../../../../Assets/components/moto-service.svg';
import emptyPacket from '../../../../../Assets/components/packet-service-empty.svg';
import packet from '../../../../../Assets/components/packet-service.svg';
import SuspendModal from '../../components/SuspendModal';
import TerminateModal from '../../components/TerminateModal';

const RestaurantsCard = ({ setRestPage, store, setStoreId }) => {
	const [isSuspendModalVisible, setIsSuspendModalVisible] = useState(false);
	const [isTerminateModalVisible, setIsTerminateModalVisible] = useState(false);

	const handleCancel = () => {
		setIsTerminateModalVisible(false);
		setIsSuspendModalVisible(false);
	};

	const { delivery_type } = store.package_setting;

	return (
		<CustomCard>
			<Panel style={{ padding: '0px', position: 'relative' }}>
				<IconsWrapper>
					<IconContainer style={{ backgroundColor: delivery_type === '1' ? '#8FD89F' : '#fff' }}>
						<ServiceImg src={delivery_type === '1' ? emptyPacket : packet} alt='' />
					</IconContainer>
					<IconContainer style={{ marginLeft: '8px', backgroundColor: delivery_type === '2' ? '#8FD89F' : '#fff' }}>
						<ServiceImg src={delivery_type === '2' ? emptyMoto : moto} alt='' />
					</IconContainer>
				</IconsWrapper>

				<PanelImg src={store.background !== null ? store.background : backImg} alt='' />
			</Panel>
			<Profile span={6} offset={1}>
				<ProfileImg src={store.photo !== null ? store.photo : profileImg} alt='' />
			</Profile>
			<CardItem>
				<Col span={8} style={{ marginTop: '30px' }}>
					<StarImg src={starIcon} alt='' />
					<Text size='16px' weight='light'>
						{store?.avg_review?.toString().slice(0, 3)}
					</Text>
				</Col>
				<Col span={16}>
					<Col>
						<Text weight='semi-bold' size='regular'>
							{store.name !== null ? store.name : 'null'}
						</Text>
					</Col>
					<Col>
						<Text weight='light' color='smoke'>
							{store.city !== null ? store.city : 'null'} - {store.province !== null ? store.province : 'null'}
						</Text>
					</Col>
				</Col>
			</CardItem>
			<CardItem gutter={[16, 0]}>
				<Col span={12}>
					<SendButton
						type='secondary'
						onClick={() => {
							setRestPage(3);
							setStoreId(store.id);
						}}
					>
						<Text color='green' weight='semi-bold' size='nobleRegular'>
							Restoranta Git
						</Text>
					</SendButton>
				</Col>
				<Col span={12}>
					<Text
						color='smoke'
						weight='semi-bold'
						size='regular'
						style={{ textDecoration: 'line-through' }}
					>
						{store.package_setting !== null ? store.package_setting.min_order_price : '0'} TL
					</Text>
					<Text color='green' weight='semi-bold' size='regular' style={{ marginLeft: '16px' }}>
						{store.package_setting !== null ? store.package_setting.min_discounted_order_price : '0'} TL
					</Text>
				</Col>
			</CardItem>
			<CardItem gutter={[16, 0]}>
				<Col span={12}>
					<EditButton type='secondary' onClick={() => setIsSuspendModalVisible(true)}>
						<Text color='yellow' weight='semi-bold' size='nobleRegular'>
							Askıya Al
						</Text>
					</EditButton>
				</Col>
				<Col span={12}>
					<CancelButton onClick={() => setIsTerminateModalVisible(true)}>
						<Text color='red' weight='semi-bold' size='nobleRegular'>
							Sonlandır
						</Text>
					</CancelButton>
				</Col>
			</CardItem>
			<SuspendModal storeId={store.id} visible={isSuspendModalVisible} onCancel={handleCancel} />
			<TerminateModal storeId={store.id} visible={isTerminateModalVisible} onCancel={handleCancel} />
		</CustomCard>
	);
};

export default RestaurantsCard;
