import * as actions from './actions';

export const getStoreInfo = (id) => async (dispatch, getState, service) => {
	dispatch(actions.getStoreStartAction());
	await service({
		path: `store/${id}/`,
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getStoreSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getStoreFailAction(error));
		});
};
