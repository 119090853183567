import * as actionTypes from "./actionTypes";
import { message } from "antd";

export const authStartAction = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccessAction = (_userData) => {
  if (_userData?.user?.groups[0] === "Dongu User") {
    localStorage.setItem("token", _userData.token);
    localStorage.setItem("user", JSON.stringify(_userData.user));

    if (JSON.parse(localStorage.getItem("user")).stores) {
      return {
        type: actionTypes.AUTH_SUCCESS,
        userData: _userData,
      };
    }
    return {
      type: actionTypes.AUTH_SUCCESS_CUSTOMER,
      userData: _userData,
    };
  } else {
    message.error("You are not a Dongu User!");
    return {
      type: actionTypes.AUTH_FAIL_PERMISSION,
    };
  }
};

export const authFailAction = () => ({
  type: actionTypes.AUTH_FAIL,
});
