/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { PacketStyles, SupriseItemStyles, DefinedItemStyles, Location } from './styles';
import PacketItem from './PacketItem';
import { Text } from '../../../../../../components/Typography/styles';

const Packet = () => {
	const [isSuprise, setIsSuprise] = useState(false);
	const [isDefined, setIsDefined] = useState(false);
	return (
		<PacketStyles>
			<SupriseItemStyles span={24}>
				<Location>
					<Text weight='semi-bold'>Sürpriz Paketler </Text>
				</Location>
				{isSuprise && <PacketItem />}
			</SupriseItemStyles>
			<DefinedItemStyles span={24}>
				<Location>
					<Text weight='semi-bold'>Tanımlı Paketler</Text>
				</Location>
				{isDefined && <PacketItem />}
			</DefinedItemStyles>
		</PacketStyles>
	);
};
export default Packet;
