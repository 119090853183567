import * as actions from './actions';
import { message } from 'antd';

export const getBoxNames = () => async (dispatch, state, service) => {
	dispatch(actions.getBoxNamesStartAction());
	await service({
		path: 'box/box-name/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getBoxNamesSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getBoxNamesFailAction(error));
		});
};

// CREATE

export const createBoxNames = (storeId, name) => async (dispatch, state, service) => {
	dispatch(actions.createBoxNamesStartAction());
	await service({
		path: 'box/box-name/',
		method: 'POST',
		sendToken: true,
		body: {
			store: storeId,
			name: name,
		},
	})
		.then((data) => {
			dispatch(actions.createBoxNamesSuccessAction(data));
			message.success('Paket İsmi Başarı ile Eklendi !');
		})
		.catch((error) => {
			dispatch(actions.createBoxNamesFailAction(error));
			message.error('Paket İsmi Eklenirken Bir Hata Oluştu !');
		});
};

//UPDATE

export const updateBoxNames = (id, storeId, name) => async (dispatch, state, service) => {
	dispatch(actions.updateBoxNamesStartAction());
	await service({
		path: `box/box-name/${id}/`,
		method: 'PUT',
		sendToken: true,
		body: {
			store: storeId,
			name: name,
		},
	})
		.then((data) => {
			dispatch(actions.updateBoxNamesSuccessAction(data));
			message.success('Paket İsmi Başarı ile Güncellendi !');
		})
		.catch((error) => {
			dispatch(actions.updateBoxNamesFailAction(error));
			message.error('Paket İsmi Güncellenirken Bir Hata Oluştu !');
		});
};
