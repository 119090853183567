import * as actionTypes from './actionTypes';

export const patchAuthUserStartAction = () => ({
	type: actionTypes.PATCH_AUTH_USER_START,
});

export const patchAuthUserSuccessAction = (user) => ({
	type: actionTypes.PATCH_AUTH_USER_SUCCESS,
	payload: user,
});

export const patchAuthUserFailAction = (error) => ({
	type: actionTypes.PATCH_AUTH_USER_FAIL,
	payload: error,
});
