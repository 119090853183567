import * as actions from './actions';

export const getRestaurantStatistics = () => async (dispatch, getState, service) => {
	dispatch(actions.getRestaurantStatisticsStartAction());
	await service({
		path: 'superadmin/statistics/store-statistics/',
		method: 'GET',
		sendToken: true,
	})
		.then((data) => {
			dispatch(actions.getRestaurantStatisticsSuccessAction(data));
		})
		.catch((error) => {
			dispatch(actions.getRestaurantStatisticsFailAction(error));
		});
};
