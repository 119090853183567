import { useState } from 'react';
import {
  InfoContent,
  InfoItem,
  CancelButton,
  SendButton,
  Input,
  EditButton,
  Upload,
  Logo,
  Button,
  LeftSide,
  ImgContent,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import AddImage from '../../../../../Assets/components/add-button.png';
import restLogo from '../../../../../Assets/components/restaurant_logo.png';
import backLogo from '../../../../../Assets/components/background.jpg';
import {
  deletePendingStore,
  getPendings,
  updatePendingStore,
} from '../../../../../services/Redux/PendingRestaurants/api';

const StoreModal = ({ store }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [logo, setLogo] = useState(store ? store.photo : restLogo);
  const [logoFile, setLogoFile] = useState();
  const [background, setBackground] = useState(
    store ? store.background : backLogo
  );
  const [backgroundFile, setBackgroundFile] = useState();
  const [name, setName] = useState(store ? store.name : 'null');
  const [web, setWeb] = useState(store ? store.website_link : 'null');
  const [address, setAddress] = useState(store ? store.address : 'null');
  const [city, setCity] = useState(store ? store.city : 'null');
  const [phoneOne, setPhoneOne] = useState(store ? store.phone_number : 'null');
  const [phoneTwo, setPhoneTwo] = useState(
    store ? store.phone_number_2 : 'null'
  );
  const [postCode, setPostCode] = useState(store ? store.post_code : 'null');
  const [email, setEmail] = useState(store ? store.email : 'null');

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info, setter, fileSetter) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
    fileSetter(info.file.originFileObj);
  };

  const updateStore = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('post_code', postCode);
    formData.append('city', city);
    formData.append('phone_number', phoneOne);
    formData.append('phone_number_2', phoneTwo);
    formData.append('email', email);
    formData.append('website_link', web);
    if (logoFile) {
      formData.append('photo', logoFile);
    }
    if (backgroundFile) {
      formData.append('background', backgroundFile);
    }

    await dispatch(updatePendingStore(store.id, formData));
    dispatch(getPendings());
  };

  const deleteStore = async () => {
    await dispatch(deletePendingStore(store.id));
    dispatch(getPendings());
  };

  return (
    <InfoContent>
      <Col span={24}>
        <Text weight="bold">Mağaza Bilgisi:</Text>
      </Col>
      <LeftSide span={3}>
        <Text weight="bold">Logo</Text>
        <ImgContent span={12}>
          <Logo src={logo} alt="logo" />
          <Upload
            beforeUpload={beforeUpload}
            onChange={(info) => {
              handleChange(info, setLogo, setLogoFile);
            }}
            showUploadList={false}
          >
            <Button disabled={disabled}>
              <img src={AddImage} alt="" />
              <Text size="regular" family="secondary">
                Ekle
              </Text>
            </Button>
          </Upload>
        </ImgContent>
        <ImgContent span={12} style={{ marginTop: '22px' }}>
          <Text weight="bold">Arka Plan</Text>
          <Logo src={background} alt="background-image" />
          <Upload
            onChange={(info) => {
              handleChange(info, setBackground, setBackgroundFile);
            }}
            showUploadList={false}
          >
            <Button disabled={disabled}>
              <img src={AddImage} alt="" />
              <Text size="regular" family="secondary">
                Ekle
              </Text>
            </Button>
          </Upload>
        </ImgContent>
      </LeftSide>

      <Col span={20} offset={1}>
        <InfoItem>
          <Col span={11}>
            <Text weight="bold">İsim</Text>
            <Input
              disabled={disabled}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col span={5} offset={8}>
            <Text weight="bold">Web Sitesi</Text>
            <Input
              disabled={disabled}
              value={web}
              onChange={(e) => setWeb(e.target.value)}
            />
          </Col>
        </InfoItem>

        <InfoItem>
          <Col span={11}>
            <Text weight="bold">Adres</Text>
            <Input
              disabled={disabled}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Col>
          <Col span={5} offset={2}>
            <Text weight="bold">Bölge</Text>
            <Input disabled={disabled} />
          </Col>
          <Col span={5} offset={1}>
            <Text weight="bold">Şehir</Text>
            <Input
              disabled={disabled}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Col>
        </InfoItem>

        <InfoItem>
          <Col span={5}>
            <Text weight="bold">Telefon 1</Text>
            <Input
              disabled={disabled}
              value={phoneOne}
              onChange={(e) => setPhoneOne(e.target.value)}
            />
          </Col>
          <Col span={5} offset={1}>
            <Text weight="bold">Telefon 2</Text>
            <Input
              disabled={disabled}
              value={phoneTwo}
              onChange={(e) => setPhoneTwo(e.target.value)}
            />
          </Col>
          <Col span={5} offset={2}>
            <Text weight="bold">Email</Text>
            <Input
              disabled={disabled}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col span={5} offset={1}>
            <Text weight="bold">Posta Kodu</Text>
            <Input
              disabled={disabled}
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </Col>
        </InfoItem>

        <InfoItem gutter={[32, 0]}>
          <Col span={5} offset={9}>
            <CancelButton onClick={() => deleteStore()}>
              <Text color="red" weight="semi-bold" size="nobleRegular">
                İptal Et
              </Text>
            </CancelButton>
          </Col>
          <Col span={5}>
            <EditButton type="secondary" onClick={() => setDisabled(!disabled)}>
              <Text color="yellow" weight="semi-bold" size="nobleRegular">
                Düzenle
              </Text>
            </EditButton>
          </Col>
          <Col span={5}>
            <SendButton type="secondary" onClick={() => updateStore()}>
              <Text color="white" weight="semi-bold" size="nobleRegular">
                Onayla
              </Text>
            </SendButton>
          </Col>
        </InfoItem>
      </Col>
    </InfoContent>
  );
};

export default StoreModal;
