import { Col, Row, Modal, Input as input, Button as btn } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../../components/Button';

export const CustomModal = styled(Modal)`
	.ant-modal-header {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		border-bottom: 3px solid ${({ theme }) => theme.borderColor};
	}

	.ant-modal-content {
		border-radius: 15px;
	}

	.ant-modal-footer {
		display: none;
		.ant-btn {
			display: none;
		}
		background: ${({ theme }) => theme.borderColor};
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
`;

export const FoodItemStyles = styled(Col)`
	padding-bottom: 20px;
	padding-top: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
export const Content = styled(Row)`
	.avatar-uploader > .ant-upload {
		width: 84px;
		height: 84px;
	}
`;
export const ContentTop = styled(Col)`
	margin-top: 32px;
`;
export const ContentBottom = styled(Col)``;
export const Footer = styled(Row)`
	padding-top: 20px;
`;

export const FoodImg = styled.img`
	width: 60px;
	height: 100%;
`;

export const Input = styled(input)`
	width: 100%;
	height: 32px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	padding-right: 0px;
`;

export const CustomBtn = styled(Button)`
	padding: 7px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 32px !important;
	:hover {
		border-color: ${({ theme }) => theme.quaternaryColor};
	}
	width: 170px;
`;

export const FoodnameLabel = styled.div`
	padding: 5px 8px;
	border: 2px solid ${({ theme }) => theme.borderColor};
	margin-right: 4px;
	margin-bottom: 1px;
	border-radius: 3px;
`;

export const SendButton = styled(Button)`
	height: 32px !important;
	width: 97%;
`;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 97%;
	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const AddFoodBtn = styled(Button)`
	border: 2px solid ${({ theme }) => theme.borderColor};
	height: 34px !important;
	width: 26px !important;

	border-radius: 3px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	:hover {
		border-color: ${({ theme }) => theme.quaternaryColor};
	}
`;
