/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { Row, Col, Button as btn, Input as input, Upload as upload, Image } from 'antd';
import button from '../../../../components/Button/index';

const colorPicker = (theme, isOpen, disabled) => {
	if (disabled) {
		return theme.inactiveSecondaryColor;
	}
	if (isOpen) {
		return theme.quaternaryColor;
	}
	return theme.inactiveSecondaryColor;
};

export const InfoContent = styled(Row)`
	margin-bottom: 20px;
	padding: 24px 40px;
	border-radius: 8px;
	border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const InfoItem = styled(Row)`
	margin-top: 32px;
`;

export const Input = styled(input)`
	width: 100%;
	height: 32px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	padding-right: 0px;
`;
export const SendButton = styled(button)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
`;

export const CancelButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #f38262 !important;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 32px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;

export const Upload = styled(upload)`
	margin-left: auto;
	margin-right: auto;
`;

export const Logo = styled(Image)`
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	height: 90%;
	max-width: 220px;
	max-height: 98px;
`;

export const Button = styled(button)`
	margin-top: 12px;
	img {
		width: 20px;
		height: 20px;
		margin-right: 6px;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	width: 75% !important; //max-width:77px
`;

export const LeftSide = styled(Col)`
	margin-top: 32px;
`;

export const ImgContent = styled(Col)`
	display: flex;
	flex-direction: column;
	max-width: 100%;
`;
