import { useState } from 'react';
import { Col, Row } from 'antd';
import { FoodStyles, Location, CancelButton, InfoItem, InfoContent } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import cancelIcon from '../../../../../../../../Assets/components/cancel.svg';

const FoodCategoriesInfo = () => {
	const [isShown, setIsShown] = useState(false);
	return (
		<FoodStyles span={24}>
			<Location style={{ borderBottom: isShown && '0px' }}>
				<Col span={7} style={{ paddingBottom: '12px' }}>
					<Text weight='semi-bold'>Gıda Kategorileri</Text>
				</Col>
				<Col span={13}>
					<Text weight='light'>Ana Yemek, Vegan, Hamburger </Text>
				</Col>
				{isShown ? (
					<Col span={2} offset={2}>
						<Row justify='center' align='middle'>
							<img src={cancelIcon} alt='cancel' style={{ cursor: 'pointer' }} onClick={() => setIsShown(false)} />
						</Row>
					</Col>
				) : (
					<Col span={4}>
						<CancelButton type='secondary' onClick={() => setIsShown(true)}>
							<Text color='red' weight='semi-bold' size='nobleRegular'>
								Detaylar
							</Text>
						</CancelButton>
					</Col>
				)}
			</Location>
			<Row style={{ display: isShown ? 'block' : 'none' }}>
				<InfoContent>
					<Col span={24}>
						<InfoItem style={{ marginTop: '0px' }}>
							<Col span={24}>
								<Text weight='bold'>Mevcut Kategoriler:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									Ana Yemek, Vegan, Hamburger
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>Acık Büfe Güvenliği:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									Bu öğe açık büfeden yiyecek içeriyor.
								</Text>
							</Col>
						</InfoItem>
					</Col>

					<Col span={24}>
						<InfoItem>
							<Col span={24}>
								<Text weight='bold'>Ek Önlemler:</Text>
								<Text weight='light' style={{ marginLeft: '12px' }}>
									İşletmemiz,yiyeceklerinizi nasıl kullanacağınıza ve depolayacağınıza öneriler tanımladıysa, bunları
									aşağıdaki alanda paylaşabilirsiniz.
								</Text>
							</Col>
						</InfoItem>
					</Col>
				</InfoContent>
			</Row>

			{isShown && <Location />}
		</FoodStyles>
	);
};
export default FoodCategoriesInfo;
