/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Upload, message } from 'antd';
import {
	CustomModal,
	FoodItemStyles,
	Content,
	FoodImg,
	Input,
	ContentBottom,
	ContentTop,
	FoodnameLabel,
	SendButton,
	CancelButton,
	AddFoodBtn,
} from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import emptyImg from '../../../../../../../Assets/components/empty-file-img.svg';
import { useDispatch } from 'react-redux';
import { createMeal, getCategories, updateMeal } from '../../../../../../../services/Redux/PendingMenu/api';
import AddTagModal from './AddTagModal';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const NewMealModal = ({
	mealId,
	visible,
	onCancel,
	catId,
	storeId,
	isEdit,
	editName,
	editLabels,
	editDesc,
	editPrice,
	editImage,
}) => {
	const dispatch = useDispatch();
	const [isTagModal, setIsTagModal] = useState(false);
	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [description, setDescription] = useState('');
	const [labels, setLabels] = useState([]);

	const [image, setImage] = useState(emptyImg);
	const [imageFile, setImageFile] = useState();

	useEffect(() => {
		if (visible) {
			setName(isEdit ? editName : '');
			setPrice(isEdit ? editPrice : '');
			setDescription(isEdit ? editDesc : '');
			setLabels(isEdit ? editLabels : []);
			setImage(isEdit ? editImage : emptyImg);
		}
	}, [visible]);

	const handleCancel = () => {
		setIsTagModal(false);
	};

	const handleLabels = (addedTags) => {
		setLabels(addedTags);
	};

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	}

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleChange = (info, setter, fileSetter) => {
		getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
		fileSetter(info.file.originFileObj);
	};

	const setClear = () => {
		setLabels([]);
	};

	const createNewMeal = async () => {
		const formData = new FormData();
		formData.append('name', name);
		formData.append('description', description);
		formData.append('price', price);
		formData.append('favourite', true);
		formData.append('category', catId);
		formData.append('store', storeId);
		labels.map((element) => formData.append('tag', element.id));
		if (imageFile) {
			formData.append('photo', imageFile);
		}
		if (!isEdit && mealId === undefined) {
			await dispatch(createMeal(formData));
			dispatch(getCategories());
			setClear();
			onCancel();
		} else {
			await dispatch(updateMeal(mealId, formData));
			dispatch(getCategories());
			onCancel();
		}
	};

	return (
		<>
			<CustomModal onCancel={onCancel} visible={visible} title={<Title text='Yeni Ürün' />} width='800px'>
				<FoodItemStyles span={24}>
					<Content>
						<ContentTop span={24}>
							<Row>
								<Col span={3}>
									<Upload
										beforeUpload={beforeUpload}
										onChange={(info) => {
											handleChange(info, setImage, setImageFile);
										}}
										showUploadList={false}
									>
										<FoodImg alt='' src={image ? image : emptyImg} />
									</Upload>
								</Col>
								<Col span={21}>
									<Row>
										<Col span={24}>
											<Row gutter={[32, 0]}>
												<Col span={18}>
													<Input placeholder='Ürün Adı' value={name} onChange={(e) => setName(e.target.value)} />
												</Col>
												<Col span={6}>
													<Input
														placeholder='Fiyatı'
														value={price}
														onChange={(event) => {
															const tmp = parseInt(event.target.value);
															if (Number.isNaN(tmp)) {
																setPrice(0);
															} else {
																setPrice(parseInt(tmp));
															}
														}}
													/>
												</Col>
											</Row>
										</Col>
										<Col span={24}>
											<Row style={{ marginTop: '32px' }}>
												<Input
													placeholder='Ürün Açıklaması'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
												/>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</ContentTop>
						<ContentBottom span={24}>
							<Row style={{ marginTop: '32px' }}>
								<Col span={1}>
									<AddFoodBtn onClick={() => setIsTagModal(true)}>
										<Text weight='bold' size='noble-regular' color='yellow'>
											+
										</Text>
									</AddFoodBtn>
								</Col>
								<Col span={11}>
									<Row style={{ marginLeft: '12px' }}>
										{labels && labels.length > 0 ? (
											labels.map((item) => (
												<FoodnameLabel>
													<Text size='non-regular' family='secondary' color='smoke' weight='light'>
														{item.name}
													</Text>
												</FoodnameLabel>
											))
										) : (
											<Col span={11}>
												<Text color='smoke' family='secondary' weight='light'>
													Etiket Seç
												</Text>
											</Col>
										)}
									</Row>
								</Col>
								<Col span={12}>
									<Row align='middle' gutter={[32, 0]}>
										<Col span={12}>
											<CancelButton
												type='secondary'
												onClick={() => {
													onCancel();
													setLabels([]);
												}}
											>
												<Text color='red' weight='semi-bold' size='nobleRegular'>
													İptal Et
												</Text>
											</CancelButton>
										</Col>
										<Col span={12}>
											<SendButton type='secondary' onClick={() => createNewMeal()}>
												<Text color='white' weight='semi-bold' size='nobleRegular'>
													Onayla
												</Text>
											</SendButton>
										</Col>
									</Row>
								</Col>
							</Row>
						</ContentBottom>
					</Content>
				</FoodItemStyles>
			</CustomModal>
			<AddTagModal
				handleLabels={handleLabels}
				visible={isTagModal}
				onCancel={handleCancel}
				setVisible={setIsTagModal}
			/>
		</>
	);
};

export default NewMealModal;
