import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	categoryList: null,
	categoryError: null,

	createMeal: null,
	createMealError: null,

	updateMeal: null,
	updateMealError: null,

	deleteDraft: null,
	deleteDraftError: null,

	updatePendingMeal: null,
	updatePendingMealError: null,
};

const getCategoryStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getCategorySuccessReducer = (state, action) => ({
	...state,
	loading: false,
	categoryList: action.payload,
});

const getCategoryFailReducer = (state, action) => ({
	...state,
	loading: false,
	categoryError: action.payload,
});

//CREATE MEAL

const createMealStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createMealSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createMeal: action.payload,
});

const createMealFailReducer = (state, action) => ({
	...state,
	loading: false,
	createMealError: action.payload,
});

//UPDATE MEAL

const updateMealStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateMealSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateMeal: action.payload,
});

const updateMealFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateMealError: action.payload,
});

//DELETE MEAL

const deleteMealStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteMealSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteDraft: action.payload,
});

const deleteMealFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteDraftError: action.payload,
});

//DELETE

const deleteDraftMealStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteDraftMealSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteDraft: action.payload,
});

const deleteDraftMealFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteDraftError: action.payload,
});

//UPDATE PENDING

const updatePendingMealStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updatePendingMealSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updatePendingMeal: action.payload,
});

const updatePendingMealFailReducer = (state, action) => ({
	...state,
	loading: false,
	updatePendingMealError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CATEGORY_START:
			return getCategoryStartReducer(state);
		case actionTypes.GET_CATEGORY_SUCCESS:
			return getCategorySuccessReducer(state, action);
		case actionTypes.GET_CATEGORY_FAIL:
			return getCategoryFailReducer(state, action);
		case actionTypes.CREATE_MEAL_START:
			return createMealStartReducer(state);
		case actionTypes.CREATE_MEAL_SUCCESS:
			return createMealSuccessReducer(state, action);
		case actionTypes.CREATE_MEAL_FAIL:
			return createMealFailReducer(state, action);
		case actionTypes.UPDATE_MEAL_START:
			return updateMealStartReducer(state);
		case actionTypes.UPDATE_MEAL_SUCCESS:
			return updateMealSuccessReducer(state, action);
		case actionTypes.UPDATE_MEAL_FAIL:
			return updateMealFailReducer(state, action);

		case actionTypes.DELETE_MEAL_START:
			return deleteMealStartReducer(state);
		case actionTypes.DELETE_MEAL_SUCCESS:
			return deleteMealSuccessReducer(state, action);
		case actionTypes.DELETE_MEAL_FAIL:
			return deleteMealFailReducer(state, action);

		case actionTypes.DELETE_DRAFT_MEAL_START:
			return deleteDraftMealStartReducer(state);
		case actionTypes.DELETE_DRAFT_MEAL_SUCCESS:
			return deleteDraftMealSuccessReducer(state, action);
		case actionTypes.DELETE_DRAFT_MEAL_FAIL:
			return deleteDraftMealFailReducer(state, action);
		case actionTypes.UPDATE_PENDING_MEAL_START:
			return updatePendingMealStartReducer(state);
		case actionTypes.UPDATE_PENDING_MEAL_SUCCESS:
			return updatePendingMealSuccessReducer(state, action);
		case actionTypes.UPDATE_PENDING_MEAL_FAIL:
			return updatePendingMealFailReducer(state, action);
		default:
			return state;
	}
};
export default reducer;
