import { RestaurantInfoContent, CardTitle, TitleDivider } from './styles';
import { Row, Col } from 'antd';
import Packet from './Packet';
import Info from './Info';

const Title = ({ isPacketPanel, setIsPacketPanel }) => (
	<Row align='middle'>
		<Col span={12} onClick={() => setIsPacketPanel(true)}>
			<CardTitle weight={isPacketPanel ? 'bold' : 'light'} color={isPacketPanel ? 'red' : 'black'}>
				Paketler
			</CardTitle>
			{isPacketPanel && <TitleDivider />}
		</Col>
		<Col span={12} onClick={() => setIsPacketPanel(false)}>
			<CardTitle weight={!isPacketPanel ? 'bold' : 'light'} color={!isPacketPanel ? 'red' : 'black'}>
				Bilgiler
			</CardTitle>
			{!isPacketPanel && <TitleDivider />}
		</Col>
	</Row>
);

const RestaurantsInfo = ({ storeId, isPacketPanel, setIsPacketPanel, boxes, settings }) => {
	return (
		<RestaurantInfoContent title={<Title isPacketPanel={isPacketPanel} setIsPacketPanel={setIsPacketPanel} />}>
			{isPacketPanel ? <Packet boxes={boxes} settings={settings} /> : <Info storeId={storeId} />}
		</RestaurantInfoContent>
	);
};

export default RestaurantsInfo;
