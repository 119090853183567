import * as actionTypes from './actionTypes';

// GET
export const getSpecialReasonsStartAction = () => ({
	type: actionTypes.GET_SPECIAL__REASONS_START,
});

export const getSpecialReasonsSuccessAction = (reasons) => ({
	type: actionTypes.GET_SPECIAL_REASONS_SUCCESS,
	payload: reasons,
});

export const getSpecialReasonsFailAction = (error) => ({
	type: actionTypes.GET_SPECIAL_REASONS_FAIL,
	payload: error,
});

// CREATE

export const createSpecialReasonsStartAction = () => ({
	type: actionTypes.CREATE_SPECIAL__REASONS_START,
});

export const createSpecialReasonsSuccessAction = (reasons) => ({
	type: actionTypes.CREATE_SPECIAL_REASONS_SUCCESS,
	payload: reasons,
});

export const createSpecialReasonsFailAction = (error) => ({
	type: actionTypes.CREATE_SPECIAL_REASONS_FAIL,
	payload: error,
});

// UPDATE

export const updateSpecialReasonsStartAction = () => ({
	type: actionTypes.UPDATE_SPECIAL__REASONS_START,
});

export const updateSpecialReasonsSuccessAction = (reasons) => ({
	type: actionTypes.UPDATE_SPECIAL_REASONS_SUCCESS,
	payload: reasons,
});

export const updateSpecialReasonsFailAction = (error) => ({
	type: actionTypes.UPDATE_SPECIAL_REASONS_FAIL,
	payload: error,
});

//DELETE

export const deleteSpecialReasonsStartAction = () => ({
	type: actionTypes.DELETE_SPECIAL__REASONS_START,
});

export const deleteSpecialReasonsSuccessAction = (reasons) => ({
	type: actionTypes.DELETE_SPECIAL_REASONS_SUCCESS,
	payload: reasons,
});

export const deleteSpecialReasonsFailAction = (error) => ({
	type: actionTypes.DELETE_SPECIAL_REASONS_FAIL,
	payload: error,
});
