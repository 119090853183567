import React, { useState, useEffect } from "react";
import CategoriesStyle, {
  CardTitle,
  Content,
  Divider,
  ColorBtnContent,
  Item,
  Input,
  SendButton,
  ColorButton,
  ImgContent,
  CancelButton,
  ImgContainer,
  EditButton,
  AddButton,
  Upload,
  DeleteButton,
  ExistCategory,
} from "./styles";
import { Text } from "../../components/Typography/styles";
import { Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ImageAdd from "../../../Assets/components/add-button.png";
import {
  getFoodCategories,
  createFoodCategory,
  updateFoodCategory,
} from "../../../services/Redux/FoodCategories/api";
import AlertModal from "./AlertModal";

const Colors = [
  { key: 1, color: "#f69f8e" },
  { key: 2, color: "#8FD89F" },
  { key: 3, color: "#ffbc41" },
  { key: 4, color: "#F38262" },
];

const FoodCategories = () => {
  const dispatch = useDispatch();
  const [mealName, setMealName] = useState(null);
  const [logo, setLogo] = useState();
  const [logoFile, setLogoFile] = useState();
  const [logoColor, setLogoColor] = useState("#f69f8e");
  const [categoryToRender, setCategoryToRender] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleCancel = () => {
    setIsAlertVisible(false);
    setSelectedCategory();
  };

  useEffect(() => {
    dispatch(getFoodCategories());
  }, [dispatch]);

  const { foodCategoryList } = useSelector(
    (state) => state.foodCategoriesStore
  );

  useEffect(() => {
    setCategoryToRender(foodCategoryList);
  }, [foodCategoryList]);

  function beforeUpload(file) {
    const isSVG = file.type === "image/svg+xml";
    if (!isSVG) {
      message.error("You can only upload SVG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    if (isSVG) {
      return isSVG && isLt2M;
    } else {
      return false;
    }
  }

  function getBase64(img, callback) {
    if (img) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader?.result));
      reader?.readAsDataURL(img);
    }
  }

  const handleChange = (info, setter, fileSetter) => {
    getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
    fileSetter(info.file.originFileObj);
  };

  const handleInputChange = (e) => {
    setMealName(e.target.value);
  };

  const setEmtpy = () => {
    setLogoFile();
    setIsEdit(false);
    setMealName();
    setLogoColor("#f69f8e");
    setLogo();
  };

  const createCategory = async () => {
    const formData = new FormData();
    if (!isEdit) {
      formData.append("name", mealName);
      formData.append("color", logoColor);
      if (logoFile) {
        formData.append("photo", logoFile);
        await dispatch(createFoodCategory(formData));
        setEmtpy();
        dispatch(getFoodCategories());
      } else {
        message.error("Lütfen Resim Ekleyin !");
      }
    } else {
      formData.append("name", mealName);
      formData.append("color", logoColor);
      if (logoFile) {
        formData.append("photo", logoFile);
        await dispatch(updateFoodCategory(selectedCategory, formData));
        setEmtpy();
        dispatch(getFoodCategories());
      } else {
        await dispatch(updateFoodCategory(selectedCategory, formData));
        setEmtpy();
        dispatch(getFoodCategories());
      }
    }
  };

  const editCategory = () => {
    const selected = categoryToRender.find(({ id }) => id === selectedCategory);
    if (!selected) {
      message.error("Düzenlemek için Mevcut Bir Kategori Seçiniz. !");
    } else {
      const { name, color, photo } = selected;
      setMealName(name);
      setLogoColor(color);
      setLogo(photo);
      setIsEdit(true);
    }
  };

  const cancelCategory = () => {
    setSelectedCategory();
    setEmtpy();
  };

  return (
    <Col md={24} lg={24} xl={21} xxl={21}>
      <CategoriesStyle
        title={
          <CardTitle size="title" weight="light">
            Yemek Kategorileri
          </CardTitle>
        }
      >
        <Content>
          <Item>
            {isEdit ? (
              <Text weight="semi-bold">Kategori Düzenle:</Text>
            ) : (
              <Text weight="semi-bold">Kategori Oluştur:</Text>
            )}
          </Item>
        </Content>
        <Row>
          <Content md={12} lg={6} xl={6} style={{ marginTop: "32px" }}>
            <Item md={16} lg={22} xl={24}>
              <Text>Fotoğraf Ekle:</Text>
              <ColorBtnContent>
                <ImgContent style={{ backgroundColor: logoColor }}>
                  <img src={logo} alt="" />
                </ImgContent>
                <Upload
                  beforeUpload={beforeUpload}
                  onChange={(info) => {
                    handleChange(info, setLogo, setLogoFile);
                  }}
                  showUploadList={false}
                >
                  <AddButton>
                    <img src={ImageAdd} alt="" />
                    <Text family="secondary" weight="bold">
                      Ekle
                    </Text>
                  </AddButton>
                </Upload>
              </ColorBtnContent>
            </Item>
          </Content>
          <Content md={12} lg={6} xl={6} style={{ marginTop: "32px" }}>
            <Item md={24} lg={20} xl={16}>
              <Text>Renk Seç:</Text>
              <ColorBtnContent>
                {Colors.map(({ key, color }) => (
                  <ColorButton
                    style={{ backgroundColor: color }}
                    onClick={() => setLogoColor(color)}
                  >
                    {" "}
                  </ColorButton>
                ))}
              </ColorBtnContent>
            </Item>
          </Content>
          <Col md={24} lg={12} xl={12} xxl={12}>
            <Row>
              <Item span={24} style={{ marginTop: "32px" }}>
                <Text> Kategori İsmi:</Text>
              </Item>
            </Row>
            <Row gutter={[32, 0]}>
              <Content md={18} lg={16} xl={17} xxl={17}>
                <Input
                  placeholder="Ana Yemek"
                  value={mealName}
                  onChange={(e) => handleInputChange(e)}
                />
              </Content>
              <Content md={6} lg={8} xl={7} xxl={7}>
                <SendButton type="secondary" onClick={() => createCategory()}>
                  <Text color="white" weight="semi-bold">
                    Kaydet
                  </Text>
                </SendButton>
              </Content>
            </Row>
          </Col>
        </Row>
        <Divider />
        <ExistCategory>
          <Content>
            <Item>
              <Text weight="semi-bold">Mevcut Kategoriler:</Text>
            </Item>
          </Content>
          <Content>
            <Col span={12}>
              <Row gutter={[32, 0]}>
                {categoryToRender &&
                  categoryToRender.map(({ id, name, color, photo }) => (
                    <ImgContainer xxl={6} xl={6} lg={6} md={12}>
                      <ImgContent
                        style={{ backgroundColor: color }}
                        onClick={() => setSelectedCategory(id)}
                      >
                        <img src={photo} alt="" />
                      </ImgContent>
                      <Text style={{ marginTop: "8px" }}>{name}</Text>
                    </ImgContainer>
                  ))}
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[32, 0]}>
                <Content
                  xl={{ span: 7, offset: 10 }}
                  lg={{ span: 8, offset: 8 }}
                  md={{ span: 11, offset: 2 }}
                >
                  <CancelButton onClick={() => cancelCategory()}>
                    <Text color="red">İptal Et</Text>
                  </CancelButton>
                </Content>
                <Content xl={{ span: 7 }} lg={{ span: 8 }} md={{ span: 11 }}>
                  <EditButton onClick={() => editCategory()}>
                    <Text color="yellow"> Düzenle</Text>
                  </EditButton>
                </Content>
              </Row>
            </Col>
          </Content>
        </ExistCategory>
        <Row>
          <Col
            xxl={{ span: 4, offset: 20 }}
            xl={{ span: 4, offset: 20 }}
            lg={{ span: 6, offset: 18 }}
            md={{ span: 8, offset: 16 }}
          >
            <DeleteButton
              onClick={() => {
                if (selectedCategory !== undefined || null) {
                  setIsAlertVisible(true);
                } else {
                  message.error("Silmek İçin Bir Kategori Seçmelisiniz !");
                }
              }}
            >
              <Text color="white" weight="semi-bold">
                Kategori Sil
              </Text>
            </DeleteButton>
          </Col>
        </Row>
      </CategoriesStyle>
      <AlertModal
        categoryId={selectedCategory}
        visible={isAlertVisible}
        onCancel={handleCancel}
      />
    </Col>
  );
};

export default FoodCategories;
