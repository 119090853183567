import * as actionTypes from './actionTypes';

export const getCategoryStartAction = () => ({
	type: actionTypes.GET_CATEGORY_START,
});

export const getCategorySuccessAction = (category) => ({
	type: actionTypes.GET_CATEGORY_SUCCESS,
	payload: category,
});

export const getCategoryFailAction = (error) => ({
	type: actionTypes.GET_CATEGORY_FAIL,
	payload: error,
});

//CREATE MEAL

export const createMealStartAction = () => ({
	type: actionTypes.CREATE_MEAL_START,
});

export const createMealSuccessAction = (meal) => ({
	type: actionTypes.CREATE_MEAL_SUCCESS,
	payload: meal,
});

export const createMealFailAction = (error) => ({
	type: actionTypes.CREATE_MEAL_FAIL,
	payload: error,
});

// UPDATE MEAL

export const updateMealStartAction = () => ({
	type: actionTypes.UPDATE_MEAL_START,
});

export const updateMealSuccessAction = (meal) => ({
	type: actionTypes.UPDATE_MEAL_SUCCESS,
	payload: meal,
});

export const updateMealFailAction = (error) => ({
	type: actionTypes.UPDATE_MEAL_FAIL,
	payload: error,
});

// DELETE MEAL

export const deleteMealStartAction = () => ({
	type: actionTypes.DELETE_MEAL_START,
});

export const deleteMealSuccessAction = (meal) => ({
	type: actionTypes.DELETE_MEAL_SUCCESS,
	payload: meal,
});

export const deleteMealFailAction = (error) => ({
	type: actionTypes.DELETE_MEAL_FAIL,
	payload: error,
});

// DELETE PENDING MEAL

export const deleteDraftMealStartAction = () => ({
	type: actionTypes.DELETE_DRAFT_MEAL_START,
});

export const deleteDraftMealSuccessAction = (meal) => ({
	type: actionTypes.DELETE_DRAFT_MEAL_SUCCESS,
	payload: meal,
});

export const deleteDraftMealFailAction = (error) => ({
	type: actionTypes.DELETE_DRAFT_MEAL_FAIL,
	payload: error,
});

// UPDATE DRAFT

export const updatePendingMealStartAction = () => ({
	type: actionTypes.UPDATE_PENDING_MEAL_START,
});

export const updatePendingMealSuccessAction = (meal) => ({
	type: actionTypes.UPDATE_PENDING_MEAL_SUCCESS,
	payload: meal,
});

export const updatePendingMealFailAction = (error) => ({
	type: actionTypes.UPDATE_PENDING_MEAL_FAIL,
	payload: error,
});
