import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	helpRequests: null,
	helpRequestError: null,

	setSolved: null,
	setSolvedError: null,

	setRejected: null,
	setRejectedError: null,
};

const getHelpRequestsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getHelpRequestsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	helpRequests: action.payload.results,
});

const getHelpRequestsFailReducer = (state, action) => ({
	...state,
	loading: false,
	helpRequestError: action.payload,
});

//SET SOLVED

const setSolvedRequestStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const setSolvedRequestSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	setSolved: action.payload,
});

const setSolvedRequestFailReducer = (state, action) => ({
	...state,
	loading: false,
	setSolvedError: action.payload,
});

// SET REJECTED

const setRejectedRequestStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const setRejectedRequestSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	setRejected: action.payload,
});

const setRejectedRequestFailReducer = (state, action) => ({
	...state,
	loading: false,
	setRejectedError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_HELP_REQUESTS_START:
			return getHelpRequestsStartReducer(state);
		case actionTypes.GET_HELP_REQUESTS_SUCCESS:
			return getHelpRequestsSuccessReducer(state, action);
		case actionTypes.GET_HELP_REQUESTS_FAIL:
			return getHelpRequestsFailReducer(state, action);
		case actionTypes.SET_SOLVED_REQUEST_START:
			return setSolvedRequestStartReducer(state);
		case actionTypes.SET_SOLVED_REQUEST_SUCCESS:
			return setSolvedRequestSuccessReducer(state, action);
		case actionTypes.SET_SOLVED_REQUEST_FAIL:
			return setSolvedRequestFailReducer(state, action);
		case actionTypes.SET_REJECTED_REQUEST_START:
			return setRejectedRequestStartReducer(state);
		case actionTypes.SET_REJECTED_REQUEST_SUCCESS:
			return setRejectedRequestSuccessReducer(state, action);
		case actionTypes.SET_REJECTED_REQUEST_FAIL:
			return setRejectedRequestFailReducer(state, action);
		default:
			return state;
	}
};
export default reducer;
