import * as actions from './actions';

export const getActiveOrders = () => async (dispatch, getState, service) => {
  dispatch(actions.getActiveOrdersStartAction());
  await service({
    path: 'order/?status=1,2,3,9',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getActiveOrdersSuccessAction(data.results));
    })
    .catch((error) => {
      dispatch(actions.getActiveOrdersFailAction(error));
    });
};

export const getPassedOrders = () => async (dispatch, getState, service) => {
  dispatch(actions.getPassedOrdersStartAction());
  await service({
    path: 'order/?status=6',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getPassedOrdersSuccessAction(data.results));
    })
    .catch((error) => {
      dispatch(actions.getPassedOrdersFailAction(error));
    });
};

export const getCancelledOrders = () => async (dispatch, getState, service) => {
  dispatch(actions.getCancelledOrdersStartAction());
  await service({
    path: 'order/?status=4,5',
    method: 'GET',
    sendToken: true,
  })
    .then((data) => {
      dispatch(actions.getCancelledOrdersSuccessAction(data.results));
    })
    .catch((error) => {
      dispatch(actions.getCancelledOrdersFailAction(error));
    });
};
