export const GET_ACTIVE_USERS_START = 'GET_ACTIVE_USERS_START';
export const GET_ACTIVE_USERS_SUCCESS = 'GET_ACTIVE_USERS_SUCCESS';
export const GET_ACTIVE_USERS_FAIL = 'GET_ACTIVE_USERS_FAIL';

export const GET_SUSPEND_USERS_START = 'GET_SUSPEND_USERS_START';
export const GET_SUSPEND_USERS_SUCCESS = 'GET_SUSPEND_USERS_SUCCESS';
export const GET_SUSPEND_USERS_FAIL = 'GET_SUSPEND_USERS_FAIL';

export const CREATE_SUSPEND_USER_START = 'CREATE_SUSPEND_USER_START';
export const CREATE_SUSPEND_USER_SUCCESS = 'CREATE_SUSPEND_USER_SUCCESS';
export const CREATE_SUSPEND_USER_FAIL = 'CREATE_SUSPEND_USER_FAIL';

export const GET_TERMINATE_USERS_START = 'GET_TERMINATE_USERS_START';
export const GET_TERMINATE_USERS_SUCCESS = 'GET_TERMINATE_USERS_SUCCESS';
export const GET_TERMINATE_USERS_FAIL = 'GET_TERMINATE_USERS_FAIL';

export const CREATE_TERMINATE_USER_START = 'CREATE_TERMINATE_USER_START';
export const CREATE_TERMINATE_USER_SUCCESS = 'CREATE_TERMINATE_USER_SUCCESS';
export const CREATE_TERMINATE_USER_FAIL = 'CREATE_TERMINATE_USER_FAIL';
