import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Location = styled(Row)`
	margin-top: 32px;
	border-bottom: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const ImgContent = styled.div`
	width: 86px;
	height: 86px;
	border-radius: 50px !important;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ImgContainer = styled(Col)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 86px;
	height: 130px;
	margin-top: 32px;
`;
