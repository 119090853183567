import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	pendingList: null,
	pendingError: null,

	pendingStore: null,
	pendingStoreError: null,

	pendingPacket: null,
	pendingPacketError: null,
};

const getPendingApprovalStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getPendingApprovalSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	pendingList: action.payload,
});

const getPendingAprrovalFailReducer = (state, action) => ({
	...state,
	loading: false,
	pendingError: action.payload,
});

// UPDATE PENDİNG STORE

const updatePendingStoreStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updatePendingStoreSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	pendingStore: action.payload,
});

const updatePendingStoreFailReducer = (state, action) => ({
	...state,
	loading: false,
	pendingStoreError: action.payload,
});

// UPDATE PENDİNG STORE

const updatePendingPacketStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updatePendingPacketSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	pendingPacket: action.payload,
});

const updatePendingPacketFailReducer = (state, action) => ({
	...state,
	loading: false,
	pendingPacketError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PENDING_APPROVAL_START:
			return getPendingApprovalStartReducer(state);
		case actionTypes.GET_PENDING_APPROVAL_SUCCESS:
			return getPendingApprovalSuccessReducer(state, action);
		case actionTypes.GET_PENDING_APPROVAL_FAIL:
			return getPendingAprrovalFailReducer(state, action);
		case actionTypes.UPDATE_PENDING_STORE_START:
			return updatePendingStoreStartReducer(state);
		case actionTypes.UPDATE_PENDING_STORE_SUCCESS:
			return updatePendingStoreSuccessReducer(state, action);
		case actionTypes.UPDATE_PENDING_STORE_FAIL:
			return updatePendingStoreFailReducer(state, action);
		case actionTypes.UPDATE_PENDING_PACKET_START:
			return updatePendingPacketStartReducer(state);
		case actionTypes.UPDATE_PENDING_PACKET_SUCCESS:
			return updatePendingPacketSuccessReducer(state, action);
		case actionTypes.UPDATE_PENDING_PACKET_FAIL:
			return updatePendingPacketFailReducer(state, action);
		default:
			return state;
	}
};
export default reducer;
