import React, { useState } from 'react';
import { QuestionWrapper, AnswerWrapper, QuestionItemStyles } from './styles';
import { Text } from '../../../../components/Typography/styles';

const QuestionItem = ({ id, question, answer, setSelectedQuestion }) => {
	const [isShown, setisShown] = useState(false);

	const handleClick = (id) => {
		setisShown(!isShown);
		setSelectedQuestion(id);
	};

	return (
		<QuestionItemStyles>
			<QuestionWrapper onClick={() => handleClick(id)}>
				{isShown ? (
					<Text weight='semi-bold' color='green'>
						{question}
					</Text>
				) : (
					<Text weight='semi-bold'>{question}</Text>
				)}
			</QuestionWrapper>
			<AnswerWrapper style={{ display: isShown ? 'block' : 'none' }}>
				<Text color='smoke' family='secondary'>
					{answer}
				</Text>
			</AnswerWrapper>
		</QuestionItemStyles>
	);
};

export default QuestionItem;
