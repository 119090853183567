import { theme } from '../../themes/theme';

export const ButtonTheme = {
    Color: {
        primary: theme.primaryColor,
        secondary: theme.secondaryColor,
        tertiary: theme.tertiaryColor,
        quaternary: theme.quaternaryColor,

        hoverSecondary: '#7ED190',
        hoverQuaternary: '#F0B241',
        hoverBordered: '#EDF9F0',
        hoverTertiary: '#F5856F',
    },

    Radius: {
        primary: '5px',
    },
    Shadow: {
        primary: '0 3px 3px 0 rgba(0, 0, 0, 0.03)',
    },
};
