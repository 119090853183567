import { useState } from 'react';
import { TableStyle, Item, EditButton, CancelButton } from './styles';
import { Text } from '../../../components/Typography/styles';
import editImg from '../../../../Assets/components/edit-btn.svg';
import cancelImg from '../../../../Assets/components/cancel-btn.svg';
import { deleteRoles, getRoles } from '../../../../services/Redux/Roles/api';
import { useDispatch } from 'react-redux';
import AlertModal from '../AlertModal';
import { Row, Col } from 'antd';

const TableItem = ({ id, role, permission, editRole }) => {
	const dispatch = useDispatch();
	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const handleCancel = () => {
		setIsAlertVisible(false);
	};

	const deleteRole = async () => {
		await dispatch(deleteRoles(id));
		dispatch(getRoles());
	};

	return (
		<>
			<TableStyle>
				<Item span={2} style={{ alignItems: 'center', border: '0px', justifyContent: 'center' }}>
					<Text weight='light'>{id}</Text>
				</Item>
				<Item span={3} style={{ alignItems: 'center', justifyContent: 'start' }}>
					<Text weight='light' style={{ marginLeft: '22px' }}>
						{role}
					</Text>
				</Item>
				<Item
					md={15}
					lg={16}
					xl={16}
					xxl={17}
					style={{ alignItems: 'center', justifyContent: 'start', paddingLeft: '12px' }}
				>
					{permission.map(({ id, permission }) => (
						<Text weight='light' style={{ marginLeft: '10px' }}>
							{permission}
						</Text>
					))}
				</Item>
				<Item md={4} lg={3} xl={3} xxl={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
					<Row gutter={[16, 0]}>
						<Col span={12}>
							<EditButton src={editImg} onClick={() => editRole(id, role, permission)} />
						</Col>
						<Col span={12}>
							<CancelButton src={cancelImg} onClick={() => setIsAlertVisible(true)} />
						</Col>
					</Row>
				</Item>
			</TableStyle>
			<AlertModal deleteRole={deleteRole} visible={isAlertVisible} onCancel={handleCancel} />
		</>
	);
};

export default TableItem;
