import { useState } from 'react';
import { Row, Col } from 'antd';
import { StatsContent, StatsContentCol, CardTitle, TitleDivider } from './styles';
import DonguStats from './DonguStats';
import RestaurantStats from './RestaurantStats';

const Title = ({ page, setPage }) => (
	<Row align='middle'>
		<Col span={12} onClick={() => setPage(0)}>
			<CardTitle size='title' weight={page === 0 ? 'bold' : 'light'} color={page === 0 ? 'red' : 'black'}>
				Döngü Hakkında
			</CardTitle>
			{page === 0 && <TitleDivider />}
		</Col>
		<Col span={12} onClick={() => setPage(1)}>
			<CardTitle size='title' weight={page === 1 ? 'bold' : 'light'} color={page === 1 ? 'red' : 'black'}>
				Restorantlar Hakkında
			</CardTitle>
			{page === 1 && <TitleDivider />}
		</Col>
	</Row>
);

const Statistics = () => {
	const [page, setPage] = useState(0);

	return (
		<StatsContentCol md={24} lg={24} xl={22} xxl={22}>
			<StatsContent title={<Title page={page} setPage={setPage} />}>
				{page === 0 ? <DonguStats /> : <RestaurantStats />}
			</StatsContent>
		</StatsContentCol>
	);
};

export default Statistics;
