/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { CustomModal, CustomDivider, CustomButton, FoodnameLabel } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getFoodTags } from '../../../../../../../services/Redux/FoodTags/api';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const AddTagModal = ({ visible, onCancel, handleLabels, setVisible, labels }) => {
	const dispatch = useDispatch();
	const [tagsToRender, setTagsToRender] = useState([]);
	const [selectedTags, setSelectedTags] = useState(labels);

	useEffect(() => {
		dispatch(getFoodTags());
	}, []);

	const { foodTagsList } = useSelector((state) => state.foodTagsStore);

	useEffect(() => {
		if (foodTagsList) {
			setTagsToRender(foodTagsList);
		}
	}, [foodTagsList]);

	const selected = (tag) => {
		const findBefore = selectedTags.find((item) => item.id === tag.id);

		if (findBefore === undefined) {
			setSelectedTags([...selectedTags, { id: tag.id, name: tag.name, confirmed: tag.confirmed }]);
		} else {
			const { id } = findBefore;
			setSelectedTags(selectedTags.filter((item) => item.id !== id));
		}
	};

	return (
		<>
			<CustomModal onCancel={onCancel} visible={visible} title={<Title text='Etiketler' />}>
				<Row justify='center'>
					<Text>Lütfen eklemek istediğiniz etiketi seçiniz.</Text>
				</Row>
				<CustomDivider />
				<Row gutter={[0, 18]} justify='center'>
					{tagsToRender && tagsToRender.length > 0 ? (
						tagsToRender.map((item) =>
							selectedTags.find(({ id }) => id === item.id) ? (
								<Col span={5}>
									<FoodnameLabel className='selected-tag' onClick={() => selected(item)}>
										<Text size='non-regular' family='secondary' color='smoke' weight='light'>
											{item.name}
										</Text>
									</FoodnameLabel>
								</Col>
							) : (
								<Col span={5}>
									<FoodnameLabel className='unselected-tag' onClick={() => selected(item)}>
										<Text size='non-regular' family='secondary' color='smoke' weight='light'>
											{item.name}
										</Text>
									</FoodnameLabel>
								</Col>
							),
						)
					) : (
						<div>nodata</div>
					)}
				</Row>
				<CustomDivider />
				<Row justify='center'>
					<Col span={12}>
						<CustomButton type='quaternary' onClick={() => setVisible(false)}>
							<Text weight='light'>İptal</Text>
						</CustomButton>
					</Col>
					<Col>
						<CustomButton
							type='secondary'
							onClick={() => {
								handleLabels(selectedTags);
								setVisible(false);
							}}
						>
							<Text color='white' weight='light'>
								Onay
							</Text>
						</CustomButton>
					</Col>
				</Row>
			</CustomModal>
		</>
	);
};

export default AddTagModal;
