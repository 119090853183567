export const theme = {
    primaryColor: '#F38262',
    secondaryColor: '#8FD89F',
    secondaryLightColor: '#DDF3E2',
    tertiaryColor: '#f69f8e',
    quaternaryColor: '#ffbc41',
    quinaryColor: '#E0E9F0',
    inactiveColor: '#A4B1BA',
    inactiveSecondaryColor: '#BCBCBC',
    inactiveTertiaryColor: '#424242',

    modalPrimaryColor: '#837F7F',

    contentBackground: '#F5F9FC',
    lightColor: '#FFFFFF',

    labelBackground: '#F5F9FC',
    borderColor: '#F2F2FC',
    borderSecondaryColor: '#F5BE5A',
    layoutStyles: {
        headerColor: '#FFFFFF',
        footerColor: '#2D483B',
        footerColorDark: '#2C4137',

        headerHeight: '90px',
        footerHeight: '360px',
        contentHeight: '100vh',

        headerPaddingVertical: '18px',
        paddingHorizontal: '120px',
        paddingTop: '45px',
    },
};
