import styled from 'styled-components';
import { Row, Col, Divider as divider, Input as input, Upload as upload, Image, Form as form, Select } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../components/Typography/styles';
import button from '../../../components/Button/index';
import card from '../../../components/Card/index';

const { TextArea } = input;

export default styled(card)`
    /* width: 100%; */

    .ant-card-head-title {
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 39px;
    }
    margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Content = styled(Row)`
    width: 100%;
    margin: 0px;
`;

export const Item = styled(Col)`
    padding: 0;
    display: flex;
    flex-direction: column;
`;

export const Input = styled(input)`
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const Divider = styled(divider)`
    margin-left: auto;
    margin-right: auto;
    width: 2px;
    height: 100%;
    background: ${({ theme }) => theme.borderColor};
`;

export const Text = styled(text)``;

export const EditButton = styled(button)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important; //32px
    width: 100%;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    border-radius: 5px;
    margin-top: 26px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
`;

export const SendButton = styled(button)`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100% !important;
    width: 100%;
    margin-top: 26px;
`;

export const Logo = styled(Image)`
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 90%;
    max-width: 220px;
    margin-top: 10px;
`;

export const Upload = styled(upload)`
    margin-left: auto;
    margin-right: auto;
`;

export const Button = styled(button)`
    margin-top: 12px;
    img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 75% !important; //max-width:77px
`;

export const Address = styled(TextArea)`
    width: 100%;
    max-width: 496px;
    border-radius: 5px;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    margin-top: 10px;
    resize: none;
`;

export const FormItem = styled(form.Item)`
    .ant-form-item-label {
        padding: 0px;
    }
`;

export const CustomSelect = styled(Select)`
    width: 100%;

    & > div {
        height: 48px !important;
        border-radius: 4px !important;

        & > span {
            line-height: 48px !important;
            font-size: 14px !important;
            font-family: 'Montserrat', 'Roboto';
        }

        & > CustomOption {
            color: black;
            background-color: gray;
        }
    }
`;

export const CustomOption = styled(Select.Option)`
    bacground-color: red !important;

    .ant-select-item-option {
        width: 200px;
    }
`;

export const Form = styled(form)``;
