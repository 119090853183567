/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Text } from '../../../components/Typography/styles';
import search from '../../../../Assets/components/search.png';
import {
  Select,
  Header,
  Input,
  Location,
  TableStyle,
  Content,
  Footer,
  NavButton,
} from './styles';
import { Col, Row, Spin } from 'antd';
import leftArrow from '../../../../Assets/components/left-arrow.svg';
import rightArrow from '../../../../Assets/components/right-arrow.svg';
import { getTerminateUsers } from '../../../../services/Redux/Users/api';
import { useDispatch, useSelector } from 'react-redux';
import TableItem from './TableItem';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import { FilterButton } from '../../PendingRestaurants/styles';

const SearchIcon = () => (
  <Row
    align="middle"
    style={{
      height: '48px',
      width: '48px',
      backgroundColor: '#ffbc41',
      justifyContent: 'center',
      borderRadius: '50px',
      position: 'absolute',
      right: '-18px',
    }}
  >
    <img src={search} alt="search" />
  </Row>
);

const TerminateUsers = ({ users }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [city, setCity] = useState('Tüm');
  const [selectToRender, setSelectToRender] = useState([]);
  const [count] = useState(7);
  const [search, setSearch] = useState('');
  const [sortByName, setSortByName] = useState(false);

  const { xxl } = useResponsive();

  useEffect(() => {
    dispatch(getTerminateUsers());
  }, [dispatch]);

  const { terminateUsersList } = useSelector((state) => state?.usersStore);
  const provinceList = [
    ...new Set(terminateUsersList?.map((item) => item?.user?.active_address)),
  ];

  useEffect(() => {
    if (terminateUsersList) {
      setSelectToRender(terminateUsersList?.slice(0, 7));
    }
  }, [terminateUsersList]);

  useEffect(() => {
    if (city === 'Tüm') {
      return setSelectToRender(terminateUsersList);
    } else if (city === 'undefined') {
      setSelectToRender(
        terminateUsersList?.filter(
          (item) => item?.user?.active_address === null
        )
      );
    } else {
      setSelectToRender(
        terminateUsersList?.filter(
          (item) => item?.user?.active_address?.province === city
        )
      );
    }
  }, [city]);

  useEffect(() => {
    if (search !== '') {
      const searchedName = selectToRender
        ? selectToRender.filter(
            ({ user }) =>
              user?.first_name
                ?.toLowerCase()
                .includes(search?.toString().toLocaleLowerCase()) ||
              user?.last_name
                ?.toLowerCase()
                .includes(search?.toString().toLocaleLowerCase()) ||
              user?.id
                ?.toString()
                .includes(search?.toString().toLocaleLowerCase())
          )
        : setSelectToRender(terminateUsersList?.slice(0, 7));
      setSelectToRender(searchedName);
    } else {
      if (city === 'Tüm') {
        return setSelectToRender(terminateUsersList);
      } else if (city === 'undefined') {
        setSelectToRender(
          terminateUsersList?.filter((item) => item?.active_address === null)
        );
      } else {
        setSelectToRender(
          terminateUsersList?.filter(
            (item) => item?.active_address?.province === city
          )
        );
      }
    }
  }, [search]);

  function prevPage() {
    const slice = terminateUsersList?.slice(
      count * (page - 2),
      count * (page - 1)
    );
    if (slice?.length !== 0) {
      setSelectToRender(slice);
      setPage(page - 1);
    }
  }

  function nextPage() {
    const slice = terminateUsersList?.slice(count * page, count * (page + 1));
    if (slice?.length !== 0) {
      setSelectToRender(slice);
      setPage(page + 1);
    }
  }

  return (
    <Col>
      <Header>
        <Col span={10} style={{ display: 'flex' }}>
          <Select
            placeholder="Bölgeye Göre"
            value={city}
            onChange={(e) => setCity(e)}
          >
            <Option value="Tüm">Tüm</Option>
            {provinceList[0] === null ||
              provinceList.map((province) =>
                province === undefined || null ? (
                  <Option key={province} value="undefined">
                    Belirtilmemiş
                  </Option>
                ) : (
                  <Option key={province} value={province}>
                    {province}
                  </Option>
                )
              )}
          </Select>
          <FilterButton onClick={() => setSortByName(!sortByName)}>
            İsme Göre
          </FilterButton>
        </Col>
        <Col span={13}>
          <Input
            placeholder="Ara"
            suffix={<SearchIcon />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Header>
      <Location>
        <Text>Restoranlar</Text>
      </Location>
      <TableStyle>
        <Content>
          <Col span={2}>
            <Text weight="semi-bold" size="regular">
              ID:
            </Text>
          </Col>
          <Col span={4}>
            <Text weight="semi-bold" size="regular">
              {xxl ? 'İsim Soyisim/Şehir-Bölge' : 'İsim / Bölge'}
            </Text>
          </Col>
          <Col span={5}>
            <Text weight="semi-bold" size="regular">
              E-posta/Telefon
            </Text>
          </Col>
          <Col span={2}>
            <Text weight="semi-bold" size="regular">
              Durumu
            </Text>
          </Col>
          <Col span={3}>
            <Text weight="semi-bold" size="regular">
              {xxl ? 'Kayıt Tarih/Saat' : 'Kayıt'}
            </Text>
          </Col>
          <Col span={3}>
            <Text weight="semi-bold" size="regular">
              Sonlandırma Tarihi
            </Text>
          </Col>
          <Col span={3}>
            <Text weight="semi-bold" size="regular">
              Nedeni
            </Text>
          </Col>
        </Content>
        {selectToRender?.length !== 0 ? (
          selectToRender ? (
            selectToRender.map((user) => <TableItem user={user} />)
          ) : (
            <Spin />
          )
        ) : (
          <Text
            weight="semi-bold"
            color="red"
            style={{ marginTop: '32px', alignSelf: 'center' }}
          >
            Sonuç bulunamadı
          </Text>
        )}

        <Footer align="middle" justify="center">
          <NavButton
            src={leftArrow}
            alt="left-arrow"
            onClick={() => {
              if (page !== 1) {
                prevPage();
              }
            }}
          />
          <Text>{page}</Text>
          <NavButton
            src={rightArrow}
            alt="right-arrow"
            onClick={() => nextPage()}
          />
        </Footer>
      </TableStyle>
    </Col>
  );
};

export default TerminateUsers;
