import { useState } from 'react';
import { Row, Col } from 'antd';
import { HelpContent, HelpContentCol, CardTitle, TitleDivider } from './styles';
import RestaurantCard from './RestaurantCard';
import MobilCard from './MobilCard';
import useResponsive from '../../../helpers/Hooks/useMediaQuery';

const Title = ({ isCard, setCard, xxl }) => (
	<Row align='middle'>
		<Col span={12} onClick={() => setCard(true)}>
			<CardTitle size='title' weight={isCard ? 'bold' : 'light'} color={isCard ? 'red' : 'black'}>
				{xxl ? 'Restorant Paneli İçerik Oluşturma' : 'Restorant'}
			</CardTitle>
			{isCard && <TitleDivider />}
		</Col>
		<Col span={12} onClick={() => setCard(false)}>
			<CardTitle size='title' weight={!isCard ? 'bold' : 'light'} color={!isCard ? 'red' : 'black'}>
				{xxl ? 'Web/Mobil İçerik Oluşturma' : 'Web/Mobil'}
			</CardTitle>
			{!isCard && <TitleDivider />}
		</Col>
	</Row>
);

const HelpContentCreator = () => {
	const [isRestaurant, setIsRestaurant] = useState(true);

	const { xxl } = useResponsive();

	return (
		<HelpContentCol md={24} lg={24} xl={xxl ? 22 : 24} xxl={22}>
			<HelpContent title={<Title isCard={isRestaurant} setCard={setIsRestaurant} xxl={xxl} />}>
				{isRestaurant ? <RestaurantCard /> : <MobilCard />}
			</HelpContent>
		</HelpContentCol>
	);
};

export default HelpContentCreator;
