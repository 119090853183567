/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  SiderStyles,
  MenuWrapper,
  BtnContent,
  SiderButton,
  BtnText,
  Menu,
} from "./styles";
import useResponsive from "../../../../helpers/Hooks/useMediaQuery";

const Sider = ({ userData }) => {
  const permissions = userData?.admin_role?.permission[0]?.permission;
  const { SubMenu } = Menu;

  const siderData = [
    {
      key: 1,
      title: "Genel Ayarlar",
      path: "/",
      subMenu: [
        {
          key: 1.1,
          title: "Yemek Kategorileri",
          path: "/food-categories",
        },
        {
          key: 1.2,
          title: "Yemek Etiketleri",
          path: "/food-tags",
        },
        {
          key: 1.3,
          title: "Özel Sebep Ekle",
          path: "/special-reasons",
        },
      ],
    },
    {
      key: 2,
      title: "Restorantlar",
      path: "/restaurants",
      subMenu: [
        {
          key: 2.1,
          title: "Onay Bekleyenler",
          path: "/pending-restaurants",
        },
        {
          key: 2.2,
          title: "Kayıtlı Restorantlar",
          path: "/registered-restaurants",
        },
        {
          key: 2.3,
          title: "Restorant Ekle",
          path: "/add-restaurants",
        },
      ],
    },
    {
      key: 3,
      title: "Siparişler",
      path: "/orders",
    },
    {
      key: 4,
      title: "Kullanıcılar",
      path: "/users",
    },
    {
      key: 5,
      title: "İstatistikler",
      path: "stats",
    },
    {
      key: 6,
      title: "Yardım Merkezi",
      path: "/help-center",
      subMenu: [
        {
          key: 6.1,
          title: "Yardım Talepleri",
          path: "/help-requests",
        },
        {
          key: 6.2,
          title: "İçerik Oluşturucu",
          path: "/content-creator",
        },
      ],
    },
    {
      key: 7,
      title: "Yetkilendirme",
      path: "/auth",
      subMenu: [
        {
          key: 7.1,
          title: "Kullanıcılar",
          path: "/auth-users",
        },
        {
          key: 7.2,
          title: "Roller",
          path: "/auth-roles",
        },
        {
          key: 7.3,
          title: "Yetkiler",
          path: "/auth-permissions",
        },
      ],
    },
  ];

  const minorData = [
    {
      key: 1,
      title: "Genel Ayarlar",
      path: "/",
      subMenu: [
        {
          key: 1.1,
          title: "Yemek Kategorileri",
          path: "/food-categories",
        },
        {
          key: 1.2,
          title: "Yemek Etiketleri",
          path: "/food-tags",
        },
        {
          key: 1.3,
          title: "Özel Sebep Ekle",
          path: "/special-reasons",
        },
      ],
    },
    {
      key: 2,
      title: "Restorantlar",
      path: "/restaurants",
      subMenu: [
        {
          key: 2.1,
          title: "Onay Bekleyenler",
          path: "/pending-restaurants",
        },
        {
          key: 2.2,
          title: "Kayıtlı Restorantlar",
          path: "/registered-restaurants",
        },
        {
          key: 2.3,
          title: "Restorant Ekle",
          path: "/add-restaurants",
        },
      ],
    },
    {
      key: 3,
      title: "Siparişler",
      path: "/orders",
    },

    {
      key: 6,
      title: "Yardım Merkezi",
      path: "/help-center",
      subMenu: [
        {
          key: 6.1,
          title: "Yardım Talepleri",
          path: "/help-requests",
        },
        {
          key: 6.2,
          title: "İçerik Oluşturucu",
          path: "/content-creator",
        },
      ],
    },
  ];

  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const { lg } = useResponsive();
  const [showTitle, setShowTitle] = useState(!lg);

  const Button = ({ className, children, onClick, disabled }) => (
    <SiderButton className={className} onClick={onClick} disabled={disabled}>
      <BtnContent align="middle" justify="start">
        {children}
      </BtnContent>
    </SiderButton>
  );

  if (lg && showTitle) {
    setShowTitle(false);
  }

  return (
    <SiderStyles width={showTitle ? 213 : 60}>
      <MenuWrapper span={24}>
        <Row>
          {userData?.admin_role
            ? siderData.map(({ key, title, path, subMenu }) =>
                permissions?.includes(title) ? (
                  !subMenu ? (
                    <Button
                      onClick={() => history.push(path)}
                      className={pathname === path && "active-nav-item"}
                      key={key}
                      disabled={!showTitle ? true : false}
                    >
                      {showTitle && (
                        <BtnText color="white" weight="semi-bold" size="16px">
                          {title}
                        </BtnText>
                      )}
                    </Button>
                  ) : (
                    <Menu mode="inline" arrow={!showTitle} key={key}>
                      <SubMenu
                        title={showTitle ? title : ""}
                        key={key}
                        disabled={!showTitle ? true : false}
                      >
                        {subMenu.map((sub) => (
                          <Button
                            key={sub.key}
                            onClick={() => {
                              history.push(sub.path);
                            }}
                            className={
                              pathname === sub.path && "active-nav-item"
                            }
                          >
                            {showTitle && (
                              <BtnText
                                color="white"
                                weight="semi-bold"
                                size="noble-regular"
                              >
                                {sub.title}
                              </BtnText>
                            )}
                          </Button>
                        ))}
                      </SubMenu>
                    </Menu>
                  )
                ) : null
              )
            : minorData.map(({ key, title, path, subMenu }) =>
                !subMenu ? (
                  <Button
                    onClick={() => history.push(path)}
                    className={pathname === path && "active-nav-item"}
                    key={key}
                    disabled={!showTitle ? true : false}
                  >
                    {showTitle && (
                      <BtnText color="white" weight="semi-bold" size="16px">
                        {title}
                      </BtnText>
                    )}
                  </Button>
                ) : (
                  <Menu mode="inline" arrow={!showTitle} key={key}>
                    <SubMenu
                      title={showTitle ? title : ""}
                      key={key}
                      disabled={!showTitle ? true : false}
                    >
                      {subMenu.map((sub) => (
                        <Button
                          key={sub.key}
                          onClick={() => {
                            history.push(sub.path);
                          }}
                          className={pathname === sub.path && "active-nav-item"}
                        >
                          {showTitle && (
                            <BtnText
                              color="white"
                              weight="semi-bold"
                              size="noble-regular"
                            >
                              {sub.title}
                            </BtnText>
                          )}
                        </Button>
                      ))}
                    </SubMenu>
                  </Menu>
                )
              )}
        </Row>
        <Row align="middle" justify="center">
          {showTitle ? (
            <LeftOutlined
              style={{ color: "#FFFFFF", fontSize: "32px", fontWeight: "900" }}
              onClick={() => {
                setShowTitle(false);
              }}
            />
          ) : (
            <RightOutlined
              style={{ color: "#FFFFFF", fontSize: "32px", fontWeight: "900" }}
              onClick={() => {
                setShowTitle(true);
              }}
            />
          )}
        </Row>
      </MenuWrapper>
    </SiderStyles>
  );
};

export default Sider;
