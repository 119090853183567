/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Location, ImgContent, ImgContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodCategories } from '../../../../../../services/Redux/FoodCategories/api';
import { Text } from '../../../../../components/Typography/styles';

const RestaurantsCategories = ({ storeId, categories }) => {
	const dispatch = useDispatch();
	const [categoryToRender, setCategoryToRender] = useState();
	const [categoryId, setCategoryId] = useState([]);
	useEffect(() => {
		dispatch(getFoodCategories());
	}, [dispatch]);

	const { foodCategoryList } = useSelector((state) => state.foodCategoriesStore);

	useEffect(() => {
		setCategoryToRender(foodCategoryList);
		setCategoryId(categories.map(({ id }) => id));
	}, [foodCategoryList]);

	return (
		<Row>
			<Col span={24}>
				<Location>
					<Text>Kategoriler</Text>
				</Location>
			</Col>
			<Col span={16}>
				<Row gutter={[32, 0]}>
					{categoryToRender &&
						categoryToRender.map(
							({ id, name, color, photo }) =>
								categoryId.includes(id) && (
									<ImgContainer span={4}>
										<ImgContent style={{ backgroundColor: color }}>
											<img src={photo} alt='photos' />
										</ImgContent>
										<Text style={{ marginTop: '8px' }}>{name}</Text>
									</ImgContainer>
								),
						)}
				</Row>
			</Col>
		</Row>
	);
};

export default RestaurantsCategories;
