import styled from 'styled-components';
import { Row } from 'antd';

export const LoginStyles = styled(Row)`
	height: 80vh;

	.vertical-row {
		display: flex;
		flex-direction: column;
	}
`;
