import { Col, Progress } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card/index';

export const GeneralRatingCardStyles = styled(Card)`
	.ant-card-body {
		min-height: 215px;
	}
`;
export const CustomProgress = styled(Progress)`
	.ant-progress-text {
		font-size: 22px;
	}
`;
export const Content = styled(Col)`
	min-height: 215px;
	display: flex;
	flex-direction: column;
	align-items: middle;
	justify-content: space-between;
`;
