/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useState } from "react";
import { Col, message } from "antd";
import StoreCard, {
  Content,
  Item,
  CardTitle,
  Input,
  Divider,
  SendButton,
  Text,
  Upload,
  Logo,
  Button,
  Address,
  Form,
  CustomOption,
  CustomSelect
} from "./styles";
import AddImage from "../../../../Assets/add-button.png";
import { useDispatch } from "react-redux";
import { createStoreInfo } from "../../../../services/Redux/AddRestaurant/api";
import IlceMenu from "./DropdownMenu";

const StoreInfoCard = () => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState();
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [postCode, setPostCode] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
  const [description, setDescription] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [restorantEmail, setRestorantEmail] = useState();
  const [webSiteLink, setWebSiteLink] = useState("");
  const [background, setBackground] = useState();
  const [backgroundFile, setBackgroundFile] = useState();
  const [visible2, setVisible2] = useState(false);

  const handleAddressClick = () => {
    setVisible2(false);
  };

  const handleAddressChange = (flag) => {
    setVisible2(flag);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info, setter, fileSetter) => {
    getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
    fileSetter(info.file.originFileObj);
  };

  const createStoreInformation = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("post_code", values.postCode);
    formData.append("city", values.city);
    formData.append('province', province); 
    formData.append("phone_number", values.phoneNumber && '+90' + phoneNumber);
    if(phoneNumberTwo) formData.append("phone_number_2", values.phoneNumberTwo && '+90' + phoneNumberTwo);
    if(restorantEmail) formData.append("email", restorantEmail);
    if(webSiteLink) formData.append("website_link", values.website_link);
    formData.append("favorited_by", 1);
    formData.append("store_owner", ownerEmail);
    formData.append("latitude", values.latitude);
    formData.append("longitude", values.longitude);
    if(description) formData.append("description", values.description);
    if (logoFile) {
      formData.append("photo", logoFile);
    }
    if (backgroundFile) {
      formData.append("background", backgroundFile);
    }

    dispatch(createStoreInfo(formData));
  };

  const onFinish = (values) => {
    createStoreInformation(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Col md={24} lg={24} xl={22} xxl={22}>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <StoreCard title={<CardTitle>Mağaza Bilgisi</CardTitle>}>
          <Content>
            <Item span={24}>
              <Text color="smoke" family="secondary">
                Mağazanız hakkındaki bilgileri buradan girebilirsiniz.
              </Text>

              <Item span={24} style={{ marginTop: "1rem" }}>
                <Text weight="semi-bold">Mağaza Detayı:</Text>
              </Item>
            </Item>
          </Content>
          <Content justify="start">
            <Item span={6} md={4} xl={5} xxl={5} style={{ marginTop: "10px" }}>
              <Text weight="semi-bold">Logo</Text>
              <Item>
                <Logo src={logo} alt="" />
                <Upload
                  beforeUpload={beforeUpload}
                  onChange={(info) => {
                    handleChange(info, setLogo, setLogoFile);
                  }}
                  showUploadList={false}
                  rules={{ required: true }}
                >
                  <Button>
                    <img src={AddImage} alt="" />
                    <Text size="description" family="secondary" weight="bold">
                      Ekle
                    </Text>
                  </Button>
                </Upload>
              </Item>
              <Text weight="semi-bold" style={{ marginTop: "10rem" }}>
                Arka Plan
              </Text>
              <Item>
                <Logo src={background} alt="" />
                <Upload
                  onChange={(info) => {
                    handleChange(info, setBackground, setBackgroundFile);
                  }}
                  showUploadList={false}
                  rules={{ required: true }}
                >
                  <Button>
                    <img src={AddImage} alt="" />
                    <Text size="description" family="secondary" weight="bold">
                      Ekle
                    </Text>
                  </Button>
                </Upload>
              </Item>
            </Item>
            <Item md={2} xl={2} xxl={2}>
              <Divider type="vertical" />
            </Item>
            <Item md={17} xl={16} xxl={16} style={{ marginTop: "1rem" }}>
              <Text weight="semi-bold">İsim</Text>

              <Form.Item
                name="name"
                rules={[{ required: true, message: "İsim boş geçilemez." }]}
              >
                <Input
                  placeholder="İsim"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>

              <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                Adres
              </Text>
              <Form.Item
                name="address"
                rules={[{ required: true, message: "Adres boş geçilemez." }]}
              >
                <Address
                  placeholder="Adres"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Item>

              <Content style={{ marginTop: "1rem" }}>
                <Item md={11} xxl={11}>
                  
                <Text weight="semi-bold">Şehir</Text>
                  <Form.Item
                    name="city"
                    rules={[
                      { required: true, message: "Şehir boş geçilemez." },
                    ]}
                  >
                    <CustomSelect
                        name="city"
                        value={city}
                        onChange={(value) => setCity(value)}>
                        <CustomOption className="here" value="İstanbul">
                          <Text size="14px" weight="400">
                              İstanbul
                          </Text>
                        </CustomOption>
                        <CustomOption value="Ankara">
                          <Text size="14px" weight="400">
                              Ankara
                          </Text>
                        </CustomOption>
                        <CustomOption value="İzmir">
                          <Text size="14px" weight="400">
                              İzmir
                          </Text>
                        </CustomOption>
                      </CustomSelect>
                  </Form.Item>

                  <Text weight="semi-bold">Posta Kodu</Text>
                  <Form.Item
                    name="postCode"
                    rules={[
                      { required: true, message: "Posta Kodu boş geçilemez." },
                    ]}
                  >
                    <Input
                      placeholder="Posta Kodu"
                      value={postCode}
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Telefon Numarası 1
                  </Text>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      { required: true, message: "Telefon boş geçilemez." },
                    ]}
                  >
                    <Input
                      type="tel"
                      bordered={false}
                      style={{ display: "flex", justifyContent: "center", alignItems: "center"}}
                      addonBefore="+90"
                      placeholder="Telefon Numarası"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Kullanıcı Bölümünde Girilen Email
                  </Text>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Geçerli bir email giriniz!",
                      },
                      {
                        required: true,
                        message: "Emailinizi giriniz!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      type="email"
                      value={ownerEmail}
                      onChange={(e) => setOwnerEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Paralel Değeri
                  </Text>
                  <Form.Item
                    name="latitude"
                    rules={[
                      { required: true, message: "Paralel değeri boş geçilemez." },
                    ]}
                  >
                    <Input
                      placeholder="Enlem Değeri"
                      type="number"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Açıklama
                  </Text>
                  <Form.Item name="description">
                    <Input
                      placeholder="Açıklama"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Item>
                  
                </Item>
                <Item
                  md={{ span: 12, offset: 1 }}
                  xxl={{ span: 11, offset: 2 }}
                >
                  <Text weight="semi-bold">İlçe</Text>
                  <Form.Item name="province">
                    <IlceMenu 
                        setProvince={setProvince} 
                        city={city} 
                        handleAddressClick={handleAddressClick} 
                        handleAddressChange={handleAddressChange} 
                        visible2={visible2} 
                        province={province} />
                  </Form.Item>

                  <Text weight="semi-bold">
                    Web Sitesi
                  </Text>
                  <Form.Item name="website_link">
                    <Input
                      value={webSiteLink}
                      onChange={(e) => setWebSiteLink(e.target.value)}
                      placeholder="ornek.com.tr"
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Telefon Numarası 2
                  </Text>
                  <Form.Item
                    name="phoneNumberTwo"
                  >
                    <Input
                      type="tel"
                      bordered={false}
                      style={{ display: "flex", justifyContent: "center", alignItems: "center"}}
                      addonBefore="+90"
                      placeholder="Telefon Numarası"
                      value={phoneNumberTwo}
                      onChange={(e) => setPhoneNumberTwo(`${e.target.value}`)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Restorant Email
                  </Text>
                  <Form.Item
                    name="email2"
                    rules={[
                      {
                        type: "email",
                        message: "Geçerli bir email giriniz!",
                      },
                      {
                        required: true,
                        message: "Emailinizi giriniz!",
                      }
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      type="email"
                      value={restorantEmail}
                      onChange={(e) => setRestorantEmail(e.target.value)}
                    />
                  </Form.Item>

                  <Text weight="semi-bold" style={{ marginTop: "1rem" }}>
                    Meridyen Değeri
                  </Text>
                  <Form.Item
                    name="longitude"
                    rules={[
                      { required: true, message: "Meridyen değeri boş geçilemez." },
                    ]}
                  >
                    <Input
                      placeholder="Enlem Değeri"
                      type="number"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </Form.Item>

                </Item>
              </Content>
            </Item>
            <Content
              justify="center"
              style={{ marginTop: "1rem" }}
              gutter={[32, 0]}
            >
              <Item xxl={6} xl={7} lg={7} md={8}>
                <Form.Item>
                  <SendButton type="secondary" htmlType="submit">
                    <Text color="white" weight="semi-bold">
                      EKLE
                    </Text>
                  </SendButton>
                </Form.Item>
              </Item>
            </Content>
          </Content>
        </StoreCard>
      </Form>
    </Col>
  );
};

export default StoreInfoCard;
