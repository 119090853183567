import { useState, useEffect } from 'react';
import { Row, Col, message, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../components/Typography/styles';
import {
	SpecialCol,
	SpecialContent,
	Content,
	CardTitle,
	Divider,
	Input,
	SendButton,
	EditButton,
	CancelButton,
	RadioBtn,
	DeleteButton,
} from './styles';
import {
	getSpecialReasons,
	createSpecialReasons,
	updateSpecialReasons,
} from '../../../services/Redux/SpecialReasons/api';
import AlertModal from './AlertModal';

const SpecialReasons = () => {
	const dispatch = useDispatch();
	const [isEdit, setIsEdit] = useState();
	const [selectedReason, setSelectedReason] = useState();
	const [reasonsToRender, setReasonsToRender] = useState();
	const [reason, setReason] = useState('');

	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const handleCancel = () => {
		setIsAlertVisible(false);
		setSelectedReason();
	};

	const onChange = (e) => {
		setSelectedReason(e.target.value);
	};

	useEffect(() => {
		dispatch(getSpecialReasons());
	}, [dispatch]);

	const { specialReasonsList } = useSelector((state) => state.specialReasonsStore);

	useEffect(() => {
		setReasonsToRender(specialReasonsList);
	}, [specialReasonsList]);

	const createReason = async () => {
		if (reason !== '' && !isEdit) {
			await dispatch(createSpecialReasons(reason));
			dispatch(getSpecialReasons());
			setReason('');
		} else if (reason !== '' && isEdit) {
			await dispatch(updateSpecialReasons(selectedReason, reason));
			dispatch(getSpecialReasons());
			setSelectedReason(null);
			setReason('');
			setIsEdit(false);
		}
	};

	const editReason = () => {
		const selected = reasonsToRender.find(({ id }) => id === selectedReason);
		if (!selected && !selectedReason) {
			message.error('Düzenlemek için Mevcut Bir Sebep Seçiniz. !');
		} else {
			const { name } = selected;
			setReason(name);
			setIsEdit(true);
		}
	};

	const cancelReason = () => {
		setSelectedReason(null);
		setReason('');
		setIsEdit(false);
	};

	const handleInputChange = (e) => {
		setReason(e.target.value);
	};

	return (
		<SpecialCol md={24} lg={24} xl={21} xxl={21}>
			<SpecialContent
				title={
					<CardTitle size='title' weight='light'>
						Özel Sebep Ekle
					</CardTitle>
				}
			>
				<Content>
					{isEdit ? <Text weight='semi-bold'>Sebep Düzenle:</Text> : <Text weight='semi-bold'>Sebep Oluştur:</Text>}
				</Content>
				<Row gutter={[32, 0]}>
					<Content md={11} lg={9} xl={8}>
						<Input placeholder='Sebep' value={reason} onChange={(e) => handleInputChange(e)} />
					</Content>
					<Content md={{ span: 6 }} lg={{ span: 5 }} xl={{ span: 4 }}>
						<SendButton type='secondary' onClick={() => createReason()}>
							<Text color='white' weight='semi-bold'>
								Kaydet
							</Text>
						</SendButton>
					</Content>
				</Row>
				<Divider />
				<Row>
					<Text weight='semi-bold'>Mevcut Sebepler:</Text>
				</Row>
				<Row>
					<Col md={12} lg={14} xl={16}>
						<Radio.Group onChange={onChange} value={selectedReason}>
							<Row style={{ marginTop: '10px' }}>
								{reasonsToRender &&
									reasonsToRender.map(({ id, name }) => (
										<Col span={12}>
											<RadioBtn value={id}>
												<Text weight='light'>{name}</Text>
											</RadioBtn>
										</Col>
									))}
							</Row>
						</Radio.Group>
					</Col>
					<Col md={12} lg={10} xl={8} style={{ marginTop: '16px' }}>
						<Row gutter={[32, 0]}>
							<Content span={12}>
								<CancelButton onClick={() => cancelReason()}>
									<Text color='red'>İptal Et</Text>
								</CancelButton>
							</Content>
							<Content span={12}>
								<EditButton onClick={() => editReason()}>
									<Text color='yellow'> Düzenle</Text>
								</EditButton>
							</Content>
						</Row>
					</Col>
				</Row>
				<Row gutter={[32, 0]} style={{ marginTop: '32px' }}>
					<Content
						md={{ span: 8, offset: 16 }}
						lg={{ span: 6, offset: 18 }}
						xl={{ span: 6, offset: 18 }}
						xxl={{ span: 5, offset: 19 }}
					>
						<DeleteButton
							onClick={() => {
								if (selectedReason !== undefined) {
									setIsAlertVisible(true);
								} else {
									message.error('Silmek İçin Bir Sebep Seçmelisiniz !');
								}
							}}
						>
							<Text color='white' weight='semi-bold'>
								Sebep Sil
							</Text>
						</DeleteButton>
					</Content>
				</Row>
			</SpecialContent>
			<AlertModal reasonId={selectedReason} visible={isAlertVisible} onCancel={handleCancel} />
		</SpecialCol>
	);
};

export default SpecialReasons;
