import { Row, Col } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card/index';

export const SaleStatsCardStyles = styled(Card)`
	.ant-card-body {
		min-height: 180px;
		padding-left: 30px;
		padding-right: 30px;
	}
`;

export const Content = styled(Col)`
	min-height: 215px;

	display: flex;
	flex-direction: column;
	align-items: middle;
	justify-content: space-between;
	padding-bottom: 45px;
	.ant-progress {
		display: flex;
	}
`;

export const DescriptionContainer = styled(Row)`
	margin-top: 32px;
`;
