/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Row } from 'antd';
import { MenuPreviewCardStyles, Header, Content, SearchInput } from './styles';
import CategoryItem from './CategoryItem';
import { Text } from '../../../../components/Typography/styles';
import searchOptic from '../../../../../Assets/components/search-optic-icon.png';

const Title = ({ text }) => (
	<Row align='middle' justify='center'>
		<Text size='title' weight='light'>
			{text}
		</Text>
	</Row>
);

const SearchPrefixImg = () => <img src={searchOptic} alt='' srcSet='' />;

const MenuPreviewCard = ({ storeName, category, storeId }) => {
	const [ownCategory, setOwnCategory] = useState([]);
	const [search, setSearch] = useState('');

	useEffect(() => {
		if (category) {
			setOwnCategory(category);
		}
	}, [category]);

	useEffect(() => {
		if (search !== '') {
			const searchedMeal = category.filter(({ name }) => name.toLowerCase().includes(search.toLocaleLowerCase()));

			setOwnCategory(searchedMeal);
		} else {
			setOwnCategory(category);
		}
	}, [search]);

	return (
		<MenuPreviewCardStyles title={<Title text={storeName + ' ' + 'Restorant Menü'} />}>
			<Header>
				<SearchInput
					placeholder='Kategori Ara'
					prefix={<SearchPrefixImg />}
					onChange={(e) => setSearch(e.target.value)}
				/>
			</Header>
			<Content>
				{ownCategory && ownCategory.length > 0 ? (
					ownCategory.map(
						({ id, name, meals, store }) =>
							store === storeName && <CategoryItem storeId={storeId} catId={id} categoryName={name} meals={meals} />,
					)
				) : (
					<Row align='middle' justify='center' style={{ marginTop: '20px' }}>
						<Text weight='semi-bold' color='smoke'>
							Kategori Bulunamadı
						</Text>
					</Row>
				)}
			</Content>
		</MenuPreviewCardStyles>
	);
};

export default MenuPreviewCard;
