/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, message, Upload } from 'antd';
import {
  FoodItemStyles,
  Content,
  FoodImg,
  Input,
  ContentBottom,
  ContentTop,
  CustomText,
  FoodnameLabel,
  EditButton,
  SendButton,
  CancelButton,
  AddFoodBtn,
} from './styles';
import { Text } from '../../../../../components/Typography/styles';
import emptyImg from '../../../../../../Assets/components/empty-img.png';
import {
  updatePendingMeal,
  getCategories,
} from '../../../../../../services/Redux/PendingMenu/api';
import { getPendings } from '../../../../../../services/Redux/PendingRestaurants/api';
import { useDispatch } from 'react-redux';
import AddTagModal from './AddTagModal';
import AlertModal from './AlertModal';

const FoodItem = ({
  id,
  name,
  description,
  tags,
  price,
  photo,
  category,
  storeId,
  setIsShown,
}) => {
  const dispatch = useDispatch();
  const [isAlertModal, setIsAlertModal] = useState(false);
  const [isTagModal, setIsTagModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [itemName, setItemName] = useState(name);
  const [itemPrice, setItemPrice] = useState(price);
  const [itemDescription, setItemDescription] = useState(description);
  const [labels, setLabels] = useState(tags);
  const [image, setImage] = useState(photo);
  const [imageFile, setImageFile] = useState();

  const handleCancel = () => {
    setIsTagModal(false);
    setIsAlertModal(false);
  };

  useEffect(() => {
    setItemName(name);
    setItemPrice(price);
    setItemDescription(description);
    setLabels(tags);
    setImage(image);
  }, [name, description, tags, photo, price, id]);

  const handleLabels = (addedTags) => {
    setLabels(addedTags);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info, setter, fileSetter) => {
    getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
    fileSetter(info.file.originFileObj);
  };

  const saveEdit = async () => {
    const formData = new FormData();
    labels.map((element) => formData.append('tag', element.id));
    formData.append('name', itemName);
    formData.append('price', itemPrice);
    formData.append('description', itemDescription);
    formData.append('category', category);
    formData.append('store', storeId);
    if (imageFile) {
      formData.append('photo', imageFile);
    }
    if (itemName === '' && itemPrice === '' && itemDescription === '') {
      message.error('Boş Alanları Doldurunuz. !');
    } else {
      await dispatch(updatePendingMeal(id, formData));
      await dispatch(getPendings());
      await dispatch(getCategories());
      setIsShown(false);
    }
  };

  return (
    <FoodItemStyles span={24}>
      <Content>
        <ContentTop span={24}>
          <Row>
            <Col span={3}>
              <Upload
                beforeUpload={beforeUpload}
                onChange={(info) => {
                  handleChange(info, setImage, setImageFile);
                }}
                showUploadList={false}
                disabled={!isEdit}
              >
                <FoodImg alt="" src={image ? image : emptyImg} />
              </Upload>
            </Col>
            <Col span={21}>
              <Row>
                <Col span={12}>
                  <Row>
                    {!isEdit ? (
                      <Text family="secondary" size="non-regular" weight="bold">
                        {name}
                      </Text>
                    ) : (
                      <Input
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                      />
                    )}
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    {!isEdit ? (
                      <Text
                        family="secondary"
                        size="non-regular"
                        color="smoke"
                        weight="light"
                      >
                        {price} TL
                      </Text>
                    ) : (
                      <Input
                        value={itemPrice}
                        onChange={(event) => {
                          const tmp = parseInt(event.target.value);
                          if (Number.isNaN(tmp)) {
                            setItemPrice(0);
                          } else {
                            setItemPrice(parseInt(tmp));
                          }
                        }}
                      />
                    )}
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    {!isEdit ? (
                      <CustomText
                        line-height="description"
                        size="non-regular"
                        family="secondary"
                        color="smoke"
                        weight="light"
                      >
                        {description}
                      </CustomText>
                    ) : (
                      <Input
                        value={itemDescription}
                        onChange={(e) => setItemDescription(e.target.value)}
                      />
                    )}
                  </Row>
                </Col>
                <Col span={11} style={{ marginTop: '5px' }}>
                  <Row gutter={[0, 18]}>
                    {labels && labels.length > 0 ? (
                      labels.map((item) => (
                        <FoodnameLabel>
                          <Text
                            size="non-regular"
                            family="secondary"
                            color="smoke"
                            weight="light"
                          >
                            {item.name}
                          </Text>
                        </FoodnameLabel>
                      ))
                    ) : (
                      <Text color="smoke" fontStyle="italic" weight="light">
                        Etiket Ekleyiniz
                      </Text>
                    )}
                  </Row>
                </Col>
                <Col span={1}>
                  <AddFoodBtn
                    disabled={!isEdit}
                    onClick={() => setIsTagModal(true)}
                  >
                    <Text weight="bold" size="noble-regular" color="yellow">
                      +
                    </Text>
                  </AddFoodBtn>
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentTop>
        <ContentBottom span={24}>
          <Row>
            <Col span={18} offset={6}>
              <Row align="middle" gutter={[32, 0]}>
                <Col span={8}>
                  <CancelButton
                    type="secondary"
                    onClick={() => setIsAlertModal(true)}
                  >
                    <Text color="red" weight="semi-bold" size="nobleRegular">
                      İptal Et
                    </Text>
                  </CancelButton>
                </Col>
                <Col span={8}>
                  <EditButton
                    type="secondary"
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    <Text color="yellow" weight="semi-bold" size="nobleRegular">
                      Düzenle
                    </Text>
                  </EditButton>
                </Col>
                <Col span={8}>
                  <SendButton type="secondary" onClick={() => saveEdit()}>
                    <Text color="white" weight="semi-bold" size="nobleRegular">
                      Onayla
                    </Text>
                  </SendButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentBottom>
      </Content>
      <AddTagModal
        handleLabels={handleLabels}
        visible={isTagModal}
        onCancel={handleCancel}
        setVisible={setIsTagModal}
        labels={labels}
      />
      <AlertModal
        visible={isAlertModal}
        onCancel={handleCancel}
        storeId={id}
        setIsShown={setIsShown}
      />
    </FoodItemStyles>
  );
};

export default FoodItem;
