import styled from 'styled-components';
import Card from '../../components/Card/index';
import { between } from 'polished';
import { Text as text } from '../../components/Typography/styles';
import { Input as input, Button as btn, Row } from 'antd';
import button from '../../components/Button/index';

export const AuthPermissionContent = styled(Card)`
	width: 100%;

	.ant-card-head-title {
		text-align: center;
	}
	.ant-card-body {
		padding-left: 39px;
		padding-right: 41px;
	}
	margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
	font-size: ${between('14px', '22px')};
`;

export const Item = styled(Row)`
	margin-top: 32px;
`;
export const Content = styled(Row)`
	margin-top: 32px;
	margin-bottom: 16px;
	display: flex;
	align-content: center;
`;

export const TableStyle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	border-radius: 0 0 2px 2px;
	background: ${({ theme }) => theme.lightColor};
`;

export const Input = styled(input)`
	width: 100%;
	height: 48px;
	border-radius: 5px;
	border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
`;

export const SendButton = styled(button)`
	height: 48px !important;
	width: 100%;
`;

export const CancelButton = styled(btn)`
	height: 48px !important;
	width: 100%;

	border: 2px solid #f38262;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #f38262;
		transition: 0.2s;
	}
`;

export const EditButton = styled(btn)`
	height: 48px !important;
	width: 100%;
	margin-top: 32px;
	border: 2px solid #ffbc41;
	border-radius: 5px;

	&.ant-btn:hover,
	&.ant-btn:focus {
		border-color: #ffbc41;
		transition: 0.2s;
	}
`;
