import { message } from 'antd';
import * as actionTypes from './actionTypes';

// CREATE USER

export const createUserStartAction = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccessAction = (user) => {
  message.success('İçerik Başarı ile Oluşturuldu !');
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    payload: user,
  };
};

export const createUserFailAction = (error) => {
  error.email
    ? message.error('Bu emaile sahip bir kullanıcı zaten var.')
    : message.error('Bu telefon numarasına sahip bir kullanıcı zaten var.');
  return {
    type: actionTypes.CREATE_USER_FAIL,
    payload: error,
  };
};

// CREATE STORE

export const createStoreStartAction = () => ({
  type: actionTypes.CREATE_STORE_START,
});

export const createStoreSuccessAction = (store) => {
  message.success('İçerik Başarı ile Oluşturuldu !');
  return {
    type: actionTypes.CREATE_STORE_SUCCESS,
    payload: store,
  };
};

export const createStoreFailAction = (error) => {
  error.phone_number &&
    message.error('Bu telefon numarasına sahip bir mağaza zaten var.');
  error.message === 'Durum: 500, Sistemde bir hata oluştu' &&
    message.error('İlerlemek için lütfen bir resim ekleyiniz.');
  Array.isArray(error) && message.error(error[0]);
  return {
    type: actionTypes.CREATE_STORE_FAIL,
    payload: error,
  };
};

// CREATE Package

export const createPackageStartAction = () => ({
  type: actionTypes.CREATE_PACKAGE_START,
});

export const createPackageSuccessAction = (pack) => {
  message.success('İçerik Başarı ile Oluşturuldu !');
  return {
    type: actionTypes.CREATE_PACKAGE_SUCCESS,
    payload: pack,
  };
};

export const createPackageFailAction = (error) => {
  error.delivery_type && message.error('Lütfen teslimat türünü seçiniz.');
  error.open_buffet &&
    message.error('Lütfen size uyan seçeneği işaretleyiniz.');
  Array.isArray(error) && message.error(error[0]);
  return {
    type: actionTypes.CREATE_PACKAGE_FAIL,
    payload: error,
  };
};

// CREATE Type

export const createTypeStartAction = () => ({
  type: actionTypes.CREATE_TYPE_START,
});

export const createTypeSuccessAction = (pack) => {
  message.success('İçerik Başarı ile Oluşturuldu !');
  return {
    type: actionTypes.CREATE_TYPE_SUCCESS,
    payload: pack,
  };
};

export const createTypeFailAction = (error) => {
  // message.error(error);
  Array.isArray(error) && message.error(error[0]);
  return {
    type: actionTypes.CREATE_TYPE_FAIL,
    payload: error,
  };
};

// CREATE Category

export const createCategoryStartAction = () => ({
  type: actionTypes.CREATE_CATEGORY_START,
});

export const createCategorySuccessAction = (pack) => {
  message.success('İçerik Başarı ile Oluşturuldu !');
  return {
    type: actionTypes.CREATE_CATEGORY_SUCCESS,
    payload: pack,
  };
};

export const createCategoryFailAction = (error) => {
  typeof error === 'string' && message.error(error.split('detail: ')[1]);
  message.error('Gönderdiğiniz e-maile kayıtlı restoran sahibi bulunamadı!');
  return {
    type: actionTypes.CREATE_CATEGORY_FAIL,
    payload: error,
  };
};
