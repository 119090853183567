import * as actionTypes from './actionTypes';

const initialState = {
	user: null,
	signedIn: false,
	loading: false,
	restaurantInfo: null,
	message: "",
};

const authStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const authFailReducer = (state, action) => ({
	...state,
	loading: false,
});

const authFailPermissionReducer = (state, action) => ({
	...state,
	loading: false,
	message: "You are not a Dongu User"
});

const authSuccessReducer = (state, action) => ({
	...state,
	user: action.userData,
	restaurantInfo: action.userData.user.stores[0],
	signedIn: true,
	loading: false,
});

const authSuccessCustomerReducer = (state, action) => ({
	...state,
	user: action.userData,
	signedIn: true,
	loading: false,
});

const authLogOutReducer = (state, action) => ({
	...state,
	user: null,
	signedIn: false,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return authStartReducer(state, action);
		case actionTypes.AUTH_FAIL:
			return authFailReducer(state, action);
		case actionTypes.AUTH_FAIL_PERMISSION:
			return authFailPermissionReducer(state, action);
		case actionTypes.AUTH_SUCCESS:
			return authSuccessReducer(state, action);
		case actionTypes.AUTH_LOG_OUT:
			return authLogOutReducer(state, action);
		case actionTypes.AUTH_SUCCESS_CUSTOMER:
			return authSuccessCustomerReducer(state, action);
		default:
			return state;
	}
};

export default reducer;
