import * as actionTypes from './actionTypes';

const initialState = {
	loading: null,
	specialReasonsList: null,
	specialReasonsError: null,

	createSpecialReasons: null,
	createSpecialReasonsError: null,

	updateSpecialReasons: null,
	updateSpecialReasonsError: null,

	deleteSpecialReasons: null,
	deleteSpecialReasonsError: null,
};

const getSpecialReasonsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const getSpecialReasonsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	specialReasonsList: action.payload.results,
});

const getSpecialReasonsFailReducer = (state, action) => ({
	...state,
	loading: false,
	specialReasonsError: action.payload,
});

// CREATE

const createSpecialReasonsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const createSpecialReasonsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	createSpecialReasons: action.payload,
});

const createSpecialReasonsFailReducer = (state, action) => ({
	...state,
	loading: false,
	createSpecialReasonsError: action.payload,
});

// UPDATE

const updateSpecialReasonsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const updateSpecialReasonsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	updateSpecialReasons: action.payload,
});

const updateSpecialReasonsFailReducer = (state, action) => ({
	...state,
	loading: false,
	updateSpecialReasonsError: action.payload,
});

// DELETE

const deleteSpecialReasonsStartReducer = (state, action) => ({
	...state,
	loading: true,
});

const deleteSpecialReasonsSuccessReducer = (state, action) => ({
	...state,
	loading: false,
	deleteSpecialReasons: action.payload,
});

const deleteSpecialReasonsFailReducer = (state, action) => ({
	...state,
	loading: false,
	deleteSpecialReasonsError: action.payload,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SPECIAL__REASONS_START:
			return getSpecialReasonsStartReducer(state);
		case actionTypes.GET_SPECIAL_REASONS_SUCCESS:
			return getSpecialReasonsSuccessReducer(state, action);
		case actionTypes.GET_SPECIAL_REASONS_FAIL:
			return getSpecialReasonsFailReducer(state, action);
		case actionTypes.CREATE_SPECIAL__REASONS_START:
			return createSpecialReasonsStartReducer(state);
		case actionTypes.CREATE_SPECIAL_REASONS_SUCCESS:
			return createSpecialReasonsSuccessReducer(state, action);
		case actionTypes.CREATE_SPECIAL_REASONS_FAIL:
			return createSpecialReasonsFailReducer(state, action);
		case actionTypes.UPDATE_SPECIAL__REASONS_START:
			return updateSpecialReasonsStartReducer(state);
		case actionTypes.UPDATE_SPECIAL_REASONS_SUCCESS:
			return updateSpecialReasonsSuccessReducer(state, action);
		case actionTypes.UPDATE_SPECIAL_REASONS_FAIL:
			return updateSpecialReasonsFailReducer(state, action);
		case actionTypes.DELETE_SPECIAL__REASONS_START:
			return deleteSpecialReasonsStartReducer(state);
		case actionTypes.DELETE_SPECIAL_REASONS_SUCCESS:
			return deleteSpecialReasonsSuccessReducer(state, action);
		case actionTypes.DELETE_SPECIAL_REASONS_FAIL:
			return deleteSpecialReasonsFailReducer(state, action);
		default:
			return state;
	}
};

export default reducer;
