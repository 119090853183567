import * as actionTypes from './actionTypes';

const initialState = {
  loading: null,
  activeUsersList: null,
  activeUsersListError: null,

  suspendUsersList: null,
  suspendUsersError: null,

  createSuspend: null,
  createSuspendError: null,

  terminateUsersList: null,
  terminateUsersError: null,

  createTerminate: null,
  createTerminateError: null,
};

const getActiveUsersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getActiveUsersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  activeUsersList: action.payload,
});

const getActiveUsersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  activeUsersListError: action.payload,
});

const getSuspendUsersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getSuspendUsersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  suspendUsersList: action.payload,
});

const getSuspendUsersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  suspendUsersError: action.payload,
});

const createSuspendUserStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createSuspendUserSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createSuspend: action.payload,
});

const createSuspendUserFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createSuspendError: action.payload,
});

const getTerminateUsersStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const getTerminateUsersSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  terminateUsersList: action.payload,
});

const getTerminateUsersFailReducer = (state, action) => ({
  ...state,
  loading: false,
  terminateUsersError: action.payload,
});

const createTerminateUserStartReducer = (state, action) => ({
  ...state,
  loading: true,
});

const createTerminateUserSuccessReducer = (state, action) => ({
  ...state,
  loading: false,
  createTerminate: action.payload,
});

const createTerminateUserFailReducer = (state, action) => ({
  ...state,
  loading: false,
  createTerminateError: action.payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_USERS_START:
      return getActiveUsersStartReducer(state);
    case actionTypes.GET_ACTIVE_USERS_SUCCESS:
      return getActiveUsersSuccessReducer(state, action);
    case actionTypes.GET_ACTIVE_USERS_FAIL:
      return getActiveUsersFailReducer(state, action);
    case actionTypes.GET_SUSPEND_USERS_START:
      return getSuspendUsersStartReducer(state);
    case actionTypes.GET_SUSPEND_USERS_SUCCESS:
      return getSuspendUsersSuccessReducer(state, action);
    case actionTypes.GET_SUSPEND_USERS_FAIL:
      return getSuspendUsersFailReducer(state, action);
    case actionTypes.CREATE_SUSPEND_USER_START:
      return createSuspendUserStartReducer(state);
    case actionTypes.CREATE_SUSPEND_USER_SUCCESS:
      return createSuspendUserSuccessReducer(state, action);
    case actionTypes.CREATE_SUSPEND_USER_FAIL:
      return createSuspendUserFailReducer(state, action);
    case actionTypes.GET_TERMINATE_USERS_START:
      return getTerminateUsersStartReducer(state);
    case actionTypes.GET_TERMINATE_USERS_SUCCESS:
      return getTerminateUsersSuccessReducer(state, action);
    case actionTypes.GET_TERMINATE_USERS_FAIL:
      return getTerminateUsersFailReducer(state, action);
    case actionTypes.CREATE_TERMINATE_USER_START:
      return createTerminateUserStartReducer(state);
    case actionTypes.CREATE_TERMINATE_USER_SUCCESS:
      return createTerminateUserSuccessReducer(state, action);
    case actionTypes.CREATE_TERMINATE_USER_FAIL:
      return createTerminateUserFailReducer(state, action);
    default:
      return state;
  }
};
export default reducer;
