import React, { useState } from 'react';
import { CancelButton, CustomModal, Input, SendButton } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import {
  createTerminateUser,
  getActiveUsers,
} from '../../../../../services/Redux/Users/api';

const Title = ({ text }) => (
  <Text weight="light" size="title">
    {text}
  </Text>
);

const TerminateModal = ({ userId, onCancel, visible }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');

  const createTerminate = async () => {
    if (description === '') {
      message.error(' Sebep alanı boş geçilemez !');
    } else {
      await dispatch(createTerminateUser(userId, description));
      setDescription('');
      await dispatch(getActiveUsers());
      onCancel();
    }
  };

  return (
    <CustomModal
      onCancel={onCancel}
      visible={visible}
      title={<Title text="Sonlandırma Nedeni" />}
    >
      <Text weight="light">
        Aşağıda yapacağınız sonlandırma nedeni hesab sahibine bilgilendirme
        olarak iletilecektir.
      </Text>
      <Row style={{ marginTop: '48px' }}>
        <Text weight="semi-bold" style={{ marginBottom: '8px' }}>
          Sebep
        </Text>
        <Input
          placeholder="Sebep giriniz..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Row>
      <Row style={{ marginTop: '48px', marginBottom: '48px' }}>
        <Col span={6}>
          <CancelButton onClick={onCancel}>
            <Text color="red" weight="semi-bold" size="nobleRegular">
              İptal Et
            </Text>
          </CancelButton>
        </Col>
        <Col span={6} offset={12}>
          <SendButton type="secondary" onClick={() => createTerminate()}>
            <Text color="white" weight="semi-bold">
              Kaydet
            </Text>
          </SendButton>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default TerminateModal;
