export const GET_PERMISSION_START = 'GET_PERMISSION_START';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAIL = 'GET_PERMISSION_FAIL';

export const CREATE_PERMISSION_START = 'CREATE_PERMISSION_START';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAIL = 'CREATE_PERMISSION_FAIL';

export const UPDATE_PERMISSION_START = 'UPDATE_PERMISSION_START';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAIL = 'UPDATE_PERMISSION_FAIL';

export const DELETE_PERMISSION_START = 'DELETE_PERMISSION_START';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAIL = 'DELETE_PERMISSION_FAIL';
