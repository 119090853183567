import styled from 'styled-components';
import { Row, Col, Button as btn } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../components/Typography/styles';
import Card from '../../../../components/Card/index';

export const TableStyle = styled(Row)`
  height: 64px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const Item = styled(Col)`
  display: flex !important;

  justify-content: center !important;
  height: 100%;
  border-left: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;
export const CardTitle = styled(text)`
  font-size: ${between('14px', '22px')};
`;

export const PanelButton = styled(btn)`
  height: 32px !important;
  width: 32px;
  border: 2px solid #8fd89f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #8fd89f;
    transition: 0.2s;
  }
`;
export const InfoWrapper = styled(Card)`
  width: 100%;

  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-body {
    padding-left: 39px;
    padding-right: 41px;
  }
  margin-bottom: 30px;
`;

export const InfoContent = styled(Row)`
  padding: 24px 40px;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const InfoItem = styled(Row)`
  margin-top: 32px;
`;

export const CancelButton = styled(btn)`
  height: 32px !important;
  width: 90%;

  border: 2px solid #f38262 !important;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #f38262;
    transition: 0.2s;
  }
`;

export const EditButton = styled(btn)`
  height: 32px !important;
  width: 90%;
  border: 2px solid #ffbc41;
  border-radius: 5px;

  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #ffbc41;
    transition: 0.2s;
  }
`;
