import { Row, Col, Input } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card';
import CustomBtn from '../../../../components/Button';

export const MenuPreviewCardStyles = styled(Card)`
	.ant-card-body {
		min-height: 530px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 20px;
		padding-bottom: 33px;
	}
`;

export const Header = styled(Row)`
	height: 100%;
`;

export const Content = styled(Col)`
	height: 428px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 14px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
	}

	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
		background: #ffffff;
	}
`;
export const Footer = styled(Row)`
	min-height: 50px;
	border: 1px solid purple;
`;

export const SearchInput = styled(Input)`
	width: 100%;
	padding: 8px 11px;
	border: 2px solid ${({ theme }) => theme.borderColor};
	margin-right: 35px;
	margin-left: 35px;
	margin-bottom: 15px;
	input.ant-input {
		padding-left: 15px;
	}
`;

export const Btn = styled(CustomBtn)``;
